import s from './SelectControl.module.scss'

import React from 'react'
import { Select, SelectProps } from 'antd'

/** Декоратор для `SelectControl` от `antd`, принимает такие же `props` */
export const SelectControl: React.FC<SelectProps> = React.memo(props => (
    <div className={s.wrapper}>
        <Select {...props} />
    </div>
))
