import {
    RithmicCandlesIntervalTypes,
    RithmicPriceTypes,
    RithmicTransactionTypes,
} from 'api/http/models'

export const INITIAL_TRADE_SETTINGS = {
    period: RithmicCandlesIntervalTypes.min,
}

export const RITHMIC_ORDER_TYPES = {
    [RithmicPriceTypes.MARKET]: 'Market',
    [RithmicPriceTypes.LIMIT]: 'Limit',
}

export const RITHMIC_TRANSACTION_TYPES = {
    [RithmicTransactionTypes.BUY]: 'Buy',
    [RithmicTransactionTypes.SELL]: 'Sell',
}
