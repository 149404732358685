import { ColumnsType } from 'antd/lib/table'
import { LiveTradingResponseModel } from 'api/http/models'
import dayjs from 'dayjs'

export const TIMER_COUNT = 60

export const DEAL_TABLE_COLUMNS: ColumnsType<LiveTradingResponseModel> = [
    {
        title: 'Time',
        dataIndex: 'create_date',
        key: 'create_date',
        render: date => dayjs(date).format('DD.MM.YY HH:mm:ss'),
        align: 'center',
    },
    {
        title: 'Symbol',
        dataIndex: 'symbol',
        key: 'symbol',
        align: 'center',
    },
    {
        title: 'Size',
        dataIndex: 'size',
        key: 'size',
        align: 'center',
    },
    // {
    //     title: 'Entry price',
    //     dataIndex: 'entry_price',
    //     key: 'entry_price',
    //     align: 'center',
    // },
    // {
    //     title: 'Exit price',
    //     dataIndex: 'exit_price',
    //     key: 'exit_price',
    //     align: 'center',
    // },
    {
        title: 'Market commission',
        dataIndex: 'market_commission',
        key: 'market_commission',
        align: 'center',
    },
    {
        title: 'Profit',
        dataIndex: 'profit',
        key: 'profit',
        align: 'center',
    },
]
