import { BybitLimitDto } from 'api/http/models'

/**
 * Список маркеров для тикера
 * @param limits список плечей с параметрами
 */
export const getMarks = (limits: BybitLimitDto[]) => {
    const step = limits[0].maxLeverage / 5

    const marks: { [key: number]: string } = { 1: '1x' }

    for (let i = 1; i <= 5; i++) {
        marks[step * i] = `${step * i}x`
    }

    return marks
}

export const getMaximumPosition = (limits: BybitLimitDto[], value: number) =>
    limits.find(el => value >= el.maxLeverage)?.maxLimit ||
    limits[limits.length - 1].maxLimit
