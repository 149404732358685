import React, { useCallback } from 'react'
import { TRADE_TICKERS_TABLE_COLUMNS } from './TradeTickers.consts'
import { TableAdapter } from 'shared/components'
import { TradeTickersProps } from './TradeTickers.types'
import { BinanceTickersModel } from 'api/http/models'

/** Таблица тикеров для страницы торговли */
export const TradeTickers: React.FC<TradeTickersProps> = React.memo(
    ({ tickersData, onTradeSettings, symbols }) => {
        /**
         * Обработчик одиночного нажатия на строку таблицы
         * @param record - запись в таблице на которой произошел клик
         */
        const handleRowClick = useCallback(
            ({ symbol }: BinanceTickersModel) =>
                () => {
                    onTradeSettings({
                        symbolData: symbols?.find(el => el.symbol === symbol),
                    })
                },
            [symbols, onTradeSettings]
        )

        return (
            <TableAdapter
                columns={TRADE_TICKERS_TABLE_COLUMNS}
                loading={!tickersData}
                rowClassName="pointer"
                dataSource={tickersData}
                rowKey="symbol"
                onRow={record => ({
                    onClick: handleRowClick(record),
                })}
                scroll={undefined}
                pagination={{
                    pageSize: 4,
                }}
            />
        )
    }
)
