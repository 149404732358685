import s from './BinanceAccounts.module.scss'

import React, { useCallback, useContext, useEffect, useState } from 'react'
import { LoaderContext } from 'core/context'
import { AccountService, BinanceService, BinanceSymbolsService } from 'api/http'
import { CheckboxControl, PageContent, TableAdapter } from 'shared/components'
import { getAccountsTableColumns } from './BinanceAccounts.utils'
import {
    AccountModel,
    BinanceSymbolsForSelectResponseModel,
    WithdrawStatusRequestModel,
} from 'api/http/models'
import { useQueryParams } from 'shared/hooks'
import { Button, Modal, Space } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { AccountUpdateProps } from './BinanceAccounts.types'

/** Таблица пользователей */
export const BinanceAccounts: React.FC = React.memo(() => {
    const { setLoader } = useContext(LoaderContext)
    const [accountsData, setAccountsData] = useState<AccountModel[]>()
    const { paginationOptions, queryParams, setQueryParams } = useQueryParams({
        isRun: true,
    })
    const [itemsCount, setItemsCount] = useState(0)
    const [symbols, setSymbols] =
        useState<BinanceSymbolsForSelectResponseModel[]>()

    /** Запрос за данными аккаунтов */
    const fetchAccounts = useCallback(async () => {
        try {
            setLoader(true)

            const { body, limit, skip } = queryParams

            const dataSource = await AccountService.search(skip, limit, body)

            setAccountsData(dataSource.items)
            setItemsCount(dataSource.total)
        } catch (e) {
            console.log(e)
        } finally {
            setLoader(false)
        }
    }, [queryParams, setLoader])

    const handleIsRun = useCallback(
        (value: CheckboxChangeEvent) => {
            setQueryParams(prevState => ({
                ...prevState,
                body: {
                    isRun: value.target.checked,
                },
            }))
        },
        [setLoader, setQueryParams]
    )

    /**
     * Обработчик для редактирования аккаунта
     * @param values новые настройки аккаунта
     */
    const handleUpdateAccount = useCallback(
        async ({ id, ...values }: AccountUpdateProps) => {
            try {
                setLoader(true)

                await AccountService.update(id, values)
                await fetchAccounts()
            } catch (e) {
                console.log(e)
            } finally {
                setLoader(false)
            }
        },
        [setLoader, fetchAccounts]
    )

    /**
     * Обработчик для редактирования данных Binance аккаунта
     * @param values новые настройки
     */
    const handleEditBinance = useCallback(
        async (id: number, values: WithdrawStatusRequestModel) => {
            try {
                setLoader(true)

                await BinanceService.setWithdrawStatus(id, values)
                await fetchAccounts()
            } catch (e) {
                console.log(e)
            } finally {
                setLoader(false)
            }
        },
        [setLoader, fetchAccounts]
    )

    const handleStopAll = useCallback(async () => {
        try {
            setLoader(true)

            Modal.confirm({
                title: 'Внимание!',
                content: 'Будут остановлены все роботы. Продолжить?',
                onOk: async () => {
                    await BinanceService.stopAll()
                    await fetchAccounts()
                },
            })
        } catch (e) {
            console.log(e)
        } finally {
            setLoader(false)
        }
    }, [setLoader, fetchAccounts])

    useEffect(() => {
        if (symbols) return

        const fetch = async () => {
            try {
                setLoader(true)

                await fetchAccounts()

                setSymbols(await BinanceSymbolsService.forSelect())
            } catch (e) {
                console.log(e)
            } finally {
                setLoader(false)
            }
        }

        fetch()
    }, [setLoader, fetchAccounts, symbols])

    useEffect(() => {
        fetchAccounts()
    }, [fetchAccounts, queryParams])

    return (
        <PageContent>
            <div className={s.header}>
                <h2>Binance accounts</h2>

                <Space>
                    <CheckboxControl onChange={handleIsRun} defaultChecked>
                        Показать только включенные
                    </CheckboxControl>

                    <Button danger type="primary" onClick={handleStopAll}>
                        Остановить всех
                    </Button>
                </Space>
            </div>

            <TableAdapter
                columns={getAccountsTableColumns(
                    fetchAccounts,
                    handleUpdateAccount,
                    handleEditBinance,
                    symbols
                )}
                dataSource={accountsData}
                pagination={{
                    ...paginationOptions,
                    total: itemsCount,
                }}
            />
        </PageContent>
    )
})
