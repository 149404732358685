import React from 'react'
import { ColumnsType } from 'antd/lib/table'
import { defaultRuleSortingTableNumberColumn, formatDate } from 'shared/utils'
import { ColorWrapper } from 'shared/components'
import { BybitOrderHistoryDto, BybitOrderSideTypes } from 'api/http/models'

export const ORDERS_HISTORY_TABLE_COLUMNS: ColumnsType<BybitOrderHistoryDto> = [
    {
        title: 'Time',
        dataIndex: 'time',
        key: 'time',
        render: formatDate,
        align: 'center',
        showSorterTooltip: false,
        sorter: defaultRuleSortingTableNumberColumn('time'),
        sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
        title: 'Symbol',
        dataIndex: 'symbol',
        key: 'symbol',
        align: 'center',
    },
    {
        title: 'Size',
        dataIndex: 'side',
        key: 'side',
        align: 'center',
        render: side => (
            <ColorWrapper
                text={side}
                condition={side === BybitOrderSideTypes.Buy}
            />
        ),
    },
    {
        title: 'Price',
        dataIndex: 'price',
        key: 'price',
        align: 'center',
    },
    {
        title: 'Quantity',
        dataIndex: 'size',
        key: 'size',
        render: (size, { symbol }) => `${size} ${symbol.replace('USDT', '')}`,
        align: 'center',
        showSorterTooltip: false,
        sorter: defaultRuleSortingTableNumberColumn('size'),
        sortDirections: ['descend', 'ascend'],
    },
    {
        title: 'Commission',
        dataIndex: 'commission',
        key: 'commission',
        align: 'center',
        showSorterTooltip: false,
        sorter: defaultRuleSortingTableNumberColumn('commission'),
        sortDirections: ['descend', 'ascend'],
    },
]
