import s from './RithmicStatistic.module.scss'

import React from 'react'
import { BlockWrapper, StatisticControl } from 'shared/components'
import { Col, Row } from 'antd'
import { PRECISION } from './RithmicStatistic.consts'
import { RithmicStatisticProps } from './RithmicStatistic.types'
import { getStatisticTitle } from './RithmicStatistic.utils'
import cn from 'classnames'

/** Статистика торговли на Rithmic */
export const RithmicStatistic: React.FC<RithmicStatisticProps> = React.memo(
    ({ className, statistic }) => {
        return (
            <BlockWrapper className={cn(s.wrapper, className)}>
                {getStatisticTitle(
                    <h2>Profit statistics (USD)</h2>,
                    'Profit = PNL - Rithmic commission - RealColibri commission'
                )}

                <Row gutter={20}>
                    <Col xs={12} sm={6}>
                        <StatisticControl
                            title={getStatisticTitle('Today')}
                            value={statistic?.today || '-'}
                            precision={PRECISION}
                            className={s.stat}
                        />
                    </Col>

                    <Col xs={12} sm={6}>
                        <StatisticControl
                            title={getStatisticTitle('Current month')}
                            value={statistic?.month || '-'}
                            precision={PRECISION}
                            className={s.stat}
                        />
                    </Col>

                    <Col xs={12} sm={6}>
                        <StatisticControl
                            title={getStatisticTitle('Current year')}
                            value={statistic?.year || '-'}
                            precision={PRECISION}
                            className={s.stat}
                        />
                    </Col>

                    <Col xs={12} sm={6}>
                        <StatisticControl
                            title="Selected period"
                            value={statistic?.amount || '-'}
                            precision={PRECISION}
                            className={s.stat}
                        />
                    </Col>
                </Row>
            </BlockWrapper>
        )
    }
)
