import s from './InputNumberControl.module.scss'

import React from 'react'
import { InputNumber, InputNumberProps } from 'antd'
import cn from 'classnames'

/** Декоратор для `InputNumber` от `antd`, принимает такие же `props` */
export const InputNumberControl: React.FC<InputNumberProps> = React.memo(
    ({ className, ...props }) => {
        return (
            <div className={cn(s.wrapper, className)}>
                <InputNumber {...props} />
            </div>
        )
    }
)
