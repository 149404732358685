export enum WsDataEventType {
    CandlesUpdate = 'CandlesUpdate',
    TickersUpdate = 'TickersUpdate',
    PositionsUpdate = 'PositionsUpdate',
    OpenOrdersUpdate = 'OpenOrdersUpdate',
    AccountUpdate = 'AccountUpdate',
    Error = 'Error',
}

export type WsDataResponseProps = {
    event_type: WsDataEventType
    payload: any
}

export type WebSocketResponseProps = {
    eventType: WsDataEventType
    payload: any
}
