import React, { useCallback, useEffect, useRef, useState } from 'react'
import s from './BybitTrade.module.scss'
import { PageContent } from 'shared/components'
import { Button, Col, notification, Row, Space } from 'antd'
import useWebSocket from 'react-use-websocket'
import { WebSocketResponseProps, WsDataEventType } from 'api/ws/models'
import { BYBIT_URL_WEBSOCKET } from 'api/ws'
import { useNavigate, useParams } from 'react-router-dom'
import { BarData } from 'lightweight-charts'
import { TIME_ZONE_OFFSET } from 'shared/consts'
import { TradeDiagram } from './TradeDiagram'
import { SettingProps } from './BybitTrade.types'
import { INITIAL_TRADE_SETTINGS } from './BybitTrade.consts'
import { ArrowLeftOutlined } from '@ant-design/icons'
import {
    BybitOrderDto,
    BybitOrderSideTypes,
    BybitOrderStatusType,
    BybitPositionDto,
    BybitTickersModel,
} from 'api/http/models'
import { AccountService, BybitService, BybitSymbolsService } from 'api/http'
import { TradeTickers } from './TradeTickers'
import { useFetch } from 'shared/hooks'
import { Tables } from './Tables'
import { MarketBalance } from './MarketBalance'
import { TradeTabs } from './TradeTabs'

/** Страница торговли Bybit */
export const BybitTrade: React.FC = React.memo(() => {
    const { id } = useParams<{ id: string }>()
    const accountId = Number(id)
    const navigate = useNavigate()

    const [getAccount, account] = useFetch(AccountService.get)
    const [getMarket, market] = useFetch(BybitService.getByAccount)
    const [fetchSymbols, symbols] = useFetch(BybitSymbolsService.getForSelect)
    const [settings, setSettings] = useState<SettingProps>(
        INITIAL_TRADE_SETTINGS
    )
    const [candlestickData, setCandlestickData] = useState<BarData[]>()
    const [balance, setBalance] = useState<number>()
    const [tickerLastPrice, setTickerLastPrice] = useState<number>()
    const [tickersData, setTickersData] = useState<BybitTickersModel[]>()
    const [positions, setPositions] = useState<BybitPositionDto[]>()
    const [orders, setOrders] = useState<BybitOrderDto[]>()

    const didUnmount = useRef(false)
    const { sendJsonMessage, lastJsonMessage, getWebSocket } =
        useWebSocket<WebSocketResponseProps>(BYBIT_URL_WEBSOCKET, {
            shouldReconnect: () => !didUnmount.current,
            reconnectInterval: 10000,
            queryParams: { id_: accountId },
        })

    const handleFetchMarket = useCallback(async () => {
        await getMarket(accountId)
    }, [getMarket, accountId])

    const handleChangeSettings = useCallback(
        (values: Partial<SettingProps>) => {
            setSettings(({ symbol, period }) => {
                const newSettings = {
                    symbol: values.symbol || symbol,
                    period: values.period || period,
                }

                sendJsonMessage(newSettings)

                return newSettings
            })
        },
        [sendJsonMessage]
    )

    /** Обновление данных для графика и тикеров */
    const updateTradeData = useCallback((values: WebSocketResponseProps) => {
        const { eventType, payload } = values

        switch (eventType) {
            case WsDataEventType.Error:
                notification.error({
                    message: 'Websocket error',
                    description: payload,
                })
                break
            case WsDataEventType.CandlesUpdate:
                setTickerLastPrice(Number(payload[0].close))
                setCandlestickData(
                    payload.map((el: BarData) => ({
                        ...el,
                        time: Number(el.time) + TIME_ZONE_OFFSET,
                    }))
                )
                break
            case WsDataEventType.TickersUpdate:
                setTickersData(prevState => {
                    if (prevState?.length) {
                        const index = prevState.findIndex(
                            el => el.symbol === payload.symbol
                        )
                        prevState.splice(index, Number(index !== -1), payload)
                        return [...prevState].sort((a, b) =>
                            a.symbol > b.symbol ? 1 : -1
                        )
                    }
                    return [payload]
                })
                setPositions(prevState =>
                    prevState?.reduce<BybitPositionDto[]>((acc, el) => {
                        if (el.symbol === payload.symbol) {
                            const pnlValue =
                                (payload.lastPrice - el.avgPrice) * el.size
                            return [
                                ...acc,
                                {
                                    ...el,
                                    unrealisedPnl:
                                        el.side === BybitOrderSideTypes.Sell
                                            ? pnlValue * -1
                                            : pnlValue,
                                },
                            ]
                        } else {
                            return [...acc, el]
                        }
                    }, [])
                )
                break
            case WsDataEventType.AccountUpdate:
                setBalance(payload)
                break
            case WsDataEventType.PositionsUpdate:
                setPositions(prevState => {
                    if (prevState) {
                        for (const position of payload) {
                            const index = prevState.findIndex(
                                el => el.symbol === position.symbol
                            )
                            if (!position.side) {
                                if (index !== -1) {
                                    prevState.splice(index, 1)
                                }
                            } else {
                                prevState.splice(
                                    index,
                                    Number(index !== -1),
                                    position
                                )
                            }
                        }
                        return [...prevState]
                    }

                    return payload
                })
                break
            case WsDataEventType.OpenOrdersUpdate:
                setOrders(prevState => {
                    if (prevState) {
                        for (const order of payload) {
                            const index = prevState.findIndex(
                                el => el.orderId === order.orderId
                            )
                            if (
                                [
                                    BybitOrderStatusType.Cancelled,
                                    BybitOrderStatusType.Filled,
                                    BybitOrderStatusType.Deactivated,
                                    BybitOrderStatusType.Rejected,
                                ].includes(order.status)
                            ) {
                                if (index !== -1) {
                                    prevState.splice(index, 1)
                                }
                            } else {
                                prevState.splice(
                                    index,
                                    Number(index !== -1),
                                    order
                                )
                            }
                        }
                        return [...prevState]
                    }

                    return payload
                })
                break
        }
    }, [])

    useEffect(
        () => () => {
            didUnmount.current = true
        },
        [getWebSocket]
    )

    useEffect(() => {
        if (lastJsonMessage) {
            updateTradeData(lastJsonMessage)
        }
    }, [lastJsonMessage, updateTradeData])

    useEffect(() => {
        if (accountId) {
            getAccount(accountId)
            handleFetchMarket()
            fetchSymbols()
        }
    }, [accountId, getAccount, handleFetchMarket, fetchSymbols])

    return (
        <PageContent>
            <Row gutter={20}>
                <Col xs={24} md={8} xl={6}>
                    <Row gutter={20}>
                        <Col xs={24} sm={12} md={24}>
                            <Button
                                type="link"
                                icon={<ArrowLeftOutlined />}
                                className={s.back}
                                onClick={() => {
                                    navigate(-1)
                                }}
                            >
                                back
                            </Button>

                            <Space className={s.account}>
                                <h3>{account?.username}</h3>

                                <div>{`ID: ${account?.id}`}</div>
                            </Space>

                            <hr />

                            {balance !== undefined && (
                                <MarketBalance availableBalance={balance} />
                            )}

                            <TradeTabs
                                settings={settings}
                                onSettings={handleChangeSettings}
                                marketData={market}
                                onFetchMarket={handleFetchMarket}
                                symbols={symbols}
                            />
                        </Col>

                        <Col xs={24} sm={12} md={24}>
                            <TradeTickers
                                onSettings={handleChangeSettings}
                                tickersData={tickersData}
                            />
                        </Col>
                    </Row>
                </Col>

                <Col xs={24} md={16} xl={18}>
                    <TradeDiagram
                        candlestickData={candlestickData}
                        settings={settings}
                        onChangeSettings={handleChangeSettings}
                        tickerLastPrice={tickerLastPrice}
                        position={positions?.find(
                            el => el.symbol === settings.symbol
                        )}
                        orders={orders?.filter(
                            el => el.symbol === settings.symbol
                        )}
                    />
                </Col>
            </Row>

            <Tables
                symbol={settings.symbol}
                marketId={market?.id}
                marketSymbol={
                    symbols?.find(el => el.id === market?.symbolId)?.symbol
                }
                positions={positions}
                orders={orders}
            />
        </PageContent>
    )
})
