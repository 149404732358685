import { RithmicPriceTypes } from 'api/http/models'
import { RITHMIC_ORDER_TYPES } from '../../RithmicTrade.consts'

export const TAGS = [
    {
        id: RithmicPriceTypes.MARKET,
        tagName: RITHMIC_ORDER_TYPES[RithmicPriceTypes.MARKET],
    },
    {
        id: RithmicPriceTypes.LIMIT,
        tagName: RITHMIC_ORDER_TYPES[RithmicPriceTypes.LIMIT],
    },
]
