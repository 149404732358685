import React, { useCallback, useContext, useEffect, useState } from 'react'
import s from './RobotDeals.module.scss'
import { BybitTableFilters } from './BybitTableFilters'
import { TableAdapter } from 'shared/components'
import {
    DEAL_TABLE_COLUMNS,
    QUERY_PARAMS_INITIAL_VALUES,
} from './RobotDeals.consts'
import {
    BaseSearchResponseModel,
    BybitDealDto,
    BybitDealsPeriodStatisticDto,
    BybitDealsSearchDto,
} from 'api/http/models'
import { useQueryParams } from 'shared/hooks'
import { LoaderContext } from 'core/context'
import { BybitDealsService } from 'api/http'
import { useParams } from 'react-router-dom'
import { fixedNumber } from 'shared/utils'

/** Сделки робота */
export const RobotDeals: React.FC = () => {
    const { id } = useParams<{ id: string }>()
    const { setLoader } = useContext(LoaderContext)
    const [deals, setDeals] = useState<BaseSearchResponseModel<BybitDealDto>>()
    const [periodStatistic, setPeriodStatistic] = useState<number>()
    const { paginationOptions, setQueryParams, queryParams } = useQueryParams<
        Omit<BybitDealsSearchDto, 'accountId'>
    >(QUERY_PARAMS_INITIAL_VALUES)

    const handleChangePeriod = useCallback(
        async (body: Partial<Omit<BybitDealsSearchDto, 'accountId'>>) => {
            try {
                setQueryParams(prevState => ({
                    ...prevState,
                    body,
                }))
            } catch (e) {
                console.log(e)
            }
        },
        [setQueryParams, id]
    )

    const handleFetchDeals = useCallback(async () => {
        try {
            setLoader(true)

            if (id) {
                const { body, limit, skip } = queryParams

                setDeals(
                    await BybitDealsService.search(skip, limit, {
                        ...body,
                        accountId: Number(id),
                    } as BybitDealsSearchDto)
                )

                if (body.startDate) {
                    setPeriodStatistic(
                        await BybitDealsService.getPeriodStatistic(
                            Number(id),
                            body as BybitDealsPeriodStatisticDto
                        )
                    )
                }
            }
        } catch (e) {
            console.log(e)
        } finally {
            setLoader(false)
        }
    }, [id, queryParams, setLoader])

    useEffect(() => {
        handleFetchDeals()
    }, [handleFetchDeals])

    return (
        <>
            <div className={s.header}>
                <BybitTableFilters onQueryParams={handleChangePeriod} />

                {periodStatistic && (
                    <div>
                        {`Profit for the selected period: ${fixedNumber(
                            periodStatistic
                        )} USDT`}
                    </div>
                )}
            </div>

            <TableAdapter
                columns={DEAL_TABLE_COLUMNS}
                loading={!deals}
                dataSource={deals?.items}
                pagination={{
                    ...paginationOptions,
                    total: deals?.total,
                }}
            />
        </>
    )
}
