import { BinancePositionsModel, BinanceTickersModel } from 'api/http/models'
import { WsDataResponseProps } from 'api/ws/models'

export const normalizePositionsData = (
    payload: WsDataResponseProps['payload'],
    prevData: BinancePositionsModel[]
) =>
    prevData?.reduce<BinancePositionsModel[]>((acc, el) => {
        const ticker = payload.find(
            (ticker: BinanceTickersModel) => el.symbol === ticker.symbol
        )
        if (ticker && Number(el.positionAmt)) {
            const unRealizedProfit = String(
                Number(ticker.lastPrice) - Number(el.entryPrice)
            )
            return [...acc, { ...el, unRealizedProfit }]
        }
        return [...acc, el]
    }, [])
