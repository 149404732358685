import React from 'react'
import s from './Join.module.scss'
import { GetStarted } from '../GetStarted'

//** Экран Join */
export const Join: React.FC = React.memo(() => (
    <div className={s.wrapper}>
        <h2 className={s.title}> </h2>
    </div>
))
