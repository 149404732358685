import s from './ExchangeForm.module.scss'
import Icon from '@ant-design/icons'
import { ReactComponent as TrxIcon } from 'shared/img/trx-logo.svg'
import React from 'react'
import { ReactComponent as RcbcIcon } from 'shared/img/logo.svg'

export const COINS = [
    {
        value: 'trx',
        label: (
            <>
                <Icon component={TrxIcon} className={s.icon} />

                <span>TRX</span>
            </>
        ),
        key: 'trx',
    },
    {
        value: 'rcbc',
        label: (
            <>
                <Icon component={RcbcIcon} className={s.icon} />

                <span>RCBC</span>
            </>
        ),
        key: 'rcbc',
    },
]
