import { RithmicCandlesIntervalTypes } from 'api/http/models'

export interface SettingProps {
    period?: RithmicCandlesIntervalTypes
    symbol?: string
}

export enum WsRequestEventType {
    Settings = 'Settings',
    NewOrder = 'NewOrder',
    CancelOrder = 'CancelOrder',
    CancelAllOrders = 'CancelAllOrders',
}
