import React, { useCallback, useEffect, useState } from 'react'
import { TableAdapter } from 'shared/components'
import { useQueryParams } from 'shared/hooks'
import { DealsRequestModel, DealsResponseModel } from 'api/http/models'
import { BinanceDealsService } from 'api/http'
import {
    DEAL_TABLE_COLUMNS,
    QUERY_PARAMS_INITIAL_VALUES,
} from './RobotDeals.consts'
import { BinanceTableFilters } from './BinanceTableFilters'
import { useParams } from 'react-router-dom'
import s from './RobotDeals.module.scss'
import { fixedNumber } from 'shared/utils'

/** Таблица сделок робота */
export const RobotDeals: React.FC = React.memo(() => {
    const { id } = useParams<{ id?: string }>()
    const [tableData, setTableData] = useState<DealsResponseModel[]>()
    const [itemsCount, setItemsCount] = useState(0)
    const [statistics, setStatistics] = useState<number>()
    const { paginationOptions, queryParams, setQueryParams } =
        useQueryParams<DealsRequestModel>(QUERY_PARAMS_INITIAL_VALUES)

    const handleChangePeriod = useCallback(
        async (value: Partial<Omit<DealsRequestModel, 'user_id'>>) => {
            try {
                setQueryParams(prevState => ({
                    ...prevState,
                    body: {
                        start_date: value.start_date,
                        end_date: value.end_date,
                    },
                }))

                const dataSource = await BinanceDealsService.getPeriodStatistic(
                    {
                        ...value,
                        user_id: id ? Number(id) : undefined,
                    } as DealsRequestModel
                )

                setStatistics(dataSource.amount)
            } catch (e) {
                console.log(e)
            }
        },
        [setQueryParams, id]
    )

    useEffect(() => {
        const fetch = async () => {
            try {
                const { body, limit, skip } = queryParams

                const { items, total } = await BinanceDealsService.search(
                    {
                        user_id: id ? Number(id) : undefined,
                        ...body,
                    } as DealsRequestModel,
                    skip,
                    limit
                )

                setTableData(items)
                setItemsCount(total)
            } catch (e) {
                console.log(e)
            }
        }

        fetch()
    }, [queryParams, id])

    return (
        <>
            <div className={s.header}>
                <BinanceTableFilters onQueryParams={handleChangePeriod} />

                {statistics && (
                    <div>
                        {`Profit for the selected period: ${fixedNumber(
                            statistics
                        )} USDT`}
                    </div>
                )}
            </div>

            <TableAdapter
                columns={DEAL_TABLE_COLUMNS}
                loading={!tableData}
                dataSource={tableData}
                pagination={{
                    ...paginationOptions,
                    total: itemsCount,
                }}
            />
        </>
    )
})
