import s from './BinanceSymbols.module.scss'

import React, { useCallback, useContext, useEffect, useState } from 'react'
import {
    PageContent,
    PopupAdapter,
    QuestionIconTip,
    TableAdapter,
} from 'shared/components'
import { BinanceSymbolsService } from 'api/http'
import { LoaderContext } from 'core/context'
import { useFetch, useQueryParams } from 'shared/hooks'
import { getBinanceSymbolsTableColumns } from './BinanceSymbols.utils'
import { BinanceSymbol } from './BinanceSymbol'
import { BinanceSymbolsResponseModel } from 'api/http/models'
import Paragraph from 'antd/lib/typography/Paragraph'

/** Таблица символов Binance */
export const BinanceSymbols: React.FC = React.memo(() => {
    const { setLoader } = useContext(LoaderContext)
    const [changeParamsFetch] = useFetch(BinanceSymbolsService.changeParams)
    const [getParamsFetch, candleCount] = useFetch(
        BinanceSymbolsService.getParams
    )
    const [symbolsData, setSymbolsData] =
        useState<BinanceSymbolsResponseModel[]>()
    const { paginationOptions, queryParams } = useQueryParams()
    const [itemsCount, setItemsCount] = useState<number>(0)

    /** Запрос данных таблицы */
    const fetchSymbols = useCallback(async () => {
        try {
            setLoader(true)

            const { limit, skip } = queryParams

            const dataSource = await BinanceSymbolsService.search(skip, limit)

            setSymbolsData(dataSource.items)
            setItemsCount(dataSource.total)
        } catch (e) {
            console.log(e)
        } finally {
            setLoader(false)
        }
    }, [setLoader, queryParams])

    const handleChangeParams = useCallback(
        async (value: string) => {
            const count = Number(value)
            if (Number.isInteger(count) && count > 0 && count !== candleCount) {
                await changeParamsFetch({
                    candleCount: count,
                })
                await getParamsFetch()
            }
        },
        [candleCount, changeParamsFetch, getParamsFetch]
    )

    useEffect(() => {
        fetchSymbols()
        getParamsFetch()
    }, [fetchSymbols, fetchSymbols])

    return (
        <PageContent>
            <div className={s.header}>
                <h2>Symbols for Binance</h2>

                <div>
                    <span>
                        Кол-во свечей для динамического тейк-профита&nbsp;
                        <QuestionIconTip title="Настройка примениться в начале следующей минуты после сохранения" />
                        &nbsp;:
                    </span>

                    <Paragraph
                        editable={{
                            onChange: handleChangeParams,
                            maxLength: 3,
                            text: String(candleCount || ''),
                        }}
                    >
                        {candleCount?.candleCount || 'не указано'}
                    </Paragraph>
                </div>

                <PopupAdapter
                    formId="symbolCreateForm"
                    buttonText="Добавить"
                    buttonOption={{
                        type: 'primary',
                    }}
                    modalOptions={{
                        title: 'Добавить',
                    }}
                    component={BinanceSymbol}
                    onRequestFinish={fetchSymbols}
                />
            </div>

            <TableAdapter
                columns={getBinanceSymbolsTableColumns(fetchSymbols)}
                dataSource={symbolsData}
                pagination={{
                    ...paginationOptions,
                    total: itemsCount,
                }}
            />
        </PageContent>
    )
})
