import React, { useCallback, useContext } from 'react'
import { Form } from 'antd'
import { InputControl } from 'shared/components/InputControl'
import { BinanceService, BybitService } from 'api/http'
import { LoaderContext } from 'core/context'
import { PopupAdapterFormProps } from '../PopupAdapter.types'
import { isMarketBinanceType, isMarketBybitType } from 'shared/utils'
import { BinanceCreateModel } from 'api/http/models'

/** Модалка с формой ввода ключей биржи */
export const ExchangeConnectFormModal: React.FC<PopupAdapterFormProps> =
    React.memo(({ onRequestFinish, initialValues, ...props }) => {
        const { setLoader } = useContext(LoaderContext)

        /**
         * Обработчик отправки данных на сервер
         * @param value данные формы
         */
        const handleFinish = useCallback(
            async (values: BinanceCreateModel) => {
                try {
                    setLoader(true)

                    if (isMarketBinanceType(initialValues?.id)) {
                        if (!initialValues?.marketId) {
                            await BinanceService.create(values)
                        } else if (values.accessKey || values.secretKey) {
                            await BinanceService.update(
                                initialValues.marketId,
                                values
                            )
                        } else {
                            await BinanceService.delete(initialValues.marketId)
                        }
                    }

                    if (isMarketBybitType(initialValues?.id)) {
                        if (!initialValues?.marketId) {
                            await BybitService.create(values)
                        } else if (values.accessKey || values.secretKey) {
                            await BybitService.update(
                                initialValues.marketId,
                                values
                            )
                        } else {
                            await BybitService.delete(initialValues.marketId)
                        }
                    }

                    onRequestFinish?.()
                } catch (e) {
                    console.log(e)
                } finally {
                    setLoader(false)
                }
            },
            [onRequestFinish, setLoader, initialValues]
        )

        return (
            <Form<BinanceCreateModel>
                layout="vertical"
                onFinish={handleFinish}
                {...props}
            >
                {initialValues?.type !== 'unplug' ? (
                    <>
                        <Form.Item name="accessKey" label="Access key (Login)">
                            <InputControl placeholder="Access key (Login)" />
                        </Form.Item>

                        <Form.Item
                            name="secretKey"
                            label="Secret key (Password)"
                        >
                            <InputControl placeholder="Secret key (Password)" />
                        </Form.Item>
                    </>
                ) : (
                    <div>Are you sure you want to disable the exchange?</div>
                )}
            </Form>
        )
    })
