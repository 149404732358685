import s from './BlockWrapper.module.scss'

import React from 'react'
import { BlockWrapperProps } from './BlockWrapper.types'
import cn from 'classnames'

/** Обертка блока для единообразия элементов интерфейса */
export const BlockWrapper: React.FC<BlockWrapperProps> = React.memo(
    ({ children, className, ...props }) => (
        <div className={cn(s.wrapper, className)} {...props}>
            {children}
        </div>
    )
)
