import { useCallback, useState } from 'react'
import { TablePaginationConfig } from 'antd'

export interface QueryParamsProps<T> {
    skip: number
    limit: number
    body: Partial<T>
}

export const DEFAULT_SKIP = 0
export const DEFAULT_LIMIT = 10

/**
 * Формируем начальные значения запроса
 * @param body начальные параметры запроса
 */
const getQueryParamsInitialValues = <T>(body?: T): QueryParamsProps<T> => ({
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    body: body || {},
})

/**
 * Хук для запросов данных таблицы
 * @param queryOptions параметры запроса
 */
export const useQueryParams = <T extends object>(queryOptions?: T) => {
    const [queryParams, setQueryParams] = useState<QueryParamsProps<T>>(
        getQueryParamsInitialValues<T>(queryOptions)
    )

    /** Обработчик пагинации */
    const handlePaginationOptions = useCallback(
        (page: number, pageSize: number) => {
            setQueryParams(prevState => ({
                ...prevState,
                skip: page - 1,
                limit: pageSize,
            }))
        },
        []
    )

    const paginationOptions: TablePaginationConfig = {
        onChange: handlePaginationOptions,
        onShowSizeChange: handlePaginationOptions,
        showSizeChanger: true,
        current: queryParams.skip + 1,
        pageSize: queryParams.limit,
    }

    return { paginationOptions, queryParams, setQueryParams }
}
