import React from 'react'
import { ExchangeConnectFormModal, PopupAdapter } from 'shared/components'
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import { BinanceDataModel, MarketType } from 'api/http/models'
import { FnActionProps } from 'App.types'

/** Кнопки действий для ключей */
export const getKeysActions = (
    handleFetchKeys: FnActionProps,
    data?: BinanceDataModel
) => (
    <div>
        {data?.accessKey && data?.secretKey ? (
            <>
                <PopupAdapter
                    buttonOption={{
                        type: 'link',
                        icon: <EditOutlined />,
                    }}
                    component={ExchangeConnectFormModal}
                    formOptions={{
                        initialValues: {
                            id: MarketType.BINANCE,
                            marketId: data.id,
                            type: 'change',
                        },
                    }}
                    modalOptions={{
                        okText: 'Change',
                    }}
                    formId="changeMarketFormId"
                    onRequestFinish={handleFetchKeys}
                />

                <PopupAdapter
                    buttonOption={{
                        type: 'link',
                        icon: <DeleteOutlined />,
                    }}
                    component={ExchangeConnectFormModal}
                    formOptions={{
                        initialValues: {
                            id: MarketType.BINANCE,
                            marketId: data.id,
                            type: 'unplug',
                        },
                    }}
                    modalOptions={{
                        okText: 'Remove',
                    }}
                    formId="unplugMarketFormId"
                    onRequestFinish={handleFetchKeys}
                />
            </>
        ) : (
            <PopupAdapter
                buttonOption={{
                    type: 'link',
                    icon: <PlusOutlined />,
                }}
                component={ExchangeConnectFormModal}
                formOptions={{
                    initialValues: { id: MarketType.BINANCE, type: 'plug' },
                }}
                modalOptions={{
                    okText: 'Connect',
                    cancelText: 'Cancel',
                }}
                formId="plugMarketFormId"
                onRequestFinish={handleFetchKeys}
            />
        )}
    </div>
)
