import { TradeSettingProps } from '../BinanceTrade.types'
import {
    BinancePositionsModel,
    BinanceSymbolsForSelectResponseModel,
    MarketDataStatusResponseModel,
} from 'api/http/models'
import { FnActionRequiredProps } from 'App.types'

export interface TradeTabsProps {
    marketId: number
    tradeSettings: TradeSettingProps
    onRobotData: React.Dispatch<
        React.SetStateAction<MarketDataStatusResponseModel | undefined>
    >
    onTradeSettings: FnActionRequiredProps<Partial<TradeSettingProps>>
    positionsData?: BinancePositionsModel[]
    robotData?: MarketDataStatusResponseModel
    symbols?: BinanceSymbolsForSelectResponseModel[]
}

export enum TABS_KEYS {
    COLIBRI = 'COLIBRI',
    MANUAL = 'MANUAL',
}
