import React, { useContext } from 'react';
import s from './TermsAndConditions.module.scss';
import translations from './translations';
import { useLanguage } from '../../context/LanguageContext';

const TermsAndConditions: React.FC = () => {
    const { language } = useLanguage();
    const t = translations[language];

    return (
        <div className={s.termsAndConditions}>
            <h1>{t.title}</h1>

            {Object.entries(t.sections).map(([key, section]) => (
                <section key={key}>
                    <h2>{section.title}</h2>
                    <p dangerouslySetInnerHTML={{ __html: section.content }}></p>
                </section>
            ))}
        </div>
    );
};

export default TermsAndConditions;
