import React, { useCallback, useEffect, useState } from 'react'
import { TableAdapter } from 'shared/components'
import { DEAL_HISTORY_TABLE_COLUMNS } from './DealHistory.consts'
import { DealHistoryProps, FetchBinanceHistoryProps } from './DealHistory.types'
import { getTableSummary } from './DealHistory.utils'
import { Filters } from './Filters'
import s from './DealHistory.module.scss'
import dayjs from 'dayjs'
import { BinanceNativeService } from 'api/http'
import { BinanceDealHistoryResponseModel } from 'api/http/models'

/** История сделок */
export const DealHistory: React.FC<DealHistoryProps> = React.memo(
    ({ symbol, marketId }) => {
        const [dealHistoryData, setDealHistoryData] = useState<
            BinanceDealHistoryResponseModel[]
        >([])

        /** Запрос истории сделок */
        const fetchDealHistory = useCallback(
            async (value: FetchBinanceHistoryProps) => {
                try {
                    if (marketId) {
                        setDealHistoryData(
                            await BinanceNativeService.getDealHistory({
                                ...value,
                                market_id: marketId,
                            })
                        )
                    }
                } catch (e) {
                    console.log(e)
                }
            },
            [marketId]
        )

        useEffect(() => {
            if (!symbol) return

            fetchDealHistory({
                symbol,
                startTime: dayjs().startOf('day').unix() * 1000,
            })
        }, [symbol, fetchDealHistory])

        return (
            <>
                <div className={s.header}>
                    <Filters onFetch={fetchDealHistory} symbol={symbol} />

                    {getTableSummary(dealHistoryData)}
                </div>

                <TableAdapter
                    columns={DEAL_HISTORY_TABLE_COLUMNS}
                    loading={!dealHistoryData}
                    dataSource={dealHistoryData}
                />
            </>
        )
    }
)
