import s from './CryptoMarkets.module.scss'
import { Button } from 'antd'
import { NAV_LINK_NAMES } from 'shared/consts'
import React from 'react'

export const DETAILED_GUIDES = (
    <div className={s.guidesButton}>
        <Button
            href={NAV_LINK_NAMES.GUIDES}
            type="primary"
            className={s.toGuides}
        >
            DETAILED GUIDES
        </Button>
    </div>
)
