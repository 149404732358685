import React, { useContext } from 'react'
import { PageContent } from 'shared/components'
import { AccountContext } from 'core/context'
import { BlockChain } from './BlockChain'
import { Profile } from './Profile'

/** Страница аккаунта */
export const Account: React.FC = React.memo(() => {
    const { tronAddresses } = useContext(AccountContext)

    return (
        <PageContent>
            {tronAddresses.service ? <BlockChain /> : <Profile />}
        </PageContent>
    )
})
