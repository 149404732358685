import s from './Orders.module.scss'

import React from 'react'
import { ColumnsType } from 'antd/lib/table'
import { defaultRuleSortingTableNumberColumn, formatDate } from 'shared/utils'
import { ColorWrapper } from 'shared/components'
import { Button, Tag } from 'antd'
import { FnActionAsyncProps, FnActionAsyncRequiredProps } from 'App.types'
import { BybitCancelOrderDto, BybitOrderDto } from 'api/http/models'

export const getOrdersTableColumns = (
    onCancelOrder: FnActionAsyncRequiredProps<BybitCancelOrderDto>,
    onCancelAllOrders: FnActionAsyncProps
): ColumnsType<BybitOrderDto> => [
    {
        title: 'Time',
        dataIndex: 'time',
        key: 'time',
        render: formatDate,
        align: 'center',
        sorter: defaultRuleSortingTableNumberColumn('time'),
        sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
        title: 'Symbol',
        dataIndex: 'symbol',
        key: 'symbol',
        align: 'center',
    },
    {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        align: 'center',
    },
    {
        title: 'Side',
        dataIndex: 'side',
        key: 'side',
        align: 'center',
        render: side => <ColorWrapper text={side} />,
    },
    {
        title: 'Price',
        dataIndex: 'price',
        key: 'price',
        align: 'center',
    },
    {
        title: 'Amount',
        dataIndex: 'size',
        key: 'size',
        align: 'center',
        sorter: defaultRuleSortingTableNumberColumn('size'),
        sortDirections: ['descend', 'ascend'],
    },
    {
        title: () => (
            <Button onClick={onCancelAllOrders} className={s.button}>
                <Tag>cancel all</Tag>
            </Button>
        ),
        render: record => (
            <Button
                onClick={() => {
                    onCancelOrder({
                        orderId: record.orderId,
                        symbol: record.symbol,
                    })
                }}
                className={s.button}
            >
                <Tag>cancel</Tag>
            </Button>
        ),
        width: 101,
        align: 'center',
    },
]
