import {
    BinanceCandlesIntervalTypes,
    BinanceOrderSideTypes,
    BinanceOrderTypes,
} from 'api/http/models'

export const INITIAL_TRADE_SETTINGS = {
    period: BinanceCandlesIntervalTypes.min,
}

export const BINANCE_ORDER_TYPES = {
    [BinanceOrderTypes.MARKET]: 'Market',
    [BinanceOrderTypes.STOP_MARKET]: 'Stop-market',
    [BinanceOrderTypes.STOP]: 'Stop-limit',
    [BinanceOrderTypes.TRAILING_STOP_MARKET]: 'Trailing-stop',
    [BinanceOrderTypes.LIMIT]: 'Limit',
    [BinanceOrderTypes.TAKE_PROFIT]: 'Take-limit',
    [BinanceOrderTypes.TAKE_PROFIT_MARKET]: 'Take-market',
}

export const BINANCE_ORDER_SIDE_TYPES = {
    [BinanceOrderSideTypes.BUY]: 'Buy',
    [BinanceOrderSideTypes.SELL]: 'Sell',
}
