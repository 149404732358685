import s from './BinanceTrade.module.scss'

import React, {
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react'
import { PageContent } from 'shared/components'
import { Button, Col, notification, Row, Space } from 'antd'
import { TradeSettingProps } from './BinanceTrade.types'
import { BINANCE_URL_WEBSOCKET } from 'api/ws'
import { BarData, ISeriesApi } from 'lightweight-charts'
import { WsDataEventType, WsDataResponseProps } from 'api/ws/models'
import { INITIAL_TRADE_SETTINGS } from './BinanceTrade.consts'
import { AccountContext, LoaderContext } from 'core/context'
import useWebSocket, { ReadyState } from 'react-use-websocket'
import { AccountService, BinanceService, BinanceSymbolsService } from 'api/http'
import {
    AccountModel,
    BinanceAccountModel,
    BinanceDataModel,
    BinanceOrdersResponseModel,
    BinancePositionsModel,
    BinanceSymbolsForSelectResponseModel,
    BinanceTickersModel,
    MarketDataStatusResponseModel,
} from 'api/http/models'
import { useNavigate, useParams } from 'react-router-dom'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { normalizePositionsData } from './BinanceTrade.utils'
import { TIME_ZONE_OFFSET } from 'shared/consts'
import { MarketBalance } from './MarketBalance'
import { TradeTabs } from './TradeTabs'
import { TradeTickers } from './TradeTickers'
import { TradeDiagram } from './TradeDiagram'
import { Tables } from './Tables'

/** Торговля на Binance */
export const BinanceTrade: React.FC = React.memo(() => {
    const { accountData } = useContext(AccountContext)
    const { setLoader } = useContext(LoaderContext)
    const { id } = useParams<{ id?: string }>()
    const navigate = useNavigate()

    const [currentAccount, setCurrentAccount] = useState<AccountModel>()
    const [marketData, setMarketData] = useState<BinanceDataModel>()
    const [tradeSettings, setTradeSettings] = useState<TradeSettingProps>(
        INITIAL_TRADE_SETTINGS
    )
    const [tickersData, setTickersData] = useState<BinanceTickersModel[]>()
    const [tickerLastPrice, setTickerLastPrice] = useState<number>()
    const [ordersData, setOrdersData] = useState<BinanceOrdersResponseModel[]>(
        []
    )
    const [positionsData, setPositionsData] = useState<BinancePositionsModel[]>(
        []
    )
    const [exchangeAccountData, setExchangeAccountData] =
        useState<BinanceAccountModel>()
    const [robotData, setRobotData] = useState<MarketDataStatusResponseModel>()
    const [symbols, setSymbols] =
        useState<BinanceSymbolsForSelectResponseModel[]>()

    const didUnmount = useRef(false)
    const { sendJsonMessage, lastJsonMessage, readyState, getWebSocket } =
        useWebSocket<WsDataResponseProps>(BINANCE_URL_WEBSOCKET, {
            shouldReconnect: () => !didUnmount.current,
            reconnectInterval: 10000,
            queryParams: { id_: id || accountData?.id || '' },
        })

    /**
     * Обновление данных для графика и тикеров
     * @param candlestickSeries сеттер для графика
     */
    const updateTradeData = useCallback(
        (candlestickSeries?: ISeriesApi<'Candlestick'>) => {
            if (!lastJsonMessage || !candlestickSeries) return

            const { event_type, payload } = lastJsonMessage

            switch (event_type) {
                case WsDataEventType.Error:
                    notification.error({
                        message: 'Websocket error',
                        description: payload,
                    })
                    setTickersData([])
                    break
                case WsDataEventType.CandlesUpdate:
                    setTickerLastPrice(Number(payload?.[0].close))
                    if (payload.length > 1) {
                        candlestickSeries.setData(
                            payload.map((el: BarData) => ({
                                ...el,
                                time: Number(el.time) + TIME_ZONE_OFFSET,
                            }))
                        )
                    } else {
                        const tick = payload[0]
                        candlestickSeries.update({
                            ...tick,
                            time: tick.time + TIME_ZONE_OFFSET,
                        })
                    }
                    break
                case WsDataEventType.TickersUpdate:
                    setTickersData(payload)
                    setPositionsData(prevState =>
                        normalizePositionsData(payload, prevState)
                    )
                    break
                case WsDataEventType.PositionsUpdate:
                    setPositionsData(payload)
                    break
                case WsDataEventType.AccountUpdate:
                    setExchangeAccountData(payload)
                    break
                case WsDataEventType.OpenOrdersUpdate:
                    setOrdersData(
                        payload?.filter(
                            (el: BinanceOrdersResponseModel) =>
                                el.symbol === tradeSettings.symbolData?.symbol
                        )
                    )
                    break
            }
        },
        [lastJsonMessage, tradeSettings.symbolData]
    )

    const handleChangeTradeSettings = useCallback(
        (value: Partial<TradeSettingProps>) => {
            setTradeSettings(prevState => {
                const newSettings = {
                    symbolData: value.symbolData || prevState.symbolData,
                    period: value.period || prevState.period,
                }

                sendJsonMessage({
                    symbol: newSettings.symbolData?.symbol,
                    period: newSettings.period,
                })

                return newSettings
            })
        },
        [sendJsonMessage]
    )

    useEffect(
        () => () => {
            didUnmount.current = true
        },
        [getWebSocket]
    )

    useEffect(() => {
        if (
            currentAccount ||
            !tickersData ||
            !accountData ||
            readyState !== ReadyState.OPEN
        )
            return

        const fetch = async () => {
            try {
                setLoader(true)

                const dataSource = await BinanceSymbolsService.forSelect()
                const accountSource = id
                    ? await AccountService.get(Number(id))
                    : accountData
                const symbolsData = accountSource.isAdmin
                    ? dataSource
                    : dataSource.filter(el => !el.only_admin)
                const marketData = await BinanceService.get(accountSource.id)

                setCurrentAccount(accountSource)
                setMarketData(marketData)
                setSymbols(symbolsData)

                let robotResponse: MarketDataStatusResponseModel | undefined
                if (marketData.id) {
                    robotResponse = await BinanceService.getStatus(
                        marketData.id
                    )
                    setRobotData(robotResponse)
                }

                const symbolData = robotResponse?.isRun
                    ? symbolsData.find(el => el.id === robotResponse?.symbolId)
                    : symbolsData.find(
                          el => el.symbol === tickersData[0]?.symbol
                      )

                if (!symbolData) return

                handleChangeTradeSettings({ symbolData })
            } catch (e) {
                console.log(e)
            } finally {
                setLoader(false)
            }
        }

        fetch()
    }, [
        setLoader,
        tickersData,
        symbols,
        handleChangeTradeSettings,
        readyState,
        accountData,
        id,
        currentAccount,
    ])

    return (
        <PageContent>
            <Row gutter={20}>
                <Col xs={24} md={8} xl={6}>
                    <Row gutter={20}>
                        <Col xs={24} sm={12} md={24}>
                            {currentAccount && (
                                <>
                                    <Button
                                        type="link"
                                        icon={<ArrowLeftOutlined />}
                                        className={s.back}
                                        onClick={() => {
                                            navigate(-1)
                                        }}
                                    >
                                        back
                                    </Button>

                                    <Space className={s.account}>
                                        <h3>
                                            {currentAccount.tronAddress ||
                                                `${currentAccount.firstName} ${currentAccount.lastName}`}
                                        </h3>

                                        <div>{`ID: ${currentAccount.id}`}</div>
                                    </Space>

                                    <hr />
                                </>
                            )}

                            {exchangeAccountData?.availableBalance && (
                                <MarketBalance
                                    availableBalance={Number(
                                        exchangeAccountData.availableBalance
                                    )}
                                />
                            )}

                            {marketData?.id && (
                                <TradeTabs
                                    tradeSettings={tradeSettings}
                                    onTradeSettings={handleChangeTradeSettings}
                                    positionsData={positionsData}
                                    robotData={robotData}
                                    onRobotData={setRobotData}
                                    marketId={marketData?.id}
                                    symbols={symbols}
                                />
                            )}
                        </Col>

                        <Col xs={24} sm={12} md={24}>
                            <TradeTickers
                                onTradeSettings={handleChangeTradeSettings}
                                tickersData={tickersData}
                                symbols={symbols}
                            />
                        </Col>
                    </Row>
                </Col>

                <Col xs={24} md={16} xl={18}>
                    <TradeDiagram
                        onTradeSettings={handleChangeTradeSettings}
                        onUpdateTradeData={updateTradeData}
                        tradeSettings={tradeSettings}
                        tickerLastPrice={tickerLastPrice}
                        ordersData={ordersData}
                        positionData={positionsData?.find(
                            el => el.symbol === tradeSettings.symbolData?.symbol
                        )}
                    />
                </Col>
            </Row>

            <Tables
                positionsData={positionsData?.filter(el =>
                    Number(el.positionAmt)
                )}
                ordersData={ordersData}
                robotData={robotData}
                symbol={tradeSettings.symbolData?.symbol}
                marketId={marketData?.id}
            />
        </PageContent>
    )
})
