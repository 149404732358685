import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Col, Row } from 'antd'
import { Info } from './Info'
import { Deposit } from '../Deposit'
import { Staking } from './Staking'
import { Calculated } from './Calculated'
import { AccountContext, LoaderContext } from 'core/context'
import { ServiceProps } from './BlockChain.types'
import { TronLink } from 'core/tron'
import { fixedNumber, removeZeroInStringEnd } from 'shared/utils'

/** Раздел с блокчейном */
export const BlockChain: React.FC = React.memo(() => {
    const { tronAddresses, accountData } = useContext(AccountContext)
    const { setLoader } = useContext(LoaderContext)
    const [serviceData, setServiceData] = useState<ServiceProps>({
        stake: 0,
        calculatedAmount: 0,
        balance: 0,
    })

    const fetchServiceBalance = useCallback(async () => {
        const balance = await TronLink.instanceRcbc
            ?.balanceOf(tronAddresses.service)
            .call()

        setServiceData(prevState => ({
            ...prevState,
            balance: fixedNumber(
                removeZeroInStringEnd((balance || 0).toString())
            ),
        }))
    }, [tronAddresses.service])

    const fetchServiceStake = useCallback(async () => {
        const stake = await TronLink.instanceService.stakedAmount().call()

        setServiceData(prevState => ({
            ...prevState,
            stake: fixedNumber(removeZeroInStringEnd((stake || 0).toString())),
        }))
    }, [])

    const fetchServiceCalculatedAmount = useCallback(async () => {
        const calculatedAmount = await TronLink.instanceService
            .calculatedEarnings()
            .call()

        setServiceData(prevState => ({
            ...prevState,
            calculatedAmount: fixedNumber(
                removeZeroInStringEnd((calculatedAmount || 0).toString())
            ),
        }))
    }, [])

    useEffect(() => {
        const fetch = async () => {
            try {
                setLoader(true)

                await fetchServiceBalance()
                await fetchServiceStake()
                await fetchServiceCalculatedAmount()
            } catch (e) {
                console.log(e)
            } finally {
                setLoader(false)
            }
        }

        fetch()
    }, [
        fetchServiceBalance,
        fetchServiceStake,
        setLoader,
        fetchServiceCalculatedAmount,
    ])

    return (
        <>
            <Row gutter={20}>
                <Col xs={24} xl={accountData?.id ? 18 : 24}>
                    <Info
                        tronAddresses={tronAddresses}
                        onServiceBalance={fetchServiceBalance}
                        serviceData={serviceData}
                        accountData={accountData}
                    />
                </Col>

                {accountData?.id && (
                    <Col xs={24} xl={6}>
                        <Deposit />
                    </Col>
                )}
            </Row>

            <Row gutter={20}>
                <Col xs={24} xl={14}>
                    <Staking
                        serviceData={serviceData}
                        onServiceStake={fetchServiceStake}
                        onServiceBalance={fetchServiceBalance}
                        tronAddresses={tronAddresses}
                    />
                </Col>

                <Col xs={24} xl={10}>
                    <Calculated
                        serviceData={serviceData}
                        onServiceCalculatedAmount={fetchServiceCalculatedAmount}
                        onServiceBalance={fetchServiceBalance}
                    />
                </Col>
            </Row>
        </>
    )
})
