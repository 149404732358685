import s from './BinanceStatistic.module.scss'

import React from 'react'
import { QuestionIconTip } from 'shared/components'

/**
 * Формируем заголовок для показателя статистики
 * @param title заголовок
 * @param tip подсказка
 */
export const getStatisticTitle = (
    title: string | React.ReactNode,
    tip?: string
) => (
    <>
        {title}

        <QuestionIconTip
            title={
                tip ||
                'The beginning of the day is calculated according to UTC+0'
            }
            className={s.tip}
        />
    </>
)
