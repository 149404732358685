import { FeeStatusTypes, RithmicSystemsTypes } from 'api/http/models'

export const FEE_STATUS = {
    [FeeStatusTypes.SUCCESS]: 'Успешно',
    [FeeStatusTypes.FAIL]: 'Ошибка',
}

export const RITHMIC_SYSTEM = {
    [RithmicSystemsTypes.RITHMIC_01]: 'Live trading',
    [RithmicSystemsTypes.RITHMIC_PAPER_TRADING]: 'Demo trading',
}
