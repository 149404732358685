import React, { useCallback, useEffect, useState } from 'react'
import {
    CheckboxControl,
    InputControl,
    InputNumberControl,
    SelectControl,
} from 'shared/components'
import { BinanceNativeService, BinanceSymbolsService } from 'api/http'
import { Form, Modal, Spin } from 'antd'
import { PopupAdapterFormProps } from 'shared/components/popups/PopupAdapter.types'
import { Store } from 'antd/lib/form/interface'
import {
    BinanceGetSymbolsForSelectResponseModel,
    BinanceSymbolsUpdateRequestModel,
} from 'api/http/models'
import { normalizeDataForSelectAndRadio } from 'shared/utils'

/** Форма для символов Binance */
export const BinanceSymbol: React.FC<PopupAdapterFormProps> = React.memo(
    ({ initialValues, onRequestFinish, ...props }) => {
        const [symbolsData, setSymbolsData] =
            useState<BinanceGetSymbolsForSelectResponseModel[]>()
        const [localLoader, setLocalLoader] = useState(false)

        const [form] = Form.useForm()

        const handleFinish = useCallback(
            async (values: BinanceSymbolsUpdateRequestModel) => {
                try {
                    setLocalLoader(true)

                    if (values.id) {
                        Modal.confirm({
                            title: 'Внимание!',
                            content:
                                'Роботы, использующие этот символом, будут перезапущены с новыми настройки. Продолжить?',
                            onOk: async () => {
                                const dataSource =
                                    await BinanceSymbolsService.update(values)

                                if (dataSource?.length) {
                                    Modal.warning({
                                        title: 'Важно!',
                                        content: (
                                            <>
                                                <h4>
                                                    Роботы остановлены после
                                                    применения настроек:
                                                </h4>

                                                {dataSource.map(el => (
                                                    <div
                                                        key={el.user_id}
                                                    >{`Account id ${el.user_id} - ${el.error}`}</div>
                                                ))}
                                            </>
                                        ),
                                        onOk: () => {
                                            onRequestFinish?.()
                                        },
                                    })
                                } else {
                                    onRequestFinish?.()
                                }
                            },
                        })

                        return
                    }

                    await BinanceSymbolsService.create(values)
                    onRequestFinish?.()
                } catch (e) {
                    console.log(e)
                } finally {
                    setLocalLoader(false)
                }
            },
            [onRequestFinish]
        )

        const changeFieldsOptions = useCallback(
            (symbolData?: BinanceGetSymbolsForSelectResponseModel) => {
                if (!symbolData) return

                form.setFieldsValue({
                    price_digits: symbolData.price_precision,
                    quantity_digits: symbolData.quantity_precision,
                })
            },
            [form]
        )

        const handleValuesChange = useCallback(
            (changeValue: Store) => {
                const [key, value] = Object.entries(changeValue)[0]

                if (key === 'symbol') {
                    changeFieldsOptions(
                        symbolsData?.find(el => el.symbol === value)
                    )
                }
            },
            [changeFieldsOptions, symbolsData]
        )

        useEffect(() => {
            const fetch = async () => {
                try {
                    setLocalLoader(true)
                    const dataSource =
                        await BinanceNativeService.getSymbolsForSelect()

                    setSymbolsData(dataSource)

                    changeFieldsOptions(
                        initialValues?.symbol
                            ? dataSource.find(
                                  el => el.symbol === initialValues.symbol
                              )
                            : dataSource[0]
                    )
                } catch (e) {
                    console.log(e)
                } finally {
                    setLocalLoader(false)
                }
            }

            fetch()
        }, [initialValues?.symbol, changeFieldsOptions])

        return (
            <Spin spinning={localLoader}>
                <Form
                    {...props}
                    initialValues={initialValues || { only_admin: true }}
                    form={form}
                    layout="vertical"
                    onFinish={handleFinish}
                    onValuesChange={handleValuesChange}
                >
                    <Form.Item name="id" hidden>
                        <InputControl />
                    </Form.Item>

                    <Form.Item name="price_digits" hidden>
                        <InputControl />
                    </Form.Item>

                    <Form.Item name="quantity_digits" hidden>
                        <InputControl />
                    </Form.Item>

                    <Form.Item name="name" label="Название">
                        <InputControl maxLength={20} />
                    </Form.Item>

                    <Form.Item name="symbol" label="Биржевой символ">
                        <SelectControl
                            options={symbolsData?.map(el =>
                                normalizeDataForSelectAndRadio(el.symbol)
                            )}
                            showSearch
                        />
                    </Form.Item>

                    <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, nextValues) =>
                            prevValues.price_digits !==
                                nextValues.price_digits ||
                            prevValues.quantity_digits !==
                                nextValues.quantity_digits
                        }
                    >
                        {({ getFieldsValue }) => {
                            const { price_digits, quantity_digits } =
                                getFieldsValue()
                            const step = 1 / 10 ** (price_digits || 1)
                            const quantity = 1 / 10 ** (quantity_digits || 1)

                            return (
                                <>
                                    <Form.Item
                                        name="step_size"
                                        label="Размер шага"
                                    >
                                        <InputNumberControl
                                            min={step}
                                            step={step}
                                            placeholder={String(step)}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        name="default_position_size"
                                        label="Размер позиции при открытии"
                                    >
                                        <InputNumberControl
                                            min={quantity}
                                            step={quantity}
                                            placeholder={String(quantity)}
                                        />
                                    </Form.Item>
                                </>
                            )
                        }}
                    </Form.Item>

                    <Form.Item name="default_max_lots" label="Максимум лотов">
                        <InputNumberControl min={0.0000000000000000000000001} placeholder="100" />
                    </Form.Item>

                    <Form.Item name="min_balance" label="Минимальный баланс">
                        <InputNumberControl placeholder="5000" min={1} />
                    </Form.Item>

                    <Form.Item
                        name="take_profit_ratio"
                        label="Шагов до тейк-профита"
                    >
                        <InputNumberControl min={1} placeholder="1" />
                    </Form.Item>

                    <Form.Item name="only_admin" valuePropName="checked">
                        <CheckboxControl>
                            Только для администратора
                        </CheckboxControl>
                    </Form.Item>
                </Form>
            </Spin>
        )
    }
)
