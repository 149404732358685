import React from 'react'
import { BinanceDealHistoryResponseModel } from 'api/http/models'
import { Space } from 'antd'
import s from './DealHistory.module.scss'

/**
 * Статистика данных таблицы
 * @param deals
 */
export const getTableSummary = (deals: BinanceDealHistoryResponseModel[]) => {
    let summaryPnl = 0
    let summaryQuote = 0
    let summaryCommission = 0
    let summarySymbol = ''
    let summaryCommissionAsset = ''

    deals.forEach(
        ({ realizedPnl, qty, commission, symbol, commissionAsset }) => {
            if (!symbol || !summaryCommissionAsset) {
                summarySymbol = symbol
                summaryCommissionAsset = commissionAsset
            }

            summaryCommission += Number(commission)
            if (Number(realizedPnl)) {
                summaryPnl += Number(realizedPnl)
            } else {
                summaryQuote += Number(qty)
            }
        }
    )

    return (
        <Space size={15} className={s.summary}>
            <div>
                <b>Quantity:</b>
                {` ${parseFloat(
                    summaryQuote.toFixed(5)
                )} ${summarySymbol.replace('USDT', '')}`}
            </div>

            <div>
                <b>Commission:</b>
                {` ${parseFloat(
                    summaryCommission.toFixed(4)
                )} ${summaryCommissionAsset}`}
            </div>

            <div>
                <b>Realized profit:</b>
                {` ${parseFloat(summaryPnl.toFixed(4))} USDT`}
            </div>
        </Space>
    )
}
