import s from './MarketBalance.module.scss'

import React from 'react'
import { CreditCardOutlined } from '@ant-design/icons'
import { Space, Tooltip } from 'antd'
import { RithmicAccountModel } from 'api/http/models'

/** Остаток денег на бирже */
export const MarketBalance: React.FC<RithmicAccountModel> = React.memo(
    ({ balance }) => {
        return (
            <Tooltip title="Available balance" className={s.wrapper}>
                <Space className={s.balance}>
                    <CreditCardOutlined />

                    <div>{balance}</div>
                </Space>
            </Tooltip>
        )
    }
)
