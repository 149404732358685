import React from 'react'
import { ColumnsType } from 'antd/lib/table'
import {
    BybitDataModel,
    BybitSymbolForSelectDto,
    BybitUpdateDto,
} from 'api/http/models'
import { fixedNumber, formatDate } from 'shared/utils'
import { Button } from 'antd'
import { EyeOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { NAV_LINK_NAMES } from 'shared/consts'
import { BybitActions } from './BybitActions'
import { FnActionAsyncProps } from 'App.types'
import { CheckboxControl } from 'shared/components'

/** Настройки колонок таблицы аккаунтов */
export const getAccountsTableColumns = (
    onFetchAccounts: FnActionAsyncProps,
    onMarketUpdate: (id: number, body: BybitUpdateDto) => void,
    symbols?: BybitSymbolForSelectDto[]
): ColumnsType<BybitDataModel> => [
    {
        title: 'AID',
        dataIndex: ['account', 'id'],
        key: 'id',
        align: 'center',
    },
    {
        title: 'Create date',
        dataIndex: 'createDate',
        key: 'createDate',
        render: formatDate,
    },
    {
        title: 'Username',
        dataIndex: ['account', 'username'],
        key: 'username',
    },
    {
        title: 'Deposit',
        dataIndex: ['account', 'deposit'],
        key: 'deposit',
        render: deposit => fixedNumber(deposit),
    },
    {
        title: 'Locked',
        dataIndex: ['account', 'locked'],
        key: 'locked',
        render: locked => fixedNumber(locked),
    },
    {
        title: 'Access key',
        key: 'accessKey',
        dataIndex: 'accessKey',
    },
    {
        title: 'Secret key',
        key: 'secretKey',
        dataIndex: 'secretKey',
    },
    {
        title: 'Commission',
        key: 'isWithdraw',
        dataIndex: 'isWithdraw',
        render: (isWithdraw, record) => (
            <CheckboxControl
                checked={isWithdraw}
                onChange={e => {
                    onMarketUpdate(record.id, {
                        isWithdraw: e.target.checked,
                    })
                }}
            />
        ),
        align: 'center',
    },
    {
        key: 'view',
        dataIndex: ['account', 'id'],
        render: id => (
            <Link to={`${NAV_LINK_NAMES.BYBIT_TRADE}/${id}`}>
                <Button type="link" icon={<EyeOutlined />} />
            </Link>
        ),
        align: 'center',
    },
    {
        title: 'Статус робота',
        key: 'robot',
        render: record => (
            <BybitActions
                onFetchAccounts={onFetchAccounts}
                record={record}
                symbols={
                    record.account.isAdmin
                        ? symbols
                        : symbols?.filter(el => !el.onlyAdmin)
                }
            />
        ),
        width: 120,
    },
]
