import s from './Bybit.module.scss'

import React from 'react'
import { TableFilters } from './TableFilters'
import { QueryParamsProps } from 'shared/hooks'
import { BybitDealsSearchDto } from 'api/http/models'

/**
 * Формируем заголовок таблицы
 * @param onQueryParams колбэк для сохранения параметров фильтра
 */
export const getTableTitle =
    (
        onQueryParams: React.Dispatch<
            React.SetStateAction<
                QueryParamsProps<Omit<BybitDealsSearchDto, 'accountId'>>
            >
        >
    ) =>
    () =>
        (
            <div className={s.title}>
                <h2>Profit table</h2>

                <TableFilters onQueryParams={onQueryParams} />
            </div>
        )
