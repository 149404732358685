import s from './Positions.module.scss'

import React from 'react'
import { ColumnsType } from 'antd/lib/table'
import { ColorWrapper } from 'shared/components'
import { Button, Tag } from 'antd'
import {
    BybitOrderSideTypes,
    BybitPositionDto,
    BybitSendOrderDto,
} from 'api/http/models'
import { FnActionAsyncRequiredProps } from 'App.types'

export const getPositionsTableColumns = (
    onCancelOrder: FnActionAsyncRequiredProps<
        Pick<BybitSendOrderDto, 'size' | 'symbol' | 'side'>
    >,
    marketSymbol?: string
): ColumnsType<BybitPositionDto> => [
    {
        title: 'Symbol',
        dataIndex: 'symbol',
        key: 'symbol',
    },
    {
        title: 'Leverage',
        dataIndex: 'leverage',
        key: 'leverage',
        render: leverage => `${leverage}x`,
        align: 'center',
    },
    {
        title: 'Size',
        dataIndex: 'size',
        key: 'size',
        render: (size, record) => (
            <ColorWrapper
                text={`${size} ${record.symbol.replace('USDT', '')}`}
                condition={record.side === BybitOrderSideTypes.Buy}
            />
        ),
        align: 'center',
    },
    {
        title: 'Avg price',
        dataIndex: 'avgPrice',
        key: 'avgPrice',
        align: 'center',
        render: avgPrice => avgPrice.toFixed(8),
    },
    {
        title: 'Liq. price',
        dataIndex: 'liqPrice',
        key: 'liqPrice',
        align: 'center',
        render: liqPrice => (liqPrice ? liqPrice.toFixed(8) : '-'),
    },
    {
        title: 'PNL',
        dataIndex: 'unrealisedPnl',
        key: 'unrealisedPnl',
        align: 'center',
        render: unrealisedPnl => (
            <ColorWrapper
                text={`${unrealisedPnl?.toFixed(5)}`}
                condition={unrealisedPnl >= 0}
            />
        ),
    },
    {
        render: ({ symbol, size, side }) => (
            <Button
                onClick={() => {
                    onCancelOrder({ symbol, size, side })
                }}
                disabled={symbol === marketSymbol}
                className={s.button}
            >
                <Tag>market</Tag>
            </Button>
        ),
        align: 'center',
    },
]
