import React, { useCallback, useContext } from 'react'
import { PositionsProps } from './Positions.types'
import { TableAdapter } from 'shared/components'
import { message } from 'antd'
import { getPositionsTableColumns } from './Positions.utils'
import { LoaderContext } from 'core/context'
import { BybitNativeService } from 'api/http'
import {
    BybitOrderSideTypes,
    BybitOrderTypes,
    BybitSendOrderDto,
} from 'api/http/models'

/** Таблица позиций */
export const Positions: React.FC<PositionsProps> = React.memo(
    ({ marketId, positions, marketSymbol }) => {
        const { setLoader } = useContext(LoaderContext)

        /** Обработчик закрытия позиции */
        const handleCancelPosition = useCallback(
            async ({
                symbol,
                size,
                side,
            }: Pick<BybitSendOrderDto, 'size' | 'symbol' | 'side'>) => {
                try {
                    setLoader(true)
                    if (!marketId) return

                    const { orderId } = await BybitNativeService.sendOrder(
                        marketId,
                        {
                            size: Math.abs(size),
                            symbol,
                            orderType: BybitOrderTypes.Market,
                            side:
                                side === BybitOrderSideTypes.Buy
                                    ? BybitOrderSideTypes.Sell
                                    : BybitOrderSideTypes.Buy,
                        }
                    )

                    message.success(`Ордер успешно размещен. ID ${orderId}`)
                } catch (e) {
                    console.log(e)
                } finally {
                    setLoader(false)
                }
            },
            [setLoader, marketId]
        )

        return (
            <TableAdapter
                title={() => <h2>Positions</h2>}
                columns={getPositionsTableColumns(
                    handleCancelPosition,
                    marketSymbol
                )}
                dataSource={positions}
                rowKey="symbol"
                pagination={false}
            />
        )
    }
)
