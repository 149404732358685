export interface BybitTickersModel {
    symbol: string
    lastPrice: number
}

export interface BybitOrderIdDto {
    orderId: string
}

export interface BybitPositionDto {
    symbol: string
    leverage: number
    avgPrice: number
    size: number
    liqPrice: number
    unrealisedPnl: number
    side?: BybitOrderSideTypes
}

export interface BybitOrderDto {
    orderId: string
    time: number
    symbol: string
    price: number
    size: number
    side: BybitOrderSideTypes
    type: BybitOrderTypes
    status: BybitOrderStatusType
}

export interface BybitCancelOrderDto {
    orderId: string
    symbol: string
}

export interface BybitBalanceDto {
    balance: number
}

export interface BybitLimitDto {
    maxLimit: number
    maxLeverage: number
}

export interface BybitChangeLeverageDto {
    symbol: string
    leverage: number
}

export interface BybitGetOrdersHistoryDto {
    symbol: string
}

export interface BybitOrderHistoryDto {
    orderId: string
    time: number
    symbol: string
    price: number
    size: number
    side: BybitOrderSideTypes
    commission: number
}

export interface BybitGetPositionsHistoryDto {
    symbol: string
}

export interface BybitPositionHistoryDto {
    orderId: number
    time: number
    symbol: string
    size: number
    side: BybitOrderSideTypes
    pnl: number
    entryPrice: number
    exitPrice: number
}

export interface BybitGetInstrumentInfoDto {
    symbol: string
    priceStep: number
    quantityStep: number
}

export interface BybitGetLeverageDto {
    symbol: string
}

export interface BybitLeverageDto {
    leverage: number
}

export interface BybitRiskLimitsDto {
    limits: BybitLimitDto[]
}

export enum BybitCandlesIntervalTypes {
    min = '1',
    min3 = '3',
    min5 = '5',
    min15 = '15',
    min30 = '30',
    hour1 = '60',
    hour2 = '120',
    hour4 = '240',
    hour6 = '360',
    hour12 = '720',
    day1 = 'D',
    week1 = 'W',
    mon1 = 'M',
}

export enum BybitOrderSideTypes {
    Buy = 'Buy',
    Sell = 'Sell',
}

export enum BybitOrderTypes {
    Limit = 'Limit',
    Market = 'Market',
}

export enum BybitOrderStatusType {
    Created = 'Created',
    New = 'New',
    Rejected = 'Rejected',
    PartiallyFilled = 'PartiallyFilled',
    Filled = 'Filled',
    Cancelled = 'Cancelled',
    Untriggered = 'Untriggered',
    Triggered = 'Triggered',
    Deactivated = 'Deactivated',
    Active = 'Active',
}

export interface BybitSendOrderDto {
    symbol: string
    side: BybitOrderSideTypes
    orderType: BybitOrderTypes
    size: number
    price?: number
}
