import React, { useCallback, useEffect, useState } from 'react'
import { TableAdapter } from 'shared/components'
import { POSITIONS_HISTORY_TABLE_COLUMNS } from './PositionsHistory.consts'
import { PositionsHistoryProps } from './PositionsHistory.types'
import { BybitNativeService } from 'api/http'
import { BybitPositionHistoryDto } from 'api/http/models'

/** История позиций */
export const PositionsHistory: React.FC<PositionsHistoryProps> = React.memo(
    ({ symbol, marketId }) => {
        const [positions, setPositions] = useState<BybitPositionHistoryDto[]>()

        const handlePositionsFetch = useCallback(async () => {
            try {
                if (symbol && marketId) {
                    setPositions(
                        await BybitNativeService.getPositionsHistory(marketId, {
                            symbol,
                        })
                    )
                }
            } catch (e) {
                console.log(e)
            }
        }, [symbol, marketId])

        useEffect(() => {
            handlePositionsFetch()
        }, [handlePositionsFetch])

        return (
            <TableAdapter
                columns={POSITIONS_HISTORY_TABLE_COLUMNS}
                loading={!positions}
                dataSource={positions}
                rowKey="orderId"
            />
        )
    }
)
