export const translations = {
    en: {
        about: {
            learnMore: "Learn more about RealColibri",
            autotrading: {
                header: "Autotrading",
                welcome: "Welcome to the world of high-tech trading with RealColibri!",
                description: `RealColibri is an advanced automated trading system specializing in futures
                            contracts of crypto exchanges and global markets. Our system is characterized
                            by outstanding long-term success and an innovative approach to investing.`,
                unique: "What makes the RealColibri system unique:",
                list: [
                    "Excellent results: RealColibri delivers impressive results over the long term. Our system is based on advanced algorithms and rigorous data analysis, which allows us to achieve stable and significant investment growth, as well as minimize risk.",
                    "Automated approach: Forget about the need to constantly monitor the market! RealColibri automatically analyzes thousands of data points and makes real-time trading decisions, freeing you from stress and increasing your investment performance.",
                    "Diversification: The ability to trade on different instruments within one account by creating additional API key or login-password pairs and using them on multiple RealColibri accounts.",
                    `Use of RCBC token: RealColibri uses RCBC as a commission for services in the amount of 50% of the deposit size. The commission deposit is spent on
                    each trade. This innovative solution allows investors to "fuel" the algorithm for trading, ensuring it is always running and optimizing the investment
                    process. Having spent 1 RCBC on commission, the investor receives 2 times more on their trading account! At the same time, the investor keeps the profit in its
                    entirety. And thanks to the constant growth of RCBC value, RealColibri's commission becomes smaller and smaller over time.`,
                    `Full market fee compensation: RealColibri provides the option of full market
                    fee compensation by distributing the corresponding amount of RCBC equally between
                    the referral and staking systems. This allows investors to maximize their investments
                    and increase their profitability.`,
                    "Free trial period: Try our algorithm for free for one week! You can also extend this period for another week for each customer you invite to our system. Read more about this feature in the FAQ section.",
                ]
            },
            realColibriCoin: {
                header: "RealColibri Coin",
                welcome: "Open new horizons with RealColibri Coin!",
                description: `RCBC is more than just a cryptocurrency. It is a key element of our
                            entire ecosystem, contributing to its constant growth and development.
                            Think of RCBC as the fuel that powers our services and makes them even
                            more powerful and efficient. The constant turnover of RCBC in the RealColibri
                            ecosystem contributes to the token's value and creates a favorable environment
                            for all participants:`,
                list: [
                    `Favorable commission: The higher the value of RCBC, the fewer tokens will be
                    charged for RealColibri's trading automation services, making them even more
                    affordable and attractive to traders.`,
                    `Full market fee compensation: RealColibri fully compensates the market fee 
                    generated by all users of our system. 50% of this amount is contributed to 
                    the referral system and the other 50% to the staking system, then distributed 
                    to the participants according to the respective conditions.`,
                    `Increased referral profits: The steady increase in the price of RCBC increases 
                    your benefit from the referral program. Invite new members to the RealColibri 
                    ecosystem and earn more and more rewards, which will also increase your assets 
                    as the token value grows.`,
                    `Profit from staking: By using RCBC for staking, you have an additional opportunity
                    to earn an income. As the value of RCBC in the RealColibri ecosystem grows, your
                    profit from staking will only increase, making this method of investing even more attractive.`,
                    `Token Exchange: Our ecosystem also allows users to sell/exchange RCBC with each
                    other without RealColibri's involvement. In the future, when we list RCBC on
                    centralized and decentralized exchanges, the token will be tradable. This gives 
                    you even more freedom and flexibility in managing your assets.`,
                ],
                whatCanIDo: "What can I do with RCBC?",
                actions: [
                    "Fund the commission deposit and use them as a commission for using autotrading.",
                    "Lock them into the staking system and accumulate profits. Read more in the Staking section.",
                    "Sell directly to another RealColibri member who wishes to purchase RCBC.",
                    "Trade them on exchanges once RCBC is listed in the future.",
                ],
                joinNow: "Join RealColibri today and discover new opportunities and paths to financial success!"
            },
            referralSystem: {
                header: "Referral system",
                welcome: "Build your business with our referral system and receive 50% of your clients' market fees!",
                description: `RealColibri's referral system allows you to earn by referring new users to our
                            platform. Just share your unique link with your friends, colleagues or subscribers
                            and get 50% of the market fees generated by them and/or their referrals on our platform!`,
                howItWorks: "How does it work?",
                list: [
                    "Bring in new users: Get your unique link and share it with friends, colleagues and on social media.",
                    `Earn from every trade: When your referrals trade on our platform and generate
                    market fees, you receive 50% of those fees as a reward in the RCBC token.`,
                    `Multi-level structure: The depth of referral levels is infinite, and rewards
                    are divided between program participants in the ratio 80/20 - 80% go to you,
                    the remaining 20% go to the level above. You are rewarded not only for the
                    activity of your direct referrals (80%), but also for the activity of their
                    own referrals (20%), and so on to infinity. This means that your income
                    potential grows with each new user in your chain.`,
                    `Example: Let's assume that you invited Alex to our platform.
                    During a month Alex traded 5000 USD and the market fee averaged 30%, or 1500 USD.
                    A half of this amount (750 USD) will go to the referral program in RCBC equivalent.
                    600 USD (80%) will go to you and the remaining 150 USD (20%) will go to the level above
                    and is divided between the one who invited you and the one who invited them
                    (80/20), and it goes like that up to the first invitee in that chain. After that,
                    Alex also brought Jane onto our platform. In the same month Jane traded 3000 USD.
                    The market fee also averaged 30%, or 900 USD. A half of this amount (450 USD) will also go
                    to the referral program in RCBC equivalent. From this amount 360 USD (80%) will go to
                    Alex who invited Jane, and the remaining 90 USD (20%) will be divided between
                    you and the person who invited you to the platform (80%/20% or 72 USD/18
                    USD). This means you got 600 USD + 72 USD = 672 USD in RCBC token in a month by
                    inviting just one user. But imagine if there were 5, 10 or more!`,
                ],
                joinNow: "Join our referral program now and start increasing your income with us!"
            },
            staking: {
                header: "Staking",
                welcome: "Increase your RCBC asset passively with staking!",
                description: `Want to increase your RCBC asset without putting in any extra effort?
                            Now it's possible thanks to our staking feature!`,
                whatIsStaking: "What is staking?",
                stakingDescription: `It is a process by which you can freeze (or block)
                            your RCBCs and passively increase your asset.`,
                howItWorks: "How does it work?",
                howItWorksDescription: `Simply freeze your tokens, and from now on you
                            will continuously accumulate in your account a share of 50% of the traded
                            market fees of all RealColibri users in proportion to your staking weight.
                            The more and longer you have RCBC tokens, the higher your staking weight,
                            and the bigger share you will get!`,
                benefits: "The benefits of staking at RealColibri:",
                list: [
                    "Passive income: No complicated actions are required. Just freeze your tokens and get rewards.",
                    "Asset growth: your RCBCs will grow (both in number and value) even if you are not trading and bringing people in.",
                    "Transparency and fairness: The distribution of the market fees is fair and transparent. Each staker receives his share according to the size and duration of the staking.",
                ],
                joinNow: "Don't miss the opportunity to increase your RCBC asset with minimal effort! Use staking right now and let your tokens work for you!"
            },
            faq: {
                header: "FAQ",
                questions: [
                    {
                        question: "How do I start trading?",
                        answer: `To start automated trading you will need: <br/>
                            Register on our website and connect TronLink wallet. <br/>
                            Purchase RCBC and refill the commission deposit in your RealColibri account. <br/>
                            Connect your exchange trading account on the trading page using API keys,
                            select a trading instrument and start the algorithm.`,
                    },
                    {
                        question: "How much can I start trading with and what is the return?",
                        answer: `The minimum deposit is 10,000 USDT. The optimal deposit size should be at least
                            3.75 times the minimum deposit. If the deposit size is lower than the optimal
                            one, the monthly profit depends on the minimum deposit size. If the deposit
                            size is higher than the optimal one, the calculation of compound interest is
                            enabled, and the monthly profit depends on the current deposit size and will
                            increase proportionally to the deposit size. Every 5% of profit increases the
                            position volume by 5%. Therefore, the larger the deposit, the higher the return.`,
                    },
                    {
                        question: "Can I try autotrading for free?",
                        answer: `Yes, we offer the opportunity to try out the algorithm for free for a week. 
                            During this period you will not need to have RCBC to run the algorithm. 
                            To activate the free trial period, send an email to mail@realcolibri.com with 
                            the appropriate request and your Tronlink wallet address, with which you 
                            registered on our website, and we will provide you with access as soon as 
                            possible. Each customer you refer to our system guarantees you one additional 
                            week of free period. Just invite a friend, provide the address of their 
                            TronLink wallet registered in our system and we will give you one more 
                            week of free trial period. Keep in mind that you need to be registered in our
                            system through TronLink (Complete access) to get your free trial.`,
                    },
                    {
                        question: "Where does the profit go?",
                        answer: `The profit received remains in your exchange account in its entirety.`,
                    },
                    {
                        question: "How do I acquire RCBC?",
                        answer: `Use the form in your RealColibri account or on
                            the Global markets and Crypto markets pages.`,
                    },
                    {
                        question: "What can I do with RCBC?",
                        answer: `Fund the commission deposit and use them as a commission for using autotrading. <br/>
                            Lock them into the staking system and accumulate profits. Read more in the Staking section. <br/>
                            Sell directly to another RealColibri member who wishes to purchase RCBC. <br/>
                            Trade them on exchanges once RCBC is listed in the future.`,
                    },
                    {
                        question: "How do I start earning in the referral and staking system?",
                        answer: `You will need to register via blockchain to join the referral system and staking.
                            You can do this immediately when you first register on our website by
                            clicking Register with TronLink in the Complete Access section, or later using
                            the Get complete access button in your RealColibri account (you can also specify the TronLink address of the person
                            who invited you in the corresponding field). The TRON blockchain charges a
                            transaction fee of ≈606 TRX.`,
                    }
                ]
            }
        }
    },
    es: {
        about: {
            learnMore: "Aprende más sobre RealColibri",
            autotrading: {
                header: "Automatización",
                welcome: "¡Bienvenido al mundo del trading de alta tecnología con RealColibri!",
                description: `RealColibri es un sistema de trading automatizado avanzado que se especializa en contratos
                            de futuros de intercambios de criptomonedas y mercados globales. Nuestro sistema se caracteriza
                            por un éxito sobresaliente a largo plazo y un enfoque innovador en la inversión.`,
                unique: "¿Qué hace único al sistema RealColibri?",
                list: [
                    "Resultados excelentes: RealColibri ofrece resultados impresionantes a largo plazo. Nuestro sistema se basa en algoritmos avanzados y un análisis riguroso de datos, lo que nos permite lograr un crecimiento de inversión estable y significativo, así como minimizar el riesgo.",
                    "Enfoque automatizado: ¡Olvídate de la necesidad de monitorear constantemente el mercado! RealColibri analiza automáticamente miles de puntos de datos y toma decisiones de trading en tiempo real, liberándote del estrés y aumentando tu rendimiento de inversión.",
                    "Diversificación: La capacidad de operar en diferentes instrumentos dentro de una cuenta creando pares adicionales de clave API o usuario-contraseña y utilizándolos en múltiples cuentas RealColibri.",
                    `Uso del token RCBC: RealColibri utiliza RCBC como comisión por servicios en la cantidad del 50% del tamaño del depósito. El depósito de comisión se gasta en cada operación. Esta solución innovadora permite a los inversores "alimentar" el algoritmo para operar, asegurando que siempre esté funcionando y optimizando el proceso de inversión. Habiendo gastado 1 RCBC en la comisión, el inversor recibe 2 veces más en su cuenta de trading. Al mismo tiempo, el inversor mantiene el beneficio en su totalidad. Y gracias al crecimiento constante del valor de RCBC, la comisión de RealColibri se vuelve cada vez menor con el tiempo.`,
                    `Compensación total de tarifas del mercado: RealColibri ofrece la opción de compensación total de tarifas del mercado distribuyendo la cantidad correspondiente de RCBC equitativamente entre
                    los sistemas de referidos y staking. Esto permite a los inversores maximizar sus inversiones y aumentar su rentabilidad.`,
                    "Período de prueba gratuito: ¡Prueba nuestro algoritmo de forma gratuita durante una semana! También puedes extender este período por una semana más por cada cliente que invites a nuestro sistema. Lee más sobre esta característica en la sección de FAQ.",
                ]
            },
            realColibriCoin: {
                header: "Moneda RealColibri",
                welcome: "¡Abre nuevos horizontes con la Moneda RealColibri!",
                description: `RCBC es más que una criptomoneda. Es un elemento clave de todo nuestro ecosistema, contribuyendo a su crecimiento y desarrollo constante.
                            Piensa en RCBC como el combustible que impulsa nuestros servicios y los hace aún más poderosos y eficientes. El constante giro de RCBC en el ecosistema RealColibri contribuye al valor del token y crea un entorno favorable para todos los participantes:`,
                list: [
                    `Comisión favorable: Cuanto mayor sea el valor de RCBC, menos tokens se cobrarán por los servicios de automatización de trading de RealColibri, haciéndolos aún más asequibles y atractivos para los traders.`,
                    `Compensación total de tarifas del mercado: RealColibri compensa totalmente las tarifas del mercado generadas por todos los usuarios de nuestro sistema. El 50% de esta cantidad se contribuye al sistema de referidos y el otro 50% al sistema de staking, luego se distribuye a los participantes según las condiciones respectivas.`,
                    `Aumenta los beneficios de referidos: El aumento constante del precio de RCBC aumenta tu beneficio del programa de referidos. Invita a nuevos miembros al ecosistema de RealColibri y gana cada vez más recompensas, lo que también aumentará tus activos a medida que crezca el valor del token.`,
                    `Ganancias por staking: Al utilizar RCBC para el staking, tienes una oportunidad adicional de obtener ingresos. A medida que el valor de RCBC en el ecosistema RealColibri crece, tu ganancia por staking solo aumentará, haciendo que este método de inversión sea aún más atractivo.`,
                    `Intercambio de tokens: Nuestro ecosistema también permite a los usuarios vender/intercambiar RCBC entre ellos sin la intervención de RealColibri. En el futuro, cuando listemos RCBC en intercambios centralizados y descentralizados, el token será negociable. Esto te da aún más libertad y flexibilidad en la gestión de tus activos.`,
                ],
                whatCanIDo: "¿Qué puedo hacer con RCBC?",
                actions: [
                    "Financiar el depósito de la comisión y utilizarlos como comisión por el uso de la automatización del trading.",
                    "Bloquearlos en el sistema de staking y acumular beneficios. Lee más en la sección de Staking.",
                    "Vender directamente a otro miembro de RealColibri que desee comprar RCBC.",
                    "Intercambiarlos en los intercambios una vez que RCBC esté listado en el futuro.",
                ],
                joinNow: "¡Únete a RealColibri hoy y descubre nuevas oportunidades y caminos hacia el éxito financiero!"
            },
            referralSystem: {
                header: "Sistema de referidos",
                welcome: "¡Construye tu negocio con nuestro sistema de referidos y recibe el 50% de las tarifas de mercado de tus clientes!",
                description: `El sistema de referidos de RealColibri te permite ganar refiriendo nuevos usuarios a nuestra plataforma. Solo comparte tu enlace único con tus amigos, colegas o suscriptores
                            y recibe el 50% de las tarifas de mercado generadas por ellos y/o sus referidos en nuestra plataforma!`,
                howItWorks: "¿Cómo funciona?",
                list: [
                    "Trae nuevos usuarios: Obtén tu enlace único y compártelo con amigos, colegas y en las redes sociales.",
                    `Gana con cada operación: Cuando tus referidos operan en nuestra plataforma y generan
                    tarifas de mercado, recibes el 50% de esas tarifas como recompensa en el token RCBC.`,
                    `Estructura multinivel: La profundidad de los niveles de referidos es infinita, y las recompensas
                    se dividen entre los participantes del programa en la proporción 80/20 - 80% van para ti,
                    el 20% restante va para el nivel superior. Se te recompensa no solo por la
                    actividad de tus referidos directos (80%), sino también por la actividad de sus
                    propios referidos (20%), y así sucesivamente hasta el infinito. Esto significa que tu potencial
                    de ingresos crece con cada nuevo usuario en tu cadena.`,
                    `Ejemplo: Supongamos que invitaste a Alex a nuestra plataforma.
                    Durante un mes Alex operó 5000 USD y la tarifa de mercado promedió el 30%, o 1500 USD.
                    La mitad de esta cantidad (750 USD) irá al programa de referidos en equivalente RCBC.
                    600 USD (80%) irán para ti y los restantes 150 USD (20%) irán para el nivel superior
                    y se dividen entre el que te invitó y el que lo invitó
                    (80/20), y así hasta el primer invitado en esa cadena. Después de eso,
                    Alex también trajo a Jane a nuestra plataforma. En el mismo mes Jane operó 3000 USD.
                    La tarifa de mercado también promedió el 30%, o 900 USD. La mitad de esta cantidad (450 USD) también irá
                    al programa de referidos en equivalente RCBC. De esta cantidad 360 USD (80%) irán para
                    Alex que invitó a Jane, y los restantes 90 USD (20%) se dividirán entre
                    tú y la persona que te invitó a la plataforma (80%/20% o 72 USD/18
                    USD). Esto significa que obtuviste 600 USD + 72 USD = 672 USD en el token RCBC en un mes invitando
                    a solo un usuario. ¡Pero imagina si hubiera 5, 10 o más!`,
                ],
                joinNow: "¡Únete a nuestro programa de referidos ahora y empieza a aumentar tus ingresos con nosotros!"
            },
            staking: {
                header: "Staking",
                welcome: "¡Aumenta tu activo RCBC de forma pasiva con el staking!",
                description: `¿Quieres aumentar tu activo RCBC sin ningún esfuerzo adicional?
                            ¡Ahora es posible gracias a nuestra función de staking!`,
                whatIsStaking: "¿Qué es el staking?",
                stakingDescription: `Es un proceso por el cual puedes congelar (o bloquear)
                            tus RCBCs y aumentar pasivamente tu activo.`,
                howItWorks: "¿Cómo funciona?",
                howItWorksDescription: `Simplemente congela tus tokens, y a partir de ahora
                            acumularás continuamente en tu cuenta una parte del 50% de las tarifas de mercado negociadas
                            de todos los usuarios de RealColibri en proporción a tu peso de staking.
                            Cuantos más y por más tiempo tengas tokens RCBC, mayor será tu peso de staking,
                            ¡y mayor será la parte que obtendrás!`,
                benefits: "Los beneficios del staking en RealColibri:",
                list: [
                    "Ingresos pasivos: No se requieren acciones complicadas. Simplemente congela tus tokens y obtén recompensas.",
                    "Crecimiento de activos: tus RCBCs crecerán (tanto en número como en valor) incluso si no estás operando ni trayendo personas.",
                    "Transparencia y equidad: La distribución de las tarifas de mercado es justa y transparente. Cada participante de staking recibe su parte según el tamaño y la duración del staking.",
                ],
                joinNow: "¡No pierdas la oportunidad de aumentar tu activo RCBC con el mínimo esfuerzo! ¡Usa el staking ahora mismo y deja que tus tokens trabajen para ti!"
            },
            faq: {
                header: "FAQ",
                questions: [
                    {
                        question: "¿Cómo empiezo a operar?",
                        answer: `Para empezar a operar automáticamente necesitarás: <br/>
                            Regístrate en nuestro sitio web y conecta la billetera TronLink. <br/>
                            Compra RCBC y recarga el depósito de la comisión en tu cuenta RealColibri. <br/>
                            Conecta tu cuenta de trading en la página de trading usando claves API,
                            selecciona un instrumento de trading y comienza el algoritmo.`,
                    },
                    {
                        question: "¿Con cuánto puedo empezar a operar y cuál es el retorno?",
                        answer: `El depósito mínimo es de 10,000 USDT. El tamaño óptimo del depósito debe ser al menos
                            3.75 veces el depósito mínimo. Si el tamaño del depósito es menor que el óptimo,
                            la ganancia mensual depende del tamaño del depósito mínimo. Si el tamaño del depósito
                            es mayor que el óptimo, se habilita el cálculo del interés compuesto, y la ganancia mensual depende
                            del tamaño actual del depósito y aumentará proporcionalmente al tamaño del depósito. Cada 5% de ganancia aumenta el
                            volumen de la posición en un 5%. Por lo tanto, cuanto mayor sea el depósito, mayor será el retorno.`,
                    },
                    {
                        question: "¿Puedo probar el trading automático gratis?",
                        answer: `Sí, ofrecemos la oportunidad de probar el algoritmo de forma gratuita durante una semana. 
                            Durante este período no necesitarás tener RCBC para ejecutar el algoritmo. 
                            Para activar el período de prueba gratuito, envía un correo a mail@realcolibri.com con 
                            la solicitud correspondiente y tu dirección de billetera Tronlink, con la que te 
                            registraste en nuestro sitio web, y te proporcionaremos acceso lo antes posible. 
                            Cada cliente que refieras a nuestro sistema te garantiza una semana adicional 
                            de período de prueba gratuito. Solo invita a un amigo, proporciona la dirección de su 
                            billetera TronLink registrada en nuestro sistema y te daremos una semana más 
                            de período de prueba gratuito. Ten en cuenta que necesitas estar registrado en nuestro
                            sistema a través de TronLink (Acceso Completo) para obtener tu prueba gratuita.`,
                    },
                    {
                        question: "¿Dónde va la ganancia?",
                        answer: `La ganancia recibida permanece en tu cuenta de intercambio en su totalidad.`,
                    },
                    {
                        question: "¿Cómo adquiero RCBC?",
                        answer: `Utiliza el formulario en tu cuenta RealColibri o en
                            las páginas de Mercados Globales y Mercados de Cripto.`,
                    },
                    {
                        question: "¿Qué puedo hacer con RCBC?",
                        answer: `Financiar el depósito de la comisión y utilizarlos como comisión por el uso de la automatización del trading. <br/>
                            Bloquearlos en el sistema de staking y acumular beneficios. Lee más en la sección de Staking. <br/>
                            Vender directamente a otro miembro de RealColibri que desee comprar RCBC. <br/>
                            Intercambiarlos en los intercambios una vez que RCBC esté listado en el futuro.`,
                    },
                    {
                        question: "¿Cómo empiezo a ganar en el sistema de referidos y staking?",
                        answer: `Necesitarás registrarte a través de blockchain para unirte al sistema de referidos y staking.
                            Puedes hacerlo inmediatamente cuando te registres por primera vez en nuestro sitio web haciendo
                            clic en Regístrate con TronLink en la sección Acceso Completo, o más tarde usando
                            el botón Obtener acceso completo en tu cuenta RealColibri (también puedes especificar la dirección TronLink de la persona
                            que te invitó en el campo correspondiente). La blockchain de TRON cobra una
                            tarifa de transacción de ≈606 TRX.`,
                    }
                ]
            }
        }
    },
    ru: {
        about: {
            learnMore: "Узнайте больше о RealColibri",
            autotrading: {
                header: "Автоматическая торговля",
                welcome: "Добро пожаловать в мир высокотехнологичной торговли с RealColibri!",
                description: `RealColibri — это передовая автоматизированная система торговли, специализирующаяся на фьючерсных
                            контрактах криптовалютных бирж и глобальных рынков. Наша система характеризуется
                            выдающимися долгосрочными успехами и инновационным подходом к инвестированию.`,
                unique: "Что делает систему RealColibri уникальной?",
                list: [
                    "Превосходные результаты: RealColibri обеспечивает впечатляющие результаты в долгосрочной перспективе. Наша система основана на передовых алгоритмах и тщательном анализе данных, что позволяет нам достигать стабильного и значительного роста инвестиций, а также минимизировать риски.",
                    "Автоматический подход: Забудьте о необходимости постоянно следить за рынком! RealColibri автоматически анализирует тысячи точек данных и принимает торговые решения в реальном времени, освобождая вас от стресса и повышая вашу инвестиционную эффективность.",
                    "Диверсификация: Возможность торговать разными инструментами в одной учетной записи, создавая дополнительные пары ключей API или логин-пароль и используя их на нескольких учетных записях RealColibri.",
                    `Использование токена RCBC: RealColibri использует RCBC в качестве комиссии за услуги в размере 50% от суммы депозита. Комиссионный депозит тратится на каждую сделку. Это инновационное решение позволяет инвесторам "питать" алгоритм для торговли, гарантируя его постоянную работу и оптимизацию инвестиционного процесса. Потратив 1 RCBC на комиссию, инвестор получает в 2 раза больше на своем торговом счете! При этом инвестор сохраняет всю прибыль. А благодаря постоянному росту стоимости RCBC комиссия RealColibri со временем становится все меньше и меньше.`,
                    `Полная компенсация рыночной комиссии: RealColibri предоставляет возможность полной компенсации
                    рыночной комиссии, распределяя соответствующую сумму RCBC поровну между реферальной и стейкинговой системами. Это позволяет инвесторам максимизировать свои инвестиции и увеличить их доходность.`,
                    "Бесплатный пробный период: Попробуйте наш алгоритм бесплатно в течение одной недели! Вы также можете продлить этот период на еще одну неделю за каждого клиента, которого вы пригласите в нашу систему. Подробнее об этой функции читайте в разделе FAQ.",
                ]
            },
            realColibriCoin: {
                header: "Монета RealColibri",
                welcome: "Откройте новые горизонты с монетой RealColibri!",
                description: `RCBC — это не просто криптовалюта. Это ключевой элемент всей нашей экосистемы, способствующий ее постоянному росту и развитию.
                            Думайте о RCBC как о топливе, которое питает наши услуги и делает их еще более мощными и эффективными. Постоянный оборот RCBC в экосистеме RealColibri способствует росту стоимости токена и создает благоприятную среду для всех участников:`,
                list: [
                    `Выгодная комиссия: Чем выше стоимость RCBC, тем меньше токенов будет взиматься за услуги автоматизации торговли RealColibri, делая их еще более доступными и привлекательными для трейдеров.`,
                    `Полная компенсация рыночной комиссии: RealColibri полностью компенсирует рыночную комиссию, генерируемую всеми пользователями нашей системы. 50% этой суммы вносится в реферальную систему, а оставшиеся 50% — в стейкинговую систему, затем распределяется между участниками в соответствии с условиями.`,
                    `Увеличение прибыли от рефералов: Постоянный рост цены RCBC увеличивает вашу выгоду от реферальной программы. Приглашайте новых участников в экосистему RealColibri и зарабатывайте все больше и больше вознаграждений, которые также увеличат ваши активы по мере роста стоимости токена.`,
                    `Доход от стейкинга: Используя RCBC для стейкинга, вы получаете дополнительную возможность заработать доход. По мере роста стоимости RCBC в экосистеме RealColibri ваш доход от стейкинга будет только увеличиваться, делая этот метод инвестирования еще более привлекательным.`,
                    `Обмен токенов: Наша экосистема также позволяет пользователям продавать/обменивать RCBC друг с другом без участия RealColibri. В будущем, когда мы листим RCBC на централизованных и децентрализованных биржах, токен будет торгуемым. Это дает вам еще больше свободы и гибкости в управлении вашими активами.`,
                ],
                whatCanIDo: "Что я могу сделать с RCBC?",
                actions: [
                    "Пополнить комиссионный депозит и использовать их в качестве комиссии за использование автоматической торговли.",
                    "Заблокировать их в системе стейкинга и накапливать прибыль. Подробнее читайте в разделе Стейкинг.",
                    "Продать напрямую другому участнику RealColibri, который желает приобрести RCBC.",
                    "Торговать ими на биржах, когда RCBC будет листиться в будущем.",
                ],
                joinNow: "Присоединяйтесь к RealColibri сегодня и откройте новые возможности и пути к финансовому успеху!"
            },
            referralSystem: {
                header: "Реферальная система",
                welcome: "Стройте свой бизнес с нашей реферальной системой и получайте 50% рыночных сборов ваших клиентов!",
                description: `Реферальная система RealColibri позволяет вам зарабатывать, привлекая новых пользователей на нашу платформу. Просто поделитесь своей уникальной ссылкой с друзьями, коллегами или подписчиками
                            и получайте 50% рыночных сборов, сгенерированных ими и/или их рефералами на нашей платформе!`,
                howItWorks: "Как это работает?",
                list: [
                    "Привлекайте новых пользователей: Получите свою уникальную ссылку и поделитесь ею с друзьями, коллегами и в социальных сетях.",
                    `Зарабатывайте с каждой сделки: Когда ваши рефералы торгуют на нашей платформе и генерируют
                    рыночные сборы, вы получаете 50% этих сборов в качестве вознаграждения в токене RCBC.`,
                    `Многоуровневая структура: Глубина реферальных уровней бесконечна, и вознаграждения
                    делятся между участниками программы в соотношении 80/20 - 80% идут вам,
                    оставшиеся 20% идут на уровень выше. Вам вознаграждается не только за
                    активность ваших прямых рефералов (80%), но и за активность их
                    собственных рефералов (20%) и так далее до бесконечности. Это означает, что ваш потенциал
                    дохода растет с каждым новым пользователем в вашей цепочке.`,
                    `Пример: Допустим, вы пригласили Алекса на нашу платформу.
                    За месяц Алекс торговал на 5000 USD, а средний рыночный сбор составил 30%, или 1500 USD.
                    Половина этой суммы (750 USD) пойдет на реферальную программу в эквиваленте RCBC.
                    600 USD (80%) пойдут вам, а оставшиеся 150 USD (20%) пойдут на уровень выше
                    и делятся между тем, кто пригласил вас, и тем, кто пригласил его
                    (80/20), и так до первого приглашенного в этой цепочке. После этого,
                    Алекс также привел Джейн на нашу платформу. В том же месяце Джейн торговала на 3000 USD.
                    Средний рыночный сбор также составил 30%, или 900 USD. Половина этой суммы (450 USD) также пойдет
                    на реферальную программу в эквиваленте RCBC. Из этой суммы 360 USD (80%) пойдут
                    Алексу, который пригласил Джейн, а оставшиеся 90 USD (20%) будут разделены между
                    вами и человеком, который пригласил вас на платформу (80%/20% или 72 USD/18
                    USD). Это означает, что вы получили 600 USD + 72 USD = 672 USD в токене RCBC за месяц, пригласив
                    только одного пользователя. Но представьте, если бы их было 5, 10 или больше!`,
                ],
                joinNow: "Присоединяйтесь к нашей реферальной программе сейчас и начните увеличивать свой доход с нами!"
            },
            staking: {
                header: "Стейкинг",
                welcome: "Увеличивайте свой актив RCBC пассивно с помощью стейкинга!",
                description: `Хотите увеличить свой актив RCBC без лишних усилий?
                            Теперь это возможно благодаря нашей функции стейкинга!`,
                whatIsStaking: "Что такое стейкинг?",
                stakingDescription: `Это процесс, при котором вы можете заморозить (или заблокировать)
                            свои RCBC и пассивно увеличить свой актив.`,
                howItWorks: "Как это работает?",
                howItWorksDescription: `Просто заморозьте свои токены, и с этого момента
                            вы будете непрерывно накапливать на своем счете долю 50% рыночных сборов
                            всех пользователей RealColibri пропорционально вашему весу стейкинга.
                            Чем больше и дольше у вас есть токены RCBC, тем выше ваш вес стейкинга,
                            и тем большую долю вы получите!`,
                benefits: "Преимущества стейкинга в RealColibri:",
                list: [
                    "Пассивный доход: Не требуются сложные действия. Просто заморозьте свои токены и получайте вознаграждения.",
                    "Рост активов: ваши RCBC будут расти (как в количестве, так и в стоимости) даже если вы не торгуете и не привлекаете людей.",
                    "Прозрачность и справедливость: Распределение рыночных сборов является справедливым и прозрачным. Каждый участник стейкинга получает свою долю в зависимости от размера и продолжительности стейкинга.",
                ],
                joinNow: "Не упустите возможность увеличить свой актив RCBC с минимальными усилиями! Используйте стейкинг прямо сейчас и пусть ваши токены работают на вас!"
            },
            faq: {
                header: "FAQ",
                questions: [
                    {
                        question: "Как начать торговать?",
                        answer: `Чтобы начать автоматическую торговлю, вам нужно: <br/>
                            Зарегистрируйтесь на нашем сайте и подключите кошелек TronLink. <br/>
                            Приобретите RCBC и пополните комиссионный депозит в своем аккаунте RealColibri. <br/>
                            Подключите свою торговую учетную запись на странице торговли, используя API-ключи,
                            выберите торговый инструмент и запустите алгоритм.`,
                    },
                    {
                        question: "С какой суммы я могу начать торговать и какой доход?",
                        answer: `Минимальный депозит составляет 10,000 USDT. Оптимальный размер депозита должен быть не менее
                            3.75 раз больше минимального депозита. Если размер депозита меньше оптимального,
                            ежемесячная прибыль зависит от минимального размера депозита. Если размер депозита
                            больше оптимального, включается расчет сложного процента, и ежемесячная прибыль зависит
                            от текущего размера депозита и будет увеличиваться пропорционально размеру депозита. Каждые 5% прибыли увеличивают
                            объем позиции на 5%. Поэтому чем больше депозит, тем выше доход.`,
                    },
                    {
                        question: "Могу ли я попробовать автоматическую торговлю бесплатно?",
                        answer: `Да, мы предлагаем возможность попробовать алгоритм бесплатно в течение недели. 
                            В течение этого периода вам не нужно будет иметь RCBC для запуска алгоритма. 
                            Чтобы активировать бесплатный пробный период, отправьте электронное письмо на адрес mail@realcolibri.com с 
                            соответствующим запросом и адресом вашего кошелька Tronlink, с которым вы 
                            зарегистрировались на нашем сайте, и мы предоставим вам доступ как можно скорее. 
                            Каждый клиент, которого вы пригласите в нашу систему, гарантирует вам одну дополнительную 
                            неделю бесплатного периода. Просто пригласите друга, предоставьте адрес его 
                            кошелька TronLink, зарегистрированного в нашей системе, и мы дадим вам еще одну 
                            неделю бесплатного пробного периода. Учтите, что вам нужно быть зарегистрированным в нашей
                            системе через TronLink (Полный доступ), чтобы получить бесплатный пробный период.`,
                    },
                    {
                        question: "Куда идет прибыль?",
                        answer: `Полученная прибыль остается на вашем биржевом счете в полном объеме.`,
                    },
                    {
                        question: "Как приобрести RCBC?",
                        answer: `Используйте форму в своем аккаунте RealColibri или на
                            страницах Глобальные рынки и Криптовалютные рынки.`,
                    },
                    {
                        question: "Что я могу сделать с RCBC?",
                        answer: `Пополните комиссионный депозит и используйте их в качестве комиссии за использование автоматической торговли. <br/>
                            Заблокируйте их в системе стейкинга и накапливайте прибыль. Подробнее читайте в разделе Стейкинг. <br/>
                            Продайте напрямую другому участнику RealColibri, который желает приобрести RCBC. <br/>
                            Торгуйте ими на биржах, когда RCBC будет листиться в будущем.`,
                    },
                    {
                        question: "Как начать зарабатывать в реферальной и стейкинговой системе?",
                        answer: `Вам нужно будет зарегистрироваться через блокчейн, чтобы присоединиться к реферальной системе и стейкингу.
                            Вы можете сделать это сразу, когда впервые регистрируетесь на нашем сайте, нажав Регистрация с TronLink в разделе Полный доступ, или позже, используя
                            кнопку Получить полный доступ в своем аккаунте RealColibri (вы также можете указать адрес TronLink человека,
                            который вас пригласил, в соответствующем поле). Блокчейн TRON взимает
                            комиссию за транзакцию в размере ≈606 TRX.`,
                    }
                ]
            }
        }
    },
    hy: {
        about: {
          learnMore: "Իմացեք ավելին RealColibri-ի մասին",
          autotrading: {
            header: "Ավտոմատ առևտուր",
            welcome: "Բարի գալուստ բարձր տեխնոլոգիական առևտրի աշխարհ՝ RealColibri-ի հետ!",
            description: `RealColibri-ն առաջատար ավտոմատացված առևտրի համակարգ է, որը մասնագիտացված է կրիպտոարժույթների բորսաների
                          և համաշխարհային շուկաների ֆյուչերսային պայմանագրերի վրա։ Մեր համակարգը բնութագրվում է երկարաժամկետ հաջողություններով
                          և նորարարական ներդրումային մոտեցմամբ։`,
            unique: "Ինչ է դարձնում RealColibri համակարգը եզակի?",
            list: [
              "Բարձր արդյունքներ: RealColibri-ն ապահովում է տպավորիչ արդյունքներ երկարաժամկետ հեռանկարում։ Մեր համակարգը հիմնված է առաջադեմ ալգորիթմների վրա և մանրակրկիտ տվյալների վերլուծությամբ, ինչը թույլ է տալիս հասնել կայուն և նշանակալի ներդրումային աճին, ինչպես նաև նվազեցնել ռիսկերը։",
              "Ավտոմատացված մոտեցում: Մոռացեք շուկան մշտապես հետևելու անհրաժեշտության մասին: RealColibri-ն ավտոմատ կերպով վերլուծում է հազարավոր տվյալների կետեր և իրական ժամանակում ընդունում առևտրային որոշումներ, ազատելով ձեզ սթրեսից և բարձրացնելով ձեր ներդրումային արդյունավետությունը:",
              "Դիվերսիֆիկացիա: Մեկ հաշվում հնարավոր է տարբեր գործիքներով առևտուր իրականացնել, ստեղծելով հավելյալ API բանալիների զույգեր կամ լոգին-գաղտնաբառ և օգտագործելով դրանք մի քանի RealColibri հաշիվներում:",
              `RCBC թոքենի օգտագործումը: RealColibri-ն օգտագործում է RCBC որպես ծառայությունների միջնորդավճար 50%-ով հանած գումարի գումարից: Միջնորդավճարային դեպոզիտը ծախսվում է յուրաքանչյուր գործարքի վրա: Այս նորարարական լուծումը թույլ է տալիս ներդրողներին "կերակրել" ալգորիթմը առևտրի համար՝ ապահովելով նրա մշտական աշխատանքը և ներդրումային գործընթացի օպտիմալացումը։ Մեկ RCBC ծախսելով միջնորդավճարով, ներդրողը ստանում է կրկնակի ավելին իր առևտրային հաշվին! Բացի այդ, RCBC արժեքի շարունակական աճի շնորհիվ RealColibri-ի միջնորդավճարը ժամանակի ընթացքում դառնում է ավելի փոքր և ավելի փոքր:`,
              `Շուկայի միջնորդավճարի ամբողջական փոխհատուցում: RealColibri-ն առաջարկում է շուկայի միջնորդավճարի ամբողջական փոխհատուցում՝ համապատասխան RCBC գումարը հավասարապես բաժանելով ռեֆերալային և ստեյքինգ համակարգերի միջև։ Սա թույլ է տալիս ներդրողներին առավելագույնի հասցնել իրենց ներդրումներն ու մեծացնել իրենց եկամտաբերությունը:`,
              "Անվճար փորձաշրջան: Փորձեք մեր ալգորիթմը անվճար մեկ շաբաթվա ընթացքում: Դուք կարող եք նաև երկարաձգել այս շրջանը յուրաքանչյուր հաճախորդի համար, որին դուք հրավիրեք մեր համակարգում։ Այս գործառույթի մասին ավելի մանրամասն կարդացեք FAQ բաժնում:",
            ]
          },
          realColibriCoin: {
            header: "RealColibri մետաղադրամ",
            welcome: "Բացահայտեք նոր հորիզոններ RealColibri մետաղադրամով!",
            description: `RCBC-ն պարզապես կրիպտոարժույթ չէ։ Դա մեր էկոհամակարգի հիմնական տարրն է, որը նպաստում է նրա շարունակական աճին և զարգացմանը։ Կարծեք RCBC-ի մասին որպես վառելիք, որը սնուցում է մեր ծառայությունները և դրանք դարձնում ավելի հզոր և արդյունավետ: RCBC-ի շարունակական շրջանառությունը RealColibri էկոհամակարգում նպաստում է թոքենի արժեքի աճին և ստեղծում բարենպաստ միջավայր բոլոր մասնակիցների համար։`,
            list: [
              `Հարմարավետ միջնորդավճար: Որքան բարձր է RCBC-ի արժեքը, այնքան պակաս թոքեններ են պահանջվում RealColibri-ի ավտոմատացված առևտրային ծառայությունների համար, դարձնելով դրանք ավելի մատչելի և գրավիչ առևտրականների համար։`,
              `Շուկայի միջնորդավճարի ամբողջական փոխհատուցում: RealColibri-ն ամբողջությամբ փոխհատուցում է շուկայի միջնորդավճարը, որը ստեղծվել է մեր համակարգի բոլոր օգտատերերի կողմից։ Այս գումարի 50%-ը բաժանվում է ռեֆերալային համակարգին, իսկ մնացած 50%-ը՝ ստեյքինգ համակարգին, այնուհետև բաժանվում է մասնակիցների միջև ըստ պայմանների։`,
              `Ռեֆերալներից եկամուտի աճ: RCBC-ի արժեքի շարունակական աճը մեծացնում է ձեր եկամուտը ռեֆերալ ծրագրից։ Հրավիրեք նոր մասնակիցներ RealColibri-ի էկոհամակարգում և ստացեք ավելի ու ավելի շատ պարգևներ, որոնք կավելացնեն ձեր ակտիվները թոքենի արժեքի աճի հետ։`,
              `Ստեյքինգից եկամուտ: RCBC-ի օգտագործումը ստեյքինգի համար թույլ է տալիս ստանալ լրացուցիչ եկամուտ։ RCBC-ի արժեքի աճի հետ ձեր ստեյքինգի եկամուտը միայն կավելանա, դարձնելով այս ներդրումային մեթոդը ավելի գրավիչ։`,
              `Թոքենների փոխանակում: Մեր էկոհամակարգը նաև թույլ է տալիս օգտատերերին վաճառել/փոխանակել RCBC առանց RealColibri-ի միջամտության։ Ապագայում, երբ մենք կցուցենք RCBC-ն կենտրոնացված և դեչենտրալիզացված բորսաներում, թոքենը կլինի առևտրի առարկա։ Սա ձեզ ավելի շատ ազատություն և ճկունություն է տալիս ձեր ակտիվների կառավարմանը։`,
            ],
            whatCanIDo: "Ինչ կարող եմ անել RCBC-ով?",
            actions: [
              "Լրացնել միջնորդավճարային դեպոզիտը և օգտագործել այն որպես ավտոմատացված առևտրի ծառայության համար միջնորդավճար։",
              "Ապահովել դրանք ստեյքինգ համակարգում և ստանալ շահույթ։ Մանրամասները կարդացեք Ստեյքինգ բաժնում։",
              "Վաճառել անմիջապես այլ RealColibri-ի մասնակցի, ով ցանկանում է ձեռք բերել RCBC։",
              "Առևտրել դրանցով բորսաներում, երբ RCBC-ն ցուցակագրվի ապագայում։",
            ],
            joinNow: "Միացեք RealColibri-ին այսօր և բացահայտեք նոր հնարավորություններ ու ուղիներ դեպի ֆինանսական հաջողություն!"
          },
          referralSystem: {
            header: "Ռեֆերալ համակարգ",
            welcome: "Կառուցեք ձեր բիզնեսը մեր ռեֆերալ համակարգով և ստացեք 50% շուկայական միջնորդավճար ձեր հաճախորդների համար!",
            description: `RealColibri-ի ռեֆերալ համակարգը թույլ է տալիս ձեզ գումար վաստակել՝ հրավիրելով նոր օգտատերեր մեր պլատֆորմին: Պարզապես կիսվեք ձեր յուրահատուկ հղումով ընկերների, գործընկերների կամ հետևորդների հետ
                          և ստացեք 50% շուկայական միջնորդավճար, որը ստեղծվել է նրանց և/կամ նրանց ռեֆերալների կողմից մեր պլատֆորմում!`,
            howItWorks: "Ինչպես է այն աշխատում?",
            list: [
              "Հրավիրեք նոր օգտատերեր: Ստացեք ձեր յուրահատուկ հղումը և կիսվեք այն ձեր ընկերների, գործընկերների և սոցիալական ցանցերում։",
              `Վաստակեք յուրաքանչյուր գործարքից: Երբ ձեր ռեֆերալները առևտուր են իրականացնում մեր պլատֆորմում և ստեղծում շուկայական միջնորդավճար, դուք ստանում եք 50% այդ միջնորդավճարներից որպես պարգևատրում RCBC թոքենով։`,
              `Բազմաշերտ կառուցվածք: Ռեֆերալների մակարդակի խորությունը անսահման է, և պարգևատրումները բաժանվում են ծրագրի մասնակիցների միջև 80/20 հարաբերակցությամբ՝ 80% գնում է ձեզ, իսկ մնացած 20%-ը գնում է մեկ մակարդակ վեր։ Ձեզ պարգևատրում է ոչ միայն ձեր ուղղակի ռեֆերալների ակտիվության համար (80%), այլև նրանց ռեֆերալների ակտիվության համար (20%) և այլն մինչև անսահմանություն։ Սա նշանակում է, որ ձեր եկամտի ներուժը աճում է յուրաքանչյուր նոր օգտատերով ձեր շղթայում։`,
              `Օրինակ: Պատկերացրեք, որ դուք հրավիրել եք Ալեքսին մեր պլատֆորմին։ 
               Մեկ ամսվա ընթացքում Ալեքսը առևտրել է 5000 USD, իսկ միջին շուկայական միջնորդավճարը կազմել է 30%, կամ 1500 USD։ 
               Այս գումարի կեսը (750 USD) կգնա ռեֆերալային ծրագրին RCBC-ի էկվիվալենտով։ 
               600 USD (80%) կգնա ձեզ, իսկ մնացած 150 USD (20%) կգնա մեկ մակարդակ վեր 
               և կբաժանվի այն մարդուն, ով հրավիրել է ձեզ, և այն մարդուն, ով հրավիրել է նրան 
               (80/20), և այսպես շարունակ մինչև առաջին հրավիրվածը այս շղթայում։ 
               Հետո Ալեքսն էլ հրավիրել է Ջեյնին մեր պլատֆորմին։ Նույն ամսում Ջեյնը առևտրել է 3000 USD։ 
               Միջին շուկայական միջնորդավճարը նույնպես կազմել է 30%, կամ 900 USD։ 
               Այս գումարի կեսը (450 USD) նույնպես կգնա ռեֆերալային ծրագրին RCBC-ի էկվիվալենտով։ 
               Այս գումարից 360 USD (80%) կգնա Ալեքսին, ով հրավիրել է Ջեյնին, իսկ մնացած 90 USD (20%) կբաժանվի 
               ձեզ և այն մարդուն, ով հրավիրել է ձեզ (80%/20% կամ 72 USD/18 USD)։ 
               Սա նշանակում է, որ դուք ստացել եք 600 USD + 72 USD = 672 USD RCBC թոքենով մեկ ամսվա ընթացքում՝ 
               հրավիրելով միայն մեկ օգտատեր։ Բայց պատկերացրեք, եթե նրանք լինեին 5, 10 կամ ավելի!`,
            ],
            joinNow: "Միացեք մեր ռեֆերալ ծրագրին հիմա և սկսեք ավելացնել ձեր եկամուտը մեզ հետ!"
          },
          staking: {
            header: "Ստեյքինգ",
            welcome: "Աճեք ձեր RCBC ակտիվը պասիվ կերպով ստեյքինգի միջոցով!",
            description: `Ցանկանու՞մ եք մեծացնել ձեր RCBC ակտիվը առանց լրացուցիչ ջանքերի:
                          Այժմ դա հնարավոր է մեր ստեյքինգի գործառույթի միջոցով!`,
            whatIsStaking: "Ինչ է ստեյքինգը?",
            stakingDescription: `Սա գործընթաց է, որով դուք կարող եք սառեցնել (կամ բլոկավորել)
                                 ձեր RCBC և պասիվ կերպով մեծացնել ձեր ակտիվը։`,
            howItWorks: "Ինչպես է այն աշխատում?",
            howItWorksDescription: `Պարզապես սառեցրեք ձեր թոքենները, և այս պահից սկսած
                                   դուք շարունակաբար կկուտակեք ձեր հաշվում շուկայական միջնորդավճարների 50%-ի բաժինը
                                   բոլոր RealColibri օգտատերերից ըստ ձեր ստեյքինգի քաշի։
                                   Որքան մեծ և երկար ժամանակահատված է ձեր RCBC-ի քանակը, այնքան բարձր է ձեր ստեյքինգի քաշը
                                   և այնքան մեծ բաժին կստանաք դուք!`,
            benefits: "RealColibri-ում ստեյքինգի առավելությունները:",
            list: [
              "Պասիվ եկամուտ: Չկա անհրաժեշտություն բարդ գործողությունների։ Պարզապես սառեցրեք ձեր թոքենները և ստացեք պարգևատրում։",
              "Ակտիվների աճ: ձեր RCBC-ն կաճի (ինչպես քանակով, այնպես էլ արժեքով) նույնիսկ եթե դուք չեք առևտրում և չեք հրավիրում մարդկանց։",
              "Թափանցիկություն և արդարություն: Շուկայական միջնորդավճարների բաշխումը արդար և թափանցիկ է։ Ամեն մասնակից ստեյքինգի ստանում է իր բաժինը ըստ իր ստեյքինգի քաշի և տևողության։",
            ],
            joinNow: "Չկորցրեք ձեր հնարավորությունը մեծացնել ձեր RCBC ակտիվը նվազագույն ջանքերով: Օգտագործեք ստեյքինգ հենց հիմա և թող ձեր թոքենները աշխատեն ձեզ համար!"
          },
          faq: {
            header: "Հաճախակի տրվող հարցեր",
            questions: [
              {
                question: "Ինչպես սկսել առևտուրը?",
                answer: `Ավտոմատ առևտուր սկսելու համար ձեզ անհրաժեշտ է: <br/>
                         Գրանցվեք մեր կայքում և կապեք TronLink դրամապանակը։ <br/>
                         Գնեք RCBC և լրացրեք միջնորդավճարային դեպոզիտը ձեր RealColibri հաշվում։ <br/>
                         Կապեք ձեր առևտրային հաշիվը առևտրի էջում՝ օգտագործելով API բանալիներ, 
                         ընտրեք առևտրային գործիքը և մեկնարկեք ալգորիթմը։`,
              },
              {
                question: "Որքա՞ն գումարով կարող եմ սկսել առևտուրը և ինչպիսի՞ եկամուտ կունենամ:",
                answer: `Նվազագույն դեպոզիտը 10,000 USDT է։ Օպտիմալ դեպոզիտի չափը պետք է լինի առնվազն
                         3.75 անգամ ավելի մեծ նվազագույն դեպոզիտից։ Եթե դեպոզիտի չափը ավելի փոքր է օպտիմալից,
                         ամսական եկամուտը կախված կլինի նվազագույն դեպոզիտից։ Եթե դեպոզիտի չափը
                         մեծ է օպտիմալից, բարդ տոկոսների հաշվարկը կիրարկվում է, և ամսական եկամուտը կախված կլինի
                         դեպոզիտի ընթացիկ չափից և կավելանա դեպոզիտի չափին համամասնորեն։ Ամեն 5% շահույթը ավելացնում է
                         պոզիցիայի ծավալը 5%-ով։ Հետևաբար, որքան մեծ է դեպոզիտը, այնքան բարձր է եկամուտը։`,
              },
              {
                question: "Կարո՞ղ եմ փորձել ավտոմատ առևտուրը անվճար:",
                answer: `Այո, մենք առաջարկում ենք հնարավորություն փորձել ալգորիթմը անվճար մեկ շաբաթվա ընթացքում։ 
                         Այս ժամանակահատվածում ձեզ հարկավոր չէ ունենալ RCBC ալգորիթմը մեկնարկելու համար։ 
                         Անվճար փորձաշրջանը ակտիվացնելու համար ուղարկեք էլեկտրոնային նամակ mail@realcolibri.com հասցեին՝ 
                         համապատասխան խնդրանքով և ձեր TronLink դրամապանակի հասցեով, որը դուք 
                         գրանցվել եք մեր կայքում, և մենք հնարավորինս շուտ կտրամադրենք ձեզ մուտքը։ 
                         Յուրաքանչյուր հաճախորդ, որին դուք հրավիրում եք մեր համակարգում, երաշխավորում է ձեզ մեկ հավելյալ 
                         շաբաթ անվճար ժամանակահատված։ Պարզապես հրավիրեք ընկերոջը, տրամադրեք նրա 
                         TronLink դրամապանակի հասցեն, որը գրանցվել է մեր համակարգում, և մենք ձեզ կտանք մեկ հավելյալ 
                         շաբաթ անվճար ժամանակահատված։ Նշեք, որ դուք պետք է գրանցված լինեք մեր 
                         համակարգում TronLink-ի միջոցով (Լրիվ մուտք), որպեսզի ստանաք անվճար փորձաշրջան։`,
              },
              {
                question: "Ո՞ւր է գնում շահույթը:",
                answer: `Ստացված շահույթը մնում է ձեր բորսային հաշվին ամբողջությամբ։`,
              },
              {
                question: "Ինչպես ձեռք բերել RCBC?",
                answer: `Օգտագործեք ձեր RealColibri հաշվի ձևը կամ
                         Գլոբալ շուկաներ և Կրիպտոարժույթների շուկաներ էջերը։`,
              },
              {
                question: "Ինչ կարող եմ անել RCBC-ով?",
                answer: `Լրացրեք միջնորդավճարային դեպոզիտը և օգտագործեք այն որպես ավտոմատացված առևտրի համար միջնորդավճար։ <br/>
                         Ապահովեք դրանք ստեյքինգ համակարգում և ստացեք շահույթ։ Մանրամասները կարդացեք Ստեյքինգ բաժնում։ <br/>
                         Վաճառեք անմիջապես այլ RealColibri մասնակցի, ով ցանկանում է ձեռք բերել RCBC։ <br/>
                         Առևտրեք դրանցով բորսաներում, երբ RCBC-ն ցուցակագրվի ապագայում։`,
              },
              {
                question: "Ինչպես սկսել վաստակել ռեֆերալային և ստեյքինգ համակարգերում?",
                answer: `Ձեզ անհրաժեշտ է գրանցվել բլոկչեյնի միջոցով՝ միանալու համար ռեֆերալային համակարգին և ստեյքինգին։
                         Դուք կարող եք դա անել անմիջապես, երբ առաջին անգամ գրանցվում եք մեր կայքում՝ սեղմելով Գրանցվել TronLink-ով Լրիվ մուտք բաժնում, կամ ավելի ուշ օգտագործելով
                         Լրիվ մուտք կոճակը ձեր RealColibri հաշվին (դուք նույնպես կարող եք նշել TronLink հասցեն այն անձի,
                         ով ձեզ հրավիրել է, համապատասխան դաշտում)։ TRON բլոկչեյնը պահանջում է
                         ≈606 TRX տրանսակցիայի համար։`,
              }
            ]
          }
        }
      },
      de: {
        about: {
            learnMore: "Erfahren Sie mehr über RealColibri",
            autotrading: {
                header: "Autotrading",
                welcome: "Willkommen in der Welt des Hightech-Handels mit RealColibri!",
                description: `RealColibri ist ein fortschrittliches automatisiertes Handelssystem, das sich auf
                            Terminkontrakte von Kryptobörsen und globalen Märkten spezialisiert hat.
                            Unser System zeichnet sich durch langfristige Erfolge und einen innovativen
                            Ansatz beim Investieren aus.`,
                unique: "Was macht das RealColibri-System einzigartig:",
                list: [
                    "Ausgezeichnete Ergebnisse: RealColibri liefert beeindruckende Ergebnisse auf lange Sicht. Unser System basiert auf fortschrittlichen Algorithmen und einer gründlichen Datenanalyse, die es uns ermöglichen, ein stabiles und signifikantes Investmentwachstum zu erzielen und das Risiko zu minimieren.",
                    "Automatisierter Ansatz: Vergessen Sie die Notwendigkeit, den Markt ständig zu überwachen! RealColibri analysiert automatisch Tausende von Datenpunkten und trifft Handelsentscheidungen in Echtzeit, wodurch Sie vom Stress befreit werden und Ihre Investmentleistung gesteigert wird.",
                    "Diversifikation: Die Möglichkeit, verschiedene Instrumente innerhalb eines Kontos zu handeln, indem Sie zusätzliche API-Schlüssel oder Login-Passwort-Paare erstellen und diese auf mehreren RealColibri-Konten verwenden.",
                    `Verwendung des RCBC-Tokens: RealColibri verwendet RCBC als Provision für Dienstleistungen in Höhe von 50 % der Depotgröße. Die Provisions-Einlage wird bei jedem Handel verbraucht. Diese innovative Lösung ermöglicht es Investoren, den Algorithmus für den Handel zu "tanken", wodurch er immer läuft und den Investmentprozess optimiert. Nachdem ein RCBC als Provision verbraucht wurde, erhält der Investor das Doppelte auf seinem Handelskonto! Gleichzeitig behält der Investor den Gewinn in voller Höhe. Und dank des ständigen Wertzuwachses von RCBC wird die Provision von RealColibri im Laufe der Zeit immer kleiner.`,
                    `Vollständige Marktgebührenkompensation: RealColibri bietet die Möglichkeit der vollständigen
                    Marktgebührenkompensation, indem der entsprechende Betrag an RCBC gleichmäßig auf das
                    Empfehlungs- und das Staking-System verteilt wird. Dies ermöglicht es Investoren, ihre Investitionen zu maximieren und ihre Rentabilität zu steigern.`,
                    "Kostenlose Testphase: Testen Sie unseren Algorithmus eine Woche lang kostenlos! Sie können diesen Zeitraum auch um eine weitere Woche verlängern, für jeden Kunden, den Sie in unser System einladen. Weitere Informationen zu dieser Funktion finden Sie im FAQ-Bereich.",
                ]
            },
            realColibriCoin: {
                header: "RealColibri Coin",
                welcome: "Entdecken Sie neue Horizonte mit RealColibri Coin!",
                description: `RCBC ist mehr als nur eine Kryptowährung. Es ist ein Schlüsselelement unseres gesamten
                            Ökosystems, das zu seinem ständigen Wachstum und seiner Entwicklung beiträgt.
                            Betrachten Sie RCBC als den Treibstoff, der unsere Dienste antreibt und sie noch leistungsfähiger und effizienter macht. Der ständige Umsatz von RCBC im RealColibri-Ökosystem trägt zum Wert des Tokens bei und schafft ein günstiges Umfeld für alle Teilnehmer:`,
                list: [
                    `Günstige Provision: Je höher der Wert von RCBC, desto weniger Tokens werden für die
                    Handelsautomatisierungsdienste von RealColibri berechnet, wodurch sie noch erschwinglicher und attraktiver für Händler werden.`,
                    `Vollständige Marktgebührenkompensation: RealColibri kompensiert vollständig die
                    Marktgebühren, die von allen Nutzern unseres Systems erzeugt werden. 50 % dieses Betrags
                    fließen in das Empfehlungsprogramm und die anderen 50 % in das Staking-System und werden dann entsprechend den Bedingungen an die Teilnehmer verteilt.`,
                    `Erhöhte Empfehlungseinnahmen: Der stetige Preisanstieg von RCBC erhöht Ihre
                    Vorteile im Empfehlungsprogramm. Laden Sie neue Mitglieder in das RealColibri-Ökosystem ein und verdienen Sie immer mehr Belohnungen, die auch Ihr Vermögen steigern, wenn der Token-Wert wächst.`,
                    `Gewinn aus Staking: Durch die Verwendung von RCBC für das Staking haben Sie eine zusätzliche
                    Möglichkeit, ein Einkommen zu erzielen. Mit dem Wachstum des RCBC-Wertes im RealColibri-Ökosystem wird Ihr Gewinn aus Staking nur noch steigen, was diese Investitionsmethode noch attraktiver macht.`,
                    `Token-Austausch: Unser Ökosystem ermöglicht es Benutzern auch, RCBC untereinander ohne
                    Eingriff von RealColibri zu verkaufen/zu tauschen. In Zukunft, wenn wir RCBC an zentralisierten
                    und dezentralisierten Börsen listen, wird der Token handelbar sein. Dies gibt Ihnen noch mehr Freiheit und Flexibilität bei der Verwaltung Ihrer Vermögenswerte.`,
                ],
                whatCanIDo: "Was kann ich mit RCBC tun?",
                actions: [
                    "Finanzieren Sie die Provisions-Einlage und verwenden Sie diese als Provision für die Nutzung des automatisierten Handels.",
                    "Sperren Sie diese im Staking-System und akkumulieren Sie Gewinne. Lesen Sie mehr im Staking-Bereich.",
                    "Verkaufen Sie direkt an ein anderes RealColibri-Mitglied, das RCBC kaufen möchte.",
                    "Handeln Sie diese an Börsen, sobald RCBC in der Zukunft gelistet ist.",
                ],
                joinNow: "Schließen Sie sich RealColibri heute an und entdecken Sie neue Chancen und Wege zum finanziellen Erfolg!"
            },
            referralSystem: {
                header: "Empfehlungssystem",
                welcome: "Bauen Sie Ihr Geschäft mit unserem Empfehlungssystem auf und erhalten Sie 50 % der Marktgebühren Ihrer Kunden!",
                description: `Das Empfehlungssystem von RealColibri ermöglicht es Ihnen, durch die Empfehlung neuer Benutzer auf unserer Plattform zu verdienen. Teilen Sie einfach Ihren einzigartigen Link mit Ihren Freunden, Kollegen oder Abonnenten
                            und erhalten Sie 50 % der Marktgebühren, die von ihnen und/oder ihren Empfehlungen auf unserer Plattform generiert werden!`,
                howItWorks: "Wie funktioniert das?",
                list: [
                    "Bringen Sie neue Benutzer: Erhalten Sie Ihren einzigartigen Link und teilen Sie ihn mit Freunden, Kollegen und in sozialen Medien.",
                    `Verdienen Sie bei jedem Handel: Wenn Ihre Empfehlungen auf unserer Plattform handeln und
                    Marktgebühren generieren, erhalten Sie 50 % dieser Gebühren als Belohnung in RCBC.`,
                    `Mehrstufige Struktur: Die Tiefe der Empfehlungsebenen ist unbegrenzt, und die Belohnungen
                    werden zwischen den Teilnehmern des Programms im Verhältnis 80/20 aufgeteilt - 80 % gehen an Sie,
                    die restlichen 20 % gehen an die höhere Ebene. Sie werden nicht nur für die
                    Aktivität Ihrer direkten Empfehlungen (80 %) belohnt, sondern auch für die Aktivität ihrer
                    eigenen Empfehlungen (20 %) und so weiter bis ins Unendliche. Das bedeutet, dass Ihr Einkommenspotenzial mit jedem neuen Benutzer in Ihrer Kette wächst.`,
                    `Beispiel: Angenommen, Sie haben Alex auf unsere Plattform eingeladen.
                    Im Laufe eines Monats hat Alex 5000 USD gehandelt, und die durchschnittliche Marktgebühr betrug 30 % oder 1500 USD.
                    Die Hälfte dieses Betrags (750 USD) wird dem Empfehlungsprogramm im RCBC-Äquivalent zugewiesen.
                    600 USD (80 %) gehen an Sie und die restlichen 150 USD (20 %) gehen an die höhere Ebene
                    und werden zwischen der Person, die Sie eingeladen hat, und der Person, die sie eingeladen hat,
                    aufgeteilt (80/20), und so weiter bis zum ersten Eingeladenen in dieser Kette. Danach
                    brachte Alex auch Jane auf unsere Plattform. Im selben Monat hat Jane 3000 USD gehandelt.
                    Die durchschnittliche Marktgebühr betrug ebenfalls 30 % oder 900 USD. Die Hälfte dieses Betrags (450 USD) wird ebenfalls
                    dem Empfehlungsprogramm im RCBC-Äquivalent zugewiesen. Aus diesem Betrag gehen 360 USD (80 %) an
                    Alex, der Jane eingeladen hat, und die restlichen 90 USD (20 %) werden zwischen
                    Ihnen und der Person, die Sie eingeladen hat, aufgeteilt (80 %/20 % oder 72 USD/18 USD).
                    Das bedeutet, dass Sie 600 USD + 72 USD = 672 USD im RCBC-Token innerhalb eines Monats verdient haben,
                    indem Sie nur einen Benutzer eingeladen haben. Aber stellen Sie sich vor, es wären 5, 10 oder mehr!`,
                ],
                joinNow: "Treten Sie unserem Empfehlungssystem bei und beginnen Sie jetzt, Ihr Einkommen mit uns zu steigern!"
            },
            staking: {
                header: "Staking",
                welcome: "Erhöhen Sie Ihr RCBC-Vermögen passiv durch Staking!",
                description: `Möchten Sie Ihr RCBC-Vermögen ohne zusätzlichen Aufwand vergrößern?
                            Jetzt ist es dank unserer Staking-Funktion möglich!`,
                whatIsStaking: "Was ist Staking?",
                stakingDescription: `Es ist ein Prozess, bei dem Sie Ihre RCBC einfrieren (oder sperren)
                            und passiv Ihr Vermögen erhöhen können.`,
                howItWorks: "Wie funktioniert es?",
                howItWorksDescription: `Frieren Sie einfach Ihre Tokens ein, und von nun an
                            werden Sie kontinuierlich einen Anteil von 50 % der gehandelten
                            Marktgebühren aller RealColibri-Nutzer in Ihrem Konto entsprechend Ihrem Staking-Gewicht akkumulieren.
                            Je mehr und länger Sie RCBC-Token haben, desto höher ist Ihr Staking-Gewicht,
                            und desto größer wird Ihr Anteil sein!`,
                benefits: "Die Vorteile des Stakings bei RealColibri:",
                list: [
                    "Passives Einkommen: Keine komplizierten Aktionen sind erforderlich. Frieren Sie einfach Ihre Tokens ein und erhalten Sie Belohnungen.",
                    "Vermögenswachstum: Ihre RCBCs werden wachsen (sowohl in der Anzahl als auch im Wert), selbst wenn Sie nicht handeln und keine Leute einladen.",
                    "Transparenz und Fairness: Die Verteilung der Marktgebühren ist fair und transparent. Jeder Teilnehmer erhält seinen Anteil entsprechend der Größe und Dauer des Stakings.",
                ],
                joinNow: "Verpassen Sie nicht die Gelegenheit, Ihr RCBC-Vermögen mit minimalem Aufwand zu steigern! Nutzen Sie jetzt das Staking und lassen Sie Ihre Tokens für Sie arbeiten!"
            },
            faq: {
                header: "FAQ",
                questions: [
                    {
                        question: "Wie starte ich den Handel?",
                        answer: `Um den automatisierten Handel zu starten, benötigen Sie: <br/>
                            Registrieren Sie sich auf unserer Website und verbinden Sie die TronLink-Brieftasche. <br/>
                            Kaufen Sie RCBC und füllen Sie die Provisions-Einlage in Ihrem RealColibri-Konto auf. <br/>
                            Verbinden Sie Ihr Handelskonto auf der Handelsseite mit API-Schlüsseln,
                            wählen Sie ein Handelsinstrument aus und starten Sie den Algorithmus.`,
                    },
                    {
                        question: "Mit wie viel kann ich den Handel starten und wie hoch ist der Ertrag?",
                        answer: `Die Mindesteinlage beträgt 10.000 USDT. Die optimale Einlagengröße sollte mindestens
                            das 3,75-fache der Mindesteinlage betragen. Wenn die Einlagengröße kleiner als die optimale
                            Größe ist, hängt der monatliche Gewinn von der Mindesteinlage ab. Wenn die Einlagengröße
                            größer ist als die optimale Größe, wird die Berechnung des Zinseszinses aktiviert, und der monatliche Gewinn hängt
                            von der aktuellen Einlagengröße ab und wird proportional zur Einlagengröße zunehmen. Jeder 5% Gewinn erhöht das
                            Positionsvolumen um 5%. Daher gilt: Je größer die Einlage, desto höher der Ertrag.`,
                    },
                    {
                        question: "Kann ich den automatisierten Handel kostenlos ausprobieren?",
                        answer: `Ja, wir bieten die Möglichkeit, den Algorithmus eine Woche lang kostenlos zu testen. 
                            Während dieses Zeitraums müssen Sie kein RCBC besitzen, um den Algorithmus zu betreiben. 
                            Um den kostenlosen Testzeitraum zu aktivieren, senden Sie eine E-Mail an mail@realcolibri.com mit 
                            der entsprechenden Anfrage und Ihrer TronLink-Brieftaschenadresse, mit der Sie 
                            sich auf unserer Website registriert haben, und wir werden Ihnen so schnell wie möglich Zugang gewähren. 
                            Jeder Kunde, den Sie in unser System einladen, garantiert Ihnen eine weitere Woche 
                            des kostenlosen Zeitraums. Laden Sie einfach einen Freund ein, geben Sie die Adresse seiner 
                            TronLink-Brieftasche an, die in unserem System registriert ist, und wir gewähren Ihnen eine weitere 
                            Woche kostenlosen Testzeitraum. Beachten Sie, dass Sie in unserem
                            System über TronLink registriert sein müssen (Vollständiger Zugang), um Ihren kostenlosen Testzeitraum zu erhalten.`,
                    },
                    {
                        question: "Wohin geht der Gewinn?",
                        answer: `Der erzielte Gewinn verbleibt vollständig auf Ihrem Handelskonto.`,
                    },
                    {
                        question: "Wie erwerbe ich RCBC?",
                        answer: `Verwenden Sie das Formular in Ihrem RealColibri-Konto oder auf den
                            Seiten Globale Märkte und Kryptomärkte.`,
                    },
                    {
                        question: "Was kann ich mit RCBC tun?",
                        answer: `Finanzieren Sie die Provisions-Einlage und verwenden Sie sie als Provision für die Nutzung des automatisierten Handels. <br/>
                            Sperren Sie diese im Staking-System und akkumulieren Sie Gewinne. Lesen Sie mehr im Staking-Bereich. <br/>
                            Verkaufen Sie direkt an ein anderes RealColibri-Mitglied, das RCBC kaufen möchte. <br/>
                            Handeln Sie diese an Börsen, sobald RCBC in der Zukunft gelistet ist.`,
                    },
                    {
                        question: "Wie beginne ich im Empfehlungs- und Staking-System zu verdienen?",
                        answer: `Sie müssen sich über die Blockchain registrieren, um dem Empfehlungs- und Staking-System beizutreten.
                            Sie können dies sofort tun, wenn Sie sich zum ersten Mal auf unserer Website registrieren, indem Sie auf Registrieren mit TronLink im Abschnitt Vollständiger Zugang klicken, oder später mit
                            der Schaltfläche Vollständigen Zugang erhalten in Ihrem RealColibri-Konto (Sie können auch die TronLink-Adresse der Person angeben,
                            die Sie eingeladen hat, im entsprechenden Feld). Die TRON-Blockchain erhebt eine
                            Transaktionsgebühr von ca. 606 TRX.`,
                    }
                ]
            }
        }
    },
    fr: {
        about: {
            learnMore: "En savoir plus sur RealColibri",
            autotrading: {
                header: "Trading automatique",
                welcome: "Bienvenue dans le monde du trading high-tech avec RealColibri!",
                description: `RealColibri est un système de trading automatisé avancé spécialisé dans les contrats à terme
                            des échanges cryptographiques et des marchés mondiaux. Notre système se caractérise par
                            un succès à long terme exceptionnel et une approche innovante de l'investissement.`,
                unique: "Ce qui rend le système RealColibri unique:",
                list: [
                    "Excellents résultats: RealColibri fournit des résultats impressionnants à long terme. Notre système est basé sur des algorithmes avancés et une analyse de données rigoureuse, ce qui nous permet d'obtenir une croissance stable et significative des investissements, tout en minimisant les risques.",
                    "Approche automatisée: Oubliez la nécessité de surveiller constamment le marché! RealColibri analyse automatiquement des milliers de points de données et prend des décisions de trading en temps réel, vous libérant du stress et augmentant vos performances d'investissement.",
                    "Diversification: La possibilité de trader sur différents instruments au sein d'un même compte en créant des paires de clés API ou login-mot de passe supplémentaires et en les utilisant sur plusieurs comptes RealColibri.",
                    `Utilisation du token RCBC: RealColibri utilise RCBC comme commission pour les services à hauteur de 50 % de la taille du dépôt. Le dépôt de commission est dépensé à chaque transaction. Cette solution innovante permet aux investisseurs de "alimenter" l'algorithme pour trader, en s'assurant qu'il fonctionne toujours et en optimisant le processus d'investissement. Après avoir dépensé 1 RCBC en commission, l'investisseur reçoit deux fois plus sur son compte de trading! En même temps, l'investisseur conserve l'intégralité du bénéfice. Et grâce à la croissance constante de la valeur de RCBC, la commission de RealColibri devient de plus en plus petite avec le temps.`,
                    `Compensation totale des frais de marché: RealColibri offre la possibilité d'une compensation totale des frais
                    de marché en distribuant le montant correspondant de RCBC équitablement entre
                    le système de parrainage et le système de staking. Cela permet aux investisseurs de maximiser leurs investissements et d'augmenter leur rentabilité.`,
                    "Période d'essai gratuite: Essayez notre algorithme gratuitement pendant une semaine! Vous pouvez également prolonger cette période d'une semaine supplémentaire pour chaque client que vous invitez à notre système. Lisez-en plus sur cette fonctionnalité dans la section FAQ.",
                ]
            },
            realColibriCoin: {
                header: "RealColibri Coin",
                welcome: "Découvrez de nouveaux horizons avec le RealColibri Coin!",
                description: `RCBC est bien plus qu'une simple cryptomonnaie. C'est un élément clé de tout notre écosystème,
                            contribuant à sa croissance et à son développement constants. Considérez RCBC comme le carburant qui alimente nos services et les rend encore plus puissants et efficaces. Le chiffre d'affaires constant de RCBC dans l'écosystème RealColibri contribue à la valeur du token et crée un environnement favorable pour tous les participants:`,
                list: [
                    `Commission favorable: Plus la valeur de RCBC est élevée, moins de tokens seront
                    facturés pour les services d'automatisation du trading de RealColibri, les rendant ainsi encore plus abordables et attrayants pour les traders.`,
                    `Compensation totale des frais de marché: RealColibri compense totalement les
                    frais de marché générés par tous les utilisateurs de notre système. 50 % de ce montant
                    est attribué au système de parrainage et les autres 50 % au système de staking, puis répartis entre les participants selon les conditions.`,
                    `Augmentation des bénéfices de parrainage: L'augmentation constante du prix du RCBC augmente vos
                    avantages dans le programme de parrainage. Invitez de nouveaux membres dans l'écosystème RealColibri et gagnez de plus en plus de récompenses, ce qui augmentera également vos actifs à mesure que la valeur du token augmente.`,
                    `Profit du staking: En utilisant RCBC pour le staking, vous avez une opportunité supplémentaire de
                    gagner un revenu. Avec la croissance de la valeur de RCBC dans l'écosystème RealColibri, votre profit du staking ne fera qu'augmenter, rendant cette méthode d'investissement encore plus attrayante.`,
                    `Échange de tokens: Notre écosystème permet également aux utilisateurs de vendre/échanger RCBC entre eux sans
                    l'intervention de RealColibri. À l'avenir, lorsque nous listerons RCBC sur des bourses centralisées et décentralisées, le token sera négociable. Cela vous donne encore plus de liberté et de flexibilité dans la gestion de vos actifs.`,
                ],
                whatCanIDo: "Que puis-je faire avec RCBC?",
                actions: [
                    "Alimentez le dépôt de commission et utilisez-les comme commission pour l'utilisation du trading automatisé.",
                    "Verrouillez-les dans le système de staking et accumulez des profits. Lisez-en plus dans la section Staking.",
                    "Vendez-les directement à un autre membre de RealColibri qui souhaite acheter RCBC.",
                    "Tradez-les sur des bourses une fois que RCBC sera listé à l'avenir.",
                ],
                joinNow: "Rejoignez RealColibri dès aujourd'hui et découvrez de nouvelles opportunités et des chemins vers le succès financier!"
            },
            referralSystem: {
                header: "Système de parrainage",
                welcome: "Construisez votre entreprise avec notre système de parrainage et recevez 50 % des frais de marché de vos clients!",
                description: `Le système de parrainage de RealColibri vous permet de gagner en recommandant de nouveaux utilisateurs sur notre plateforme. Partagez simplement votre lien unique avec vos amis, collègues ou abonnés
                            et recevez 50 % des frais de marché générés par eux et/ou leurs parrainages sur notre plateforme!`,
                howItWorks: "Comment ça marche?",
                list: [
                    "Amenez de nouveaux utilisateurs: Obtenez votre lien unique et partagez-le avec des amis, collègues et sur les réseaux sociaux.",
                    `Gagnez sur chaque transaction: Lorsque vos parrainages échangent sur notre plateforme et
                    génèrent des frais de marché, vous recevez 50 % de ces frais en tant que récompense en RCBC.`,
                    `Structure à plusieurs niveaux: La profondeur des niveaux de parrainage est infinie, et les récompenses
                    sont réparties entre les participants du programme selon un ratio de 80/20 - 80 % pour vous,
                    les 20 % restants vont au niveau supérieur. Vous êtes récompensé non seulement pour l'activité de vos parrainages directs (80 %), mais aussi pour l'activité de leurs
                    propres parrainages (20 %), et ainsi de suite à l'infini. Cela signifie que votre potentiel de revenu augmente avec chaque nouvel utilisateur dans votre chaîne.`,
                    `Exemple: Supposons que vous ayez invité Alex sur notre plateforme.
                    Au cours d'un mois, Alex a échangé 5000 USD, et les frais de marché moyens étaient de 30 %, soit 1500 USD.
                    La moitié de ce montant (750 USD) sera allouée au programme de parrainage en équivalent RCBC.
                    600 USD (80 %) iront à vous et les 150 USD restants (20 %) iront au niveau supérieur
                    et seront répartis entre la personne qui vous a invité et la personne qui l'a invité,
                    selon un ratio de 80/20, et ainsi de suite jusqu'au premier invité de cette chaîne. Ensuite,
                    Alex a également amené Jane sur notre plateforme. Au cours du même mois, Jane a échangé 3000 USD.
                    Les frais de marché moyens étaient également de 30 %, soit 900 USD. La moitié de ce montant (450 USD) sera également
                    allouée au programme de parrainage en équivalent RCBC. Sur ce montant, 360 USD (80 %) iront à
                    Alex qui a invité Jane, et les 90 USD restants (20 %) seront répartis entre
                    vous et la personne qui vous a invité sur la plateforme (80 %/20 % ou 72 USD/18 USD).
                    Cela signifie que vous avez gagné 600 USD + 72 USD = 672 USD en RCBC en un mois,
                    en invitant un seul utilisateur. Mais imaginez s'ils étaient 5, 10 ou plus!`,
                ],
                joinNow: "Rejoignez notre programme de parrainage maintenant et commencez à augmenter vos revenus avec nous!"
            },
            staking: {
                header: "Staking",
                welcome: "Augmentez passivement votre actif RCBC avec le staking!",
                description: `Vous souhaitez augmenter votre actif RCBC sans effort supplémentaire?
                            Maintenant, c'est possible grâce à notre fonctionnalité de staking!`,
                whatIsStaking: "Qu'est-ce que le staking?",
                stakingDescription: `C'est un processus par lequel vous pouvez geler (ou bloquer)
                            vos RCBC et augmenter passivement votre actif.`,
                howItWorks: "Comment ça marche?",
                howItWorksDescription: `Il suffit de geler vos tokens, et à partir de maintenant
                            vous accumulerez continuellement dans votre compte une part de 50 % des frais de marché
                            de tous les utilisateurs de RealColibri, proportionnellement à votre poids de staking.
                            Plus vous avez de tokens RCBC et plus vous les gardez longtemps, plus votre poids de staking est élevé,
                            et plus grande sera votre part!`,
                benefits: "Les avantages du staking chez RealColibri:",
                list: [
                    "Revenus passifs: Aucune action compliquée n'est requise. Il suffit de geler vos tokens et de recevoir des récompenses.",
                    "Croissance des actifs: vos RCBC augmenteront (en nombre et en valeur) même si vous ne tradez pas et n'invitez pas de personnes.",
                    "Transparence et équité: La répartition des frais de marché est équitable et transparente. Chaque participant reçoit sa part en fonction de la taille et de la durée du staking.",
                ],
                joinNow: "Ne manquez pas l'opportunité d'augmenter votre actif RCBC avec un minimum d'effort! Utilisez le staking dès maintenant et laissez vos tokens travailler pour vous!"
            },
            faq: {
                header: "FAQ",
                questions: [
                    {
                        question: "Comment commencer à trader?",
                        answer: `Pour commencer le trading automatisé, vous aurez besoin de: <br/>
                            Vous inscrire sur notre site web et connecter le portefeuille TronLink. <br/>
                            Acheter du RCBC et recharger le dépôt de commission dans votre compte RealColibri. <br/>
                            Connectez votre compte de trading sur la page de trading en utilisant les clés API,
                            sélectionnez un instrument de trading et lancez l'algorithme.`,
                    },
                    {
                        question: "Avec combien puis-je commencer à trader et quel est le rendement?",
                        answer: `Le dépôt minimum est de 10 000 USDT. La taille optimale du dépôt devrait être au moins
                            3,75 fois la taille du dépôt minimum. Si la taille du dépôt est inférieure à celle optimale,
                            le profit mensuel dépend de la taille du dépôt minimum. Si la taille du dépôt
                            est supérieure à celle optimale, le calcul des intérêts composés est activé, et le profit mensuel dépendra
                            de la taille actuelle du dépôt et augmentera proportionnellement à la taille du dépôt. Chaque 5 % de profit augmente
                            le volume de la position de 5 %. Par conséquent, plus le dépôt est grand, plus le rendement est élevé.`,
                    },
                    {
                        question: "Puis-je essayer le trading automatique gratuitement?",
                        answer: `Oui, nous offrons la possibilité de tester l'algorithme gratuitement pendant une semaine. 
                            Pendant cette période, vous n'aurez pas besoin d'avoir du RCBC pour exécuter l'algorithme. 
                            Pour activer la période d'essai gratuite, envoyez un e-mail à mail@realcolibri.com avec 
                            la demande appropriée et l'adresse de votre portefeuille Tronlink, avec laquelle vous 
                            vous êtes inscrit sur notre site web, et nous vous fournirons l'accès dès que possible. 
                            Chaque client que vous invitez dans notre système vous garantit une semaine supplémentaire 
                            de période d'essai gratuite. Invitez simplement un ami, fournissez l'adresse de son 
                            portefeuille TronLink enregistré dans notre système et nous vous accorderons une semaine supplémentaire 
                            de période d'essai gratuite. Gardez à l'esprit que vous devez être inscrit dans notre
                            système via TronLink (Accès Complet) pour obtenir votre période d'essai gratuite.`,
                    },
                    {
                        question: "Où va le profit?",
                        answer: `Le profit obtenu reste entièrement sur votre compte d'échange.`,
                    },
                    {
                        question: "Comment acquérir du RCBC?",
                        answer: `Utilisez le formulaire dans votre compte RealColibri ou sur
                            les pages Marchés mondiaux et Marchés des cryptomonnaies.`,
                    },
                    {
                        question: "Que puis-je faire avec RCBC?",
                        answer: `Alimentez le dépôt de commission et utilisez-le comme commission pour l'utilisation du trading automatisé. <br/>
                            Verrouillez-les dans le système de staking et accumulez des profits. Lisez-en plus dans la section Staking. <br/>
                            Vendez-les directement à un autre membre de RealColibri qui souhaite acheter du RCBC. <br/>
                            Tradez-les sur des bourses une fois que RCBC sera listé à l'avenir.`,
                    },
                    {
                        question: "Comment commencer à gagner dans le système de parrainage et de staking?",
                        answer: `Vous devrez vous inscrire via la blockchain pour rejoindre le système de parrainage et de staking.
                            Vous pouvez le faire immédiatement lors de votre première inscription sur notre site web en cliquant sur S'inscrire avec TronLink dans la section Accès Complet, ou plus tard en utilisant
                            le bouton Obtenir un accès complet dans votre compte RealColibri (vous pouvez également spécifier l'adresse TronLink de la personne
                            qui vous a invité dans le champ correspondant). La blockchain TRON facture des frais
                            de transaction d'environ 606 TRX.`,
                    }
                ]
            }
        }
    }
};
