import s from './LoaderContextProvider.module.scss'

import React, { PropsWithChildren, useCallback, useState } from 'react'
import { Spin } from 'antd'

import { LoaderContext } from './LoaderContext'
import { POPUPS_TARGET } from 'shared/consts'

export const LoaderContextProvider: React.FC<PropsWithChildren> = React.memo(
    ({ children }) => {
        const [loaderCount, setLoaderCount] = useState(0)

        const handleLoader = useCallback((value: boolean) => {
            const action = value ? 1 : -1
            setLoaderCount(prevState => prevState + action)
        }, [])

        return (
            <LoaderContext.Provider
                value={{ isLoader: !!loaderCount, setLoader: handleLoader }}
            >
                <Spin
                    size="large"
                    spinning={!!loaderCount}
                    wrapperClassName={s.wrapper}
                >
                    <div id={POPUPS_TARGET}>{children}</div>
                </Spin>
            </LoaderContext.Provider>
        )
    }
)
