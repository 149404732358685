import React, { useCallback, useContext, useEffect } from 'react'
import { SelectControl } from 'shared/components'
import { Button, Form } from 'antd'
import { BybitActionsProps } from './BybitActions.types'
import { BybitService } from 'api/http'
import { LoaderContext } from 'core/context'
import { BybitStartDto } from 'api/http/models'

/** Блок с действиями для таблицы аккаунтов */
export const BybitActions: React.FC<BybitActionsProps> = React.memo(
    ({ record, onFetchAccounts, symbols }) => {
        const { setLoader } = useContext(LoaderContext)
        const [form] = Form.useForm()

        /** Обработчик формы */
        const handleFinish = useCallback(
            async (body: BybitStartDto) => {
                try {
                    setLoader(true)

                    if (record.robotId) {
                        await BybitService.stop(record.id)
                    } else {
                        await BybitService.start(record.id, body)
                    }

                    onFetchAccounts()
                } catch (e) {
                    console.log(e)
                } finally {
                    setLoader(false)
                }
            },
            [setLoader, record, onFetchAccounts]
        )

        useEffect(() => {
            if (
                symbols &&
                (!form.getFieldValue('symbolId') || record?.symbolId)
            ) {
                form.setFieldValue(
                    'symbolId',
                    record?.symbolId || symbols[0]?.id
                )
            }
        }, [record?.symbolId, symbols])

        return (
            <Form form={form} onFinish={handleFinish}>
                <Form.Item name="symbolId">
                    <SelectControl
                        disabled={!!record.robotId}
                        options={symbols?.map(el => ({
                            label: el.name,
                            value: el.id,
                            key: el.id,
                        }))}
                    />
                </Form.Item>

                <Button
                    block
                    htmlType="submit"
                    type="primary"
                    danger={!!record.robotId || !symbols}
                    disabled={!symbols?.length}
                >
                    {record.robotId ? 'Отключить' : 'Включить'}
                </Button>
            </Form>
        )
    }
)
