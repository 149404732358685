import s from './Copyrights.module.scss'

import React from 'react'
import { Col, Row } from 'antd'

/** Права, используемые системой */
export const Copyrights: React.FC = React.memo(() => {
    return (
        <Row className={s.wrapper} align="middle">
            <Col xs={24} md={12} className={s.rights}>
                The R | Protocol API™ software is Copyright ©&nbsp;
                {new Date().getFullYear()} by Rithmic, LLC. All rights reserved
            </Col>

            <Col xs={24} md={12} className={s.rights}>
                The OMNE™ software is Copyright © {new Date().getFullYear()} by
                Omnesys, LLC and Omnesys Technologies, Inc. All rights reserved
            </Col>
        </Row>
    )
})
