const translations = {
    en: {
        title: "Rithmic Accounts",
        description: "Manage your Rithmic accounts here.",
    },
    fr: {
        title: "Comptes Rithmic",
        description: "Gérez vos comptes Rithmic ici.",
    },
    de: {
        title: "Rithmic-Konten",
        description: "Verwalten Sie hier Ihre Rithmic-Konten.",
    },
    ru: {
        title: "Аккаунты Rithmic",
        description: "Управляйте своими аккаунтами Rithmic здесь.",
    },
    es: {
        title: "Cuentas de Rithmic",
        description: "Administre sus cuentas de Rithmic aquí.",
    },
    hy: {
        title: "Rithmic հաշիվներ",
        description: "Կառավարեք ձեր Rithmic հաշիվները այստեղ:",
    },
};

export default translations;
