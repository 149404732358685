// translations.ts
export const translations = {
    en: {
        BANNER_TITLE: 'REALCOLIBRI PREMIUM',
        HEADER: 'Get all the benefits of automated trading forever by paying only once',
        NO_FEES: 'No additional fees.',
        NO_LIMITS: 'No usage limits.',
        SIMPLE: 'It is that simple!',
        SELECT_EXCHANGE_PACKAGE: 'Select an exchange and a package',
        NEXT: 'NEXT',
        PURCHASE: 'Purchase',
        YOU_ARE_ABOUT_TO_PURCHASE: 'You are about to purchase:',
        PAYMENT_INSTRUCTIONS: 'Make a payment in TRX (TRON) to the above address in the amount equal to the selected value in USDT.',
        ALERT: 'Make sure to use TRC-20 (TRON) network for the transfer',
        CONTACT: 'After payment, please contact us by e-mail, provide us with the transaction receipt, the exchange you chose and your username on our website. We will contact you as soon as possible for further instructions.',
        REDIRECT: 'When you click Proceed, you will be redirected to the RealColibri algorithm management page. Make sure you are logged into RealColibri your account.',
        PROCEED: 'PROCEED',
        BINANCE_INSTRUCTIONS: 'Binance instructions',
        BINANCE_STEPS: [
            'Open an account on an exchange and complete KYC verification.',
            'Make sure you have a futures wallet created.',
            'To start the algorithm, you will need to create API keys in your exchange account and add them in the corresponding section on the algorithm management page.',
            'A few minutes after adding the API keys, the algorithm will start trading on your account.'
        ],
        GLOBAL_MARKET_INSTRUCTIONS: 'Global market instructions',
        GLOBAL_MARKET_STEPS: [
            'Open an account with an FCM which supports Rithmic datafeed (the list of FCMs).',
            'Fund your FCM trading account (minimum 10,000 USD).',
            'Subscribe to Rithmic market data (we recommend CME Bundle by default).',
            'Request and receive a login and password (demo or live) and add them on the algorithm management page.',
            'A few minutes after adding your credentials, the algorithm will start trading on your account.'
        ],
        USE_GUIDES: 'Use our guides to assist you with the setup',
        GUIDES: 'GUIDES',
        ABOUT_REALCOLIBRI_PREMIUM: 'About RealColibri Premium',
        ABOUT_TEXT_1: 'The cost of each package corresponds to the size of the trading deposit on the exchange...',
        ABOUT_TEXT_2: 'Given that the minimum deposit size for risk-free trading is 10,000 USDT...',
        TABLE_NOTE: '* The number of RCP packages is limited.',
        FAQ_TITLE: 'PREMIUM FAQ',
        FAQ_QUESTIONS: [
            { question: 'What are the returns?', answer: 'The returns on each package on average is from 10% per month of the minimum deposit size. For example, the returns on RCP-10000 package will average from 1,000 USDT per month, no matter how much the deposit size increases. If the deposit size is lower than the minimum deposit size, the trading algorithm will pause. Returns may vary depending on the situation on the market.' },
            { question: 'Is there a commission?', answer: 'RealColibri does not charge any additional fees. You only pay the exchange commission for your trades.' },
            { question: 'Where does the profit go?', answer: 'The profit received remains in your exchange account in its entirety.' },
            { question: 'How long are the packages available for? What if I no longer need the package? Will I get my money back?', answer: 'The purchased package is permanently assigned to your account. RealColibri does not provide refunds for package purchases.' },
            { question: 'How much do the packages cost?', answer: 'The cost of each package corresponds to the size of the deposit specified in the name, in TRX. For example, RCP-10000 package is designed for trading on a deposit of 10,000 USDT and the purchase cost of this package is equal to 10,000 USDT in TRX equivalent at the current TRX/USDT exchange rate at the time of payment.' },
            { question: 'How do I pay for and receive the package?', answer: 'When selecting a package you will see the address of the wallet to which TRX should be transferred. After payment you should send your username, specified during registration on our website, the selected exchange and the transaction receipt to mail@realcolibri.com. Then your account will be set up with pre-made trading algorithm settings according to the purchased package.' },
            { question: 'What if I want to increase my returns?', answer: 'Since the package returns are tied to the minimum deposit amount, to increase the returns you will need to buy a new package corresponding to the desired returns. Keep in mind that the increased returns will also require an increased deposit amount. For example, if you use the RCP-10000 package and your deposit is 5,000 USDT, the returns will remain at the same level of no less than 1,000 USDT per month. In order to earn no less than 5,000 USDT per month, you will need to buy the RCP-50000 package.' },
            { question: 'What if the returns on the package are decreasing?', answer: 'We constantly monitor the situation on the exchanges and connect only the most optimal trading instruments. If you notice that the returns on a particular trading instrument may be higher than your current package - write to us at mail@realcolibri.com with a request to change the trading instrument on your account.' },
            { question: 'Why is this promotion limited?', answer: 'Funds from the sale of packages will be used for the development of the RealColibri system. Among other things, it will help improve the trading algorithm for even more profitable, stable and safe trading, as well as accelerate the introduction of new features.' }
        ],
        FEEL_FREE_CONTACT: 'Feel free to reach us if you have any questions or need additional information:',
        EMAIL: 'mail@realcolibri.com'
    },
    ru: {
        BANNER_TITLE: 'REALCOLIBRI PREMIUM',
        HEADER: 'Получите все преимущества автоматической торговли навсегда, заплатив всего один раз',
        NO_FEES: 'Без дополнительных сборов.',
        NO_LIMITS: 'Без ограничений на использование.',
        SIMPLE: 'Это так просто!',
        SELECT_EXCHANGE_PACKAGE: 'Выберите биржу и пакет',
        NEXT: 'ДАЛЕЕ',
        PURCHASE: 'Покупка',
        YOU_ARE_ABOUT_TO_PURCHASE: 'Вы собираетесь купить:',
        PAYMENT_INSTRUCTIONS: 'Произведите оплату в TRX (TRON) по указанному адресу на сумму, равную выбранному значению в USDT.',
        ALERT: 'Убедитесь, что вы используете сеть TRC-20 (TRON) для перевода',
        CONTACT: 'После оплаты, пожалуйста, свяжитесь с нами по электронной почте, предоставьте нам квитанцию о транзакции, выбранную вами биржу и ваше имя пользователя на нашем сайте</b>. Мы свяжемся с вами как можно скорее для дальнейших инструкций.',
        REDIRECT: 'Когда вы нажмете "Продолжить", вы будете перенаправлены на страницу управления алгоритмом RealColibri. Убедитесь, что вы вошли в свою учетную запись RealColibri.',
        PROCEED: 'ПРОДОЛЖИТЬ',
        BINANCE_INSTRUCTIONS: 'Инструкции для Binance',
        BINANCE_STEPS: [
            'Откройте учетную запись на бирже и пройдите проверку KYC.',
            'Убедитесь, что у вас создан кошелек для фьючерсов.',
            'Чтобы запустить алгоритм, вам нужно будет создать API-ключи в учетной записи биржи и добавить их в соответствующий раздел на странице управления алгоритмом.',
            'Через несколько минут после добавления API-ключей алгоритм начнет торговать на вашем счете.'
        ],
        GLOBAL_MARKET_INSTRUCTIONS: 'Инструкции для глобального рынка',
        GLOBAL_MARKET_STEPS: [
            'Откройте учетную запись у FCM, который поддерживает данные Rithmic (список FCM).',
            'Пополните свой торговый счет FCM (минимум 10,000 USD).',
            'Подпишитесь на рыночные данные Rithmic (мы рекомендуем CME Bundle по умолчанию).',
            'Запросите и получите логин и пароль (демо или живой) и добавьте их на страницу управления алгоритмом.',
            'Через несколько минут после добавления ваших учетных данных алгоритм начнет торговать на вашем счете.'
        ],
        USE_GUIDES: 'Используйте наши руководства для настройки',
        GUIDES: 'РУКОВОДСТВА',
        ABOUT_REALCOLIBRI_PREMIUM: 'О RealColibri Premium',
        ABOUT_TEXT_1: 'Стоимость каждого пакета соответствует размеру торгового депозита на бирже...',
        ABOUT_TEXT_2: 'Учитывая, что минимальный размер депозита для торговли без риска составляет 10,000 USDT...',
        TABLE_NOTE: '* Количество пакетов RCP ограничено.',
        FAQ_TITLE: 'ЧАСТО ЗАДАВАЕМЫЕ ВОПРОСЫ',
        FAQ_QUESTIONS: [
            { question: 'Какова доходность?', answer: 'Доходность каждого пакета в среднем составляет от 10% в месяц от минимального размера депозита. Например, доходность пакета RCP-10000 будет составлять в среднем от 1,000 USDT в месяц, независимо от того, насколько увеличивается размер депозита. Если размер депозита ниже минимального размера депозита, торговый алгоритм приостанавливается. Доходность может варьироваться в зависимости от ситуации на рынке.' },
            { question: 'Есть ли комиссия?', answer: 'RealColibri не взимает дополнительных сборов. Вы оплачиваете только биржевую комиссию за ваши сделки.' },
            { question: 'Куда идет прибыль?', answer: 'Полученная прибыль остается на вашем биржевом счете в полном объеме.' },
            { question: 'Как долго доступны пакеты? Что если мне больше не нужен пакет? Вернут ли мне деньги?', answer: 'Купленный пакет навсегда привязан к вашей учетной записи. RealColibri не предоставляет возвраты за покупку пакетов.' },
            { question: 'Сколько стоят пакеты?', answer: 'Стоимость каждого пакета соответствует размеру депозита, указанному в названии, в TRX. Например, пакет RCP-10000 предназначен для торговли на депозите 10,000 USDT, и стоимость покупки этого пакета равна 10,000 USDT в эквиваленте TRX по текущему курсу TRX/USDT на момент оплаты.' },
            { question: 'Как оплатить и получить пакет?', answer: 'При выборе пакета вы увидите адрес кошелька, на который нужно перевести TRX. После оплаты вы должны отправить ваше имя пользователя, указанное при регистрации на нашем сайте, выбранную биржу и квитанцию о транзакции на mail@realcolibri.com. Затем ваша учетная запись будет настроена с готовыми настройками торгового алгоритма в соответствии с купленным пакетом.' },
            { question: 'Что если я хочу увеличить доходность?', answer: 'Так как доходность пакета привязана к минимальному размеру депозита, чтобы увеличить доходность, вам нужно будет купить новый пакет, соответствующий желаемой доходности. Имейте в виду, что увеличенная доходность также потребует увеличенного размера депозита. Например, если вы используете пакет RCP-10000, а ваш депозит составляет 5,000 USDT, доходность останется на уровне не менее 1,000 USDT в месяц. Чтобы заработать не менее 5,000 USDT в месяц, вам нужно будет купить пакет RCP-50000.' },
            { question: 'Что если доходность пакета снижается?', answer: 'Мы постоянно отслеживаем ситуацию на биржах и подключаем только самые оптимальные торговые инструменты. Если вы заметили, что доходность определенного торгового инструмента может быть выше, чем у вашего текущего пакета, напишите нам на mail@realcolibri.com с запросом на изменение торгового инструмента на вашем счете.' },
            { question: 'Почему эта акция ограничена?', answer: 'Средства от продажи пакетов будут использованы на развитие системы RealColibri. В частности, это поможет улучшить торговый алгоритм для еще более прибыльной, стабильной и безопасной торговли, а также ускорить внедрение новых функций.' }
        ],
        FEEL_FREE_CONTACT: 'Не стесняйтесь обращаться к нам, если у вас есть вопросы или вам нужна дополнительная информация:',
        EMAIL: 'mail@realcolibri.com'
    },
    es: {
        BANNER_TITLE: 'REALCOLIBRI PREMIUM',
        HEADER: 'Obtén todos los beneficios del comercio automatizado para siempre pagando solo una vez',
        NO_FEES: 'Sin comisiones adicionales.',
        NO_LIMITS: 'Sin límites de uso.',
        SIMPLE: '¡Así de simple!',
        SELECT_EXCHANGE_PACKAGE: 'Selecciona un intercambio y un paquete',
        NEXT: 'SIGUIENTE',
        PURCHASE: 'Compra',
        YOU_ARE_ABOUT_TO_PURCHASE: 'Estás a punto de comprar:',
        PAYMENT_INSTRUCTIONS: 'Realiza un pago en TRX (TRON) a la dirección anterior por un monto igual al valor seleccionado en USDT.',
        ALERT: 'Asegúrate de utilizar la red TRC-20 (TRON) para la transferencia',
        CONTACT: 'Después del pago, contáctanos por correo electrónico, proporciona el recibo de la transacción, el intercambio que elegiste y tu nombre de usuario en nuestro sitio web</b>. Nos pondremos en contacto contigo lo antes posible para más instrucciones.',
        REDIRECT: 'Cuando hagas clic en Continuar, serás redirigido a la página de gestión del algoritmo de RealColibri. Asegúrate de haber iniciado sesión en tu cuenta de RealColibri.',
        PROCEED: 'CONTINUAR',
        BINANCE_INSTRUCTIONS: 'Instrucciones de Binance',
        BINANCE_STEPS: [
            'Abre una cuenta en un intercambio y completa la verificación KYC.',
            'Asegúrate de haber creado una billetera de futuros.',
            'Para iniciar el algoritmo, necesitarás crear claves API en tu cuenta del intercambio y agregarlas en la sección correspondiente en la página de gestión del algoritmo.',
            'Unos minutos después de agregar las claves API, el algoritmo comenzará a operar en tu cuenta.'
        ],
        GLOBAL_MARKET_INSTRUCTIONS: 'Instrucciones del mercado global',
        GLOBAL_MARKET_STEPS: [
            'Abre una cuenta con un FCM que soporte el flujo de datos de Rithmic (la lista de FCMs).',
            'Fondea tu cuenta de trading FCM (mínimo 10,000 USD).',
            'Suscríbete a los datos de mercado de Rithmic (recomendamos el CME Bundle por defecto).',
            'Solicita y recibe un nombre de usuario y contraseña (demo o en vivo) y agrégalos en la página de gestión del algoritmo.',
            'Unos minutos después de agregar tus credenciales, el algoritmo comenzará a operar en tu cuenta.'
        ],
        USE_GUIDES: 'Utiliza nuestras guías para la configuración',
        GUIDES: 'GUÍAS',
        ABOUT_REALCOLIBRI_PREMIUM: 'Acerca de RealColibri Premium',
        ABOUT_TEXT_1: 'El costo de cada paquete corresponde al tamaño del depósito de negociación en el intercambio...',
        ABOUT_TEXT_2: 'Dado que el tamaño mínimo del depósito para el comercio sin riesgo es de 10,000 USDT...',
        TABLE_NOTE: '* El número de paquetes RCP es limitado.',
        FAQ_TITLE: 'FAQ PREMIUM',
        FAQ_QUESTIONS: [
            { question: '¿Cuáles son los retornos?', answer: 'Los retornos de cada paquete son, en promedio, del 10% mensual del tamaño del depósito mínimo. Por ejemplo, los retornos del paquete RCP-10000 promediarán desde 1,000 USDT por mes, sin importar cuánto aumente el tamaño del depósito. Si el tamaño del depósito es inferior al tamaño mínimo del depósito, el algoritmo de negociación se pausará. Los retornos pueden variar dependiendo de la situación en el mercado.' },
            { question: '¿Hay alguna comisión?', answer: 'RealColibri no cobra tarifas adicionales. Solo pagas la comisión del intercambio por tus operaciones.' },
            { question: '¿A dónde va la ganancia?', answer: 'La ganancia recibida permanece en tu cuenta de intercambio en su totalidad.' },
            { question: '¿Cuánto tiempo están disponibles los paquetes? ¿Qué pasa si ya no necesito el paquete? ¿Recibiré mi dinero de vuelta?', answer: 'El paquete comprado está permanentemente asignado a tu cuenta. RealColibri no proporciona reembolsos por la compra de paquetes.' },
            { question: '¿Cuánto cuestan los paquetes?', answer: 'El costo de cada paquete corresponde al tamaño del depósito especificado en el nombre, en TRX. Por ejemplo, el paquete RCP-10000 está diseñado para operar con un depósito de 10,000 USDT y el costo de compra de este paquete es igual a 10,000 USDT en equivalente TRX al tipo de cambio actual TRX/USDT en el momento del pago.' },
            { question: '¿Cómo pago y recibo el paquete?', answer: 'Al seleccionar un paquete, verás la dirección de la billetera a la que se debe transferir TRX. Después del pago, debes enviar tu nombre de usuario, especificado durante el registro en nuestro sitio web, el intercambio seleccionado y el recibo de la transacción a mail@realcolibri.com. Luego, tu cuenta se configurará con las configuraciones predefinidas del algoritmo de negociación según el paquete comprado.' },
            { question: '¿Qué pasa si quiero aumentar mis retornos?', answer: 'Dado que los retornos del paquete están vinculados al tamaño mínimo del depósito, para aumentar los retornos necesitarás comprar un nuevo paquete correspondiente a los retornos deseados. Ten en cuenta que los retornos aumentados también requerirán un tamaño de depósito aumentado. Por ejemplo, si usas el paquete RCP-10000 y tu depósito es de 5,000 USDT, los retornos permanecerán en el mismo nivel de no menos de 1,000 USDT por mes. Para ganar no menos de 5,000 USDT por mes, necesitarás comprar el paquete RCP-50000.' },
            { question: '¿Qué pasa si los retornos del paquete están disminuyendo?', answer: 'Constantemente monitoreamos la situación en los intercambios y conectamos solo los instrumentos de negociación más óptimos. Si notas que los retornos de un instrumento de negociación en particular pueden ser más altos que los de tu paquete actual, escríbenos a mail@realcolibri.com con una solicitud para cambiar el instrumento de negociación en tu cuenta.' },
            { question: '¿Por qué esta promoción es limitada?', answer: 'Los fondos de la venta de paquetes se utilizarán para el desarrollo del sistema RealColibri. Entre otras cosas, esto ayudará a mejorar el algoritmo de negociación para una negociación aún más rentable, estable y segura, así como acelerar la introducción de nuevas funciones.' }
        ],
        FEEL_FREE_CONTACT: 'No dudes en contactarnos si tienes preguntas o necesitas información adicional:',
        EMAIL: 'mail@realcolibri.com'
    },
        hy: {
            BANNER_TITLE: 'REALCOLIBRI ՊՐԵՄԻՈՒՄ',
            HEADER: 'Ստացեք ավտոմատացված առևտրի բոլոր առավելությունները ընդմիշտ՝ վճարելով միայն մեկ անգամ',
            NO_FEES: 'Առանց լրացուցիչ վճարների:',
            NO_LIMITS: 'Առանց օգտագործման սահմանափակումների:',
            SIMPLE: 'Այն այդքան էլ պարզ է!',
            SELECT_EXCHANGE_PACKAGE: 'Ընտրեք բորսան և փաթեթը',
            NEXT: 'ՀԱՋՈՐԴԸ',
            PURCHASE: 'Գնում',
            YOU_ARE_ABOUT_TO_PURCHASE: 'Դուք պատրաստվում եք գնել՝',
            PAYMENT_INSTRUCTIONS: 'Կատարեք վճարումը TRX (TRON) բորսայի վրա նշված հասցեով, որը համապատասխանում է ընտրված արժեքին USDT-ով:',
            ALERT: 'Համոզվեք, որ օգտագործում եք TRC-20 (TRON) ցանցը փոխանցման համար',
            CONTACT: 'Վճարումից հետո, խնդրում ենք կապվել մեզ հետ էլ. փոստով, տրամադրեք մեզ գործարքի անդորագիրը, ընտրված բորսան և ձեր մուտքանունը մեր կայքում։ Մենք կկապվենք ձեզ հետ հնարավորինս շուտ՝ հետագա հրահանգների համար:',
            REDIRECT: 'Երբ սեղմեք Շարունակել, դուք կուղղորդվեք RealColibri ալգորիթմի կառավարման էջին։ Համոզվեք, որ մուտք եք գործել ձեր RealColibri հաշիվ:',
            PROCEED: 'ՇԱՐՈՒՆԱԿԵԼ',
            BINANCE_INSTRUCTIONS: 'Binance-ի հրահանգներ',
            BINANCE_STEPS: [
                'Բացեք հաշիվ բորսայում և ավարտեք KYC վավերացումը:',
                'Համոզվեք, որ ստեղծել եք ֆյուչերսների դրամապանակ:',
                'Ալգորիթմը սկսելու համար դուք պետք է ստեղծեք API բանալիներ ձեր բորսայի հաշվին և ավելացնեք դրանք ալգորիթմի կառավարման էջում:',
                'API բանալիների ավելացումից մի քանի րոպե անց ալգորիթմը կսկսի առևտուր կատարել ձեր հաշվով:'
            ],
            GLOBAL_MARKET_INSTRUCTIONS: 'Համաշխարհային շուկայի հրահանգներ',
            GLOBAL_MARKET_STEPS: [
                'Բացեք հաշիվ FCM-ում, որը աջակցում է Rithmic տվյալների հոսքին (FCM-ների ցանկը):',
                'Ֆինանսավորեք ձեր FCM առևտրային հաշիվը (առնվազն 10,000 USD):',
                'Բաժանորդագրվեք Rithmic շուկայական տվյալներին (նախնականում առաջարկում ենք CME Bundle):',
                'Խնդրեք և ստացեք մուտքանուն և գաղտնաբառ (դեմո կամ իրական) և ավելացրեք դրանք ալգորիթմի կառավարման էջում:',
                'Ձեր հավատարմագրերի ավելացումից մի քանի րոպե անց ալգորիթմը կսկսի առևտուր կատարել ձեր հաշվով:'
            ],
            USE_GUIDES: 'Օգտագործեք մեր ուղեցույցները կարգավորման համար',
            GUIDES: 'ՈՒՂԵՑՈՒՅՑՆԵՐ',
            ABOUT_REALCOLIBRI_PREMIUM: 'RealColibri Պրեմիումի մասին',
            ABOUT_TEXT_1: 'Յուրաքանչյուր փաթեթի արժեքը համապատասխանում է բորսայի վրա առևտրային դեպոզիտի չափին...',
            ABOUT_TEXT_2: 'Հաշվի առնելով, որ ռիսկից ազատ առևտրի նվազագույն դեպոզիտի չափը 10,000 USDT է...',
            TABLE_NOTE: '* RCP փաթեթների քանակը սահմանափակ է:',
            FAQ_TITLE: 'ՊՐԵՄԻՈՒՄ ՀՏՀ',
            FAQ_QUESTIONS: [
                { question: 'Ի՞նչ է վերադարձը:', answer: 'Յուրաքանչյուր փաթեթի միջին վերադարձը կազմում է նվազագույն դեպոզիտի չափի 10% ամսական։ Օրինակ, RCP-10000 փաթեթի վերադարձը կկազմի միջինում 1,000 USDT ամսական, անկախ նրանից, թե որքան է ավելանում դեպոզիտի չափը։ Եթե դեպոզիտի չափը ավելի փոքր է նվազագույն դեպոզիտից, առևտրային ալգորիթմը դադարում է։ Վերադարձը կարող է փոփոխվել շուկայում եղած իրավիճակից կախված։' },
                { question: 'Կա՞ միջնորդավճար:', answer: 'RealColibri-ն չի գանձում լրացուցիչ վճարներ։ Դուք վճարում եք միայն ձեր գործարքների համար բորսայի միջնորդավճարը։' },
                { question: 'Ո՞ւր է գնում շահույթը:', answer: 'Ստացված շահույթը մնում է ձեր բորսային հաշվին ամբողջությամբ։' },
                { question: 'Որքա՞ն ժամանակ են փաթեթները հասանելի: Եթե այլևս փաթեթը ինձ պետք չէ, կստանամ արդյո՞ք իմ գումարը հետ:', answer: 'Գնված փաթեթը մշտապես կցվում է ձեր հաշվին։ RealColibri-ն չի տրամադրում փաթեթների գնման համար փոխհատուցումներ։' },
                { question: 'Ինչքա՞ն արժեն փաթեթները:', answer: 'Յուրաքանչյուր փաթեթի արժեքը համապատասխանում է դեպոզիտի չափին, որը նշված է անվանման մեջ, TRX-ով։ Օրինակ, RCP-10000 փաթեթը նախատեսված է 10,000 USDT դեպոզիտի վրա առևտրի համար, և այդ փաթեթի գնման արժեքը համապատասխանում է 10,000 USDT-ին TRX-ի էկվիվալենտում ըստ վճարման պահին TRX/USDT ընթացիկ փոխարժեքի։' },
                { question: 'Ինչպե՞ս վճարել և ստանալ փաթեթը:', answer: 'Փաթեթը ընտրելիս դուք կտեսնեք դրամապանակի հասցեն, որի վրա պետք է փոխանցեք TRX։ Վճարումից հետո դուք պետք է ուղարկեք ձեր մուտքանունը, որը նշվել է մեր կայքում գրանցման ժամանակ, ընտրված բորսան և գործարքի անդորագիրը mail@realcolibri.com հասցեին։ Այնուհետև ձեր հաշիվը կկարգավորվի նախապես պատրաստված առևտրային ալգորիթմի կարգավորումներով՝ համապատասխան գնված փաթեթին։' },
                { question: 'Ինչ կլինի, եթե ուզում եմ մեծացնել իմ եկամուտները:', answer: 'Քանի որ փաթեթի եկամուտները կապված են նվազագույն դեպոզիտի չափի հետ, եկամուտները մեծացնելու համար անհրաժեշտ է գնել նոր փաթեթ, որը համապատասխանում է ցանկալի եկամուտներին։ Հիշեք, որ մեծացված եկամուտները նաև կպահանջեն մեծացված դեպոզիտի չափ։ Օրինակ, եթե օգտագործում եք RCP-10000 փաթեթը, իսկ ձեր դեպոզիտը կազմում է 5,000 USDT, եկամուտը կմնա նվազագույնը 1,000 USDT ամսական։ Որպեսզի վաստակեք նվազագույնը 5,000 USDT ամսական, պետք է գնել RCP-50000 փաթեթը։' },
                { question: 'Ինչ կլինի, եթե փաթեթի եկամուտները նվազում են:', answer: 'Մենք մշտապես հետևում ենք բորսաներում եղած իրավիճակին և միացնում միայն առավել օպտիմալ առևտրային գործիքները։ Եթե նկատում եք, որ որոշակի առևտրային գործիքի եկամուտները կարող են ավելի բարձր լինել ձեր ընթացիկ փաթեթից, գրեք մեզ mail@realcolibri.com հասցեին խնդրանքով՝ փոխելու ձեր հաշվին առևտրային գործիքը։' },
                { question: 'Ինչու՞ է այս ակցիան սահմանափակ:', answer: 'Փաթեթների վաճառքից ստացված միջոցները կօգտագործվեն RealColibri համակարգի զարգացման համար։ Ի թիվս այլնի, դա կօգնի բարելավել առևտրային ալգորիթմը՝ դարձնելու համար այն ավելի շահավետ, կայուն և անվտանգ, ինչպես նաև արագացնելու նոր հնարավորությունների ներդրումը։' }
            ],
            FEEL_FREE_CONTACT: 'Խնդրում ենք կապնվել մեզ հետ, եթե ունեք հարցեր կամ ձեզ անհրաժեշտ է լրացուցիչ տեղեկություններ՝',
            EMAIL: 'mail@realcolibri.com'
        },
        fr: {
            BANNER_TITLE: 'REALCOLIBRI PREMIUM',
            HEADER: 'Profitez de tous les avantages du trading automatisé pour toujours en ne payant qu\'une seule fois',
            NO_FEES: 'Aucuns frais supplémentaires.',
            NO_LIMITS: 'Aucune limite d\'utilisation.',
            SIMPLE: 'C\'est aussi simple que ça!',
            SELECT_EXCHANGE_PACKAGE: 'Sélectionnez une bourse et un package',
            NEXT: 'SUIVANT',
            PURCHASE: 'Acheter',
            YOU_ARE_ABOUT_TO_PURCHASE: 'Vous êtes sur le point d\'acheter:',
            PAYMENT_INSTRUCTIONS: 'Effectuez un paiement en TRX (TRON) à l\'adresse ci-dessus pour un montant égal à la valeur sélectionnée en USDT.',
            ALERT: 'Assurez-vous d\'utiliser le réseau TRC-20 (TRON) pour le transfert',
            CONTACT: 'Après le paiement, veuillez nous contacter par e-mail, en nous fournissant le reçu de la transaction, la bourse que vous avez choisie et votre nom d\'utilisateur sur notre site web. Nous vous contacterons dès que possible pour plus d\'instructions.',
            REDIRECT: 'Lorsque vous cliquez sur "Continuer", vous serez redirigé vers la page de gestion de l\'algorithme RealColibri. Assurez-vous d\'être connecté à votre compte RealColibri.',
            PROCEED: 'CONTINUER',
            BINANCE_INSTRUCTIONS: 'Instructions pour Binance',
            BINANCE_STEPS: [
                'Ouvrez un compte sur une bourse et complétez la vérification KYC.',
                'Assurez-vous d\'avoir créé un portefeuille de futures.',
                'Pour démarrer l\'algorithme, vous devrez créer des clés API dans votre compte de la bourse et les ajouter dans la section correspondante sur la page de gestion de l\'algorithme.',
                'Quelques minutes après l\'ajout des clés API, l\'algorithme commencera à trader sur votre compte.'
            ],
            GLOBAL_MARKET_INSTRUCTIONS: 'Instructions pour le marché global',
            GLOBAL_MARKET_STEPS: [
                'Ouvrez un compte chez un FCM qui prend en charge le flux de données Rithmic (la liste des FCM).',
                'Financer votre compte de trading FCM (minimum 10 000 USD).',
                'Souscrivez aux données de marché Rithmic (nous recommandons le CME Bundle par défaut).',
                'Demandez et recevez un identifiant et un mot de passe (démo ou réel) et ajoutez-les sur la page de gestion de l\'algorithme.',
                'Quelques minutes après l\'ajout de vos identifiants, l\'algorithme commencera à trader sur votre compte.'
            ],
            USE_GUIDES: 'Utilisez nos guides pour vous aider à la configuration',
            GUIDES: 'GUIDES',
            ABOUT_REALCOLIBRI_PREMIUM: 'À propos de RealColibri Premium',
            ABOUT_TEXT_1: 'Le coût de chaque package correspond à la taille du dépôt de trading sur la bourse...',
            ABOUT_TEXT_2: 'Étant donné que la taille minimale du dépôt pour un trading sans risque est de 10 000 USDT...',
            TABLE_NOTE: '* Le nombre de packages RCP est limité.',
            FAQ_TITLE: 'FAQ PREMIUM',
            FAQ_QUESTIONS: [
                { question: 'Quels sont les rendements?', answer: 'Les rendements de chaque package sont en moyenne de 10 % par mois du montant du dépôt minimum. Par exemple, les rendements du package RCP-10000 seront en moyenne de 1 000 USDT par mois, quel que soit le montant du dépôt. Si la taille du dépôt est inférieure au montant du dépôt minimum, l\'algorithme de trading sera mis en pause. Les rendements peuvent varier en fonction de la situation sur le marché.' },
                { question: 'Y a-t-il des commissions?', answer: 'RealColibri ne facture pas de frais supplémentaires. Vous ne payez que la commission de la bourse pour vos transactions.' },
                { question: 'Où vont les bénéfices?', answer: 'Les bénéfices réalisés restent intégralement sur votre compte de la bourse.' },
                { question: 'Combien de temps les packages sont-ils disponibles? Et si je n\'ai plus besoin du package? Vais-je être remboursé?', answer: 'Le package acheté est définitivement attribué à votre compte. RealColibri ne fournit pas de remboursement pour l\'achat de packages.' },
                { question: 'Combien coûtent les packages?', answer: 'Le coût de chaque package correspond à la taille du dépôt spécifié dans le nom, en TRX. Par exemple, le package RCP-10000 est conçu pour trader sur un dépôt de 10 000 USDT et le coût d\'achat de ce package est égal à 10 000 USDT en équivalent TRX au taux de change TRX/USDT en vigueur au moment du paiement.' },
                { question: 'Comment payer et recevoir le package?', answer: 'En sélectionnant un package, vous verrez l\'adresse du portefeuille à laquelle transférer les TRX. Après le paiement, vous devrez envoyer votre nom d\'utilisateur, spécifié lors de votre inscription sur notre site web, la bourse sélectionnée et le reçu de la transaction à mail@realcolibri.com. Ensuite, votre compte sera configuré avec des paramètres de trading préétablis en fonction du package acheté.' },
                { question: 'Que faire si je souhaite augmenter mes rendements?', answer: 'Étant donné que les rendements des packages sont liés au montant du dépôt minimum, pour augmenter les rendements, vous devrez acheter un nouveau package correspondant aux rendements souhaités. Gardez à l\'esprit que les rendements augmentés nécessiteront également un dépôt plus important. Par exemple, si vous utilisez le package RCP-10000 et que votre dépôt est de 5 000 USDT, les rendements resteront au même niveau de pas moins de 1 000 USDT par mois. Pour gagner pas moins de 5 000 USDT par mois, vous devrez acheter le package RCP-50000.' },
                { question: 'Que faire si les rendements du package diminuent?', answer: 'Nous surveillons constamment la situation sur les bourses et connectons uniquement les instruments de trading les plus optimaux. Si vous constatez que les rendements d\'un instrument de trading particulier peuvent être plus élevés que ceux de votre package actuel, écrivez-nous à mail@realcolibri.com avec une demande pour changer l\'instrument de trading sur votre compte.' },
                { question: 'Pourquoi cette promotion est-elle limitée?', answer: 'Les fonds provenant de la vente des packages seront utilisés pour le développement du système RealColibri. Entre autres choses, cela aidera à améliorer l\'algorithme de trading pour un trading encore plus rentable, stable et sûr, ainsi qu\'à accélérer l\'introduction de nouvelles fonctionnalités.' }
            ],
            FEEL_FREE_CONTACT: 'N\'hésitez pas à nous contacter si vous avez des questions ou si vous avez besoin d\'informations supplémentaires:',
            EMAIL: 'mail@realcolibri.com'
        },
        de: {
            BANNER_TITLE: 'REALCOLIBRI PREMIUM',
            HEADER: 'Erhalten Sie alle Vorteile des automatisierten Handels für immer, indem Sie nur einmal bezahlen',
            NO_FEES: 'Keine zusätzlichen Gebühren.',
            NO_LIMITS: 'Keine Nutzungseinschränkungen.',
            SIMPLE: 'So einfach ist das!',
            SELECT_EXCHANGE_PACKAGE: 'Wählen Sie eine Börse und ein Paket',
            NEXT: 'WEITER',
            PURCHASE: 'Kaufen',
            YOU_ARE_ABOUT_TO_PURCHASE: 'Sie sind dabei, folgendes zu kaufen:',
            PAYMENT_INSTRUCTIONS: 'Zahlen Sie in TRX (TRON) an die oben angegebene Adresse einen Betrag in Höhe des ausgewählten Wertes in USDT.',
            ALERT: 'Stellen Sie sicher, dass Sie das TRC-20 (TRON) Netzwerk für die Übertragung verwenden',
            CONTACT: 'Nach der Zahlung kontaktieren Sie uns bitte per E-Mail, geben Sie uns den Transaktionsbeleg, die ausgewählte Börse und Ihren Benutzernamen auf unserer Website an. Wir werden uns so schnell wie möglich mit Ihnen in Verbindung setzen, um weitere Anweisungen zu geben.',
            REDIRECT: 'Wenn Sie auf "Fortfahren" klicken, werden Sie auf die Seite zur Verwaltung des RealColibri-Algorithmus weitergeleitet. Stellen Sie sicher, dass Sie in Ihrem RealColibri-Konto angemeldet sind.',
            PROCEED: 'FORTFAHREN',
            BINANCE_INSTRUCTIONS: 'Anweisungen für Binance',
            BINANCE_STEPS: [
                'Eröffnen Sie ein Konto an einer Börse und vervollständigen Sie die KYC-Verifizierung.',
                'Stellen Sie sicher, dass Sie ein Futures-Wallet erstellt haben.',
                'Um den Algorithmus zu starten, müssen Sie API-Schlüssel in Ihrem Börsenkonto erstellen und diese im entsprechenden Bereich auf der Algorithmus-Verwaltungsseite hinzufügen.',
                'Einige Minuten nach dem Hinzufügen der API-Schlüssel beginnt der Algorithmus, auf Ihrem Konto zu handeln.'
            ],
            GLOBAL_MARKET_INSTRUCTIONS: 'Anweisungen für den globalen Markt',
            GLOBAL_MARKET_STEPS: [
                'Eröffnen Sie ein Konto bei einem FCM, das den Rithmic-Datenfeed unterstützt (die Liste der FCMs).',
                'Finanzieren Sie Ihr FCM-Handelskonto (mindestens 10.000 USD).',
                'Abonnieren Sie Rithmic-Marktdaten (wir empfehlen das CME-Bundle als Standard).',
                'Fordern Sie einen Login und ein Passwort an (Demo oder live) und fügen Sie diese auf der Algorithmus-Verwaltungsseite hinzu.',
                'Einige Minuten nach dem Hinzufügen Ihrer Anmeldeinformationen beginnt der Algorithmus, auf Ihrem Konto zu handeln.'
            ],
            USE_GUIDES: 'Verwenden Sie unsere Anleitungen, um Ihnen bei der Einrichtung zu helfen',
            GUIDES: 'ANLEITUNGEN',
            ABOUT_REALCOLIBRI_PREMIUM: 'Über RealColibri Premium',
            ABOUT_TEXT_1: 'Die Kosten für jedes Paket entsprechen der Größe des Handelsdeposits an der Börse...',
            ABOUT_TEXT_2: 'Da die Mindestgröße des Depots für risikofreien Handel 10.000 USDT beträgt...',
            TABLE_NOTE: '* Die Anzahl der RCP-Pakete ist begrenzt.',
            FAQ_TITLE: 'PREMIUM FAQ',
            FAQ_QUESTIONS: [
                { question: 'Wie hoch sind die Renditen?', answer: 'Die Renditen jedes Pakets betragen im Durchschnitt 10 % pro Monat der Mindestdepositgröße. Zum Beispiel werden die Renditen des RCP-10000-Pakets im Durchschnitt ab 1.000 USDT pro Monat betragen, unabhängig davon, wie stark die Depositgröße steigt. Wenn die Depositgröße unter der Mindestdepositgröße liegt, wird der Handelsalgorithmus pausiert. Die Renditen können je nach Marktsituation variieren.' },
                { question: 'Gibt es eine Kommission?', answer: 'RealColibri erhebt keine zusätzlichen Gebühren. Sie zahlen nur die Börsenprovision für Ihre Trades.' },
                { question: 'Wohin geht der Gewinn?', answer: 'Der erzielte Gewinn verbleibt vollständig auf Ihrem Börsenkonto.' },
                { question: 'Wie lange sind die Pakete verfügbar? Was ist, wenn ich das Paket nicht mehr brauche? Bekomme ich mein Geld zurück?', answer: 'Das gekaufte Paket wird dauerhaft Ihrem Konto zugewiesen. RealColibri bietet keine Rückerstattungen für den Kauf von Paketen an.' },
                { question: 'Wie viel kosten die Pakete?', answer: 'Die Kosten jedes Pakets entsprechen der im Namen angegebenen Depositgröße, in TRX. Zum Beispiel ist das RCP-10000-Paket für den Handel mit einem Deposit von 10.000 USDT konzipiert, und die Kosten für den Kauf dieses Pakets entsprechen 10.000 USDT in TRX-Äquivalent zum aktuellen TRX/USDT-Wechselkurs zum Zeitpunkt der Zahlung.' },
                { question: 'Wie bezahle ich und erhalte das Paket?', answer: 'Beim Auswählen eines Pakets sehen Sie die Adresse der Wallet, auf die TRX übertragen werden sollen. Nach der Zahlung sollten Sie Ihren Benutzernamen, den Sie bei der Registrierung auf unserer Website angegeben haben, die ausgewählte Börse und den Transaktionsbeleg an mail@realcolibri.com senden. Dann wird Ihr Konto mit voreingestellten Handelseinstellungen gemäß dem gekauften Paket eingerichtet.' },
                { question: 'Was ist, wenn ich meine Renditen erhöhen möchte?', answer: 'Da die Paket-Renditen an die Mindestdepositgröße gebunden sind, müssen Sie ein neues Paket kaufen, das den gewünschten Renditen entspricht, um die Renditen zu erhöhen. Beachten Sie, dass die erhöhten Renditen auch eine erhöhte Depositgröße erfordern. Zum Beispiel, wenn Sie das RCP-10000-Paket verwenden und Ihre Depositgröße 5.000 USDT beträgt, bleiben die Renditen auf dem gleichen Niveau von mindestens 1.000 USDT pro Monat. Um nicht weniger als 5.000 USDT pro Monat zu verdienen, müssen Sie das RCP-50000-Paket kaufen.' },
                { question: 'Was ist, wenn die Renditen des Pakets sinken?', answer: 'Wir überwachen ständig die Situation an den Börsen und verbinden nur die optimalsten Handelsinstrumente. Wenn Sie feststellen, dass die Renditen eines bestimmten Handelsinstruments höher sein könnten als bei Ihrem aktuellen Paket, schreiben Sie uns an mail@realcolibri.com mit einer Anfrage zur Änderung des Handelsinstruments auf Ihrem Konto.' },
                { question: 'Warum ist diese Promotion begrenzt?', answer: 'Die Mittel aus dem Verkauf der Pakete werden für die Entwicklung des RealColibri-Systems verwendet. Unter anderem wird dies dazu beitragen, den Handelsalgorithmus für noch profitableren, stabileren und sichereren Handel zu verbessern sowie die Einführung neuer Funktionen zu beschleunigen.' }
            ],
            FEEL_FREE_CONTACT: 'Kontaktieren Sie uns gerne, wenn Sie Fragen haben oder zusätzliche Informationen benötigen:',
            EMAIL: 'mail@realcolibri.com'
        }
    };

    export default translations