import 'antd/dist/reset.css'
import './index.scss'

import React from 'react'
import ReactDOM from 'react-dom/client'
import { App } from './App'
import { Compose } from 'shared/components'
import { BrowserRouter } from 'react-router-dom'
import { LoaderContextProvider } from 'core/context/Loader/LoaderContextProvider'
import { AccountContextProvider } from 'core/context/Account/AccountContextProvider'
import { ConfigProvider } from 'antd'
import en from 'antd/lib/locale/en_US'
import enDayjs from 'dayjs/locale/en'
import dayjs from 'dayjs'

dayjs.locale(enDayjs)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
    <ConfigProvider
        locale={en}
        theme={{
            token: {
                colorPrimary: '#1890ff',
            },
        }}
    >
        <Compose
            components={[
                BrowserRouter,
                LoaderContextProvider,
                AccountContextProvider,
            ]}
        >
            <App />
        </Compose>
    </ConfigProvider>
)
