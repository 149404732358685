import React from 'react'
import { ColumnsType } from 'antd/lib/table'
import { PopupAdapter } from 'shared/components'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { BybitSymbol } from './BybitSymbol'
import { BybitSymbolDto } from 'api/http/models'
import { BybitSymbolDelete } from './BybitSymbolDelete'
import { FnActionProps } from 'App.types'

export const getBybitSymbolsTableColumns = (
    handleRequestFinish: FnActionProps
): ColumnsType<BybitSymbolDto> => [
    {
        title: 'Название',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Биржевой символ',
        dataIndex: 'symbol',
        key: 'symbol',
    },
    {
        title: 'Размер шага',
        dataIndex: 'stepSize',
        key: 'stepSize',
    },
    {
        title: 'Размер позиции при открытии',
        dataIndex: 'defaultPositionSize',
        key: 'defaultPositionSize',
    },
    {
        title: 'Максимум лотов',
        dataIndex: 'defaultMaxLots',
        key: 'defaultMaxLots',
    },
    {
        title: 'Минимальный баланс',
        dataIndex: 'minBalance',
        key: 'minBalance',
    },
    {
        title: 'Шагов до тейк-профита',
        dataIndex: 'takeProfitRatio',
        key: 'takeProfitRatio',
    },
    {
        title: 'Свечей для тейк-профита',
        dataIndex: 'feedCount',
        key: 'feedCount',
    },
    {
        title: 'Только для админа',
        dataIndex: 'onlyAdmin',
        key: 'onlyAdmin',
        render: onlyAdmin => (onlyAdmin ? 'Да' : 'Нет'),
    },
    {
        key: 'actions',
        align: 'right',
        width: 80,
        render: record => (
            <>
                <PopupAdapter
                    formId="symbolEditForm"
                    buttonOption={{
                        type: 'link',
                        icon: <EditOutlined />,
                    }}
                    formOptions={{
                        initialValues: record,
                    }}
                    modalOptions={{
                        title: 'Редактировать',
                    }}
                    component={BybitSymbol}
                    onRequestFinish={handleRequestFinish}
                />

                <PopupAdapter
                    formId="symbolDeleteForm"
                    buttonOption={{
                        type: 'link',
                        icon: <DeleteOutlined />,
                    }}
                    formOptions={{
                        initialValues: record,
                    }}
                    modalOptions={{
                        title: 'Удаление',
                    }}
                    component={BybitSymbolDelete}
                    onRequestFinish={handleRequestFinish}
                />
            </>
        ),
    },
]
