import React from 'react'
import dayjs from 'dayjs'
import {
    BinanceTableFiltersProps,
    FiltersFormProps,
} from './BybitTableFilters.types'
import { Button, DatePicker, Form, Space } from 'antd'
import { SearchOutlined } from '@ant-design/icons'

const { RangePicker } = DatePicker

/** Фильтры для таблицы Binance*/
export const BybitTableFilters: React.FC<BinanceTableFiltersProps> = React.memo(
    ({ onQueryParams }) => {
        /**
         * Обработчик установки значений фильтра
         * @param values значения фильтра
         */
        const handleFinish = ({ period }: FiltersFormProps) => {
            const [startDate, endDate] = period || []

            onQueryParams({
                startDate: startDate?.startOf('day').valueOf() || 0,
                endDate: endDate?.endOf('day').valueOf(),
            })
        }

        return (
            <Form layout="vertical" onFinish={handleFinish}>
                <Space align="start">
                    <Form.Item name="period">
                        <RangePicker
                            disabledDate={current =>
                                current && current > dayjs().endOf('day')
                            }
                            presets={[
                                { label: 'Today', value: [dayjs(), dayjs()] },
                                {
                                    label: 'This Month',
                                    value: [
                                        dayjs().startOf('month'),
                                        dayjs().endOf('month'),
                                    ],
                                },
                            ]}
                        />
                    </Form.Item>

                    <Button htmlType="submit" icon={<SearchOutlined />}>
                        Show
                    </Button>
                </Space>
            </Form>
        )
    }
)
