import s from './ManualTrade.module.scss'

import React, { useCallback, useEffect, useState } from 'react'
import {
    ManualTradeProps,
    RithmicManualTradeFormProps,
} from './ManualTrade.types'
import { Button, Form, Tag } from 'antd'
import { WsRequestEventType } from '../../RithmicTrade.types'
import { RithmicPriceTypes, RithmicTransactionTypes } from 'api/http/models'
import { InputNumberControl } from 'shared/components'
import { TAGS } from './ManualTrade.consts'
import { isTagTypeLimitRithmic } from './ManualTrade.utils'

/** Ручная торговля */
export const ManualTrade: React.FC<ManualTradeProps> = React.memo(
    ({ onSendMessage, symbol }) => {
        const [form] = Form.useForm()
        const [orderType, setOrderType] = useState<RithmicPriceTypes>(
            RithmicPriceTypes.MARKET
        )
        const [side, setSide] = useState<RithmicTransactionTypes>()

        /**
         * Выбор типа заявки
         * @param tagName тип заявки
         */
        const handleChangeTag = useCallback(
            (tagName: RithmicPriceTypes) => {
                setOrderType(tagName)
                form.setFieldsValue({
                    price: undefined,
                })
            },
            [form]
        )

        const handleFinish = useCallback(
            (payload: RithmicManualTradeFormProps) => {
                if (!symbol || !side) return

                onSendMessage({
                    event_type: WsRequestEventType.NewOrder,
                    payload: {
                        ...payload,
                        symbol,
                        order_type: orderType,
                        side,
                    },
                })
            },
            [orderType, side, onSendMessage, symbol]
        )

        useEffect(() => {
            if (symbol) form.resetFields()
        }, [symbol, form])

        return (
            <Form
                onFinish={handleFinish}
                layout="vertical"
                className={s.wrapper}
                form={form}
            >
                <div className={s.tags}>
                    {TAGS.map(tag => (
                        <Tag.CheckableTag
                            key={tag.id}
                            checked={orderType === tag.id}
                            onChange={() => handleChangeTag(tag.id)}
                        >
                            {tag.tagName}
                        </Tag.CheckableTag>
                    ))}
                </div>

                <Form.Item name="quantity" label={`Size (${symbol || ''})`}>
                    <InputNumberControl min={0} placeholder="0" precision={0} />
                </Form.Item>

                <Form.Item name="price" label="Price (USD)">
                    <InputNumberControl
                        min={0}
                        placeholder="0.0"
                        disabled={!isTagTypeLimitRithmic(orderType)}
                    />
                </Form.Item>

                <div className={s.btn}>
                    <Button
                        htmlType="submit"
                        type="primary"
                        onClick={() => {
                            setSide(RithmicTransactionTypes.BUY)
                        }}
                        block
                    >
                        Buy/Long
                    </Button>

                    <Button
                        htmlType="submit"
                        type="primary"
                        onClick={() => {
                            setSide(RithmicTransactionTypes.SELL)
                        }}
                        danger
                        block
                    >
                        Sell/Short
                    </Button>
                </div>
            </Form>
        )
    }
)
