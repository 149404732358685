import React from 'react'
import s from './VideoIFrame.module.scss'
import { VideoIFrameProps } from './VideoIFrame.model'
import cn from 'classnames'

/** iframe для видео */
export const VideoIFrame: React.FC<VideoIFrameProps> = React.memo(
    ({ title, src, className }) => {
        return (
            <iframe
                className={cn(s.wrapper, className)}
                width="100%"
                height="auto"
                src={src}
                title={title}
                allowFullScreen
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            />
        )
    }
)
