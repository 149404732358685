import { TronWebProps } from './tron.types'
import { notification } from 'antd'
import { FACTORY_ABI, RCBC_ABI, SERVICE_ABI, STAKING_ABI } from './abi.config'
import {
    RCBC_ADDRESS,
    RCFACTORY_ADDRESS,
    RCSTAKING_ADDRESS,
} from './tron.consts'

class TronLinkHelper {
    tronWeb: TronWebProps | undefined
    base58Address: string | undefined
    hexAddress: string | undefined
    instanceRcbc: any
    instanceReferral: any
    instanceService: any
    instanceStaking: any

    private _connect = async (): Promise<boolean> => {
        if (!window.tronLink) return false

        const dataSource = await window.tronLink.request({
            method: 'tron_requestAccounts',
        })

        if (dataSource?.code === 200) return true

        notification.error({
            message: `TronLink error ${dataSource?.code || ''}`,
            description: dataSource?.message || 'Something went wrong',
        })

        return false
    }

    private _getInstanceContract = async (address: string, abi?: any[]) => {
        try {
            const instance = await this.tronWeb?.contract?.().at(address)
            if (abi) {
                instance?.loadAbi(abi)
            }

            return instance
        } catch (e) {
            console.log(e)
        }
    }

    initTronWeb = async () => {
        if (!(await this._connect())) {
            throw Error
        }

        this.tronWeb = window.tronLink.tronWeb

        if (
            process.env.NODE_ENV === 'production' &&
            this.tronWeb.fullNode.host !== 'https://api.trongrid.io'
        ) {
            notification.warning({
                message: 'Not mainnet',
                description:
                    'Please, change mainnet in TronLink and refresh page',
            })

            throw Error
        }

        if (
            process.env.NODE_ENV === 'development' &&
            this.tronWeb.fullNode.host !== 'https://api.nileex.io'
        ) {
            notification.warning({
                message: 'Not testnet',
                description:
                    'Please, change testnet in TronLink and refresh page',
            })

            throw Error
        }

        this.instanceRcbc = await this._getInstanceContract(
            RCBC_ADDRESS,
            RCBC_ABI
        )
        this.instanceReferral = await this._getInstanceContract(
            RCFACTORY_ADDRESS,
            FACTORY_ABI
        )
        this.instanceStaking = await this._getInstanceContract(
            RCSTAKING_ADDRESS,
            STAKING_ABI
        )
        this.base58Address = this.tronWeb.defaultAddress.base58
        this.hexAddress = this.tronWeb.defaultAddress.hex

        return this
    }

    signMessage = async (nonce: string): Promise<string | undefined> =>
        await this.tronWeb?.trx.signMessage(nonce)

    setServiceContract = async (address: string) => {
        this.instanceService = await this._getInstanceContract(
            address,
            SERVICE_ABI
        )
    }
}

export const TronLink = new TronLinkHelper()
