import { MarketType } from 'api/http/models'

/**
 * Проверка типа биржи
 * @param marketType тип биржи
 */
export const isMarketBinanceType = (marketType?: MarketType) =>
    marketType === MarketType.BINANCE
export const isMarketRithmicType = (marketType?: MarketType) =>
    marketType === MarketType.RITHMIC
export const isMarketBybitType = (marketType?: MarketType) =>
    marketType === MarketType.BYBIT
