import s from './BinanceSymbols.module.scss'

import React, { useCallback, useContext, useEffect, useState } from 'react'
import { PageContent, PopupAdapter, TableAdapter } from 'shared/components'
import { useQueryParams } from 'shared/hooks'
import { getBybitSymbolsTableColumns } from './BybitSymbols.utils'
import { BybitSymbol } from './BybitSymbol'
import { BybitSymbolsService } from 'api/http'
import { BybitSymbolDto } from 'api/http/models'
import { LoaderContext } from 'core/context'

/** Таблица символов Bybit */
export const BybitSymbols: React.FC = () => {
    const { setLoader } = useContext(LoaderContext)
    const [symbols, setSymbols] = useState<BybitSymbolDto[]>()
    const [itemsCount, setItemsCount] = useState(0)
    const { paginationOptions, queryParams } = useQueryParams()

    const fetchBybitSymbols = useCallback(async () => {
        try {
            setLoader(true)

            const dataSource = await BybitSymbolsService.search(
                queryParams.skip,
                queryParams.limit
            )
            setSymbols(dataSource.items)
            setItemsCount(dataSource.total)
        } catch (e) {
            console.log(e)
        } finally {
            setLoader(false)
        }
    }, [setLoader])

    useEffect(() => {
        fetchBybitSymbols()
    }, [fetchBybitSymbols])

    return (
        <PageContent>
            <div className={s.header}>
                <h2>Symbols for Bybit</h2>

                <PopupAdapter
                    formId="symbolCreateForm"
                    buttonText="Добавить"
                    buttonOption={{
                        type: 'primary',
                    }}
                    modalOptions={{
                        title: 'Добавить',
                    }}
                    component={BybitSymbol}
                    onRequestFinish={fetchBybitSymbols}
                />
            </div>

            <TableAdapter
                columns={getBybitSymbolsTableColumns(fetchBybitSymbols)}
                dataSource={symbols}
                pagination={{
                    ...paginationOptions,
                    total: itemsCount,
                }}
            />
        </PageContent>
    )
}
