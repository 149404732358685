import React, { useCallback, useEffect, useState } from 'react'
import { TableAdapter } from 'shared/components'
import { ORDERS_HISTORY_TABLE_COLUMNS } from './OrdersHistory.consts'
import { DealsHistoryProps } from './OrdersHistory.types'
import { BybitNativeService } from 'api/http'
import { BybitOrderHistoryDto } from 'api/http/models'

/** История сделок */
export const OrdersHistory: React.FC<DealsHistoryProps> = React.memo(
    ({ symbol, marketId }) => {
        const [orders, setOrders] = useState<BybitOrderHistoryDto[]>()

        const handleOrdersFetch = useCallback(async () => {
            try {
                if (symbol && marketId) {
                    setOrders(
                        await BybitNativeService.getOrdersHistory(marketId, {
                            symbol,
                        })
                    )
                }
            } catch (e) {
                console.log(e)
            }
        }, [symbol, marketId])

        useEffect(() => {
            handleOrdersFetch()
        }, [handleOrdersFetch])

        return (
            <TableAdapter
                columns={ORDERS_HISTORY_TABLE_COLUMNS}
                loading={!orders}
                dataSource={orders}
                rowKey="orderId"
            />
        )
    }
)
