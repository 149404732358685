import React from 'react'
import dayjs from 'dayjs'
import {
    BinanceTableFiltersProps,
    FiltersFormProps,
} from './BinanceTableFilters.types'
import { Button, DatePicker, Form, Space } from 'antd'
import { SearchOutlined } from '@ant-design/icons'

const { RangePicker } = DatePicker

/** Фильтры для таблицы Binance*/
export const BinanceTableFilters: React.FC<BinanceTableFiltersProps> =
    React.memo(({ onQueryParams }) => {
        /**
         * Обработчик установки значений фильтра
         * @param values значения фильтра
         */
        const handleFinish = ({ period }: FiltersFormProps) => {
            const [start_date, end_date] = period || []

            onQueryParams(prevState => ({
                ...prevState,
                body: {
                    start_date: start_date?.startOf('day').valueOf() || 0,
                    end_date: end_date?.endOf('day').valueOf(),
                },
            }))
        }

        return (
            <Form layout="vertical" onFinish={handleFinish}>
                <Space align="start">
                    <Form.Item name="period">
                        <RangePicker
                            disabledDate={current =>
                                current && current > dayjs().endOf('day')
                            }
                            presets={[
                                { label: 'Today', value: [dayjs(), dayjs()] },
                                {
                                    label: 'This Month',
                                    value: [
                                        dayjs().startOf('month'),
                                        dayjs().endOf('month'),
                                    ],
                                },
                            ]}
                        />
                    </Form.Item>

                    <Button htmlType="submit" icon={<SearchOutlined />}>
                        Show
                    </Button>
                </Space>
            </Form>
        )
    })
