import React, { useCallback, useContext, useEffect, useState } from 'react';
import { LoaderContext } from 'core/context';
import { PageContent, TableAdapter } from 'shared/components';
import { RithmicDataSearchDto, RithmicUpdateDto } from 'api/http/models';
import { useFetch, useQueryParams } from 'shared/hooks';
import { RithmicService } from 'api/http';
import { getAccountTableColumns } from './RithmicAccounts.utils';
import s from './RithmicAccounts.module.scss';
import translations from './translations';
import { useLanguage } from 'context/LanguageContext';

/** Таблица аккаунтов Rithmic */
export const RithmicAccounts: React.FC = React.memo(() => {
    const { setLoader } = useContext(LoaderContext);
    const { language } = useLanguage();
    const t = translations[language];

    const [fetchUpdate] = useFetch(RithmicService.update);
    const [accountsData, setAccountsData] = useState<RithmicDataSearchDto[]>();
    const { paginationOptions, queryParams } = useQueryParams();
    const [itemsCount, setItemsCount] = useState(0);

    /** Запрос за данными аккаунтов */
    const fetchAccounts = useCallback(async () => {
        try {
            setLoader(true);
            const { limit, skip } = queryParams;

            const dataSource = await RithmicService.search(skip, limit);
            setAccountsData(dataSource.items);
            setItemsCount(dataSource.total);
        } catch (e) {
            console.log(e);
        } finally {
            setLoader(false);
        }
    }, [setLoader, queryParams]);

    const handleMarketUpdate = useCallback(
        async (marketId: number, body: RithmicUpdateDto) => {
            await fetchUpdate(marketId, body);
            await fetchAccounts();
        },
        [fetchAccounts, fetchUpdate]
    );

    useEffect(() => {
        fetchAccounts();
    }, [fetchAccounts]);

    return (
        <PageContent>
            <h2 className={s.title}>{t.title}</h2>
            <p className={s.description}>{t.description}</p>

            <TableAdapter
                columns={getAccountTableColumns(handleMarketUpdate)}
                dataSource={accountsData}
                pagination={{
                    ...paginationOptions,
                    total: itemsCount,
                }}
            />
        </PageContent>
    );
});

export default RithmicAccounts;
