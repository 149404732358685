import s from './TradeActions.module.scss'

import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Button, Col, Form, message, Row, Tag } from 'antd'
import {
    HiddenFormItem,
    InputNumberControl,
    PopupAdapter,
} from 'shared/components'
import { TAGS } from './TradeActions.consts'
import { isTagTypeLimitBybit } from './TradeActions.utils'
import {
    BybitLeverageDto,
    BybitOrderSideTypes,
    BybitOrderTypes,
    BybitSendOrderDto,
} from 'api/http/models'
import { LeverageModal } from './LeverageModal'
import { TradeActionsProps } from './TradeActions.types'
import { BybitNativeService } from 'api/http'
import { LoaderContext } from 'core/context'

/** Кнопки управления заявками для покупки и продажи */
export const TradeActions: React.FC<TradeActionsProps> = React.memo(
    ({ settings: { symbol }, marketId }) => {
        const { setLoader } = useContext(LoaderContext)
        const [form] = Form.useForm()
        const [leverage, setLeverage] = useState<BybitLeverageDto>()
        const [orderType, setOrderType] = useState(BybitOrderTypes.Market)
        const [side, setSide] = useState<BybitOrderSideTypes>()

        const getLeverage = useCallback(async () => {
            try {
                if (!symbol) return

                setLeverage(
                    await BybitNativeService.getLeverage(marketId, {
                        symbol,
                    })
                )
            } catch (e) {
                console.log(e)
            }
        }, [marketId])

        /** Отправка формы */
        const handleFinish = useCallback(
            async (body: Pick<BybitSendOrderDto, 'size' | 'price'>) => {
                try {
                    if (!symbol || !side) return

                    setLoader(true)

                    const { orderId } = await BybitNativeService.sendOrder(
                        marketId,
                        {
                            ...body,
                            symbol,
                            orderType,
                            side,
                        }
                    )

                    message.success(`Ордер успешно размещен. ID ${orderId}`)
                } catch (e) {
                    console.log(e)
                } finally {
                    setLoader(false)
                }
            },
            [side, symbol, orderType, marketId, setLoader]
        )

        /**
         * Выбор типа заявки
         * @param tagName тип заявки
         */
        const handleChangeTag = useCallback(
            (tagName: BybitOrderTypes) => {
                setOrderType(tagName)
                form.setFieldsValue({
                    price: undefined,
                })
            },
            [form]
        )

        /** Обработчик покупки */
        const handleBuy = useCallback(() => {
            setSide(BybitOrderSideTypes.Buy)
        }, [])

        /** Обработчик продажи */
        const handleSell = useCallback(() => {
            setSide(BybitOrderSideTypes.Sell)
        }, [])

        useEffect(() => {
            if (symbol) {
                form.resetFields()
                getLeverage()
            }
        }, [symbol, getLeverage])

        return (
            <Form
                layout="vertical"
                className={s.wrapper}
                form={form}
                initialValues={{ marketId }}
                onFinish={handleFinish}
            >
                <HiddenFormItem name="marketId" />

                <Row justify="space-between" align="middle">
                    <Col>
                        <PopupAdapter
                            component={LeverageModal}
                            formId="leverageModalId"
                            buttonText={`${leverage?.leverage || ''}x`}
                            modalOptions={{
                                title: 'Change leverage',
                            }}
                            buttonOption={{
                                title: 'Change leverage',
                                disabled: !leverage,
                            }}
                            formOptions={{
                                initialValues: {
                                    symbol,
                                    leverage: leverage?.leverage,
                                    marketId,
                                },
                            }}
                            onRequestFinish={getLeverage}
                        />
                    </Col>

                    <Col>
                        <div className={s.tags}>
                            {TAGS.map(tag => (
                                <Tag.CheckableTag
                                    key={tag.id}
                                    checked={orderType === tag.id}
                                    onChange={() => {
                                        handleChangeTag(tag.id)
                                    }}
                                >
                                    {tag.tagName}
                                </Tag.CheckableTag>
                            ))}
                        </div>
                    </Col>
                </Row>

                <Form.Item name="size" label={`Size (${symbol || ''})`}>
                    <InputNumberControl min={0} placeholder="0.0" />
                </Form.Item>

                {isTagTypeLimitBybit(orderType) && (
                    <Form.Item name="price" label="Price (USDT)">
                        <InputNumberControl min={0} placeholder="0.0" />
                    </Form.Item>
                )}

                <Button.Group className={s.buttons}>
                    <Button
                        htmlType="submit"
                        type="primary"
                        onClick={handleBuy}
                        block
                    >
                        Buy/Long
                    </Button>

                    <Button
                        htmlType="submit"
                        type="primary"
                        onClick={handleSell}
                        danger
                        block
                    >
                        Sell/Short
                    </Button>
                </Button.Group>
            </Form>
        )
    }
)
