import React, { useCallback } from 'react'
import { OrdersProps } from './Orders.types'
import { TableAdapter } from 'shared/components'
import { ordersTableColumns } from './Orders.utils'
import { WsRequestEventType } from '../../RithmicTrade.types'

/** Таблица открытых ордеров */
export const Orders: React.FC<OrdersProps> = React.memo(
    ({ ordersData, onSendMessage }) => {
        /**
         * Обработчик отмены ордера
         */
        const handleCancelOrder = useCallback(
            (basket_id: string) => {
                onSendMessage({
                    event_type: WsRequestEventType.CancelOrder,
                    payload: [{ basket_id }],
                })
            },
            [onSendMessage]
        )

        /**
         * Обработчик отмены всех ордеров
         */
        const handleCancelAllOrders = useCallback(() => {
            onSendMessage({
                event_type: WsRequestEventType.CancelAllOrders,
                payload: ordersData?.map(el => ({ basket_id: el.basket_id })),
            })
        }, [onSendMessage, ordersData])

        return (
            <TableAdapter
                title={() => <h2>Orders</h2>}
                columns={ordersTableColumns(
                    handleCancelOrder,
                    handleCancelAllOrders,
                    ordersData?.length
                )}
                rowKey="basket_id"
                dataSource={ordersData}
                loading={!ordersData}
                pagination={false}
            />
        )
    }
)
