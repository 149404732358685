import axios, { AxiosError, AxiosResponse } from 'axios'
import { HTTPValidationError, ValidationError } from './models'
import { notification } from 'antd'
import { BASE_URL_HTTP } from './api.consts'
import { NAV_LINK_NAMES, ROUTES_WITHOUT_PERMISSIONS } from 'shared/consts'

export const instance = axios.create({
    baseURL: BASE_URL_HTTP,
    withCredentials: true,
})

/**
 * Обертка для работы с Promise
 * @param axiosInstanceResponse результат запроса axios
 */
export function axiosInstance<T>(
    axiosInstanceResponse: Promise<AxiosResponse<T>>
): Promise<T> {
    return new Promise((resolve, reject) =>
        axiosInstanceResponse
            .then(res => {
                resolve(res.data)
            })
            .catch((err: AxiosError<HTTPValidationError>) => {
                serverErrorTrap(err?.response?.status, err?.response?.data)
                reject(err)
            })
    )
}

/**
 * Обработчик ошибок с сервера
 * @param status статус код
 * @param data данные с сервера
 */
const serverErrorTrap = (status?: number, data?: HTTPValidationError) => {
    switch (status) {
        case 401: {
            let pathname = window.location.pathname
            if (pathname[pathname.length - 1] === '/') {
                pathname = pathname.slice(0, pathname.length - 1)
            }

            if (
                !ROUTES_WITHOUT_PERMISSIONS.some(
                    el => el.indexOf(pathname) !== -1
                )
            ) {
                window.location.replace(NAV_LINK_NAMES.MAIN)
            }
            break
        }
        case 403:
            notification.error({
                message: 'Error',
                description: data?.detail as string,
            })
            break
        case 404:
            notification.error({
                message: 'Something went wrong',
            })
            break
        case 422: {
            const details = data?.detail as ValidationError[]
            details.forEach(el => {
                notification.error({
                    message: 'Error',
                    description: `Field: ${el.loc.join(', ')}\nMsg: ${el.msg}`,
                    style: { whiteSpace: 'break-spaces' },
                })
            })
            break
        }
        default: {
            if (Array.isArray(data?.detail)) {
                data?.detail.forEach(el => {
                    notification.error({
                        message: el.type,
                        description: el.msg,
                    })
                })
            } else {
                notification.error({
                    message: 'Error',
                    description: data?.detail,
                })
            }
        }
    }
}
