import s from './BinanceStaking.module.scss'

import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Button, Space } from 'antd'
import { LoaderContext } from 'core/context'
import { BinanceDealsService, BinanceStakingService } from 'api/http'
import { PageContent, TableAdapter, TextWithRefresh } from 'shared/components'
import { useQueryParams } from 'shared/hooks'
import { StakingResponseModel } from 'api/http/models'
import { BINANCE_STAKING_TABLE_COLUMNS } from './BinanceStaking.consts'
import { fixedNumber } from 'shared/utils'

/** Таблица переводов на тейкинг */
export const BinanceStaking: React.FC = React.memo(() => {
    const { setLoader } = useContext(LoaderContext)
    const { paginationOptions, queryParams } = useQueryParams()
    const [transactionData, setTransactionData] =
        useState<StakingResponseModel[]>()
    const [itemsCount, setItemsCount] = useState(0)
    const [commissionAmount, setCommissionAmount] = useState(0)

    const fetchStakingTransaction = useCallback(async () => {
        try {
            setLoader(true)

            const { limit, skip } = queryParams

            const dataSource = await BinanceStakingService.search(limit, skip)

            setTransactionData(dataSource.items)
            setItemsCount(dataSource.total)
        } catch (e) {
            console.log(e)
        } finally {
            setLoader(false)
        }
    }, [queryParams, setLoader])

    const getAvailableToTransfer = useCallback(async () => {
        try {
            setLoader(true)

            setCommissionAmount(await BinanceDealsService.getStakingProfit())
        } catch (e) {
            console.log(e)
        } finally {
            setLoader(false)
        }
    }, [setLoader])

    const transferToStaking = useCallback(async () => {
        try {
            setLoader(true)

            await BinanceDealsService.profitToStaking()
            await fetchStakingTransaction()
            await getAvailableToTransfer()
        } catch (e) {
            console.log(e)
        } finally {
            setLoader(false)
        }
    }, [setLoader, fetchStakingTransaction, getAvailableToTransfer])

    useEffect(() => {
        fetchStakingTransaction()
        getAvailableToTransfer()
    }, [fetchStakingTransaction, queryParams, getAvailableToTransfer])

    return (
        <PageContent>
            <div className={s.header}>
                <h2>Переводы на контракт стейкинга</h2>

                <Space>
                    <Button
                        onClick={transferToStaking}
                        type="primary"
                        disabled={commissionAmount < 10}
                    >
                        Перевести
                    </Button>

                    <TextWithRefresh
                        text={`Доступно для перевода ${fixedNumber(
                            commissionAmount
                        )} RCBC`}
                        onCallback={getAvailableToTransfer}
                    />
                </Space>
            </div>

            <TableAdapter
                columns={BINANCE_STAKING_TABLE_COLUMNS}
                dataSource={transactionData}
                pagination={{
                    ...paginationOptions,
                    total: itemsCount,
                }}
            />
        </PageContent>
    )
})
