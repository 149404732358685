import React, { useCallback, useEffect, useState } from 'react'
import { PopupAdapterFormProps } from 'shared/components/popups/PopupAdapter.types'
import { Form, Modal, Spin } from 'antd'
import { InputNumberControl, SelectControl } from 'shared/components'
import { BinanceSymbolsService } from 'api/http'
import { DefaultOptionType, LabeledValue } from 'antd/lib/select'
import { BinanceSymbolsDeleteRequestModel } from 'api/http/models'

/** Форма для удаления символа */
export const BinanceSymbolDelete: React.FC<PopupAdapterFormProps> = React.memo(
    ({ onRequestFinish, initialValues, ...props }) => {
        const [symbolsData, setSymbolsData] = useState<DefaultOptionType[]>()
        const [localLoader, setLocalLoader] = useState(false)

        const handleFinish = useCallback(
            async (values: BinanceSymbolsDeleteRequestModel) => {
                try {
                    setLocalLoader(true)

                    const dataSource = await BinanceSymbolsService.delete(
                        values
                    )

                    if (dataSource?.length) {
                        Modal.warning({
                            title: 'Важно!',
                            content: (
                                <>
                                    <h4>
                                        Роботы, остановленные после применения
                                        настроек:
                                    </h4>

                                    {dataSource.map(el => (
                                        <div
                                            key={el.user_id}
                                        >{`Account id ${el.user_id} - ${el.error}`}</div>
                                    ))}
                                </>
                            ),
                            onOk: () => {
                                onRequestFinish?.()
                            },
                        })
                    } else {
                        onRequestFinish?.()
                    }
                } catch (e) {
                    console.log(e)
                } finally {
                    setLocalLoader(false)
                }
            },
            [setLocalLoader, onRequestFinish]
        )

        useEffect(() => {
            const fetch = async () => {
                try {
                    const dataSource = await BinanceSymbolsService.forSelect()
                    setSymbolsData(
                        dataSource?.reduce<LabeledValue[]>((acc, el) => {
                            if (
                                el.id === initialValues?.id ||
                                (!initialValues?.only_admin && el.only_admin)
                            )
                                return acc

                            return [
                                ...acc,
                                {
                                    label: el.name,
                                    value: el.id,
                                    key: String(el.id),
                                },
                            ]
                        }, [])
                    )
                } catch (e) {
                    console.log(e)
                }
            }

            fetch()
        }, [initialValues?.id, initialValues?.only_admin])

        return (
            <Spin spinning={localLoader}>
                <Form
                    onFinish={handleFinish}
                    initialValues={initialValues}
                    {...props}
                >
                    <Form.Item hidden name="id">
                        <InputNumberControl />
                    </Form.Item>

                    <div>
                        Укажите новый символ для роботов, которые используют
                        удаляемый символ. Если символ не указать, то роботы
                        будут остановлены без перезапуска
                    </div>

                    <br />

                    <Form.Item name="new_symbol_id">
                        <SelectControl
                            options={symbolsData}
                            disabled={!symbolsData?.length}
                            showSearch
                        />
                    </Form.Item>
                </Form>
            </Spin>
        )
    }
)
