export const translations = {
  en: {
    signOut: 'Sign out',
    menu: 'Menu',
    autotrading: 'Autotrading',
    globalMarkets: 'Global markets',
    cryptoMarkets: 'Crypto markets',
    premium: 'Premium',
    guides: 'Guides',
    terms: 'Terms & Conditions',
    disclaimer:
      'The risk of trading futures and options can be substantial. Each investor must consider whether this is a suitable investment. Past performance is not indicative of future results. Before trading one should be aware that with potential profits there is also potential for losses that may be very large. Futures investing involves risk and is not suitable for everyone. Those acting on this information are responsible for their own actions.',
  },
  es: {
    signOut: 'Cerrar sesión',
    menu: 'Menú',
    autotrading: 'Auto trading',
    globalMarkets: 'Mercados globales',
    cryptoMarkets: 'Mercados de criptomonedas',
    premium: 'Premium',
    guides: 'Guías',
    terms: 'Términos y Condiciones',
    disclaimer:
      'El riesgo de operar con futuros y opciones puede ser sustancial. Cada inversor debe considerar si esta es una inversión adecuada. El rendimiento pasado no es indicativo de resultados futuros. Antes de operar, uno debe ser consciente de que con las posibles ganancias también hay potencial de pérdidas que pueden ser muy grandes. La inversión en futuros implica riesgo y no es adecuada para todos. Aquellos que actúen sobre esta información son responsables de sus propias acciones.',
  },
  ru: {
    signOut: 'Выйти',
    menu: 'Меню',
    autotrading: 'Автотрейдинг',
    globalMarkets: 'Глобальные рынки',
    cryptoMarkets: 'Крипторынки',
    premium: 'Премиум',
    guides: 'Руководства',
    terms: 'Условия использования RealColibri',
    disclaimer:
      'Риск торговли фьючерсами и опционами может быть значительным. Каждый инвестор должен рассмотреть, является ли это подходящей инвестицией. Прошлые результаты не являются показателем будущих результатов. Перед торговлей следует учитывать, что наряду с потенциальной прибылью существуют также потенциальные убытки, которые могут быть очень значительными. Инвестирование в фьючерсы связано с риском и не подходит для всех. Те, кто действует на основе этой информации, несут ответственность за свои действия.',
  },
  hy: {
    signOut: 'Դուրս գալ',
    menu: 'Մենյու',
    autotrading: 'Ավտոմատ առևտուր',
    globalMarkets: 'Համաշխարհային շուկաներ',
    cryptoMarkets: 'Կրիպտո շուկաներ',
    premium: 'Պրեմիում',
    guides: 'Ուղեցույցներ',
    terms: 'Ընդհանուր դրույթներ և պայմաններ',
    disclaimer:
      'Ֆյուչերսների և օպցիոնների առևտրի ռիսկը կարող է զգալի լինել: Յուրաքանչյուր ներդրող պետք է հաշվի առնի, արդյոք սա համապատասխան ներդրում է: Անցյալի ցուցանիշները չեն արտացոլում ապագայի արդյունքները: Առևտուր սկսելուց առաջ անհրաժեշտ է գիտակցել, որ հնարավոր շահույթի հետ մեկտեղ կարող են լինել նաև մեծ կորուստներ: Ֆյուչերսների ներդրումը ներառում է ռիսկեր և հարմար չէ բոլորի համար: Այս տեղեկատվության հիման վրա գործող անձինք պատասխանատու են իրենց սեփական գործողությունների համար:',
  },
  fr: {
    signOut: 'Se déconnecter',
    menu: 'Menu',
    autotrading: 'Trading automatique',
    globalMarkets: 'Marchés mondiaux',
    cryptoMarkets: 'Marchés cryptographiques',
    premium: 'Premium',
    guides: 'Guides',
    terms: 'Conditions générales',
    disclaimer:
      'Le trading de contrats à terme et d’options comporte des risques importants. Chaque investisseur doit déterminer si ce type d’investissement lui convient. Les performances passées ne garantissent pas les résultats futurs. Avant de trader, il est important de savoir que les bénéfices potentiels s’accompagnent également de risques de pertes considérables. L’investissement dans les contrats à terme comporte des risques et n’est pas adapté à tout le monde. Ceux qui agissent en fonction de ces informations sont responsables de leurs propres actions.',
  },
  de: {
    signOut: 'Abmelden',
    menu: 'Menü',
    autotrading: 'Autotrading',
    globalMarkets: 'Globale Märkte',
    cryptoMarkets: 'Kryptomärkte',
    premium: 'Premium',
    guides: 'Anleitungen',
    terms: 'Allgemeine Geschäftsbedingungen',
    disclaimer:
      'Das Risiko beim Handel mit Futures und Optionen kann erheblich sein. Jeder Anleger muss selbst entscheiden, ob diese Anlageform für ihn geeignet ist. Die vergangene Wertentwicklung ist kein Hinweis auf zukünftige Ergebnisse. Vor dem Handel sollte man sich darüber im Klaren sein, dass neben möglichen Gewinnen auch Verluste auftreten können, die sehr hoch ausfallen können. Der Handel mit Futures birgt Risiken und ist nicht für jeden geeignet. Personen, die aufgrund dieser Informationen handeln, sind für ihre eigenen Handlungen verantwortlich.',
  },
};

export type Language = 'en' | 'es' | 'ru' | 'hy' | 'fr' | 'de';

export default translations;
