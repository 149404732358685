import { BybitOrderTypes } from 'api/http/models'
import { BYBIT_ORDER_TYPES } from '../../BybitTrade.consts'

export const TAGS = [
    {
        id: BybitOrderTypes.Market,
        tagName: BYBIT_ORDER_TYPES[BybitOrderTypes.Market],
    },
    {
        id: BybitOrderTypes.Limit,
        tagName: BYBIT_ORDER_TYPES[BybitOrderTypes.Limit],
    },
]
