import s from './AutoTrade.module.scss'

import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Button, Col, Form, Row } from 'antd'
import { SelectControl, TextWithRefresh } from 'shared/components'
import { LoaderContext } from 'core/context'
import { BinanceService, BinanceSymbolsService } from 'api/http'
import { AutoTradeProps } from './AutoTrade.types'
import { Store } from 'antd/lib/form/interface'

/** Управление ботом */
export const AutoTrade: React.FC<AutoTradeProps> = React.memo(
    ({
        marketId,
        onTradeSettings,
        robotData,
        onRobotData,
        symbolData,
        symbols,
    }) => {
        const { setLoader } = useContext(LoaderContext)
        const [form] = Form.useForm()
        const [minTokenBalance, setMinTokenBalance] = useState<number>()

        /**
         * Обработчик запуска\выключения бота
         */
        const handleFinish = useCallback(
            async ({ symbolId }: { symbolId: number }) => {
                try {
                    setLoader(true)
                    if (!marketId) {
                        return
                    }

                    if (robotData?.isRun) {
                        await BinanceService.stop(marketId)
                        onRobotData(undefined)
                    } else {
                        const dataSource = await BinanceService.start(
                            marketId,
                            { symbolId: symbolId }
                        )
                        onRobotData(dataSource?.isRun ? dataSource : undefined)
                    }
                } catch (e) {
                    console.log(e)
                } finally {
                    setLoader(false)
                }
            },
            [setLoader, robotData?.isRun, marketId, onRobotData]
        )

        const handleMinTokenBalance = useCallback(async () => {
            try {
                setLoader(true)

                if (!symbolData) return

                const dataSource =
                    await BinanceSymbolsService.getMinTokenBalance({
                        symbol_id: symbolData.id,
                    })
                setMinTokenBalance(dataSource.min_balance)
            } catch (e) {
                console.log(e)
            } finally {
                setLoader(false)
            }
        }, [setLoader, symbolData])

        /**
         * Обработчик изменения значений полей формы
         */
        const handleValuesChange = useCallback(
            (changedValue: Store) => {
                const [key, value] = Object.entries(changedValue)[0]

                if (key === 'symbolId') {
                    onTradeSettings({
                        symbolData: symbols?.find(el => el.id === value),
                    })
                }
            },
            [onTradeSettings, symbols]
        )

        useEffect(() => {
            form.setFieldsValue({
                symbolId:
                    robotData?.symbolId || symbolData?.id || symbols?.[0]?.id,
            })
        }, [form, robotData?.symbolId, symbolData, symbols])

        useEffect(() => {
            if (!symbolData || !marketId) return

            handleMinTokenBalance()
        }, [symbolData, handleMinTokenBalance, marketId])

        return (
            <Form
                onFinish={handleFinish}
                layout="vertical"
                form={form}
                onValuesChange={handleValuesChange}
            >
                {symbolData && (
                    <div className={s.balance}>
                        <div>
                            {`Min balance for start: ${symbolData.min_balance} USDT`}
                        </div>

                        {!!minTokenBalance && (
                            <TextWithRefresh
                                text={`Min token balance: ${minTokenBalance} RCBC`}
                                onCallback={handleMinTokenBalance}
                            />
                        )}
                    </div>
                )}

                <Row gutter={10}>
                    <Col xs={12}>
                        <Form.Item name="symbolId" label="Symbol">
                            <SelectControl
                                disabled={robotData?.isRun || !symbols?.length}
                                options={symbols?.map(el => ({
                                    value: el.id,
                                    key: el.id,
                                    label: el.name,
                                }))}
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={12}>
                        <Button
                            className={s.button}
                            type="primary"
                            htmlType="submit"
                            danger={robotData?.isRun}
                            disabled={!marketId || !symbols?.length}
                        >
                            {robotData?.isRun ? 'STOP' : 'START'}
                        </Button>
                    </Col>
                </Row>
            </Form>
        )
    }
)
