import { BinanceOrderTypes } from 'api/http/models'
import { BINANCE_ORDER_TYPES } from '../../BinanceTrade.consts'

export const TAGS = [
    {
        id: BinanceOrderTypes.MARKET,
        tagName: BINANCE_ORDER_TYPES[BinanceOrderTypes.MARKET],
    },
    {
        id: BinanceOrderTypes.LIMIT,
        tagName: BINANCE_ORDER_TYPES[BinanceOrderTypes.LIMIT],
    },
]

export const INITIAL_VALUES = {
    reduceOnly: false,
}
