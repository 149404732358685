import React from 'react'
import { SelectControl } from 'shared/components/SelectControl'
import { COINS } from './ExchangeForm.consts'
import { FnActionRequiredProps } from 'App.types'

export const getCoinSelect = (
    defaultCoin: string,
    onChange?: FnActionRequiredProps<string>
) => (
    <SelectControl
        defaultValue={defaultCoin}
        options={COINS}
        onChange={onChange}
    />
)
