import React from 'react'
import { ColumnsType } from 'antd/lib/table'
import { FeeResponseModel, FeeStatusTypes } from 'api/http/models'
import { formatDate } from 'shared/utils'
import { Link } from 'react-router-dom'
import { FEE_STATUS, NAV_LINK_NAMES } from 'shared/consts'
import { FnActionProps, FnActionRequiredProps } from 'App.types'
import { Button, Space } from 'antd'
import { CheckOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons'
import { InputControl, SelectControl } from 'shared/components'

export const getBinanceFeeTableColumns = (
    isEditing: FnActionRequiredProps<FeeResponseModel, boolean>,
    handleEdit: FnActionRequiredProps<FeeResponseModel>,
    handleCancel: FnActionProps
): ColumnsType<FeeResponseModel> => {
    return [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: 100,
        },
        {
            title: 'Дата списания',
            dataIndex: 'create_date',
            key: 'create_date',
            render: formatDate,
            align: 'center',
        },
        {
            title: 'Сумма',
            dataIndex: 'fee',
            key: 'fee',
            align: 'center',
        },
        {
            title: 'ID клиента',
            dataIndex: 'user',
            key: 'user',
            render: account => (
                <Link to={`${NAV_LINK_NAMES.BINANCE_TRADE}/${account.id}`}>
                    {account.id}
                </Link>
            ),
            align: 'center',
            width: 100,
        },
        {
            title: 'Статус',
            dataIndex: 'status',
            key: 'status',
            width: '10%',
            render: (_, record) => FEE_STATUS[record.status],
            onCell: record => ({
                dataIndex: 'status',
                title: FEE_STATUS[record.status],
                isEditing: isEditing(record),
                inputNode: (
                    <SelectControl
                        options={Object.keys(FEE_STATUS).map(el => ({
                            label: FEE_STATUS[el as FeeStatusTypes],
                            value: el,
                            key: el,
                        }))}
                    />
                ),
            }),
        },
        {
            title: 'Комментарий',
            dataIndex: 'comment',
            key: 'comment',
            width: '40%',
            onCell: record => ({
                dataIndex: 'comment',
                title: record.comment,
                isEditing: isEditing(record),
                inputNode: <InputControl />,
            }),
        },
        {
            width: 100,
            align: 'center',
            render: (_, record) =>
                isEditing(record) ? (
                    <Space>
                        <Button
                            icon={<CheckOutlined />}
                            type="link"
                            htmlType="submit"
                        />

                        <Button
                            icon={<CloseOutlined />}
                            type="link"
                            onClick={handleCancel}
                        />
                    </Space>
                ) : (
                    <Button
                        icon={<EditOutlined />}
                        type="link"
                        onClick={() => handleEdit(record)}
                        hidden={!record.binance_deals.length}
                    />
                ),
        },
    ]
}
