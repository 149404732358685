import s from './Main.module.scss';
import React from 'react';
import { PageContent } from 'shared/components';
import { About } from './About/About';
import { Banner } from './Banner';
import Features from './Features/Features';
//import { Partners } from './Partners';
import { Join } from './Join';
import WhatIs from './WhatIs/WhatIs';
import { LiveTrading } from './LiveTrading';
import { VideoDemonstration } from './VideoDemonstration';
import Steps from './Steps/Steps';
import { useLanguage } from 'context/LanguageContext';

/** Главная страница проекта */
export const Main: React.FC = React.memo(() => {
    const { language } = useLanguage();

    return (
        <PageContent className={s.wrapper}>
            <Banner />

            <WhatIs />

            <Features />

            <Steps />

            <About />

            <VideoDemonstration language={language} />

            <LiveTrading />

            <Join />
        </PageContent>
    );
});

