import s from './Deposit.module.scss'

import React, { useCallback, useContext } from 'react'
import { BlockWrapper, InputNumberControl } from 'shared/components'
import { Button, Col, Form, Row } from 'antd'
import { AccountContext, LoaderContext } from 'core/context'
import { fixedNumber, noExponents } from 'shared/utils'
import { MAX_FEE_LIMIT, RCBC_PRECISION } from 'core/tron/tron.consts'
import { AccountService } from 'api/http'
import { TronLink } from 'core/tron'
import { AccountDepositModel } from 'api/http/models'

/** Пополнение токен баланса робота */
export const Deposit: React.FC = React.memo(() => {
    const { accountData, auth } = useContext(AccountContext)
    const { setLoader } = useContext(LoaderContext)

    const handleFinish = useCallback(
        async (values: AccountDepositModel) => {
            try {
                setLoader(true)

                const valueArr = noExponents(values.deposit).split('.')
                let valuePrecision = valueArr[1] || ''
                if (valuePrecision.length < RCBC_PRECISION) {
                    valuePrecision =
                        valuePrecision +
                        '0'.repeat(RCBC_PRECISION - valuePrecision.length)
                }
                const callValue = valueArr[0] + valuePrecision

                await TronLink.instanceRcbc
                    .transfer(process.env.REACT_APP_DEPOSIT_ADDRESS, callValue)
                    .send({ shouldPollResponse: true, feeLimit: MAX_FEE_LIMIT })
                await AccountService.deposit(values)
                await auth()
            } catch (e) {
                console.log(e)
            } finally {
                setLoader(false)
            }
        },
        [setLoader, auth]
    )

    return (
        <BlockWrapper className={s.wrapper}>
            <div className={s.balance}>
                <div>
                    {`Commission deposit: ${fixedNumber(
                        accountData?.deposit || 0
                    )} RCBC`}
                </div>

                {!!accountData?.locked && (
                    <div>{`Locked deposit: ${accountData.locked} RCBC`}</div>
                )}
            </div>

            <Form onFinish={handleFinish} disabled={!accountData}>
                <Row gutter={20}>
                    <Col xs={24} sm={24} xl={24}>
                        <Form.Item name="deposit">
                            <InputNumberControl
                                min={1}
                                placeholder="1,000,000"
                                addonAfter="RCBC"
                                className={s.input}
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} xl={24}>
                        <Button className={s.buttons} htmlType="submit" type="primary" block size="large">
                            Deposit
                        </Button>
                    </Col>
                </Row>
            </Form>
        </BlockWrapper>
    )
})
