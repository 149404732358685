import React from 'react'
import { Checkbox } from 'antd'

import { CheckboxProps } from 'antd/lib/checkbox'

/** Декоратор для `Checkbox` от `antd` */
export const CheckboxControl: React.FC<CheckboxProps> = React.memo(
    ({ children, ...props }) => {
        return <Checkbox {...props}>{children}</Checkbox>
    }
)
