import s from './ExchangeForm.module.scss'

import React, { useCallback, useContext, useEffect, useState } from 'react'
import { LoaderContext } from 'core/context'
import { Button, Form, Modal, notification } from 'antd'
import { noExponents } from 'shared/utils'
import {
    MAX_FEE_LIMIT,
    RCBC_PRECISION,
    TRX_PRECISION,
} from 'core/tron/tron.consts'
import { TronLink } from 'core/tron'
import Paragraph from 'antd/lib/typography/Paragraph'
import { InputNumberControl } from 'shared/components/InputNumberControl'
import { TronLinkGuide } from 'shared/components/TronLinkGuide'
import { getCoinSelect } from './ExchangeForm.utils'
import Icon from '@ant-design/icons'
import { ReactComponent as RcbcIcon } from 'shared/img/logo.svg'
import { ReactComponent as TrxIcon } from 'shared/img/trx-logo.svg'

/** Обмен монеты */
export const ExchangeForm: React.FC = React.memo(() => {
    const { setLoader } = useContext(LoaderContext)
    const [coin, setCoin] = useState('trx')
    const [coinCount, setCoinCount] = useState('')
    const [form] = Form.useForm()
    const coinField = Form.useWatch(coin, form)

    const isTrx = coin === 'trx'

    const handleChangeCoin = useCallback(
        (coin: string) => {
            form.resetFields()
            setCoinCount('')
            setCoin(coin)
        },
        [form]
    )

    const handleFinish = useCallback(
        async (values: any) => {
            try {
                setLoader(true)

                if (!TronLink.base58Address) {
                    Modal.warning({
                        centered: true,
                        title: 'TronLink',
                        content: TronLinkGuide,
                    })

                    return
                }

                const coinCountValue = isTrx ? values.trx : values.rcbc
                const valueArr = noExponents(coinCountValue).split('.')
                let valuePrecision = valueArr[1] || ''
                const precisionCount = isTrx ? TRX_PRECISION : RCBC_PRECISION
                if (valuePrecision.length < precisionCount) {
                    valuePrecision =
                        valuePrecision +
                        '0'.repeat(precisionCount - valuePrecision.length)
                }
                const callValue = valueArr[0] + valuePrecision

                const result = isTrx
                    ? await TronLink.instanceRcbc
                          ?.buy()
                          .send({ callValue, feeLimit: MAX_FEE_LIMIT })
                    : await TronLink.instanceRcbc
                          ?.sell(callValue)
                          .send({ feeLimit: MAX_FEE_LIMIT })

                if (!result) return

                notification.info({
                    message: (
                        <Paragraph
                            copyable
                            ellipsis={{
                                rows: 2,
                                expandable: true,
                                symbol: 'more',
                            }}
                            className={s.resultMessage}
                        >
                            {`TxID: ${result}`}
                        </Paragraph>
                    ),
                    description: (
                        <div>
                            Check on&nbsp;
                            <a
                                href={`https://${
                                    process.env.NODE_ENV === 'production'
                                        ? ''
                                        : 'nile.'
                                }tronscan.org/#/transaction/${result}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                TRONSCAN
                            </a>
                        </div>
                    ),
                    duration: 30,
                })
                form.setFieldsValue({ trx: undefined, rcbc: undefined })
            } catch (e) {
                console.log(e)
            } finally {
                setLoader(false)
            }
        },
        [setLoader, TronLink.base58Address, isTrx, form]
    )

    useEffect(() => {
        if (coinField) {
            let intString = ''
            let floatString = ''

            const stringsArr = noExponents(coinField).split('.')

            if (stringsArr.length > 1) {
                floatString =
                    stringsArr[0][stringsArr[0].length - 1] + stringsArr[1]
                if (isTrx && floatString.length > RCBC_PRECISION)
                    floatString = floatString.slice(0, RCBC_PRECISION)
                if (!isTrx && floatString.length > TRX_PRECISION)
                    floatString = floatString.slice(0, TRX_PRECISION)
                intString = stringsArr[0].slice(0, -1) || '0'
            } else {
                intString =
                    (stringsArr[0].slice(0, -1) || '0') +
                    '.' +
                    stringsArr[0][stringsArr[0].length - 1]
            }

            setCoinCount(`${intString}${floatString ? `.${floatString}` : ''}`)

            return
        }

        setCoinCount('')
    }, [coinField, isTrx])

    return (
        <Form
            form={form}
            onFinish={handleFinish}
            size="large"
            className={s.wrapper}
        >
            <Form.Item
                name={coin}
                className={s.input}
                rules={[{ required: true }]}
            >
                <InputNumberControl
                    prefix="CONVERT:"
                    min={coin === 'trx' ? 10 : 1}
                    max={10000000000000000}
                    step={1}
                    precision={0}
                    placeholder="1,000,000"
                    addonAfter={getCoinSelect(coin, handleChangeCoin)}
                />
            </Form.Item>

            <div className={s.blockGet}>
                <span className={s.label}>RECEIVE:</span>

                <div className={s.exchangeCoin}>
                    <div>{coinCount || '-'}</div>

                    <div className={s.coin}>
                        <Icon
                            component={isTrx ? RcbcIcon : TrxIcon}
                            className={s.icon}
                        />

                        <span>{isTrx ? 'RCBC' : 'TRX'}</span>
                    </div>
                </div>
            </div>

            {TronLink.base58Address ? (
                <Button type="primary" htmlType="submit" block>
                    Exchange
                </Button>
            ) : (
                <Button
                    type="primary"
                    href="https://www.tronlink.org/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={s.register}
                    block
                >
                    Install TronLink
                </Button>
            )}
        </Form>
    )
})
