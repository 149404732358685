interface Translations {
    important: string;
    notification: string;
    algorithmControl: string;
    optimalDepositSize: string;
}
const translations: Record<string, Translations> = {
    en: {
        important: "Important!",
        notification: `Do not interfere with the algorithm after its launch.
                Interference in the trading process may lead to undesirable consequences.
                Do not restart the algorithm if the open position volume is higher than the initial one,
                do not cancel or set orders on the exchange manually. Even if you know what you are doing,
                please consult us first on any issue by emailing mail@realcolibri.com.
                RealColibri is not responsible for any actions taken on your trading account while the
                algorithm is running without first consulting us upon written request.`,
        algorithmControl: "Algorithm control",
        optimalDepositSize: `Optimal deposit size for running the algorithm: x3.75 of the minimum deposit.
                        When the optimal deposit size is reached, the calculation of compound interest is enabled,
                        which will increase the average monthly profit in proportion to the growth of the deposit size.
                        Every 5% of profit increases the position volume by 5%.`,
    },
    es: {
        important: "¡Importante!",
        notification: `No interfiera con el algoritmo después de su lanzamiento.
                La interferencia en el proceso de comercio puede llevar a consecuencias indeseables.
                No reinicie el algoritmo si el volumen de la posición abierta es superior al inicial,
                no cancele ni establezca órdenes en el intercambio manualmente. Incluso si sabe lo que está haciendo,
                consúltenos primero sobre cualquier problema enviando un correo a mail@realcolibri.com.
                RealColibri no se hace responsable de las acciones tomadas en su cuenta de trading mientras el
                algoritmo está en funcionamiento sin consultarnos primero por escrito.`,
        algorithmControl: "Control del algoritmo",
        optimalDepositSize: `Tamaño de depósito óptimo para ejecutar el algoritmo: x3.75 del depósito mínimo.
                        Cuando se alcanza el tamaño de depósito óptimo, se habilita el cálculo del interés compuesto,
                        lo que aumentará la ganancia mensual promedio en proporción al crecimiento del tamaño del depósito.
                        Cada 5% de ganancia aumenta el volumen de la posición en un 5%.`,
    },
    ru: {
        important: "Важно!",
        notification: `Не вмешивайтесь в алгоритм после его запуска.
                Вмешательство в процесс торговли может привести к нежелательным последствиям.
                Не перезапускайте алгоритм, если объем открытой позиции выше исходного,
                не отменяйте и не устанавливайте ордера на бирже вручную. Даже если вы знаете, что делаете,
                пожалуйста, сначала проконсультируйтесь с нами по любому вопросу, отправив письмо на mail@realcolibri.com.
                RealColibri не несет ответственности за любые действия, предпринятые на вашем торговом счете, пока
                алгоритм работает без предварительной консультации с нами по письменному запросу.`,
        algorithmControl: "Управление алгоритмом",
        optimalDepositSize: `Оптимальный размер депозита для запуска алгоритма: x3.75 от минимального депозита.
                        Когда достигается оптимальный размер депозита, включается расчет сложного процента,
                        что увеличит среднюю месячную прибыль пропорционально росту размера депозита.
                        Каждые 5% прибыли увеличивают объем позиции на 5%.`,
    },
    hy: {
        important: "Կարևոր է!",
        notification: `Խնդրում ենք չխանգարել ալգորիթմի աշխատանքին դրա մեկնարկից հետո:
                Առևտրային գործընթացում միջամտությունը կարող է բերել անցանկալի հետևանքների:
                Մի վերագործարկեք ալգորիթմը, եթե բաց դիրքի ծավալը գերազանցում է նախնականը,
                մի չեղարկեք կամ կարգեք պատվերներ բորսայում ձեռքով: Նույնիսկ եթե դուք գիտեք, թե ինչ եք անում,
                խնդրում ենք նախ կապ հաստատել մեզ հետ որևէ խնդրի դեպքում՝ ուղարկելով էլեկտրոնային նամակ mail@realcolibri.com հասցեին:
                RealColibri-ը պատասխանատվություն չի կրում ձեր առևտրային հաշվին կատարված որևէ գործողության համար, երբ
                ալգորիթմը աշխատում է առանց նախապես մեզ հետ խորհրդակցելու գրավոր պահանջով:`,
        algorithmControl: "Ալգորիթմի կառավարում",
        optimalDepositSize: `Ալգորիթմի գործարկման համար օպտիմալ դեպոզիտ՝ մինիմալ դեպոզիտի x3.75 չափով:
                        Երբ օպտիմալ դեպոզիտը հասնում է, միացվում է համակցված տոկոսների հաշվարկը,
                        ինչը կավելացնի միջին ամսական շահույթը դեպոզիտի չափի աճի համեմատ:
                        Յուրաքանչյուր 5% շահույթ մեծացնում է դիրքի ծավալը 5%-ով:`,
    },
    fr: {
        important: "Important !",
        notification: `Ne pas interférer avec l'algorithme après son lancement.
                Toute interférence dans le processus de trading peut entraîner des conséquences indésirables.
                Ne redémarrez pas l'algorithme si le volume de la position ouverte est supérieur à celui initial,
                ne pas annuler ou passer des ordres sur la bourse manuellement. Même si vous savez ce que vous faites,
                veuillez d'abord nous consulter pour toute question en envoyant un e-mail à mail@realcolibri.com.
                RealColibri n'est pas responsable des actions effectuées sur votre compte de trading pendant que
                l'algorithme est en cours d'exécution sans nous avoir d'abord consulté par demande écrite.`,
        algorithmControl: "Contrôle de l'algorithme",
        optimalDepositSize: `Taille de dépôt optimale pour exécuter l'algorithme : x3,75 du dépôt minimum.
                        Lorsque la taille de dépôt optimale est atteinte, le calcul des intérêts composés est activé,
                        ce qui augmentera le profit mensuel moyen proportionnellement à la croissance de la taille du dépôt.
                        Chaque 5 % de profit augmente le volume de la position de 5 %.`,
    },
    de: {
        important: "Wichtig!",
        notification: `Interferieren Sie nicht mit dem Algorithmus nach dessen Start.
                Eine Einmischung in den Handelsprozess kann zu unerwünschten Folgen führen.
                Starten Sie den Algorithmus nicht neu, wenn das Volumen der offenen Position höher ist als das Anfangsvolumen,
                stornieren Sie keine Aufträge und setzen Sie keine Aufträge manuell auf der Börse. Selbst wenn Sie wissen, was Sie tun,
                konsultieren Sie uns bitte zuerst zu jedem Problem, indem Sie eine E-Mail an mail@realcolibri.com senden.
                RealColibri übernimmt keine Verantwortung für Handlungen, die auf Ihrem Handelskonto durchgeführt werden, während
                der Algorithmus läuft, ohne uns vorher schriftlich zu konsultieren.`,
        algorithmControl: "Algorithmuskontrolle",
        optimalDepositSize: `Optimale Einzahlungshöhe für den Betrieb des Algorithmus: x3,75 der Mindesteinzahlung.
                        Wenn die optimale Einzahlungshöhe erreicht ist, wird die Berechnung des Zinseszinses aktiviert,
                        was den durchschnittlichen monatlichen Gewinn proportional zum Wachstum der Einzahlungsgröße erhöht.
                        Alle 5 % Gewinn erhöhen das Positionsvolumen um 5 %.`,
    },
};

export const languages = Object.keys(translations);

export default translations;
export type { Translations };
