import s from './MarketBalance.module.scss'

import React from 'react'
import { MarketBalanceProps } from './MarketBalance.types'
import { CreditCardOutlined } from '@ant-design/icons'
import { Space, Tooltip } from 'antd'

/** Остаток денег на бирже */
export const MarketBalance: React.FC<MarketBalanceProps> = React.memo(
    ({ availableBalance }) => {
        return (
            <Tooltip title="Available balance" className={s.wrapper}>
                <Space className={s.balance}>
                    <CreditCardOutlined />

                    <div>{availableBalance.toFixed(4)} USDT</div>
                </Space>
            </Tooltip>
        )
    }
)
