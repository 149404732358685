import React from 'react'
import { ColumnsType } from 'antd/lib/table'
import { defaultRuleSortingTableNumberColumn, formatDate } from 'shared/utils'
import { BybitOrderSideTypes, BybitPositionHistoryDto } from 'api/http/models'
import { ColorWrapper } from 'shared/components'

export const POSITIONS_HISTORY_TABLE_COLUMNS: ColumnsType<BybitPositionHistoryDto> =
    [
        {
            title: 'Time',
            dataIndex: 'time',
            key: 'time',
            render: formatDate,
            align: 'center',
            showSorterTooltip: false,
            sorter: defaultRuleSortingTableNumberColumn('time'),
            sortDirections: ['ascend', 'descend', 'ascend'],
        },
        {
            title: 'Symbol',
            dataIndex: 'symbol',
            key: 'symbol',
            align: 'center',
        },
        {
            title: 'Size',
            dataIndex: 'side',
            key: 'side',
            align: 'center',
            render: side => (
                <ColorWrapper
                    text={side}
                    condition={side === BybitOrderSideTypes.Buy}
                />
            ),
        },
        {
            title: 'Entry price',
            dataIndex: 'entryPrice',
            key: 'entryPrice',
            align: 'center',
        },
        {
            title: 'Exit price',
            dataIndex: 'exitPrice',
            key: 'exitPrice',
            align: 'center',
        },
        {
            title: 'Quantity',
            dataIndex: 'size',
            key: 'size',
            render: (size, { symbol }) =>
                `${size} ${symbol.replace('USDT', '')}`,
            align: 'center',
            showSorterTooltip: false,
            sorter: defaultRuleSortingTableNumberColumn('size'),
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'PnL',
            dataIndex: 'pnl',
            key: 'pnl',
            align: 'center',
        },
    ]
