import { FetchBinanceHistoryProps } from '../DealHistory.types'
import { FnActionAsyncRequiredProps } from 'App.types'

export interface FiltersProps {
    onFetch: FnActionAsyncRequiredProps<FetchBinanceHistoryProps>
    symbol?: string
}

export enum FilterTypes {
    Day = 'Day',
    Week = 'Week',
}
