import s from './TradeDiagram.module.scss'

import React, {
    useCallback,
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
} from 'react'
import {
    ChartLinesOrdersProps,
    ChartLinesProps,
    TradeDiagramProps,
} from './TradeDiagram.types'
import { DIAGRAM_OPTIONS, TIME_TAGS } from './TradeDiagram.consts'
import { Spin, Tag } from 'antd'
import {
    createChart,
    IChartApi,
    ISeriesApi,
    LineStyle,
} from 'lightweight-charts'
import {
    BinanceCandlesIntervalTypes,
    BinanceOrderSideTypes,
} from 'api/http/models'

/** Диаграмма для страницы торговли */
export const TradeDiagram: React.FC<TradeDiagramProps> = React.memo(
    ({
        tradeSettings: { symbolData, period },
        onTradeSettings,
        onUpdateTradeData,
        tickerLastPrice,
        ordersData,
        positionData,
    }) => {
        const chartContainerRef = useRef<HTMLDivElement>(null)
        const [candlestickSeries, setCandlestickSeries] =
            useState<ISeriesApi<'Candlestick'>>()
        const [chartLib, setChartLib] = useState<IChartApi>()
        const [, setChartLines] = useState<ChartLinesProps>({})

        /**
         * Выбор временного промежутка для графика
         * @param period временной промежуток
         */
        const handleChangeTag = useCallback(
            (period: BinanceCandlesIntervalTypes) => {
                if (!tickerLastPrice) return

                onTradeSettings({ period })
            },
            [onTradeSettings, tickerLastPrice]
        )

        useEffect(() => {
            if (!chartContainerRef?.current) return

            const chartLib = createChart(chartContainerRef.current, {
                width: chartContainerRef.current.clientWidth,
                height: chartContainerRef.current.clientHeight,
                ...DIAGRAM_OPTIONS,
            })

            setChartLib(chartLib)
            setCandlestickSeries(chartLib.addCandlestickSeries())
        }, [])

        useEffect(() => {
            if (!candlestickSeries) return

            if (positionData?.symbol) {
                setChartLines(prevState => {
                    if (Number(positionData.positionAmt)) {
                        if (prevState?.position?.line) {
                            candlestickSeries.removePriceLine(
                                prevState.position.line
                            )
                        }

                        return {
                            ...prevState,
                            position: {
                                line: candlestickSeries.createPriceLine({
                                    price: Number(positionData.entryPrice),
                                    color: 'blue',
                                    lineWidth: 1,
                                    title: '',
                                    axisLabelVisible: true,
                                    lineStyle: LineStyle.LargeDashed,
                                    lineVisible: true,
                                }),
                                ticker: positionData.symbol,
                            },
                        }
                    } else {
                        if (!prevState.position) return prevState
                        candlestickSeries.removePriceLine(
                            prevState.position.line
                        )
                        return {
                            ...prevState,
                            position: undefined,
                        }
                    }
                })
            }
        }, [candlestickSeries, positionData])

        useEffect(() => {
            if (!candlestickSeries) return

            if (ordersData?.length) {
                setChartLines(prevState => {
                    const currentOrdersIds = prevState?.orders?.map(
                        el => el.orderId
                    )
                    const newOpenOrdersTicker = ordersData.filter(
                        el => !currentOrdersIds?.includes(el.orderId)
                    )
                    const currentOrders =
                        prevState.orders?.reduce<ChartLinesOrdersProps[]>(
                            (acc, el) => {
                                const order = ordersData?.find(
                                    order => order.orderId === el.orderId
                                )
                                if (
                                    !order ||
                                    !(
                                        Number(order.origQty) -
                                        Number(order.executedQty)
                                    )
                                ) {
                                    candlestickSeries.removePriceLine(el.line)
                                    return acc
                                }
                                return [...acc, el]
                            },
                            []
                        ) || []
                    if (newOpenOrdersTicker.length) {
                        const addLines = newOpenOrdersTicker.map(el => ({
                            line: candlestickSeries.createPriceLine({
                                price: Number(el.price),
                                color:
                                    el.side === BinanceOrderSideTypes.SELL
                                        ? 'red'
                                        : 'green',
                                lineWidth: 1,
                                title: '',
                                axisLabelVisible: true,
                                lineStyle: LineStyle.LargeDashed,
                                lineVisible: true,
                            }),
                            orderId: el.orderId,
                        }))
                        return {
                            ...prevState,
                            orders: [...currentOrders, ...addLines],
                        }
                    }
                    return {
                        ...prevState,
                        orders: currentOrders,
                    }
                })
            } else {
                setChartLines(prevState => {
                    if (!prevState) return prevState
                    prevState.orders?.forEach(el => {
                        candlestickSeries.removePriceLine(el.line)
                    })
                    return {
                        ...prevState,
                        orders: undefined,
                    }
                })
            }
        }, [ordersData, candlestickSeries])

        useEffect(() => {
            candlestickSeries?.applyOptions({
                priceFormat: {
                    minMove: 1 / 10 ** (symbolData?.price_digits || 0),
                    precision: symbolData?.price_digits || 1,
                },
            })
            onUpdateTradeData(candlestickSeries)
        }, [onUpdateTradeData, candlestickSeries, symbolData])

        useLayoutEffect(() => {
            const chartLibWidth = () => {
                if (!chartContainerRef.current) return
                chartLib?.resize(
                    chartContainerRef.current.clientWidth,
                    chartContainerRef.current.clientHeight
                )
            }

            window.addEventListener('resize', chartLibWidth)

            return () => {
                window.removeEventListener('resize', chartLibWidth)
            }
        }, [chartLib])

        return (
            <Spin spinning={!candlestickSeries}>
                <div className={s.header}>
                    <h1>
                        {symbolData?.symbol.toUpperCase()}&nbsp;
                        {tickerLastPrice || ''}
                    </h1>

                    <div className={s.tags}>
                        {TIME_TAGS.map(tag => (
                            <Tag.CheckableTag
                                key={tag.id}
                                checked={period === tag.id}
                                onChange={() => {
                                    handleChangeTag(tag.id)
                                }}
                            >
                                {tag.tagName}
                            </Tag.CheckableTag>
                        ))}
                    </div>
                </div>

                <div ref={chartContainerRef} className={s.container} />
            </Spin>
        )
    }
)
