import s from './AutoTrade.module.scss'

import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Button, Col, Form, Row, Space } from 'antd'
import {
    QuestionIconTip,
    SelectControl,
    TextWithRefresh,
} from 'shared/components'
import { LoaderContext } from 'core/context'
import { BinanceService, BinanceSymbolsService } from 'api/http'
import { AutoTradeProps } from './AutoTrade.types'
import { Store } from 'antd/lib/form/interface'
import { BinanceSymbolsForSelectResponseModel } from 'api/http/models'

/** Управление ботом */
export const AutoTrade: React.FC<AutoTradeProps> = React.memo(
    ({ robotData, onRobotData, marketId }) => {
        const { setLoader } = useContext(LoaderContext)
        const [form] = Form.useForm()
        const [symbols, setSymbols] =
            useState<BinanceSymbolsForSelectResponseModel[]>()
        const [symbolData, setSymbolData] =
            useState<BinanceSymbolsForSelectResponseModel>()
        const [minTokenBalance, setMinTokenBalance] = useState<number>()

        /** Обработчик запуска\выключения бота */
        const handleFinish = useCallback(
            async ({ symbolId }: Store) => {
                try {
                    setLoader(true)
                    if (!marketId) {
                        return
                    }

                    if (robotData?.isRun) {
                        await BinanceService.stop(marketId)
                        onRobotData(undefined)
                    } else {
                        const dataSource = await BinanceService.start(
                            marketId,
                            { symbolId: symbolId }
                        )
                        onRobotData(dataSource?.isRun ? dataSource : undefined)
                    }
                } catch (e) {
                    console.log(e)
                } finally {
                    setLoader(false)
                }
            },
            [setLoader, robotData?.isRun, onRobotData, marketId]
        )

        const handleValuesChange = useCallback(
            (valueChanged: Store) => {
                const [key, value] = Object.entries(valueChanged)[0]
                if (key === 'symbolId') {
                    setSymbolData(symbols?.find(el => el.id === value))
                }
            },
            [symbols]
        )

        const handleMinTokenBalance = useCallback(async () => {
            try {
                setLoader(true)

                if (!symbolData) return

                const dataSource =
                    await BinanceSymbolsService.getMinTokenBalance({
                        symbol_id: symbolData.id,
                    })
                setMinTokenBalance(dataSource.min_balance)
            } catch (e) {
                console.log(e)
            } finally {
                setLoader(false)
            }
        }, [setLoader, symbolData])

        useEffect(() => {
            if (symbols) return

            const fetch = async () => {
                try {
                    const dataSource = await BinanceSymbolsService.forSelect()
                    setSymbols(dataSource)
                    const symbol =
                        dataSource.find(el => el.id === robotData?.symbolId) ||
                        dataSource[0]
                    setSymbolData(symbol)
                    form.setFieldsValue({
                        symbolId: symbol?.id,
                    })
                } catch (e) {
                    console.log(e)
                }
            }

            fetch()
        }, [form, symbolData, robotData?.symbolId, symbols])

        useEffect(() => {
            if (!symbolData || !marketId) return

            handleMinTokenBalance()
        }, [symbolData, handleMinTokenBalance, marketId])

        return (
            <div>
                {symbolData && (
                    <div className={s.balance}>
                        <div>{`Min deposit size: ${symbolData.min_balance} USDT`}</div>

                        {!!minTokenBalance && (
                            <Space>
                                <TextWithRefresh
                                    text={`Min token balance: ${minTokenBalance} RCBC`}
                                    onCallback={handleMinTokenBalance}
                                />

                                <QuestionIconTip title="Actual minimal amount is calculated from your actual trading deposit size. Reload the page to recalculate it" />
                            </Space>
                        )}
                    </div>
                )}

                <Form
                    onFinish={handleFinish}
                    layout="vertical"
                    form={form}
                    onValuesChange={handleValuesChange}
                >
                    <Row gutter={10}>
                        <Col xs={24} sm={12}>
                            <Form.Item name="symbolId" label="Symbol">
                                <SelectControl
                                    disabled={
                                        robotData?.isRun || !symbols?.length
                                    }
                                    options={symbols?.map(el => ({
                                        value: el.id,
                                        key: el.id,
                                        label: el.name,
                                    }))}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={12}>
                            <Button
                                className={s.button}
                                type="primary"
                                htmlType="submit"
                                danger={robotData?.isRun}
                                disabled={!symbols?.length || !marketId}
                            >
                                {robotData?.isRun ? 'STOP' : 'START'}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        )
    }
)
