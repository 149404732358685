export const LinkPaths = {
  Binance: {
    'RCP-10000': '/robot/binance',
    'RCP-25000': '/robot/binance',
    'RCP-50000': '/robot/binance',
    'RCP-100000': '/robot/binance',
    'RCP-250000': '/robot/binance',
    'RCP-500000': '/robot/binance',
    'RCP-1000000': '/robot/binance',
  },
  ByBit: {
    'RCP-10000': '/robot/bybit',
    'RCP-25000': '/robot/bybit',
    'RCP-50000': '/robot/bybit',
    'RCP-100000': '/robot/bybit',
    'RCP-250000': '/robot/bybit',
    'RCP-500000': '/robot/bybit',
    'RCP-1000000': '/robot/bybit',
  },
  Global: {
    'RCP-10000': '/robot/rithmic',
    'RCP-25000': '/robot/rithmic',
    'RCP-50000': '/robot/rithmic',
    'RCP-100000': '/robot/rithmic',
    'RCP-250000': '/robot/rithmic',
    'RCP-500000': '/robot/rithmic',
    'RCP-1000000': '/robot/rithmic',
  },
}

export const options = [
  {
    value: 'Binance', 
    label: 'Binance',
    children: [
      { value: 'RCP-10000', label: 'RCP-10000' },
      { value: 'RCP-25000', label: 'RCP-25000' },
      { value: 'RCP-50000', label: 'RCP-50000' },
      { value: 'RCP-100000', label: 'RCP-100000' },
      { value: 'RCP-250000', label: 'RCP-250000' },
      { value: 'RCP-500000', label: 'RCP-500000' },
      { value: 'RCP-1000000', label: 'RCP-1000000' },
    ],
  },

  // {
  //   value: 'ByBit',
  //   label: 'ByBit',
  //   children: [
  //     { value: 'RCP-10000', label: 'RCP-10000' },
  //     { value: 'RCP-25000', label: 'RCP-25000' },
  //     { value: 'RCP-50000', label: 'RCP-50000' },
  //     { value: 'RCP-100000', label: 'RCP-100000' },
  //     { value: 'RCP-250000', label: 'RCP-250000' },
  //     { value: 'RCP-500000', label: 'RCP-500000' },
  //     { value: 'RCP-1000000', label: 'RCP-1000000' },
  //   ],
  // },

  {
    value: 'Global',
    label: 'Global',
    children: [
      { value: 'RCP-10000', label: 'RCP-10000' },
      { value: 'RCP-25000', label: 'RCP-25000' },
      { value: 'RCP-50000', label: 'RCP-50000' },
      { value: 'RCP-100000', label: 'RCP-100000' },
      { value: 'RCP-250000', label: 'RCP-250000' },
      { value: 'RCP-500000', label: 'RCP-500000' },
      { value: 'RCP-1000000', label: 'RCP-1000000' },
    ],
  },
]
