import { ReactComponent as LinkedInIcon } from 'shared/img/linkedIn-icon.svg'
import { ReactComponent as FacebookIcon } from 'shared/img/facebook-icon.svg'
import { ReactComponent as TelegramIcon } from 'shared/img/telegram-icon.svg'
import { ReactComponent as DiscordIcon } from 'shared/img/discord-icon.svg'

export const LINKS = [
    {
        link: 'https://t.me/realcolibri',
        icon: <TelegramIcon />,
    },
    {
        link: 'https://discord.gg/Rgab6En5NK',
        icon: <DiscordIcon />,
    },
    {
        link: 'https://www.linkedin.com/groups/9219751',
        icon: <LinkedInIcon />,
    },
    {
        link: 'https://www.facebook.com/RealColibri',
        icon: <FacebookIcon />,
    },
]
