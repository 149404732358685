import s from './PageContent.module.scss'

import React from 'react'
import { PageContentProps } from './PageContent.types'
import cn from 'classnames'

/** Обертка для контента страницы */
export const PageContent: React.FC<PageContentProps> = React.memo(
    ({ children, className }) => (
        <section className={cn(s.wrapper, className)}>{children}</section>
    )
)
