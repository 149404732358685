import React, { useCallback } from 'react'
import { HandleCancelOrderProps, PositionsProps } from './Positions.types'
import { TableAdapter } from 'shared/components'
import { positionsTableColumns } from './Positions.utils'
import { WsRequestEventType } from '../../RithmicTrade.types'
import { RithmicPriceTypes, RithmicTransactionTypes } from 'api/http/models'

/** Таблица позиций */
export const Positions: React.FC<PositionsProps> = React.memo(
    ({ positionsData, onSendMessage }) => {
        /**
         * Обработчик закрытия позиции
         */
        const handleCancelPosition = useCallback(
            ({ symbol, quantity }: HandleCancelOrderProps) => {
                onSendMessage({
                    event_type: WsRequestEventType.NewOrder,
                    payload: {
                        symbol,
                        quantity,
                        order_type: RithmicPriceTypes.MARKET,
                        side:
                            quantity > 0
                                ? RithmicTransactionTypes.SELL
                                : RithmicTransactionTypes.BUY,
                    },
                })
            },
            [onSendMessage]
        )

        return (
            <TableAdapter
                title={() => <h2>Positions</h2>}
                columns={positionsTableColumns(handleCancelPosition)}
                loading={!positionsData}
                dataSource={positionsData}
                rowKey="symbol"
                pagination={false}
            />
        )
    }
)
