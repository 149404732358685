import React from 'react'
import { ComposeProps } from './Compose.types'

export const Compose: React.FC<ComposeProps> = React.memo(props => {
    const { components = [], children } = props

    return (
        <>
            {components.reduceRight(
                (acc, Comp) => (
                    <Comp>{acc}</Comp>
                ),
                children
            )}
        </>
    )
})
