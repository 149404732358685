import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Button, Modal } from 'antd'
import { LoaderContext } from 'core/context'

import { ModalDataProps, PopupAdapterProps } from './PopupAdapter.types'
import { getContainer } from 'shared/utils'

export const PopupAdapter: React.FC<PopupAdapterProps> = React.memo(
    ({
        component: Component,
        buttonText,
        formId,
        modalOptions,
        formOptions,
        buttonOption,
        onRequestFinish,
    }) => {
        const { isLoader } = useContext(LoaderContext)
        const [modalData, setModalData] = useState<ModalDataProps>()

        const closeModal = useCallback(() => {
            setModalData(prevState => ({
                ...prevState,
                modalOptions: {
                    ...prevState?.modalOptions,
                    open: false,
                },
            }))
        }, [])

        const showModal = useCallback(() => {
            setModalData(prevState => ({
                ...prevState,
                modalOptions: {
                    ...prevState?.modalOptions,
                    open: true,
                },
            }))
        }, [])

        const handleRequestFinish = useCallback(
            (value: any) => {
                closeModal()
                onRequestFinish?.(value)
            },
            [onRequestFinish, closeModal]
        )

        useEffect(() => {
            setModalData(prevState => ({
                formOptions: {
                    ...prevState?.formOptions,
                    ...formOptions,
                },
                modalOptions: {
                    ...prevState?.modalOptions,
                    modalOptions,
                },
            }))
        }, [formOptions, modalOptions])

        return (
            <>
                <Button {...buttonOption} onClick={showModal}>
                    {buttonText}
                </Button>

                {Component && (
                    <Modal
                        okButtonProps={{
                            htmlType: 'submit',
                            form: formId,
                            disabled: isLoader,
                        }}
                        onCancel={closeModal}
                        destroyOnClose
                        getContainer={getContainer()}
                        {...modalOptions}
                        {...modalData?.modalOptions}
                    >
                        <Component
                            onRequestFinish={handleRequestFinish}
                            id={formId}
                            {...modalData?.formOptions}
                        />
                    </Modal>
                )}
            </>
        )
    }
)
