import s from './GetStarted.module.scss'

import React from 'react'
import { PopupAdapter, RegisterForm } from 'shared/components'
import { GetStartedProps } from './GetStarted.types'
import cn from 'classnames'

/** Кнопка GetStarted */
export const GetStarted: React.FC<GetStartedProps> = React.memo(({ type }) => {
    return (
        <PopupAdapter
            component={RegisterForm}
            formId="regForm"
            buttonText="Try it now"
            buttonOption={{
                type: type,
                className: cn(s.tronLink, type !== 'primary' && s.noPrimary),
            }}
            modalOptions={{
                width: 800,
                footer: null,
                maskClosable: true,
            }}
        />
    )
})
