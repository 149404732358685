import s from './Filters.module.scss'

import React, { useCallback, useMemo, useState } from 'react'
import dayjs from 'dayjs'
import { Space, Tag } from 'antd'
import { FiltersProps, FilterTypes } from './Filters.types'
import { FILTER_TAGS, MAX_LIMIT_DEAL } from './Filters.consts'
import { QuestionIconTip } from 'shared/components'

/** Фильтры для таблиц */
export const Filters: React.FC<FiltersProps> = React.memo(
    ({ onFetch, symbol }) => {
        const [activeFilter, setActiveFilter] = useState<FilterTypes>(
            FilterTypes.Day
        )

        /**
         * Обработчик выбора фильтра
         * @param filterName тип фильтра
         */
        const handleChangeFilter = useCallback(
            async (filterName: FilterTypes) => {
                if (filterName !== activeFilter && symbol) {
                    const startTime =
                        filterName === FilterTypes.Day
                            ? dayjs().startOf('day').unix() * 1000
                            : undefined
                    setActiveFilter(filterName)
                    onFetch({ symbol, startTime, limit: MAX_LIMIT_DEAL })
                }
            },
            [activeFilter, onFetch, symbol]
        )

        /**
         * Список фильтров
         */
        const filtersList = useMemo(
            () =>
                FILTER_TAGS.map(el => (
                    <Tag.CheckableTag
                        key={el.id}
                        checked={activeFilter === el.id}
                        onClick={() => handleChangeFilter(el.id)}
                    >
                        {el.tagName}
                    </Tag.CheckableTag>
                )),
            [activeFilter, handleChangeFilter]
        )

        /**
         * Подсказка
         */
        const tooltip = (
            <span className={s.tooltip}>
                the data is available for the last 1 or 7 days, more information
                on the website
                <a href="https://www.binance.com/">Binance</a>
            </span>
        )

        return (
            <Space className={s.wrapper}>
                {filtersList}

                <QuestionIconTip title={tooltip} />
            </Space>
        )
    }
)
