import s from './BinanceKeys.module.scss'

import React, { useCallback, useContext, useEffect } from 'react'
import { AccountContext } from 'core/context'
import { getKeysActions } from './BinanceKeys.utils'
import { BlockWrapper } from 'shared/components'
import { BinanceKeysProps } from './BinanceKeys.types'
import cn from 'classnames'
import { useFetch } from 'shared/hooks'
import { BinanceService } from 'api/http'

/** Блок с ключами */
export const BinanceKeys: React.FC<BinanceKeysProps> = React.memo(
    ({ className }) => {
        const { accountData } = useContext(AccountContext)
        const [getData, data] = useFetch(BinanceService.get)

        const handleFetchKeys = useCallback(() => {
            if (accountData?.id) {
                getData(accountData.id)
            }
        }, [accountData?.id, getData])

        useEffect(() => {
            if (!data) {
                handleFetchKeys()
            }
        }, [handleFetchKeys, data])

        return (
            <BlockWrapper className={className}>
                <div className={s.title}>
                    <h2>Keys</h2>

                    {getKeysActions(handleFetchKeys, data)}
                </div>

                <a
                    href="https://www.binance.com/en/register?ref=39094470"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    binance.com
                </a>

                <div className={cn(s.text, 'mt-15')}>
                    <span className={s.label}>API key:</span>

                    <span className={s.key}>{data?.accessKey || '-'}</span>
                </div>

                <div className={s.text}>
                    <span className={s.label}>Secret key:</span>

                    <span className={s.key}>{data?.secretKey || '-'}</span>
                </div>
            </BlockWrapper>
        )
    }
)
