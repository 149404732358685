import { EXCHANGES } from 'shared/consts'

export const PARTNERS = [
    {
        key: 'GLOBAL',
        partners: EXCHANGES.global,
    },
    {
        key: 'CRYPTO',
        partners: EXCHANGES.crypto,
    },
]
