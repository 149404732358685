import React from 'react'
import s from './TextWithRefresh.module.scss'
import { ReloadOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { TextWithRefreshProps } from './TextWithRefresh.types'
import cn from 'classnames'

/** Текст с кнопкой обновить */
export const TextWithRefresh: React.FC<TextWithRefreshProps> = React.memo(
    ({ text, onCallback, className, disabled }) => {
        return (
            <div className={cn(s.wrapper, className)}>
                {text}

                <Button
                    type="link"
                    onClick={onCallback}
                    icon={<ReloadOutlined />}
                    className={s.refresh}
                    disabled={disabled}
                />
            </div>
        )
    }
)
