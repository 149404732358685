import s from './AutoTrade.module.scss'

import React, { useCallback, useContext } from 'react'
import { Button, Col, Form, Row } from 'antd'
import { SelectControl } from 'shared/components'
import { normalizeDataForSelectAndRadio } from 'shared/utils'
import { LoaderContext } from 'core/context'
import { RithmicService } from 'api/http'
import { AutoTradeProps } from './AutoTrade.types'
import { RithmicStartDto } from 'api/http/models'

/** Управление ботом */
export const AutoTrade: React.FC<AutoTradeProps> = React.memo(
    ({ marketData, onMarketFetch }) => {
        const { setLoader } = useContext(LoaderContext)

        /** Обработчик запуска\выключения бота */
        const handleFinish = useCallback(
            async (body: RithmicStartDto) => {
                try {
                    setLoader(true)
                    if (!marketData?.id) {
                        return
                    }

                    if (marketData?.robotId) {
                        await RithmicService.stop(marketData.id)
                        await onMarketFetch()
                    } else {
                        await RithmicService.start(marketData.id, body)
                        await onMarketFetch()
                    }
                } catch (e) {
                    console.log(e)
                } finally {
                    setLoader(false)
                }
            },
            [setLoader, marketData, onMarketFetch]
        )

        return (
            <div>
                <div className={s.balance}>
                    Min deposit size: 10,000 USD
                </div>

                <Form layout="vertical" onFinish={handleFinish}>
                    <Row gutter={10}>
                        <Col xs={24} sm={12}>
                            <Form.Item
                                name="symbol"
                                label="Symbol"
                                initialValue="MESU4"
                            >
                                <SelectControl
                                    disabled={!!marketData?.robotId}
                                    options={['MESU4', 'ESU4'].map(
                                        normalizeDataForSelectAndRadio
                                    )}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={12}>
                            <Button
                                className={s.button}
                                type="primary"
                                htmlType="submit"
                                danger={!!marketData?.robotId}
                                disabled={!marketData}
                            >
                                {marketData?.robotId ? 'STOP' : 'START'}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        )
    }
)
