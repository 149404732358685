import React, { useMemo, useState } from 'react'
import { Tabs } from 'antd'
import { TABS_KEYS, TradeTabsProps } from './TradeTabs.types'
import { TradeActions } from './TradeActions'
import { AutoTrade } from './AutoTrade'

/** Блок вкладок для выбора способа торговли */
export const TradeTabs: React.FC<TradeTabsProps> = React.memo(
    ({ settings, onSettings, marketData, onFetchMarket, symbols }) => {
        const [tabKey, setTabKey] = useState<string>(TABS_KEYS.COLIBRI)

        const tabItems = useMemo(
            () => [
                {
                    label: 'Colibri',
                    key: TABS_KEYS.COLIBRI,
                    children: (
                        <AutoTrade
                            marketData={marketData}
                            onFetchMarket={onFetchMarket}
                            settings={settings}
                            onSettings={onSettings}
                            symbols={symbols}
                        />
                    ),
                },
                {
                    label: 'Manual',
                    key: TABS_KEYS.MANUAL,
                    disabled:
                        !settings.symbol ||
                        !!marketData?.robotId ||
                        !marketData,
                    children: !!marketData?.id && (
                        <TradeActions
                            settings={settings}
                            marketId={marketData.id}
                        />
                    ),
                },
            ],
            [marketData, onSettings, settings, onFetchMarket, symbols]
        )

        return <Tabs activeKey={tabKey} onChange={setTabKey} items={tabItems} />
    }
)
