import { ColumnsType } from 'antd/lib/table'
import { BinanceTickersModel } from 'api/http/models'

export const TRADE_TICKERS_TABLE_COLUMNS: ColumnsType<BinanceTickersModel> = [
    {
        title: 'Symbol',
        dataIndex: 'symbol',
    },
    {
        title: 'Price',
        dataIndex: 'lastPrice',
    },
]
