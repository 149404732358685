import { TronLink } from 'core/tron'
import { CANNOT_FIND_RESULT_ERROR } from 'shared/consts'
import { TransactionErrorProps } from 'core/tron/tron.types'
import { RCBC_PRECISION } from 'core/tron/tron.consts'

export const getTransactionInfo = async (
    txn: TransactionErrorProps
): Promise<any> => {
    try {
        if (txn.error === CANNOT_FIND_RESULT_ERROR) {
            return await TronLink.tronWeb?.trx.getTransactionInfo(
                txn.transaction.txID
            )
        }
    } catch (e: any) {
        return await getTransactionInfo(txn)
    }
}

export const toRCBC = (value: number) =>
    (value * 10 ** RCBC_PRECISION).toString()
