import s from './LeverageModal.module.scss'

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { InputControl, SliderSingleControl } from 'shared/components'
import { PopupAdapterFormProps } from 'shared/components/popups/PopupAdapter.types'
import { Form, Spin } from 'antd'
import { BinanceNativeService } from 'api/http'
import {
    BinanceLeverageBracketResponseModel,
    BinanceSymbolModel,
} from 'api/http/models'
import { getMarks } from './LeverageModal.utils'

/** Модалка для выбора кредитного плеча */
export const LeverageModal: React.FC<PopupAdapterFormProps> = React.memo(
    ({ initialValues, onRequestFinish, id }) => {
        const [leverageDictionary, setLeverageDictionary] =
            useState<BinanceLeverageBracketResponseModel[]>()
        const [leverageValue, setLeverageValue] = useState<number>(
            initialValues?.leverage
        )

        /**
         * Обработчик отправки данных формы
         */
        const handleFinish = useCallback(
            async (values: BinanceSymbolModel) => {
                try {
                    const dataSource = await BinanceNativeService.setLeverage(
                        values
                    )
                    onRequestFinish?.(dataSource)
                } catch (e) {
                    console.log(e)
                }
            },
            [onRequestFinish]
        )

        /**
         * Обработчик изменения значения кредитного плеча
         * @param value выбранное значение
         */
        const handleChangeLeverage = (value?: number | string) => {
            if (!value) return

            setLeverageValue(Number(value))
        }

        /**
         * Максимальная позиция при кредитном плече
         */
        const maxNotional = useMemo(() => {
            if (!leverageDictionary || !leverageValue) return

            const { brackets } = leverageDictionary[0]
            let leverage = 0

            brackets.forEach(el => {
                if (leverageValue <= el.initialLeverage) {
                    leverage =
                        el.notionalCap !== 9223372036854776000
                            ? el.notionalCap
                            : 0
                }
            })

            return leverage
        }, [leverageDictionary, leverageValue])

        useEffect(() => {
            if (leverageDictionary) return

            const fetch = async () => {
                try {
                    setLeverageDictionary(
                        await BinanceNativeService.getLeverage({
                            symbol: initialValues?.symbol,
                            market_id: initialValues?.market_id,
                        })
                    )
                } catch (e) {
                    console.log(e)
                }
            }

            fetch()
        }, [leverageDictionary, initialValues])

        return (
            <Spin spinning={!leverageDictionary}>
                <Form
                    layout="vertical"
                    initialValues={initialValues}
                    onFinish={handleFinish}
                    id={id}
                >
                    <Form.Item hidden name="symbol">
                        <InputControl />
                    </Form.Item>

                    <Form.Item hidden name="market_id">
                        <InputControl />
                    </Form.Item>

                    {leverageDictionary && (
                        <Form.Item name="leverage" label="Leverage">
                            <SliderSingleControl
                                className={s.leverage}
                                min={1}
                                max={
                                    leverageDictionary[0].brackets[0]
                                        .initialLeverage
                                }
                                addonNumberAfter
                                value={leverageValue}
                                onChange={handleChangeLeverage}
                                marks={getMarks(leverageDictionary[0].brackets)}
                            />
                        </Form.Item>
                    )}

                    {!!maxNotional && (
                        <div>
                            Maximum position at current leverage: {maxNotional}
                            &nbsp; USDT
                        </div>
                    )}
                </Form>
            </Spin>
        )
    }
)
