export const translations = {
    en: {
        guides: {
            howToCreateTronLink: {
                title: "How to create a TronLink wallet",
                steps: [
                    "Click on the Register TronLink button on the RealColibri website or go to tronlink.org",
                    "Hover over Browser extension and click Chrome Web Store or Add-ons for Firefox, depending on your browser. For this example, Chrome",
                    "Click Add to Chrome and then click Add extension in the pop-up window",
                    "After it is successfully installed, you will be able to find the TronLink extension in Extensions of your browser. To access the extension more conveniently, you can click the Pin icon to its right to pin it to the upper right corner of your browser",
                    "Click on the icon of the extension and choose Create Wallet. Scroll down to read through and accept the user agreement",
                    "Create a name and a password for your wallet, and then click Create Wallet on the bottom",
                    "When the wallet is created, click Back up Wallet, enter the password, and then click Back up in Back up Mnemonic Phrase",
                    "Before you proceed, make sure the information is only visible to yourself. Click View Mnemonic Phrase to back up the 12 words. You are advised to write them down in the correct order in your notepad",
                    "When the mnemonic is backed up, click Already Backed up to go to Verify Mnemonic. Choose the words in the order of your mnemonic phrase, and then click Next and Done",
                    "When you see this screen, congratulations! Your new TronLink extension account has been successfully created",
                ],
                note: "Note: Do not take screenshots of your mnemonic phrase, and be sure to keep it safe",
            },
            howToConnectTronLink: {
                title: "How to connect your TronLink to RealColibri",
                steps: [
                    "Reload the homepage, the following pop-up window will appear. Click Connect to connect your TronLink wallet to the website.",
                    "Click Register in the top right corner of the homepage and then Register with TronLink. Sign the transaction in the following TronLink pop-up window (costs gas, requires ≈606 TRX)",
                ],
                note: "Note: You must have the required TRX on your Tronlink wallet. Gas fee is charged by the blockchain and does not benefit RealColibri.",
            },
            howToAddRCBC: {
                title: "How to add RCBC to your TronLink home screen",
                steps: [
                    "On the RealColibri homepage click Token address button under RealColibri Coin tab",
                    "Find the RCBC Token address and copy it",
                    "Open your TronLink wallet and press + icon",
                    "Click on the search field and paste the copied address",
                    "Add RCBC by pressing + button",
                    "RCBC will appear on the home screen of your TronLink wallet",
                ],
            },
            howToGetRCBC: {
                title: "How to get RCBC",
                subContent: [
                    {
                        key: 5,
                        steps: [
                            "On the RealColibri home page, in the exchange form, enter the amount of TRX you want to convert (1 RCBC = 10 TRX) and click Exchange",
                            "Click Sign in TronLink pop-up notification",
                            "The corresponding amount of RCBC will appear in your TronLink wallet",
                        ],
                    },
                    {
                        key: 6,
                        steps: [
                            "Go to the RCBC page",
                            "Go to the Contract tab > Write Contract and call the buy command",
                            "Enter the amount of TRX you want (10 TRX = 1 RCBC) and add 6 zeros to that amount (20000000 = 20 TRX)",
                            "Click Send and confirm the operation in the TronLink pop-up notification by pressing Sign",
                            "The corresponding amount of RCBC will appear in your TronLink wallet",
                        ],
                    },
                ],
            },

            howToCreateAndConnectBinanceAPI: {
                title: "How to create and connect a Binance API key to RealColibri",
                steps: [
                    "Sign up for Binance",
                    "Complete KYC (identity verification)",
                    "In the upper Wallet menu, go to the Futures section",
                    "Open a futures wallet by clicking on the Open Now button",
                    "Go to the API Management section",
                    "Click on the Create API button",
                    "Come up with a name (e.g. RealColibri), click Next and confirm the operation",
                    "Copy the API Key and Secret Key to a secure location",
                    "Click Edit Restrictions",
                    "Click on Restrict access to trusted IPs only (recommended), enter 65.2.84.75 (RealColibri IP address) and confirm",
                    "Check the following boxes: Enable Margin Loan, Repay & Transfer, Enable European Options, Enable Spot & Margin Trading, Enable Futures, Permits Universal Transfer and save the changes",
                    "On the RealColibri website go to Crypto>Binance",
                    "Add API keys by clicking on + icon",
                    "Paste the API Key and Secret Key and click Connect",
                ],
            },
            howToTopUpCommissionBalance: {
                title: "How to top up the RealColibri commission balance and start trading",
                steps: [
                    "Go to your RealColibri account (click your wallet address in the top menu)",
                    "Enter the required amount of RCBC and click Deposit",
                    "The current balance of the commission deposit can be checked above the input field",
                    "Choose a symbol and click START to launch automatic trading",
                ],
            },
            howToInviteFriend: {
                title: "How to invite a friend to RealColibri and get rewards",
                steps: [
                    "Go to your RealColibri account (click your wallet address in the top menu)",
                    "Copy your personal Referral link and send it to a friend",
                    "To claim a profit from your referrals, click the Transfer button",
                ],
            },
            howToLockRCBC: {
                title: "How to lock RCBC for staking and claim profit",
                steps: [
                    "Go to your RealColibri account (click your wallet address in the top menu)",
                    "In order to lock RCBC, in the Staking section, enter a number in the corresponding field and click Lock stake, then confirm the operation",
                    "To receive the accumulated profit, click on the Claim reward button (costs gas, requires TRX)",
                    "To unlock RCBC without claiming a profit, click on the Unlock staking button",
                    "To check the amount of accumulated profit, click Calculate betting (costs gas, requires TRX)",
                ],
            },
            calculationsSection: {
                title: "Calculations section",
                steps: [
                    "The Calculate profit button displays all the accumulated profit (costs gas, requires TRX)",
                    "The Calculate and claim button displays and transfers all accumulated profit to your TronLink wallet",
                ],
            },
        },
    },

    es: {
        guides: {
            howToCreateTronLink: {
                title: "Cómo crear una billetera TronLink",
                steps: [
                    "Haga clic en el botón Registrar TronLink en el sitio web de RealColibri o vaya a tronlink.org",
                    "Pase el cursor sobre Extensión del navegador y haga clic en Chrome Web Store o Complementos para Firefox, según su navegador. Para este ejemplo, Chrome",
                    "Haga clic en Agregar a Chrome y luego haga clic en Agregar extensión en la ventana emergente",
                    "Después de que se instale correctamente, podrá encontrar la extensión TronLink en Extensiones de su navegador. Para acceder a la extensión de manera más conveniente, puede hacer clic en el icono de Pin a su derecha para fijarlo en la esquina superior derecha de su navegador",
                    "Haga clic en el icono de la extensión y elija Crear billetera. Desplácese hacia abajo para leer y aceptar el acuerdo de usuario",
                    "Cree un nombre y una contraseña para su billetera, y luego haga clic en Crear billetera en la parte inferior",
                    "Cuando se cree la billetera, haga clic en Respaldar billetera, ingrese la contraseña y luego haga clic en Respaldar en Respaldar frase mnemotécnica",
                    "Antes de continuar, asegúrese de que la información sea solo visible para usted. Haga clic en Ver frase mnemotécnica para respaldar las 12 palabras. Se le recomienda escribirlas en el orden correcto en su bloc de notas",
                    "Cuando se respalde el mnemotécnico, haga clic en Ya respaldado para ir a Verificar mnemotécnico. Elija las palabras en el orden de su frase mnemotécnica, y luego haga clic en Siguiente y Hecho",
                    "Cuando vea esta pantalla, ¡felicitaciones! Su nueva cuenta de extensión TronLink se ha creado con éxito",
                ],
                note: "Nota: No tome capturas de pantalla de su frase mnemotécnica y asegúrese de mantenerla segura",
            },
            howToConnectTronLink: {
                title: "Cómo conectar su TronLink a RealColibri",
                steps: [
                    "Recargue la página de inicio, aparecerá la siguiente ventana emergente. Haga clic en Conectar para conectar su billetera TronLink al sitio web.",
                    "Haga clic en Registrar en la esquina superior derecha de la página de inicio y luego en Registrar con TronLink. Firme la transacción en la ventana emergente de TronLink (cuesta gas, requiere ≈606 TRX)",
                ],
                note: "Nota: Debe tener el TRX requerido en su billetera Tronlink. La tarifa de gas es cobrada por la cadena de bloques y no beneficia a RealColibri.",
            },
            howToAddRCBC: {
                title: "Cómo agregar RCBC a la pantalla principal de su TronLink",
                steps: [
                    "En la página de inicio de RealColibri haga clic en el botón Dirección del token en la pestaña RealColibri Coin",
                    "Encuentre la dirección del token RCBC y cópiela",
                    "Abra su billetera TronLink y presione el ícono +",
                    "Haga clic en el campo de búsqueda y pegue la dirección copiada",
                    "Agregue RCBC presionando el botón +",
                    "RCBC aparecerá en la pantalla principal de su billetera TronLink",
                ],
            },
            howToGetRCBC: {
                title: "Cómo obtener RCBC",
                subContent: [
                    {
                        key: 5,
                        steps: [
                            "En la página de inicio de RealColibri, en el formulario de intercambio, ingrese la cantidad de TRX que desea convertir (1 RCBC = 10 TRX) y haga clic en Intercambiar",
                            "Haga clic en Firmar en la notificación emergente de TronLink",
                            "La cantidad correspondiente de RCBC aparecerá en su billetera TronLink",
                        ],
                    },
                    {
                        key: 6,
                        steps: [
                            "Vaya a la página de RCBC",
                            "Vaya a la pestaña Contrato > Escribir Contrato y llame al comando comprar",
                            "Ingrese la cantidad de TRX que desea (10 TRX = 1 RCBC) y agregue 6 ceros a esa cantidad (20000000 = 20 TRX)",
                            "Haga clic en Enviar y confirme la operación en la notificación emergente de TronLink presionando Firmar",
                            "La cantidad correspondiente de RCBC aparecerá en su billetera TronLink",
                        ],
                    },
                ],
            },
 
            howToCreateAndConnectBinanceAPI: {
                title: "Cómo crear y conectar una clave API de Binance a RealColibri",
                steps: [
                    "Regístrese en Binance",
                    "Complete KYC (verificación de identidad)",
                    "En el menú superior de Billetera, vaya a la sección Futuros",
                    "Abra una billetera de futuros haciendo clic en el botón Abrir ahora",
                    "Vaya a la sección Gestión de API",
                    "Haga clic en el botón Crear API",
                    "Elija un nombre (por ejemplo, RealColibri), haga clic en Siguiente y confirme la operación",
                    "Copie la clave API y la clave secreta en un lugar seguro",
                    "Haga clic en Editar restricciones",
                    "Haga clic en Restringir el acceso solo a IPs de confianza (recomendado), ingrese 65.2.84.75 (dirección IP de RealColibri) y confirme",
                    "Marque las siguientes casillas: Habilitar préstamo de margen, reembolso y transferencia, Habilitar opciones europeas, Habilitar comercio al contado y de margen, Habilitar futuros, Permitir transferencia universal y guarde los cambios",
                    "En el sitio web de RealColibri vaya a Crypto>Binance",
                    "Agregue claves API haciendo clic en el ícono +",
                    "Pegue la clave API y la clave secreta y haga clic en Conectar",
                ],
            },
            howToTopUpCommissionBalance: {
                title: "Cómo recargar el saldo de la comisión de RealColibri y comenzar a operar",
                steps: [
                    "Vaya a su cuenta de RealColibri (haga clic en la dirección de su billetera en el menú superior)",
                    "Ingrese la cantidad requerida de RCBC y haga clic en Depositar",
                    "El saldo actual del depósito de la comisión se puede verificar sobre el campo de entrada",
                    "Elija un símbolo y haga clic en INICIAR para iniciar el comercio automático",
                ],
            },
            howToInviteFriend: {
                title: "Cómo invitar a un amigo a RealColibri y obtener recompensas",
                steps: [
                    "Vaya a su cuenta de RealColibri (haga clic en la dirección de su billetera en el menú superior)",
                    "Copie su enlace de referencia personal y envíelo a un amigo",
                    "Para reclamar una ganancia de sus referidos, haga clic en el botón Transferir",
                ],
            },
            howToLockRCBC: {
                title: "Cómo bloquear RCBC para apostar y reclamar ganancias",
                steps: [
                    "Vaya a su cuenta de RealColibri (haga clic en la dirección de su billetera en el menú superior)",
                    "Para bloquear RCBC, en la sección de Staking, ingrese un número en el campo correspondiente y haga clic en Bloquear apuesta, luego confirme la operación",
                    "Para recibir la ganancia acumulada, haga clic en el botón Reclamar recompensa (cuesta gas, requiere TRX)",
                    "Para desbloquear RCBC sin reclamar una ganancia, haga clic en el botón Desbloquear apuesta",
                    "Para verificar la cantidad de ganancia acumulada, haga clic en Calcular apuesta (cuesta gas, requiere TRX)",
                ],
            },
            calculationsSection: {
                title: "Sección de cálculos",
                steps: [
                    "El botón Calcular ganancia muestra todas las ganancias acumuladas (cuesta gas, requiere TRX)",
                    "El botón Calcular y reclamar muestra y transfiere todas las ganancias acumuladas a su billetera TronLink",
                ],
            },
        },
    },
    ru: {
        guides: {
            howToCreateTronLink: {
                title: "Как создать кошелек TronLink",
                steps: [
                    "Нажмите на кнопку Регистрация TronLink на сайте RealColibri или перейдите на tronlink.org",
                    "Наведите курсор на Расширение браузера и нажмите на Chrome Web Store или Дополнения для Firefox, в зависимости от вашего браузера. В этом примере, Chrome",
                    "Нажмите Добавить в Chrome, а затем нажмите Добавить расширение в всплывающем окне",
                    "После успешной установки вы сможете найти расширение TronLink в разделе Расширения вашего браузера. Чтобы получить более удобный доступ к расширению, вы можете нажать на значок Закрепить справа от него, чтобы закрепить его в правом верхнем углу вашего браузера",
                    "Нажмите на значок расширения и выберите Создать кошелек. Прокрутите вниз, чтобы прочитать и принять пользовательское соглашение",
                    "Создайте имя и пароль для своего кошелька, а затем нажмите Создать кошелек внизу",
                    "Когда кошелек создан, нажмите Резервное копирование кошелька, введите пароль, а затем нажмите Резервное копирование в разделе Резервное копирование мнемонической фразы",
                    "Перед тем, как продолжить, убедитесь, что информация видна только вам. Нажмите Просмотреть мнемоническую фразу, чтобы создать резервную копию 12 слов. Вам рекомендуется записать их в правильном порядке в блокнот",
                    "Когда мнемоническая фраза будет создана, нажмите Уже сделано, чтобы перейти к Проверке мнемоники. Выберите слова в порядке вашей мнемонической фразы, затем нажмите Далее и Готово",
                    "Когда вы увидите этот экран, поздравляем! Ваша новая учетная запись расширения TronLink успешно создана",
                ],
                note: "Примечание: Не делайте скриншоты своей мнемонической фразы и обязательно храните ее в безопасности",
            },
            howToConnectTronLink: {
                title: "Как подключить TronLink к RealColibri",
                steps: [
                    "Перезагрузите домашнюю страницу, появится следующее всплывающее окно. Нажмите Подключить, чтобы подключить свой кошелек TronLink к сайту.",
                    "Нажмите Регистрация в правом верхнем углу домашней страницы, а затем Регистрация с TronLink. Подпишите транзакцию в следующем всплывающем окне TronLink (требуется газ, требуется ≈606 TRX)",
                ],
                note: "Примечание: У вас должно быть необходимое количество TRX в вашем кошельке Tronlink. Плата за газ взимается блокчейном и не приносит пользы RealColibri.",
            },
            howToAddRCBC: {
                title: "Как добавить RCBC на главный экран вашего TronLink",
                steps: [
                    "На главной странице RealColibri нажмите кнопку Адрес токена на вкладке RealColibri Coin",
                    "Найдите адрес токена RCBC и скопируйте его",
                    "Откройте свой кошелек TronLink и нажмите на значок +",
                    "Нажмите на поле поиска и вставьте скопированный адрес",
                    "Добавьте RCBC, нажав на кнопку +",
                    "RCBC появится на главном экране вашего кошелька TronLink",
                ],
            },
            howToGetRCBC: {
                title: "Как получить RCBC",
                subContent: [
                    {
                        key: 5,
                        steps: [
                            "На главной странице RealColibri в форме обмена введите количество TRX, которое вы хотите конвертировать (1 RCBC = 10 TRX), и нажмите Обменять",
                            "Нажмите Подписать в всплывающем уведомлении TronLink",
                            "Соответствующее количество RCBC появится в вашем кошельке TronLink",
                        ],
                    },
                    {
                        key: 6,
                        steps: [
                            "Перейдите на страницу RCBC",
                            "Перейдите на вкладку Контракт > Написать контракт и вызовите команду покупки",
                            "Введите количество TRX, которое вы хотите (10 TRX = 1 RCBC) и добавьте 6 нулей к этой сумме (20000000 = 20 TRX)",
                            "Нажмите Отправить и подтвердите операцию в всплывающем уведомлении TronLink, нажав Подписать",
                            "Соответствующее количество RCBC появится в вашем кошельке TronLink",
                        ],
                    },
                ],
            },

            howToCreateAndConnectBinanceAPI: {
                title: "Как создать и подключить API-ключ Binance к RealColibri",
                steps: [
                    "Зарегистрируйтесь на Binance",
                    "Пройдите KYC (верификация личности)",
                    "В верхнем меню Кошелек перейдите в раздел Фьючерсы",
                    "Откройте фьючерсный кошелек, нажав на кнопку Открыть сейчас",
                    "Перейдите в раздел Управление API",
                    "Нажмите на кнопку Создать API",
                    "Придумайте имя (например, RealColibri), нажмите Далее и подтвердите операцию",
                    "Скопируйте API-ключ и секретный ключ в надежное место",
                    "Нажмите Редактировать ограничения",
                    "Нажмите на Ограничить доступ только к доверенным IP-адресам (рекомендуется), введите 65.2.84.75 (IP-адрес RealColibri) и подтвердите",
                    "Отметьте следующие флажки: Включить заем, погашение и перевод по марже, Включить европейские опционы, Включить спотовую и маржинальную торговлю, Включить фьючерсы, Разрешить универсальный перевод и сохраните изменения",
                    "На сайте RealColibri перейдите в Crypto>Binance",
                    "Добавьте API-ключи, нажав на значок +",
                    "Вставьте API-ключ и секретный ключ и нажмите Подключить",
                ],
            },
            howToTopUpCommissionBalance: {
                title: "Как пополнить баланс комиссии RealColibri и начать торговлю",
                steps: [
                    "Перейдите в свой аккаунт RealColibri (нажмите на адрес своего кошелька в верхнем меню)",
                    "Введите необходимое количество RCBC и нажмите Депозит",
                    "Текущий баланс депозита комиссии можно проверить над полем ввода",
                    "Выберите символ и нажмите НАЧАТЬ для запуска автоматической торговли",
                ],
            },
            howToInviteFriend: {
                title: "Как пригласить друга в RealColibri и получить награды",
                steps: [
                    "Перейдите в свой аккаунт RealColibri (нажмите на адрес своего кошелька в верхнем меню)",
                    "Скопируйте свою личную реферальную ссылку и отправьте ее другу",
                    "Чтобы получить прибыль от своих рефералов, нажмите на кнопку Перевод",
                ],
            },
            howToLockRCBC: {
                title: "Как заблокировать RCBC для стейкинга и получить прибыль",
                steps: [
                    "Перейдите в свой аккаунт RealColibri (нажмите на адрес своего кошелька в верхнем меню)",
                    "Чтобы заблокировать RCBC, в разделе Стейкинг введите число в соответствующее поле и нажмите Заблокировать стейк, затем подтвердите операцию",
                    "Чтобы получить накопленную прибыль, нажмите на кнопку Получить награду (требуется газ, требуется TRX)",
                    "Чтобы разблокировать RCBC без получения прибыли, нажмите на кнопку Разблокировать стейкинг",
                    "Чтобы проверить сумму накопленной прибыли, нажмите Рассчитать ставку (требуется газ, требуется TRX)",
                ],
            },
            calculationsSection: {
                title: "Раздел расчетов",
                steps: [
                    "Кнопка Рассчитать прибыль отображает всю накопленную прибыль (требуется газ, требуется TRX)",
                    "Кнопка Рассчитать и получить отображает и переводит всю накопленную прибыль на ваш кошелек TronLink",
                ],
            },
        },
    },
    hy: {
        guides: {
            howToCreateTronLink: {
                title: "Ինչպես ստեղծել TronLink դրամապանակ",
                steps: [
                    "Կլիք արեք «Գրանցվել TronLink-ում» կոճակի վրա RealColibri կայքում կամ անցեք tronlink.org",
                    "Տեղադրեք մկնիկը «Բրաուզեր ընդլայնում» հատվածի վրա և ընտրեք Chrome Web Store կամ Firefox-ի Add-ons՝ կախված ձեր բրաուզերից։ Այս օրինակում՝ Chrome",
                    "Կլիք արեք «Ավելացնել Chrome-ում» և հետո «Ավելացնել ընդլայնում» բացվող պատուհանում",
                    "Հաջողությամբ տեղադրելուց հետո, դուք կարող եք գտնել TronLink ընդլայնումը ձեր բրաուզերի ընդլայնումների ցանկում։ Ավելի հարմար մուտք ունենալու համար կարող եք կլիք անել նրա աջ կողմում գտնվող քորիզի պատկերին՝ այն բրաուզերի վերին աջ անկյունում ամրացնելու համար",
                    "Կլիք արեք ընդլայնման պատկերակին և ընտրեք «Ստեղծել դրամապանակ»։ Ոլորեք ներքև՝ կարդալու և ընդունելու օգտագործողի համաձայնագիրը",
                    "Ստեղծեք անուն և գաղտնաբառ ձեր դրամապանակի համար, և ապա կլիք արեք «Ստեղծել դրամապանակ» կոճակի վրա ներքևում",
                    "Երբ դրամապանակը ստեղծված է, կլիք արեք «Պահպանել դրամապանակը», մուտքագրեք գաղտնաբառը, և ապա կլիք արեք «Պահպանել»՝ «Պահպանել mnemonic արտահայտությունը» բաժնում",
                    "Մինչև շարունակելը, համոզվեք, որ տեղեկատվությունը հասանելի է միայն ձեզ։ Կլիք արեք «Դիտել mnemonic արտահայտությունը», որպեսզի պահպանեք 12 բառերը։ Խորհուրդ է տրվում գրել դրանք ճիշտ հերթականությամբ ձեր նոթատետրում",
                    "Երբ mnemonic արտահայտությունը պահված է, կլիք արեք «Արդեն պահված է», որպեսզի անցնեք «Հաստատել mnemonic»-ը։ Ընտրեք բառերը ձեր mnemonic արտահայտության հերթականությամբ, ապա կլիք արեք «Հաջորդ» և «Ավարտել»",
                    "Երբ տեսնում եք այս էկրանը, շնորհավորում ենք։ Ձեր նոր TronLink ընդլայնման հաշիվը հաջողությամբ ստեղծված է",
                ],
                note: "Նշում: Մի՛ վերցրեք ձեր mnemonic արտահայտության սքրինշոթը, և համոզվեք, որ այն պահված է ապահով վայրում",
            },
            howToConnectTronLink: {
                title: "Ինչպես միացնել ձեր TronLink-ը RealColibri-ին",
                steps: [
                    "Թարմացրեք գլխավոր էջը, կհայտնվի հետևյալ բացվող պատուհանը։ Կլիք արեք «Միացնել», որպեսզի ձեր TronLink դրամապանակը կապեք կայքին",
                    "Կլիք արեք «Գրանցվել» էջի վերին աջ անկյունում, ապա՝ «Գրանցվել TronLink-ով»։ Ստորագրեք գործարքը բացվող TronLink պատուհանում (արժեքը՝ գազ, պահանջում է ≈606 TRX)",
                ],
                note: "Նշում: Ձեր TronLink դրամապանակում պետք է լինի պահանջվող TRX-ը։ Գազի վճարը վերցվում է բլոկչեյնից և չի բերում RealColibri-ին օգուտ",
            },
            howToAddRCBC: {
                title: "Ինչպես ավելացնել RCBC-ն ձեր TronLink գլխավոր էկրանին",
                steps: [
                    "RealColibri գլխավոր էջում կլիք արեք «Թոքենի հասցե» կոճակի վրա RealColibri Coin բաժնում",
                    "Գտեք RCBC Թոքենի հասցեն և պատճենեք այն",
                    "Բացեք ձեր TronLink դրամապանակը և սեղմեք + պատկերակին",
                    "Կլիք արեք որոնման դաշտում և կպցրեք պատճենած հասցեն",
                    "Ավելացրեք RCBC-ն՝ սեղմելով + կոճակը",
                    "RCBC-ն կհայտնվի ձեր TronLink դրամապանակի գլխավոր էկրանին",
                ],
            },
            howToGetRCBC: {
                title: "Ինչպես ստանալ RCBC",
                subContent: [
                    {
                        key: 5,
                        steps: [
                            "RealColibri գլխավոր էջում, փոխարկման ձևում, մուտքագրեք այն TRX-ի քանակը, որը ցանկանում եք փոխարկել (1 RCBC = 10 TRX) և կլիք արեք «Փոխարկել»",
                            "Կլիք արեք «Ստորագրել» TronLink բացվող ծանուցման պատուհանում",
                            "Համապատասխան RCBC-ի քանակը կհայտնվի ձեր TronLink դրամապանակում",
                        ],
                    },
                    {
                        key: 6,
                        steps: [
                            "Գնացեք RCBC էջը",
                            "Գնացեք «Պայմանագիր» բաժինը > «Գրել պայմանագիր» և կանչեք «գնել» հրամանը",
                            "Մուտքագրեք այն TRX-ի քանակը, որը ցանկանում եք (10 TRX = 1 RCBC) և ավելացրեք 6 զրո (20000000 = 20 TRX)",
                            "Կլիք արեք «Ուղարկել» և հաստատեք գործարքը TronLink բացվող ծանուցման պատուհանում՝ սեղմելով «Ստորագրել»",
                            "Համապատասխան RCBC-ի քանակը կհայտնվի ձեր TronLink դրամապանակում",
                        ],
                    },
                ],
            },

            howToCreateAndConnectBinanceAPI: {
                title: "Ինչպես ստեղծել և կապել Binance API բանալին RealColibri-ին",
                steps: [
                    "Գրանցվեք Binance-ում",
                    "Ավարտեք KYC (անձնության հաստատում)",
                    "Վերին «Դրամապանակ» մենյուում անցեք «Ֆյուչերսներ» բաժինը",
                    "Բացեք ֆյուչերս դրամապանակ՝ կլիք անելով «Բացել հիմա» կոճակի վրա",
                    "Անցեք «API կառավարման» բաժին",
                    "Կլիք արեք «Ստեղծել API» կոճակի վրա",
                    "Հորինեք անուն (օրինակ՝ RealColibri), սեղմեք «Հաջորդ» և հաստատեք գործարքը",
                    "Պատճենեք API բանալին և գաղտնի բանալին անվտանգ վայրում",
                    "Կլիք արեք «Խմբագրել սահմանափակումները»",
                    "Կլիք արեք «Սահմանափակել մուտքը միայն վստահելի IP հասցեների վրա (առաջարկվող)», մուտքագրեք 65.2.84.75 (RealColibri IP հասցե) և հաստատեք",
                    "Ստուգեք հետևյալ տուփերը՝ «Ակտիվացնել մարժա վարկը, Վճարել և փոխանցել», «Ակտիվացնել եվրոպական օպցիոնները», «Ակտիվացնել սփոթ և մարժա առևտուրը», «Ակտիվացնել ֆյուչերսները», «Թույլատրել ունիվերսալ փոխանցումը» և պահպանեք փոփոխությունները",
                    "RealColibri կայքում անցեք «Կրիպտո > Binance» բաժին",
                    "Ավելացրեք API բանալիները՝ կլիք անելով + պատկերակին",
                    "Կպցրեք API բանալին և գաղտնի բանալին և կլիք արեք «Կապել»",
                ],
            },
            howToTopUpCommissionBalance: {
                title: "Ինչպես լրացնել RealColibri-ի միջնորդավճարի մնացորդը և սկսել առևտուրը",
                steps: [
                    "Անցեք ձեր RealColibri հաշիվը (կլիք արեք ձեր դրամապանակի հասցեին վերին մենյուում)",
                    "Մուտքագրեք անհրաժեշտ RCBC քանակը և կլիք արեք «Ավանդ»",
                    "Միջնորդավճարի ավանդի ընթացիկ մնացորդը կարելի է ստուգել մուտքագրման դաշտի վերևում",
                    "Ընտրեք սիմվոլը և կլիք արեք «ՍԿՍԵԼ»՝ ավտոմատ առևտուրը գործարկելու համար",
                ],
            },
            howToInviteFriend: {
                title: "Ինչպես հրավիրել ընկերոջը RealColibri-ում և ստանալ պարգևներ",
                steps: [
                    "Անցեք ձեր RealColibri հաշիվը (կլիք արեք ձեր դրամապանակի հասցեին վերին մենյուում)",
                    "Պատճենեք ձեր անձնական հղումը և ուղարկեք այն ձեր ընկերոջը",
                    "Ձեր հրավիրվածներից եկամուտ ստանալու համար կլիք արեք «Փոխանցել» կոճակի վրա",
                ],
            },
            howToLockRCBC: {
                title: "Ինչպես կողպել RCBC-ը staking-ի համար և ստանալ շահույթ",
                steps: [
                    "Անցեք ձեր RealColibri հաշիվը (կլիք արեք ձեր դրամապանակի հասցեին վերին մենյուում)",
                    "RCBC կողպելու համար, Staking բաժնում, մուտքագրեք թիվ համապատասխան դաշտում և կլիք արեք «Կողպել», ապա հաստատեք գործարքը",
                    "Հավաքված շահույթը ստանալու համար կլիք արեք «Պահանջել շահույթը» կոճակի վրա (արժեքը՝ գազ, պահանջում է TRX)",
                    "RCBC-ը բացելու համար առանց շահույթ պահանջելու, կլիք արեք «Բացել staking-ը» կոճակի վրա",
                    "Հավաքված շահույթի չափը ստուգելու համար կլիք արեք «Հաշվել շահույթը» (արժեքը՝ գազ, պահանջում է TRX)",
                ],
            },
            calculationsSection: {
                title: "Հաշվարկների բաժին",
                steps: [
                    "«Հաշվել շահույթը» կոճակը ցուցադրում է բոլոր հավաքված շահույթը (արժեքը՝ գազ, պահանջում է TRX)",
                    "«Հաշվել և պահանջել» կոճակը ցուցադրում է և փոխանցում է բոլոր հավաքված շահույթը ձեր TronLink դրամապանակին",
                ],
            },
        },
    },
    fr: {
        guides: {
            howToCreateTronLink: {
                title: "Comment créer un portefeuille TronLink",
                steps: [
                    "Cliquez sur le bouton Inscrire TronLink sur le site RealColibri ou allez sur tronlink.org",
                    "Survolez l'extension du navigateur et cliquez sur Chrome Web Store ou Modules complémentaires pour Firefox, selon votre navigateur. Pour cet exemple, Chrome",
                    "Cliquez sur Ajouter à Chrome puis sur Ajouter l'extension dans la fenêtre contextuelle",
                    "Une fois l'installation réussie, vous pourrez trouver l'extension TronLink dans les Extensions de votre navigateur. Pour accéder à l'extension plus facilement, vous pouvez cliquer sur l'icône d'épingle à sa droite pour l'épingler dans le coin supérieur droit de votre navigateur",
                    "Cliquez sur l'icône de l'extension et choisissez Créer un portefeuille. Faites défiler vers le bas pour lire et accepter les conditions d'utilisation",
                    "Créez un nom et un mot de passe pour votre portefeuille, puis cliquez sur Créer un portefeuille en bas",
                    "Lorsque le portefeuille est créé, cliquez sur Sauvegarder le portefeuille, entrez le mot de passe, puis cliquez sur Sauvegarder dans Sauvegarder la phrase mnémotechnique",
                    "Avant de continuer, assurez-vous que les informations ne sont visibles que par vous-même. Cliquez sur Voir la phrase mnémotechnique pour sauvegarder les 12 mots. Il est conseillé de les noter dans l'ordre correct sur votre bloc-notes",
                    "Lorsque la phrase mnémotechnique est sauvegardée, cliquez sur Déjà sauvegardé pour aller à Vérifier la phrase mnémotechnique. Choisissez les mots dans l'ordre de votre phrase mnémotechnique, puis cliquez sur Suivant et Terminé",
                    "Lorsque vous voyez cet écran, félicitations! Votre nouveau compte d'extension TronLink a été créé avec succès",
                ],
                note: "Remarque: Ne prenez pas de captures d'écran de votre phrase mnémotechnique et assurez-vous de la garder en sécurité",
            },
            howToConnectTronLink: {
                title: "Comment connecter votre TronLink à RealColibri",
                steps: [
                    "Rechargez la page d'accueil, la fenêtre contextuelle suivante apparaîtra. Cliquez sur Connecter pour connecter votre portefeuille TronLink au site.",
                    "Cliquez sur Inscrire dans le coin supérieur droit de la page d'accueil, puis Inscrire avec TronLink. Signez la transaction dans la fenêtre contextuelle TronLink suivante (coûte du gaz, nécessite ≈606 TRX)",
                ],
                note: "Remarque: Vous devez avoir le TRX requis sur votre portefeuille Tronlink. Les frais de gaz sont facturés par la blockchain et ne profitent pas à RealColibri.",
            },
            howToAddRCBC: {
                title: "Comment ajouter RCBC à l'écran d'accueil de TronLink",
                steps: [
                    "Sur la page d'accueil de RealColibri, cliquez sur le bouton Adresse du token sous l'onglet RealColibri Coin",
                    "Trouvez l'adresse du token RCBC et copiez-la",
                    "Ouvrez votre portefeuille TronLink et appuyez sur l'icône +",
                    "Cliquez sur le champ de recherche et collez l'adresse copiée",
                    "Ajoutez RCBC en appuyant sur le bouton +",
                    "RCBC apparaîtra sur l'écran d'accueil de votre portefeuille TronLink",
                ],
            },
            howToGetRCBC: {
                title: "Comment obtenir RCBC",
                subContent: [
                    {
                        key: 5,
                        steps: [
                            "Sur la page d'accueil de RealColibri, dans le formulaire d'échange, entrez le montant de TRX que vous souhaitez convertir (1 RCBC = 10 TRX) et cliquez sur Échanger",
                            "Cliquez sur Se connecter dans la notification contextuelle de TronLink",
                            "Le montant correspondant de RCBC apparaîtra dans votre portefeuille TronLink",
                        ],
                    },
                    {
                        key: 6,
                        steps: [
                            "Allez à la page RCBC",
                            "Allez à l'onglet Contrat > Écrire le contrat et appelez la commande acheter",
                            "Entrez le montant de TRX que vous souhaitez (10 TRX = 1 RCBC) et ajoutez 6 zéros à ce montant (20000000 = 20 TRX)",
                            "Cliquez sur Envoyer et confirmez l'opération dans la notification contextuelle de TronLink en appuyant sur Signer",
                            "Le montant correspondant de RCBC apparaîtra dans votre portefeuille TronLink",
                        ],
                    },
                ],
            },
            howToCreateAndConnectBinanceAPI: {
                title: "Comment créer et connecter une clé API Binance à RealColibri",
                steps: [
                    "Inscrivez-vous sur Binance",
                    "Complétez la vérification d'identité (KYC)",
                    "Dans le menu Portefeuille en haut, allez dans la section Futures",
                    "Ouvrez un portefeuille de futures en cliquant sur le bouton Ouvrir maintenant",
                    "Allez dans la section Gestion des API",
                    "Cliquez sur le bouton Créer une API",
                    "Choisissez un nom (par exemple RealColibri), cliquez sur Suivant et confirmez l'opération",
                    "Copiez la clé API et la clé secrète dans un endroit sécurisé",
                    "Cliquez sur Modifier les restrictions",
                    "Cliquez sur Restreindre l'accès uniquement aux IPs de confiance (recommandé), entrez 65.2.84.75 (adresse IP de RealColibri) et confirmez",
                    "Cochez les cases suivantes: Activer prêt de marge, Remboursement & Transfert, Activer options européennes, Activer trading au comptant & sur marge, Activer futures, Permet transfert universel et enregistrez les modifications",
                    "Sur le site de RealColibri, allez dans Crypto>Binance",
                    "Ajoutez les clés API en cliquant sur l'icône +",
                    "Collez la clé API et la clé secrète, puis cliquez sur Connecter",
                ],
            },
            howToTopUpCommissionBalance: {
                title: "Comment recharger le solde de la commission RealColibri et commencer à trader",
                steps: [
                    "Accédez à votre compte RealColibri (cliquez sur votre adresse de portefeuille dans le menu du haut)",
                    "Entrez le montant de RCBC requis et cliquez sur Dépôt",
                    "Le solde actuel du dépôt de la commission peut être vérifié au-dessus du champ de saisie",
                    "Choisissez un symbole et cliquez sur DÉMARRER pour lancer le trading automatique",
                ],
            },
            howToInviteFriend: {
                title: "Comment inviter un ami à RealColibri et obtenir des récompenses",
                steps: [
                    "Accédez à votre compte RealColibri (cliquez sur votre adresse de portefeuille dans le menu du haut)",
                    "Copiez votre lien de parrainage personnel et envoyez-le à un ami",
                    "Pour réclamer un profit de vos parrainages, cliquez sur le bouton Transfert",
                ],
            },
            howToLockRCBC: {
                title: "Comment verrouiller RCBC pour le staking et réclamer un profit",
                steps: [
                    "Accédez à votre compte RealColibri (cliquez sur votre adresse de portefeuille dans le menu du haut)",
                    "Pour verrouiller RCBC, dans la section Staking, entrez un nombre dans le champ correspondant et cliquez sur Verrouiller mise, puis confirmez l'opération",
                    "Pour recevoir le profit accumulé, cliquez sur le bouton Réclamer récompense (coûte du gaz, nécessite TRX)",
                    "Pour déverrouiller RCBC sans réclamer un profit, cliquez sur le bouton Déverrouiller staking",
                    "Pour vérifier le montant du profit accumulé, cliquez sur Calculer pari (coûte du gaz, nécessite TRX)",
                ],
            },
            calculationsSection: {
                title: "Section des calculs",
                steps: [
                    "Le bouton Calculer le profit affiche tous les profits accumulés (coûte du gaz, nécessite TRX)",
                    "Le bouton Calculer et réclamer affiche et transfère tous les profits accumulés dans votre portefeuille TronLink",
                ],
            },
        },
    },
    de: {
        guides: {
            howToCreateTronLink: {
                title: "Wie man ein TronLink Wallet erstellt",
                steps: [
                    "Klicken Sie auf die Schaltfläche TronLink registrieren auf der RealColibri-Website oder gehen Sie zu tronlink.org",
                    "Fahren Sie mit der Maus über Browsererweiterung und klicken Sie auf Chrome Web Store oder Add-ons für Firefox, je nach Ihrem Browser. In diesem Beispiel: Chrome",
                    "Klicken Sie auf Zu Chrome hinzufügen und dann im Popup-Fenster auf Erweiterung hinzufügen",
                    "Nach erfolgreicher Installation finden Sie die TronLink-Erweiterung in den Erweiterungen Ihres Browsers. Um schneller auf die Erweiterung zugreifen zu können, können Sie auf das Stecknadelsymbol rechts daneben klicken, um sie in die obere rechte Ecke Ihres Browsers zu heften",
                    "Klicken Sie auf das Symbol der Erweiterung und wählen Sie Wallet erstellen. Scrollen Sie nach unten, um die Benutzervereinbarung zu lesen und zu akzeptieren",
                    "Erstellen Sie einen Namen und ein Passwort für Ihr Wallet und klicken Sie dann unten auf Wallet erstellen",
                    "Nachdem das Wallet erstellt wurde, klicken Sie auf Wallet sichern, geben Sie das Passwort ein und klicken Sie dann auf Sichern im Bereich Mnemonic Phrase sichern",
                    "Bevor Sie fortfahren, stellen Sie sicher, dass die Informationen nur für Sie sichtbar sind. Klicken Sie auf Mnemonic Phrase anzeigen, um die 12 Wörter zu sichern. Es wird empfohlen, diese in der richtigen Reihenfolge in Ihrem Notizbuch aufzuschreiben",
                    "Wenn das Mnemonic gesichert ist, klicken Sie auf Bereits gesichert, um zur Überprüfung des Mnemonics zu gelangen. Wählen Sie die Wörter in der Reihenfolge Ihrer Mnemonic Phrase und klicken Sie dann auf Weiter und Fertig",
                    "Wenn Sie diesen Bildschirm sehen, herzlichen Glückwunsch! Ihr neues TronLink-Erweiterungskonto wurde erfolgreich erstellt",
                ],
                note: "Hinweis: Machen Sie keine Screenshots Ihrer Mnemonic Phrase und stellen Sie sicher, dass Sie sie sicher aufbewahren",
            },
            howToConnectTronLink: {
                title: "Wie man Ihr TronLink mit RealColibri verbindet",
                steps: [
                    "Laden Sie die Homepage neu, das folgende Popup-Fenster erscheint. Klicken Sie auf Verbinden, um Ihr TronLink Wallet mit der Website zu verbinden.",
                    "Klicken Sie oben rechts auf der Homepage auf Registrieren und dann auf Mit TronLink registrieren. Signieren Sie die Transaktion im folgenden TronLink-Popup-Fenster (kostet Gas, erfordert ≈606 TRX)",
                ],
                note: "Hinweis: Sie müssen das erforderliche TRX in Ihrem Tronlink Wallet haben. Die Gasgebühr wird von der Blockchain erhoben und kommt RealColibri nicht zugute.",
            },
            howToAddRCBC: {
                title: "Wie man RCBC zum Startbildschirm von TronLink hinzufügt",
                steps: [
                    "Klicken Sie auf der Startseite von RealColibri auf die Schaltfläche Token-Adresse unter dem Reiter RealColibri Coin",
                    "Finden Sie die RCBC-Token-Adresse und kopieren Sie sie",
                    "Öffnen Sie Ihr TronLink Wallet und drücken Sie auf das Symbol +",
                    "Klicken Sie auf das Suchfeld und fügen Sie die kopierte Adresse ein",
                    "Fügen Sie RCBC hinzu, indem Sie auf die Schaltfläche + drücken",
                    "RCBC wird auf dem Startbildschirm Ihres TronLink Wallets erscheinen",
                ],
            },
            howToGetRCBC: {
                title: "Wie man RCBC erhält",
                subContent: [
                    {
                        key: 5,
                        steps: [
                            "Geben Sie auf der RealColibri-Startseite im Umrechnungsformular den Betrag an TRX ein, den Sie umwandeln möchten (1 RCBC = 10 TRX) und klicken Sie auf Umtauschen",
                            "Klicken Sie auf Anmelden im TronLink-Popup-Benachrichtigung",
                            "Der entsprechende Betrag an RCBC wird in Ihrem TronLink Wallet erscheinen",
                        ],
                    },
                    {
                        key: 6,
                        steps: [
                            "Gehen Sie zur RCBC-Seite",
                            "Gehen Sie zum Reiter Vertrag > Vertrag schreiben und rufen Sie den Kaufbefehl auf",
                            "Geben Sie den gewünschten TRX-Betrag ein (10 TRX = 1 RCBC) und fügen Sie 6 Nullen zu diesem Betrag hinzu (20000000 = 20 TRX)",
                            "Klicken Sie auf Senden und bestätigen Sie die Operation im TronLink-Popup-Benachrichtigung, indem Sie auf Signieren klicken",
                            "Der entsprechende Betrag an RCBC wird in Ihrem TronLink Wallet erscheinen",
                        ],
                    },
                ],
            },
            howToCreateAndConnectBinanceAPI: {
                title: "Wie man einen Binance-API-Schlüssel erstellt und mit RealColibri verbindet",
                steps: [
                    "Melden Sie sich bei Binance an",
                    "Vervollständigen Sie KYC (Identitätsverifizierung)",
                    "Gehen Sie im oberen Menü Wallet zum Bereich Futures",
                    "Öffnen Sie ein Futures-Wallet, indem Sie auf die Schaltfläche Jetzt öffnen klicken",
                    "Gehen Sie zum Bereich API-Verwaltung",
                    "Klicken Sie auf die Schaltfläche API erstellen",
                    "Wählen Sie einen Namen (z.B. RealColibri), klicken Sie auf Weiter und bestätigen Sie den Vorgang",
                    "Kopieren Sie den API-Schlüssel und den geheimen Schlüssel an einen sicheren Ort",
                    "Klicken Sie auf Einschränkungen bearbeiten",
                    "Klicken Sie auf Zugriff nur auf vertrauenswürdige IPs beschränken (empfohlen), geben Sie 65.2.84.75 (RealColibri-IP-Adresse) ein und bestätigen Sie",
                    "Aktivieren Sie die folgenden Kontrollkästchen: Margin-Kredit aktivieren, Rückzahlung & Transfer, Europäische Optionen aktivieren, Spot- & Margin-Handel aktivieren, Futures aktivieren, Universalübertragungen erlauben und speichern Sie die Änderungen",
                    "Gehen Sie auf der RealColibri-Website zu Crypto>Binance",
                    "Fügen Sie API-Schlüssel hinzu, indem Sie auf das Symbol + klicken",
                    "Fügen Sie den API-Schlüssel und den geheimen Schlüssel ein und klicken Sie auf Verbinden",
                ],
            },
            howToTopUpCommissionBalance: {
                title: "Wie man das RealColibri-Kommissionsguthaben auflädt und mit dem Handel beginnt",
                steps: [
                    "Gehen Sie zu Ihrem RealColibri-Konto (klicken Sie auf Ihre Wallet-Adresse im oberen Menü)",
                    "Geben Sie den erforderlichen Betrag an RCBC ein und klicken Sie auf Einzahlung",
                    "Das aktuelle Guthaben des Kommissionsguthabens kann über dem Eingabefeld überprüft werden",
                    "Wählen Sie ein Symbol und klicken Sie auf START, um den automatischen Handel zu starten",
                ],
            },
            howToInviteFriend: {
                title: "Wie man einen Freund zu RealColibri einlädt und Belohnungen erhält",
                steps: [
                    "Gehen Sie zu Ihrem RealColibri-Konto (klicken Sie auf Ihre Wallet-Adresse im oberen Menü)",
                    "Kopieren Sie Ihren persönlichen Empfehlungslink und senden Sie ihn einem Freund",
                    "Um einen Gewinn von Ihren Empfehlungen zu beanspruchen, klicken Sie auf die Schaltfläche Übertragen",
                ],
            },
            howToLockRCBC: {
                title: "Wie man RCBC zum Staking sperrt und Gewinn beansprucht",
                steps: [
                    "Gehen Sie zu Ihrem RealColibri-Konto (klicken Sie auf Ihre Wallet-Adresse im oberen Menü)",
                    "Um RCBC zu sperren, geben Sie im Abschnitt Staking eine Zahl in das entsprechende Feld ein und klicken Sie auf Stake sperren, dann bestätigen Sie den Vorgang",
                    "Um den angesammelten Gewinn zu erhalten, klicken Sie auf die Schaltfläche Belohnung beanspruchen (kostet Gas, erfordert TRX)",
                    "Um RCBC ohne Gewinnforderung zu entsperren, klicken Sie auf die Schaltfläche Staking entsperren",
                    "Um den Betrag des angesammelten Gewinns zu überprüfen, klicken Sie auf Wette berechnen (kostet Gas, erfordert TRX)",
                ],
            },
            calculationsSection: {
                title: "Berechnungsabschnitt",
                steps: [
                    "Die Schaltfläche Gewinn berechnen zeigt den gesamten angesammelten Gewinn an (kostet Gas, erfordert TRX)",
                    "Die Schaltfläche Berechnen und beanspruchen zeigt den gesamten angesammelten Gewinn an und überträgt ihn auf Ihr TronLink-Wallet",
                ],
            },
        },
    },
};
