import React from 'react'
import { ColumnsType } from 'antd/lib/table'
import { defaultRuleSortingTableNumberColumn, formatDate } from 'shared/utils'
import {
    BinanceDealHistoryResponseModel,
    BinanceOrderSideTypes,
} from 'api/http/models'
import { BINANCE_ORDER_SIDE_TYPES } from '../../BinanceTrade.consts'
import { ColorWrapper } from 'shared/components'

export const DEAL_HISTORY_TABLE_COLUMNS: ColumnsType<BinanceDealHistoryResponseModel> =
    [
        {
            title: 'Time',
            dataIndex: 'time',
            key: 'time',
            render: formatDate,
            align: 'center',
            showSorterTooltip: false,
            sorter: defaultRuleSortingTableNumberColumn('time'),
            sortDirections: ['ascend', 'descend', 'ascend'],
        },
        {
            title: 'Symbol',
            dataIndex: 'symbol',
            key: 'symbol',
            align: 'center',
        },
        {
            title: 'Size',
            dataIndex: 'side',
            key: 'side',
            align: 'center',
            render: (_, record) => (
                <ColorWrapper
                    text={BINANCE_ORDER_SIDE_TYPES[record.side]}
                    condition={record.side === BinanceOrderSideTypes.BUY}
                />
            ),
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
            align: 'center',
            render: Number,
        },
        {
            title: 'Quantity',
            dataIndex: 'qty',
            key: 'qty',
            render: (_, record) =>
                `${record.qty} ${record.symbol.replace('USDT', '')}`,
            align: 'center',
            showSorterTooltip: false,
            sorter: defaultRuleSortingTableNumberColumn('qty'),
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Commission',
            dataIndex: 'commission',
            key: 'commission',
            render: (_, record) =>
                `${record.commission} ${record.commissionAsset}`,
            align: 'center',
            showSorterTooltip: false,
            sorter: defaultRuleSortingTableNumberColumn('commission'),
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Realized profit',
            dataIndex: 'realizedPnl',
            key: 'realizedPnl',
            render: (_, record) => `${record.realizedPnl} USDT`,
            align: 'center',
            showSorterTooltip: false,
            sorter: defaultRuleSortingTableNumberColumn('realizedPnl'),
            sortDirections: ['descend', 'ascend'],
        },
    ]
