import s from './GuardAuthButtons.module.scss'

import React from 'react'
import { LoginForm, PopupAdapter, RegisterForm } from 'shared/components'
import { GuardAuthButtonsProps } from './GuardAuthButtons.types'
import { Button } from 'antd'

/** Заглушка для авторизации */
export const GuardAuthButtons: React.FC<GuardAuthButtonsProps> = React.memo(
    ({ className, onCloseMenu }) => {
        return (
            <Button.Group className={className}>
                <PopupAdapter
                    component={LoginForm}
                    formId="logForm"
                    buttonText="Login"
                    modalOptions={{
                        width: 400,
                        footer: null,
                        maskClosable: true,
                    }}
                    buttonOption={{
                        className: s.button,
                        onClick: onCloseMenu,
                    }}
                />

                <PopupAdapter
                    component={RegisterForm}
                    formId="regForm"
                    buttonText="Register"
                    buttonOption={{
                        type: 'primary',
                        className: s.button,
                        onClick: onCloseMenu,
                    }}
                    modalOptions={{
                        width: 800,
                        footer: null,
                        maskClosable: true,
                    }}
                />
            </Button.Group>
        )
    }
)
