import React, { useCallback, useState } from 'react'
import { PopupAdapterFormProps } from 'shared/components/popups/PopupAdapter.types'
import { Form, Spin } from 'antd'
import { BybitSymbolsService } from 'api/http'

/** Форма для удаления символа */
export const BybitSymbolDelete: React.FC<PopupAdapterFormProps> = React.memo(
    ({ onRequestFinish, initialValues, ...props }) => {
        const [localLoader, setLocalLoader] = useState(false)

        const handleFinish = useCallback(async () => {
            try {
                setLocalLoader(true)

                if (!initialValues?.id) return

                await BybitSymbolsService.delete(initialValues.id)
                onRequestFinish?.()
            } catch (e) {
                console.log(e)
            } finally {
                setLocalLoader(false)
            }
        }, [setLocalLoader, onRequestFinish, initialValues?.id])

        return (
            <Spin spinning={localLoader}>
                <Form onFinish={handleFinish} {...props}>
                    Вы уверены, что хотите удалить символ?
                </Form>
            </Spin>
        )
    }
)
