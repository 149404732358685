import React, { useCallback, useContext, useEffect, useState } from 'react'
import { LoaderContext } from 'core/context'
import { BinanceReferrerProfitService } from 'api/http'
import { PageContent, TableAdapter } from 'shared/components'
import { useQueryParams } from 'shared/hooks'
import { ReferrerProfitResponseModel } from 'api/http/models'
import { BINANCE_REFERRERS_PROFIT_TABLE_COLUMNS } from './BinanceReferrersProfit.consts'

/** Таблица переводов на тейкинг */
export const BinanceReferrersProfit: React.FC = React.memo(() => {
    const { setLoader } = useContext(LoaderContext)
    const { paginationOptions, queryParams } = useQueryParams()
    const [transactionsData, setTransactionsData] =
        useState<ReferrerProfitResponseModel[]>()
    const [itemsCount, setItemsCount] = useState(0)

    const fetchStakingTransaction = useCallback(async () => {
        try {
            setLoader(true)

            const { limit, skip } = queryParams

            const dataSource = await BinanceReferrerProfitService.search(
                limit,
                skip
            )

            setTransactionsData(dataSource.items)
            setItemsCount(dataSource.total)
        } catch (e) {
            console.log(e)
        } finally {
            setLoader(false)
        }
    }, [queryParams, setLoader])

    useEffect(() => {
        fetchStakingTransaction()
    }, [fetchStakingTransaction, queryParams])

    return (
        <PageContent>
            <h2>Переводы на контракты реферреров</h2>

            <br />

            <TableAdapter
                columns={BINANCE_REFERRERS_PROFIT_TABLE_COLUMNS}
                dataSource={transactionsData}
                pagination={{
                    ...paginationOptions,
                    total: itemsCount,
                }}
            />
        </PageContent>
    )
})
