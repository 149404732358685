import s from './TronLinkGuide.module.scss'

import React from 'react'

/** Гайд по подключению тронлинка */
export const TronLinkGuide = (
    <div className={s.wrapper}>
        <div>Plugin not detected:</div>

        <ol className="pl-30">
            <li>
                <span>Install the plugin on the </span>

                <a
                    href="https://www.tronlink.org/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    official website
                </a>
            </li>

            <li>Refresh page</li>
        </ol>

        <div>To use the site via your phone, open the Android or iOS app</div>
    </div>
)
