import {
    RCBC_ADDRESS,
    RCFACTORY_ADDRESS,
    RCSTAKING_ADDRESS,
} from 'core/tron/tron.consts'

export const getBlockchainLinks = () => {
    const ref =
        process.env.NODE_ENV === 'production'
            ? 'https://tronscan.io/#/contract/'
            : 'https://nile.tronscan.io/#/contract/'

    return {
        token: `${ref}${RCBC_ADDRESS}`,
        factory: `${ref}${RCFACTORY_ADDRESS}`,
        staking: `${ref}${RCSTAKING_ADDRESS}`,
    }
}
