import { ChartOptions, CrosshairMode, DeepPartial } from 'lightweight-charts'
import { BybitCandlesIntervalTypes } from 'api/http/models'

export const DIAGRAM_OPTIONS: DeepPartial<ChartOptions> = {
    crosshair: {
        mode: CrosshairMode.Normal,
    },
    layout: {
        background: {
            color: '#FAFAFA' /*'rgba(255, 255, 255, 0.9)'*/,
        },
        textColor: '#333333' /*'#253248'*/,
    },
    grid: {
        vertLines: {
            color: '#e8e8e8' /*'#334158'*/,
        },
        horzLines: {
            color: '#e8e8e8' /*'#334158'*/,
        },
    },
    timeScale: {
        borderColor: '#485c7b',
        timeVisible: true,
        fixLeftEdge: true,
    },
}

export const TIME_TAGS = [
    {
        id: BybitCandlesIntervalTypes.min,
        tagName: '1m',
    },
    {
        id: BybitCandlesIntervalTypes.min5,
        tagName: '5m',
    },
    {
        id: BybitCandlesIntervalTypes.min15,
        tagName: '15m',
    },
    {
        id: BybitCandlesIntervalTypes.hour1,
        tagName: '1h',
    },
    {
        id: BybitCandlesIntervalTypes.hour4,
        tagName: '4h',
    },
]
