import React from 'react';
import s from './CryptoMarkets.module.scss';
import { BlockWrapper, ExchangeForm, PageContent } from 'shared/components';
import { EXCHANGES } from 'shared/consts';
import { Col, Row } from 'antd';
import { DETAILED_GUIDES } from './CryptoMarkets.consts';
import { TronLink } from '../../core/tron';
import { Join } from '../Main/Join';
import translations from './translations';
import { useLanguage } from '../../context/LanguageContext';    

export const CryptoMarkets: React.FC = React.memo(() => {
    const { language } = useLanguage();
    const t = translations[language];

    return (
        <PageContent>
            <div className={s.banner}>
                <div className={s.img} />
                <div className={s.overlay} />
                <div className={s.title}>{t.cryptoMarkets}</div>
            </div>

            <BlockWrapper className={s.guide}>
                <h3 className={s.guideTitle}>{t.ourFeatures}</h3>

                <ul>
                    <li>{t.feature1}</li>
                    <li>{t.feature2}</li>
                    <li>{t.feature3}</li>
                    <li>{t.feature4}</li>
                </ul>
            </BlockWrapper>

            <h2 className={s.subTitle}>{t.chooseExchange}</h2>

            <BlockWrapper className={s.exchanges}>
                {EXCHANGES.crypto.map((el, i) => (
                    <a
                        href={el.href}
                        className={s.link}
                        target="_blank"
                        rel="noreferrer"
                        key={i}
                    >
                        <img src={el.icon} alt={el.href} />
                    </a>
                ))}
            </BlockWrapper>

            <BlockWrapper className={s.guide}>
                <h3 className={s.guideTitle}>{t.requirementsTitle}</h3>

                <ul>
                    <li>{t.requirement1}</li>
                    <li>{t.requirement2}</li>
                    <li>{t.requirement3}</li>
                    <li>{t.requirement4}</li>
                </ul>
            </BlockWrapper>

            <BlockWrapper className={s.guide}>
                <h3 className={s.guideTitle}>
                    {t.automateTitle}
                </h3>

                <ol>
                    <li>{t.automateStep1}</li>
                    <li dangerouslySetInnerHTML={{ __html: t.automateStep2 }}></li>
                    <li>{t.automateStep3}</li>
                    <li>{t.automateStep4}</li>
                    <li>{t.automateStep5}</li>
                    <li>{t.automateStep6}</li>
                </ol>

                {DETAILED_GUIDES}
            </BlockWrapper>

            <Join />

            <BlockWrapper className={s.exchange}>
                <h3 className={s.exchangeTitle}>{t.exchangeTitle}</h3>

                <Row gutter={20} align="middle" className={s.exchangeContent}>
                    <Col xs={24} md={15}>
                        <ol>
                            <li>{t.exchangeStep1}</li>
                            <li>{t.exchangeStep2}</li>
                            <li>{t.exchangeStep3}</li>
                            <li>{t.exchangeStep4}</li>
                        </ol>

                        {DETAILED_GUIDES}
                    </Col>

                    <Col xs={24} md={9}>
                        <div style={{ margin: '10px', textAlign: 'center' }}>
                            <p>
                                {t.exchangeInfo}
                            </p>
                        </div>

                        <ExchangeForm />

                        {!TronLink.base58Address ? (
                            <div style={{ margin: '10px', textAlign: 'center' }}>
                                <p>
                                    {t.refreshInfo}
                                </p>
                            </div>
                        ) : null}
                    </Col>
                </Row>
            </BlockWrapper>

            <h3 className={s.mail}>
                <a href="mailto:mail@realcolibri.com">
                    <div>{t.contactUs}</div>
                    <div>{t.contactEmail}</div>
                </a>
            </h3>
        </PageContent>
    );
});
