import s from './TradeDiagram.module.scss'

import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import {
    ChartLinesOrdersProps,
    ChartLinesProps,
    TradeDiagramProps,
} from './TradeDiagram.types'
import { DIAGRAM_OPTIONS, TIME_TAGS } from './TradeDiagram.consts'
import { Spin, Tag } from 'antd'
import {
    createChart,
    IChartApi,
    ISeriesApi,
    LineStyle,
} from 'lightweight-charts'
import { BybitOrderSideTypes } from 'api/http/models'

/** Диаграмма для страницы торговли */
export const TradeDiagram: React.FC<TradeDiagramProps> = React.memo(
    ({
        settings: { period, symbol },
        onChangeSettings,
        candlestickData,
        tickerLastPrice,
        orders,
        position,
    }) => {
        const chartContainerRef = useRef<HTMLDivElement>(null)
        const [candlestickSeries, setCandlestickSeries] =
            useState<ISeriesApi<'Candlestick'>>()
        const [chartLib, setChartLib] = useState<IChartApi>()
        // TODO: перевести на useRef, по аналогии с Rithmic
        const [, setChartLines] = useState<ChartLinesProps>({})
        const lastTickTimeRef = useRef(0)

        useEffect(() => {
            if (!chartContainerRef?.current) return

            const chartLib = createChart(chartContainerRef.current, {
                width: chartContainerRef.current.clientWidth,
                height: chartContainerRef.current.clientHeight,
                ...DIAGRAM_OPTIONS,
            })

            setChartLib(chartLib)
            setCandlestickSeries(chartLib.addCandlestickSeries())
        }, [])

        useEffect(() => {
            if (candlestickData?.length && candlestickSeries) {
                if (candlestickData.length === 1) {
                    if (candlestickData[0].time >= lastTickTimeRef.current) {
                        lastTickTimeRef.current = Number(
                            candlestickData[0].time
                        )
                        candlestickSeries.update(candlestickData[0])
                    }
                } else {
                    lastTickTimeRef.current = Number(
                        candlestickData[candlestickData.length - 1].time
                    )
                    candlestickSeries.setData(candlestickData)
                }
            }
        }, [candlestickData, candlestickSeries])

        useEffect(() => {
            if (!candlestickSeries) return

            if (position) {
                setChartLines(prevState => {
                    if (position.size) {
                        if (prevState?.position) {
                            candlestickSeries.removePriceLine(
                                prevState.position.line
                            )
                        }

                        return {
                            ...prevState,
                            position: {
                                line: candlestickSeries.createPriceLine({
                                    price: position.avgPrice,
                                    color: 'blue',
                                    lineWidth: 1,
                                    title: '',
                                    axisLabelVisible: true,
                                    lineStyle: LineStyle.LargeDashed,
                                    lineVisible: true,
                                }),
                                ticker: position.symbol,
                            },
                        }
                    }
                    return prevState
                })
            } else {
                setChartLines(prevState => {
                    if (!prevState.position) return prevState
                    candlestickSeries.removePriceLine(prevState.position.line)
                    return {
                        ...prevState,
                        position: undefined,
                    }
                })
            }
        }, [candlestickSeries, position])

        useEffect(() => {
            if (!candlestickSeries) return

            if (orders?.length) {
                setChartLines(prevState => {
                    const currentLines = prevState.orders?.reduce<
                        ChartLinesOrdersProps[]
                    >((acc, el) => {
                        if (
                            orders?.findIndex(
                                order => order.orderId === el.orderId
                            ) === -1
                        ) {
                            candlestickSeries.removePriceLine(el.line)
                            return acc
                        }
                        return [...acc, el]
                    }, [])

                    const newLines = orders?.reduce<ChartLinesOrdersProps[]>(
                        (acc, el) => {
                            if (
                                prevState?.orders?.findIndex(
                                    order => order.orderId === el.orderId
                                ) === -1
                            ) {
                                return [
                                    ...acc,
                                    {
                                        line: candlestickSeries.createPriceLine(
                                            {
                                                price: el.price,
                                                color:
                                                    el.side ===
                                                    BybitOrderSideTypes.Sell
                                                        ? 'red'
                                                        : 'green',
                                                lineWidth: 1,
                                                title: '',
                                                axisLabelVisible: true,
                                                lineStyle:
                                                    LineStyle.LargeDashed,
                                                lineVisible: true,
                                            }
                                        ),
                                        orderId: el.orderId,
                                    },
                                ]
                            }

                            return acc
                        },
                        []
                    )

                    return {
                        ...prevState,
                        orders: [...(currentLines || []), ...(newLines || [])],
                    }
                })
            } else {
                setChartLines(prevState => {
                    if (!prevState) return prevState
                    prevState.orders?.forEach(el => {
                        candlestickSeries.removePriceLine(el.line)
                    })
                    return {
                        ...prevState,
                        orders: undefined,
                    }
                })
            }
        }, [orders, candlestickSeries])

        useLayoutEffect(() => {
            const chartLibWidth = () => {
                if (!chartContainerRef.current) return
                chartLib?.resize(
                    chartContainerRef.current.clientWidth,
                    chartContainerRef.current.clientHeight
                )
            }

            window.addEventListener('resize', chartLibWidth)

            return () => {
                window.removeEventListener('resize', chartLibWidth)
            }
        }, [chartLib])

        useEffect(() => {
            candlestickSeries?.applyOptions({
                priceFormat: {
                    minMove: 0.00001,
                    precision: 5,
                },
            })
        }, [candlestickSeries])

        return (
            <Spin spinning={!candlestickSeries}>
                <div className={s.header}>
                    {symbol && tickerLastPrice && (
                        <h1>{`${symbol.toUpperCase()} ${tickerLastPrice}`}</h1>
                    )}

                    <div className={s.tags}>
                        {TIME_TAGS.map(tag => (
                            <Tag.CheckableTag
                                key={tag.id}
                                checked={period === tag.id}
                                onClick={() => {
                                    onChangeSettings({ period: tag.id })
                                }}
                            >
                                {tag.tagName}
                            </Tag.CheckableTag>
                        ))}
                    </div>
                </div>

                <div ref={chartContainerRef} className={s.container} />
            </Spin>
        )
    }
)
