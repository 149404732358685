import s from './Binance.module.scss'

import React from 'react'
import { BinanceTableFilters } from './BinanceTableFilters'
import { QueryParamsProps } from 'shared/hooks'
import { DealsRequestModel } from 'api/http/models'

/**
 * Формируем заголовок таблицы
 * @param onQueryParams колбэк для сохранения параметров фильтра
 */
export const getTableTitle =
    (
        onQueryParams: React.Dispatch<
            React.SetStateAction<QueryParamsProps<DealsRequestModel>>
        >
    ) =>
    () =>
        (
            <div className={s.title}>
                <h2>Profit table</h2>

                <BinanceTableFilters onQueryParams={onQueryParams} />
            </div>
        )
