import React from 'react'
import { NotImplementedError } from 'core/errors'

import { LoaderContextProps } from './LoaderContext.types'

export const LoaderContext = React.createContext<LoaderContextProps>({
    isLoader: false,
    setLoader: () => {
        throw new NotImplementedError()
    },
})
