import React, {
    FC,
    PropsWithChildren,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react'
import { LoaderContext } from 'core/context/Loader'
import { AccountContext } from './AccountContext'
import { AccountService, AuthService } from 'api/http'
import { AccountModel } from 'api/http/models'
import { TronLink } from 'core/tron'
import { notification } from 'antd'
import { ZERO_ADDRESS } from 'shared/consts'
import { TronAddressesProps } from './AccountContext.types'
import { MAX_FEE_LIMIT, RCFACTORY_ADDRESS } from 'core/tron/tron.consts'
import { useQuery } from 'shared/hooks'
import { getTransactionInfo } from 'shared/utils'

export const AccountContextProvider: FC<PropsWithChildren> = React.memo(
    ({ children }) => {
        const { setLoader } = useContext(LoaderContext)
        const [accountData, setAccountData] = useState<AccountModel>()
        const [tronAddresses, setServiceAddress] = useState<TronAddressesProps>(
            { tron: undefined, service: undefined }
        )
        const query = useQuery()

        /** Авторизация */
        const auth = useCallback(async () => {
            try {
                setLoader(true)

                setAccountData(await AccountService.getMe())
            } catch (e) {
                console.log(e)
            } finally {
                setLoader(false)
            }
        }, [setLoader])

        /** Авторизация через тронлинк */
        const tronLinkAuth = useCallback(async () => {
            try {
                setLoader(true)

                const address = TronLink.base58Address

                if (address && TronLink.tronWeb) {
                    const contract = await TronLink.instanceReferral
                        .contracts(address)
                        .call()
                    if (contract !== ZERO_ADDRESS) {
                        const service =
                            TronLink.tronWeb.address.fromHex(contract)
                        await TronLink.setServiceContract(service)
                        setServiceAddress({ tron: address, service })
                    }

                    const { nonce, isNew } = await AuthService.getNonce({
                        address,
                    })
                    if (isNew) {
                        notification.error({
                            message: 'Auth error',
                            description: 'Unregister address tron',
                        })
                        return
                    }

                    const sign = await TronLink.signMessage(nonce)
                    if (!sign) {
                        return
                    }
                    await AuthService.tronVerify({
                        sign,
                        nonce,
                        address,
                    })
                    setAccountData(await AccountService.getMe())
                }
            } catch (e) {
                console.log(e)
            } finally {
                setLoader(false)
            }
        }, [setLoader])

        /** Регистрация через тронлинк */
        const tronLinkRegister = useCallback(async () => {
            try {
                setLoader(true)

                const address = TronLink.base58Address

                if (address && TronLink.tronWeb) {
                    let contract = await TronLink.instanceReferral
                        .contracts(address)
                        .call()
                    if (contract === ZERO_ADDRESS) {
                        const referrerAddress = localStorage.getItem('rc_ref')
                        try {
                            contract = await TronLink.instanceReferral
                                .mint(
                                    TronLink.tronWeb?.isAddress(referrerAddress)
                                        ? referrerAddress
                                        : RCFACTORY_ADDRESS
                                )
                                .send({
                                    shouldPollResponse: true,
                                    feeLimit: MAX_FEE_LIMIT,
                                })
                        } catch (e: any) {
                            console.log(await getTransactionInfo(e))
                            return
                        }
                    }
                    const service = TronLink.tronWeb.address.fromHex(contract)
                    await TronLink.setServiceContract(service)
                    setServiceAddress({ tron: address, service })

                    const { nonce, isNew } = await AuthService.getNonce({
                        address,
                    })
                    if (!isNew) {
                        notification.error({
                            message: 'Registration error',
                            description: 'Tron address is exist',
                        })
                        return
                    }

                    const sign = await TronLink.signMessage(nonce)
                    if (!sign) {
                        return
                    }
                    await AuthService.tronVerify({
                        sign,
                        nonce,
                        address,
                        referrerAddress: localStorage.getItem('rc_ref'),
                    })
                    localStorage.removeItem('rc_ref')
                    setAccountData(await AccountService.getMe())
                }
            } catch (e) {
                console.log(e)
            } finally {
                setLoader(false)
            }
        }, [setLoader])

        useEffect(() => {
            if (accountData?.id) return

            auth()
        }, [accountData, auth])

        useEffect(() => {
            const referrer = query.get('ref')
            if (!referrer) return

            localStorage.setItem('rc_ref', referrer)
        }, [query])

        useEffect(() => {
            if (TronLink.base58Address) {
                return
            }

            let counter = 0
            setLoader(true)
            const intervalId = setInterval(() => {
                counter++
                console.log(`Connecting TronLink ${counter}...`)

                if (window?.tronLink) {
                    clearInterval(intervalId)
                    TronLink.initTronWeb()
                        .then(instance => {
                            if (instance?.base58Address) {
                                setLoader(true)
                                TronLink.instanceReferral
                                    .contracts(instance?.base58Address)
                                    .call()
                                    .then((contract: string) => {
                                        if (contract !== ZERO_ADDRESS) {
                                            const service =
                                                instance?.tronWeb?.address.fromHex(
                                                    contract
                                                )
                                            if (service) {
                                                setLoader(true)
                                                TronLink.setServiceContract(
                                                    service
                                                ).finally(() => {
                                                    setLoader(false)
                                                    setServiceAddress({
                                                        tron: instance.base58Address,
                                                        service,
                                                    })
                                                })
                                            } else {
                                                setServiceAddress({
                                                    tron: instance.base58Address,
                                                })
                                            }
                                        }
                                    })
                                    .finally(() => {
                                        setLoader(false)
                                    })
                                console.log('TronLink is connected')
                            }
                        })
                        .finally(() => {
                            setLoader(false)
                        })
                } else if (counter === 3) {
                    clearInterval(intervalId)
                    setLoader(false)
                    console.log('TronLink is not connected')
                }
            }, 1000)
        }, [setLoader])

        return (
            <AccountContext.Provider
                value={{
                    accountData,
                    tronLinkRegister,
                    auth,
                    tronLinkAuth,
                    tronAddresses,
                }}
            >
                {children}
            </AccountContext.Provider>
        )
    }
)
