import s from './Staking.module.scss'

import React, { useCallback, useContext, useEffect, useState } from 'react'
import { StakingProps } from './Staking.types'
import { Button, Col, Form, Row, Space } from 'antd'
import {
    MAX_FEE_LIMIT,
    RCBC_PRECISION,
    RCSTAKING_ADDRESS,
} from 'core/tron/tron.consts'
import {
    BlockWrapper,
    CheckboxControl,
    InputNumberControl,
    QuestionIconTip,
    TextWithRefresh,
} from 'shared/components'
import { LoaderContext } from 'core/context'
import { fixedNumber, noExponents, removeZeroInStringEnd } from 'shared/utils'
import { TronLink } from 'core/tron'
import { Store } from 'antd/lib/form/interface'
import cn from 'classnames'

/** Стейкинг */
export const Staking: React.FC<StakingProps> = React.memo(
    ({ serviceData, onServiceStake, onServiceBalance, tronAddresses }) => {
        const { setLoader } = useContext(LoaderContext)
        const [stakedAt, setStakedAt] = useState<number>()
        const [stakeAward, setStakeAward] = useState<number>()
        const [isPartialWithdrawal, setPartialWithdrawal] = useState<boolean>()

        /** Посчитать вес стейка */
        const handleStakedAt = useCallback(async () => {
            try {
                setLoader(true)

                setStakedAt(await TronLink.instanceService.stakedAt().call())
            } catch (e) {
                console.log(e)
            } finally {
                setLoader(false)
            }
        }, [setLoader])

        /** Застейкать */
        const handleFinish = useCallback(
            async (values: Store) => {
                try {
                    setLoader(true)

                    const valueArr = noExponents(values.rcbc).split('.')
                    let valuePrecision = valueArr[1] || ''
                    if (valuePrecision.length < RCBC_PRECISION) {
                        valuePrecision =
                            valuePrecision +
                            '0'.repeat(RCBC_PRECISION - valuePrecision.length)
                    }
                    const callValue = valueArr[0] + valuePrecision

                    const dataSource = await TronLink.instanceRcbc
                        .approve(tronAddresses.service, callValue)
                        .send({
                            shouldPollResponse: true,
                            feeLimit: MAX_FEE_LIMIT,
                        })

                    if (dataSource) {
                        await TronLink.instanceService
                            .lockStake(callValue)
                            .send({
                                shouldPollResponse: true,
                                feeLimit: MAX_FEE_LIMIT,
                            })
                        await onServiceStake()
                        await onServiceBalance()
                        await handleStakedAt()
                    }
                } catch (e) {
                    console.log(e)
                } finally {
                    setLoader(false)
                }
            },
            [
                setLoader,
                onServiceStake,
                onServiceBalance,
                tronAddresses.service,
                handleStakedAt,
            ]
        )

        /** Вернуть стейк */
        const handleUnlockStake = useCallback(async () => {
            try {
                setLoader(true)

                await TronLink.instanceService
                    .unlockStake()
                    .send({ shouldPollResponse: true, feeLimit: MAX_FEE_LIMIT })
                await onServiceStake()
                await onServiceBalance()
                await handleStakedAt()
            } catch (e) {
                console.log(e)
            } finally {
                setLoader(false)
            }
        }, [setLoader, onServiceStake, onServiceBalance, handleStakedAt])

        /** Посчитать вес стейка */
        const handleCalculatedStake = useCallback(async () => {
            try {
                setLoader(true)

                await TronLink.instanceService
                    .calcStakeScore()
                    .send({ shouldPollResponse: true, feeLimit: MAX_FEE_LIMIT })
            } catch (e) {
                console.log(e)
            } finally {
                setLoader(false)
            }
        }, [setLoader])

        /** Вернуть вес стейка */
        const handleStakeAward = useCallback(async () => {
            try {
                setLoader(true)

                const dataSource = await TronLink.instanceStaking
                    .getStakeWeight(isPartialWithdrawal, tronAddresses.service)
                    .call()
                const stakingBalance = await TronLink.instanceRcbc
                    .balanceOf(RCSTAKING_ADDRESS)
                    .call()
                const award = fixedNumber(
                    removeZeroInStringEnd(
                        (
                            (stakingBalance * dataSource) /
                            10000000000000000000000
                        ).toString()
                    )
                )
                setStakeAward(award)
            } catch (e) {
                console.log(e)
            } finally {
                setLoader(false)
            }
        }, [setLoader, tronAddresses.service, isPartialWithdrawal])

        /** Снять вознаграждение по стейку */
        const handleGetStake = useCallback(async () => {
            try {
                setLoader(true)

                if (isPartialWithdrawal) {
                    await TronLink.instanceStaking.getStake().send({
                        shouldPollResponse: true,
                        feeLimit: MAX_FEE_LIMIT,
                    })
                } else {
                    await TronLink.instanceStaking.cascadeStake().send({
                        shouldPollResponse: true,
                        feeLimit: MAX_FEE_LIMIT,
                    })
                }
                await handleStakeAward()
                await onServiceBalance()
            } catch (e) {
                console.log(e)
            } finally {
                setLoader(false)
            }
        }, [handleStakeAward, onServiceBalance, isPartialWithdrawal, setLoader])

        /** Включить частичный режим снятия стейкинга */
        const handleTogglePartialMode = useCallback(async () => {
            try {
                setLoader(true)

                await TronLink.instanceStaking
                    .togglePartialMode()
                    .send({ shouldPollResponse: true, feeLimit: MAX_FEE_LIMIT })
                setPartialWithdrawal(true)
            } catch (e) {
                console.log(e)
            } finally {
                setLoader(false)
            }
        }, [setLoader])

        useEffect(() => {
            if (stakedAt !== undefined || isPartialWithdrawal !== undefined)
                return

            TronLink.instanceStaking
                .partialWithdrawal()
                .call()
                .then((res: boolean) => {
                    setPartialWithdrawal(res)
                    handleStakedAt()
                })
        }, [handleStakedAt, isPartialWithdrawal, stakedAt])

        useEffect(() => {
            if (isPartialWithdrawal === undefined || stakeAward !== undefined)
                return

            handleStakeAward()
        }, [handleStakeAward, isPartialWithdrawal, stakeAward])

        return (
            <BlockWrapper className={s.wrapper}>
                <Space className="mb-10">
                    <h2>Staking</h2>

                    <QuestionIconTip title="Stake your RCBC tokens to take part in distribution of 50% of market commission. The profit depends on the total daily commission profit of all ecosystem services and on the amount of RCBC tokens you have staked and the staking time." />
                </Space>

                <Row gutter={20} className="mb-0">
                    <Col xs={24} sm={8} className={s.calCol}>
                        <TextWithRefresh
                            text={`Staking amount: ${serviceData.stake} RCBC`}
                            onCallback={onServiceStake}
                        />

                        <TextWithRefresh
                            text={`Reward for staking: ${stakeAward || 0} RCBC`}
                            onCallback={handleStakeAward}
                        />

                        <TextWithRefresh
                            text={`Staking since: ${stakedAt || 0}`}
                            onCallback={handleStakedAt}
                        />
                    </Col>

                    <Col xs={24} sm={8}>
                        <Form onFinish={handleFinish} layout="vertical">
                            <Form.Item name="rcbc" rules={[{ required: true }]}>
                                <InputNumberControl
                                    min={0.000000000000000001}
                                    step={1}
                                    precision={RCBC_PRECISION}
                                    placeholder="1,000,000"
                                    addonAfter="RCBC"
                                />
                            </Form.Item>

                            <Button
                                type="primary"
                                htmlType="submit"
                                className={cn(s.btn, 'mb-10')}
                            >
                                Lock stake
                            </Button>
                        </Form>

                        <Space>
                            <Button
                                onClick={handleCalculatedStake}
                                disabled={!serviceData.stake}
                                className={s.btn}
                            >
                                Calculate staking
                            </Button>

                            <QuestionIconTip title="For stake calculations, if you want to calculate it. You can skip this step if you're not about to claim your staking rewards this time" />
                        </Space>
                    </Col>

                    <Col xs={24} sm={8}>
                        <CheckboxControl
                            checked={isPartialWithdrawal}
                            disabled={isPartialWithdrawal}
                            onClick={handleTogglePartialMode}
                            className="mb-10"
                        >
                            Enable partial mode
                        </CheckboxControl>

                        <Button
                            onClick={handleGetStake}
                            className={cn(s.btn, 'mb-10')}
                        >
                            {`Claim reward${
                                isPartialWithdrawal ? ' (partial mode)' : ''
                            }`}
                        </Button>

                        <Button
                            onClick={handleUnlockStake}
                            disabled={!serviceData.stake}
                            className={s.btn}
                        >
                            Unlock staking
                        </Button>
                    </Col>
                </Row>
            </BlockWrapper>
        )
    }
)
