import s from './Bybit.module.scss'

import React, { useCallback, useContext, useEffect, useState } from 'react'
import { BlockWrapper, PageContent, TableAdapter } from 'shared/components'
import { Col, Row } from 'antd'
import { AutoTrade } from './AutoTrade'
import { Keys } from './Keys'
import { Statistic } from './Statistic'
import {
    BaseSearchResponseModel,
    BybitDealDto,
    BybitDealsPeriodStatisticDto,
    BybitDealsSearchDto,
} from 'api/http/models'
import { DEAL_TABLE_COLUMNS, QUERY_PARAMS_INITIAL_VALUES } from './Bybit.consts'
import { getTableTitle } from './Bybit.utils'
import { useFetch, useQueryParams } from 'shared/hooks'
import { AccountContext, LoaderContext } from 'core/context'
import { BybitDealsService, BybitService } from 'api/http'

/** Торговля робота на Bybit */
export const Bybit: React.FC = () => {
    const { accountData } = useContext(AccountContext)
    const { setLoader } = useContext(LoaderContext)
    const [getMarketData, marketData] = useFetch(BybitService.getByAccount)
    const [fetchStatisticSummary, statisticSummary] = useFetch(
        BybitDealsService.getStatisticSummary
    )
    const [deals, setDeals] = useState<BaseSearchResponseModel<BybitDealDto>>()
    const [periodStatistic, setPeriodStatistic] = useState<number>()

    const { paginationOptions, setQueryParams, queryParams } = useQueryParams<
        Omit<BybitDealsSearchDto, 'accountId'>
    >(QUERY_PARAMS_INITIAL_VALUES)

    const handleMarketFetch = useCallback(async () => {
        if (accountData?.id) {
            await getMarketData(accountData.id)
        }
    }, [accountData?.id, getMarketData])

    const handleFetchDeals = useCallback(async () => {
        try {
            setLoader(true)

            if (accountData?.id) {
                const { body, limit, skip } = queryParams

                setDeals(
                    await BybitDealsService.search(skip, limit, {
                        ...body,
                        accountId: accountData.id,
                    } as BybitDealsSearchDto)
                )

                if (body.startDate) {
                    setPeriodStatistic(
                        await BybitDealsService.getPeriodStatistic(
                            accountData.id,
                            body as BybitDealsPeriodStatisticDto
                        )
                    )
                }
            }
        } catch (e) {
            console.log(e)
        } finally {
            setLoader(false)
        }
    }, [accountData?.id, queryParams, setLoader])

    useEffect(() => {
        handleMarketFetch()
        handleFetchDeals()
        if (accountData?.id) {
            fetchStatisticSummary(accountData.id)
        }
    }, [
        handleMarketFetch,
        handleFetchDeals,
        fetchStatisticSummary,
        accountData?.id,
    ])

    return (
        <PageContent>
            <BlockWrapper className={s.notification}>
                <b>Important!</b> Do not interfere with the algorithm after its launch.
                Interference in the trading process may lead to undesirable consequences.
                Do not restart the algorithm if the open position volume is higher than the initial one,
                do not cancel or set orders on the exchange manually. Even if you know what you are doing,
                please consult us first on any issue by emailing <a className={s.notification} href="mailto:mail@realcolibri.com">mail@realcolibri.com</a>.
                RealColibri is not responsible for any actions taken on your trading account while the
                algorithm is running without first consulting us upon written request.
            </BlockWrapper>

            <Row gutter={15}>
                <Col xs={24} md={16} xl={9}>
                    <BlockWrapper className={s.card}>
                        <h2 className="mb-10">Algorithm control</h2>

                        <div>
                            Optimal deposit size for running the algorithm: x3.75 of the minimum deposit.
                            When the optimal deposit size is reached, the calculation of compound interest is enabled,
                            which will increase the average monthly profit in proportion to the growth of the deposit size.
                            Every 5% of profit increases the position volume by 5%.
                        </div>

                        <AutoTrade
                            marketData={marketData}
                            onMarketFetch={handleMarketFetch}
                        />
                    </BlockWrapper>
                </Col>

                <Col xs={24} md={8} xl={5}>
                    <Keys
                        className={s.card}
                        marketData={marketData}
                        onMarketFetch={handleMarketFetch}
                    />
                </Col>

                <Col xs={24} md={24} xl={10}>
                    <Statistic
                        className={s.card}
                        statisticSummary={statisticSummary}
                        periodStatistic={periodStatistic}
                    />
                </Col>
            </Row>

            <TableAdapter
                title={getTableTitle(setQueryParams)}
                columns={DEAL_TABLE_COLUMNS}
                dataSource={deals?.items}
                pagination={{
                    ...paginationOptions,
                    total: deals?.total,
                }}
            />
        </PageContent>
    )
}
