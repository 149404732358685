import React, { useCallback, useContext, useEffect, useState } from 'react'
import { PageContent, TableAdapter } from 'shared/components'
import { LoaderContext } from 'core/context'
import { FeeResponseModel, FeeUpdateRequestModel } from 'api/http/models'
import { useQueryParams } from 'shared/hooks'
import { BinanceFeeService } from 'api/http'
import { Form } from 'antd'
import { getBinanceFeeTableColumns } from './BinanceFee.utils'
import { BinanceFeeCell } from './BinanceFeeCell'

/** Таблица списаний комиссии робота у клиентов на Бинансе */
export const BinanceFee: React.FC = React.memo(() => {
    const { setLoader } = useContext(LoaderContext)
    const [form] = Form.useForm()
    const [editingRowId, setEditingRowId] = useState<number>()
    const [feeData, setFeeData] = useState<FeeResponseModel[]>()
    const { paginationOptions, queryParams } = useQueryParams()
    const [itemsCount, setItemsCount] = useState<number>(0)

    /**
     * Проверка, является ли строка редактируемой в текущий момент
     */
    const isEditing = (record: FeeResponseModel) => record.id === editingRowId

    /**
     * Обработчик отмены редактирования строки таблицы
     */
    const handleCancel = useCallback(
        (page?: number, pageSize?: number) => {
            form.resetFields()
            setEditingRowId(undefined)
            if (page && pageSize) {
                paginationOptions.onChange?.(page, pageSize)
            }
        },
        [form, paginationOptions]
    )

    /**
     * Обработчик редактирования строки таблицы
     * @param values редактируемая запись
     */
    const handleEdit = useCallback(
        ({ status, comment, id }: FeeResponseModel) => {
            form.setFieldsValue({
                status,
                comment,
            })
            setEditingRowId(id)
        },
        [form]
    )

    /**
     * Запрос за комиссией пользователей
     */
    const fetchFee = useCallback(async () => {
        try {
            setLoader(true)

            const { limit, skip } = queryParams

            const dataSource = await BinanceFeeService.search(skip, limit)

            setFeeData(dataSource.items)
            setItemsCount(dataSource.total)
        } catch (e) {
            console.log(e)
        } finally {
            setLoader(false)
        }
    }, [setLoader, queryParams])

    /**
     * Обработчик отправки данных на бэк
     * @param values отправляемые значения формы
     */
    const handleFinish = useCallback(
        async (values: Omit<FeeUpdateRequestModel, 'id'>) => {
            try {
                setLoader(true)

                if (!editingRowId) return

                await BinanceFeeService.update({ id: editingRowId, ...values })
                await fetchFee()
                handleCancel()
            } catch (e) {
                console.log(e)
            } finally {
                setLoader(false)
            }
        },
        [setLoader, handleCancel, editingRowId, fetchFee]
    )

    useEffect(() => {
        fetchFee()
    }, [fetchFee])

    return (
        <PageContent>
            <h2 className="mb-20">Fees on Binance</h2>

            <Form form={form} onFinish={handleFinish}>
                <TableAdapter
                    components={{
                        body: {
                            cell: BinanceFeeCell,
                        },
                    }}
                    columns={getBinanceFeeTableColumns(
                        isEditing,
                        handleEdit,
                        handleCancel
                    )}
                    dataSource={feeData}
                    pagination={{
                        ...paginationOptions,
                        onChange: handleCancel,
                        total: itemsCount,
                    }}
                />
            </Form>
        </PageContent>
    )
})
