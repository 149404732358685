import s from './About.module.scss';

import React, { useContext } from 'react';
import { BlockWrapper, PopupAdapter, RegisterForm } from 'shared/components';
import { Button, Collapse, Space } from 'antd';
import { getBlockchainLinks } from './About.utils';
import { Partners } from '../Partners';
import { AccountContext } from 'core/context';
import { useLanguage } from 'context/LanguageContext';
import { translations } from './translations';

const { Panel } = Collapse;

/** Блок "узнать больше" для главной страницы */
export const About: React.FC = React.memo(() => {
    const { accountData } = useContext(AccountContext);
    const { language } = useLanguage();

    const t = translations[language].about;

    return (
        <div>
            <BlockWrapper className={s.container} id="about">
                <h2 className={s.title}>{t.learnMore}</h2>

                <Collapse bordered={false} accordion className={s.collapse} expandIconPosition="end">
                    <Panel header={t.autotrading.header} key="trade" className={s.trade}>
                        <div className={s.call}>
                            <b>{t.autotrading.welcome}</b>
                        </div>

                        <p>{t.autotrading.description}</p>

                        <p><b>{t.autotrading.unique}</b></p>

                        <ul className={s.list}>
                            {t.autotrading.list.map((item, index) => (
                                <li key={index}>{item}</li>
                            ))}
                        </ul>

                        <Partners />

                        <br/>

                        <Space wrap style={{ display: 'flex', justifyContent: 'center' }}>
                            {!accountData?.id && (
                                <PopupAdapter
                                    component={RegisterForm}
                                    formId="regForm"
                                    buttonText="START NOW"
                                    modalOptions={{
                                        width: 800,
                                        footer: null,
                                        maskClosable: true,
                                    }}
                                    buttonOption={{
                                        className: s.regbutton,
                                    }}
                                />
                            )}
                        </Space>
                    </Panel>

                    <Panel header={t.realColibriCoin.header} key="coin" className={s.coin}>
                        <div className={s.call}>
                            <b>{t.realColibriCoin.welcome}</b>
                        </div>

                        <p>{t.realColibriCoin.description}</p>

                        <ul className={s.list}>
                            {t.realColibriCoin.list.map((item, index) => (
                                <li key={index}>{item}</li>
                            ))}
                        </ul>

                        <p><b>{t.realColibriCoin.whatCanIDo}</b></p>

                        <ul className={s.list}>
                            {t.realColibriCoin.actions.map((item, index) => (
                                <li key={index}>{item}</li>
                            ))}
                        </ul>

                        <div className={s.call}>
                            <b>{t.realColibriCoin.joinNow}</b>
                        </div>

                        <Space wrap style={{ display: 'flex', justifyContent: 'center' }}>
                            {!accountData?.id && (
                                <PopupAdapter
                                    component={RegisterForm}
                                    formId="regForm"
                                    buttonText="START NOW"
                                    modalOptions={{
                                        width: 800,
                                        footer: null,
                                        maskClosable: true,
                                    }}
                                    buttonOption={{
                                        className: s.regbutton,
                                    }}
                                />
                            )}
                        </Space>
                        <br/>
                        <Space wrap style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button
                                href={getBlockchainLinks().token}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Token contract address
                            </Button>
                        </Space>
                    </Panel>

                    <Panel header={t.referralSystem.header} key="ref" className={s.ref}>
                        <div className={s.call}>
                            <b>{t.referralSystem.welcome}</b>
                        </div>

                        <p>{t.referralSystem.description}</p>

                        <p><b>{t.referralSystem.howItWorks}</b></p>

                        <ul className={s.list}>
                            {t.referralSystem.list.map((item, index) => (
                                <li key={index}>{item}</li>
                            ))}
                        </ul>

                        <div className={s.call}>
                            <b>{t.referralSystem.joinNow}</b>
                        </div>

                        <Space wrap style={{ display: 'flex', justifyContent: 'center' }}>
                            {!accountData?.id && (
                                <PopupAdapter
                                    component={RegisterForm}
                                    formId="regForm"
                                    buttonText="START NOW"
                                    modalOptions={{
                                        width: 800,
                                        footer: null,
                                        maskClosable: true,
                                    }}
                                    buttonOption={{
                                        className: s.regbutton,
                                    }}
                                />
                            )}
                        </Space>
                        <br/>
                        <Space wrap style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button
                                href={getBlockchainLinks().factory}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Referral contract address
                            </Button>
                        </Space>
                    </Panel>

                    <Panel header={t.staking.header} key="stak" className={s.stak}>
                        <div className={s.call}>
                            <b>{t.staking.welcome}</b>
                        </div>

                        <p>{t.staking.description}</p>

                        <p><b>{t.staking.whatIsStaking}</b></p>

                        <p>{t.staking.stakingDescription}</p>

                        <p><b>{t.staking.howItWorks}</b></p>

                        <p>{t.staking.howItWorksDescription}</p>

                        <p><b>{t.staking.benefits}</b></p>

                        <ul className={s.list}>
                            {t.staking.list.map((item, index) => (
                                <li key={index}>{item}</li>
                            ))}
                        </ul>

                        <div className={s.call}>
                            <b>{t.staking.joinNow}</b>
                        </div>

                        <Space wrap style={{ display: 'flex', justifyContent: 'center' }}>
                            {!accountData?.id && (
                                <PopupAdapter
                                    component={RegisterForm}
                                    formId="regForm"
                                    buttonText="START NOW"
                                    modalOptions={{
                                        width: 800,
                                        footer: null,
                                        maskClosable: true,
                                    }}
                                    buttonOption={{
                                        className: s.regbutton,
                                    }}
                                />
                            )}
                        </Space>
                        <br/>
                        <Space wrap style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button
                                href={getBlockchainLinks().staking}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Staking contract address
                            </Button>
                        </Space>
                    </Panel>
                </Collapse>

                <div style={{ borderRadius: 20, borderColor: '#dcdcdc', borderWidth: 1, borderStyle: 'solid' }}>
                    <h1 className={s.faqheader}>{t.faq.header}</h1>

                    <Collapse style={{ borderRadius: 5, textAlign: 'left', backgroundColor: '#f0f5fa' }} accordion>
                        {t.faq.questions.map((q, index) => (
                            <Panel header={<span style={{ fontWeight: 'bold' }}>{q.question}</span>} key={index}>
                                <p dangerouslySetInnerHTML={{ __html: q.answer }} />
                            </Panel>
                        ))}
                    </Collapse>
                </div>
            </BlockWrapper>
        </div>
    );
});
