import React, { useCallback, useContext, useEffect } from 'react'
import { SelectControl } from 'shared/components'
import { Button, Form } from 'antd'
import { BinanceActionsProps } from './BinanceActions.types'
import { BinanceService } from 'api/http'
import { LoaderContext } from 'core/context'

/** Блок с действиями для таблицы аккаунтов */
export const BinanceActions: React.FC<BinanceActionsProps> = React.memo(
    ({ account, onFetchAccounts, symbols }) => {
        const { setLoader } = useContext(LoaderContext)
        const [form] = Form.useForm()

        /** Обработчик формы */
        const handleFinish = useCallback(
            async ({ symbol }: { symbol: number }) => {
                try {
                    setLoader(true)

                    if (account.binance?.robotId) {
                        await BinanceService.stop(account.binance.id)
                    } else if (account.binance) {
                        await BinanceService.start(account.binance?.id, {
                            symbolId: symbol,
                        })
                    }

                    onFetchAccounts()
                } catch (e) {
                    console.log(e)
                } finally {
                    setLoader(false)
                }
            },
            [setLoader, account, onFetchAccounts]
        )

        useEffect(() => {
            form.setFieldsValue({
                symbol: account.binance?.symbolId || symbols?.[0]?.id,
            })
        }, [account.binance?.symbolId, form.setFieldsValue, symbols])

        return (
            <Form form={form} onFinish={handleFinish}>
                <Form.Item name="symbol">
                    <SelectControl
                        disabled={
                            !!account.binance?.robotId || !symbols?.length
                        }
                        options={symbols?.map(el => ({
                            value: el.id,
                            key: el.id,
                            label: el.name,
                        }))}
                    />
                </Form.Item>

                <Button
                    block
                    htmlType="submit"
                    type="primary"
                    danger={!!account.binance?.robotId}
                    disabled={!account.binance || !symbols?.length}
                >
                    {account.binance?.robotId ? 'Отключить' : 'Включить'}
                </Button>
            </Form>
        )
    }
)
