export interface BinanceMessageResponseModel {
    code: number
    msg: string
}

export interface BinanceTickersModel {
    symbol: string
    priceChange: string
    priceChangePercent: string
    weightedAvgPrice: string
    prevClosePrice: number
    lastPrice: string
    lastQty: string
    openPrice: string
    highPrice: string
    lowPrice: string
    volume: string
    quoteVolume: string
    openTime: number
    closeTime: number
    firstId: number // First tradeId
    lastId: number // Last tradeId
    count: number
}

export interface BinanceAccountModel {
    accountAlias: string // unique account code
    asset: string // asset name
    balance: string // wallet balance
    crossWalletBalance: string // crossed wallet balance
    crossUnPnl: string // unrealized profit of crossed positions
    availableBalance: string // available balance
    maxWithdrawAmount: string
}

export interface BinanceDealHistoryRequestModel {
    symbol: string
    market_id: number
    startTime?: number
}

export interface BinanceDealHistoryResponseModel {
    buyer: boolean
    commission: string
    commissionAsset: string
    id: number
    maker: boolean
    orderId: number
    price: string
    qty: string
    quoteQty: string
    realizedPnl: string
    side: BinanceOrderSideTypes
    positionSide: BinancePositionSideTypes
    symbol: string
    time: number
}

export interface BinancePositionsModel {
    entryPrice: string
    marginType: string
    isAutoAddMargin: string
    isolatedMargin: string
    leverage: number
    liquidationPrice: number
    markPrice: number
    maxNotionalValue: number
    positionAmt: string
    symbol: string
    unRealizedProfit: string
    positionSide: BinancePositionSideTypes
}

export interface BinanceSymbolModel {
    market_id: number
    symbol?: string
}

export interface BinanceLeverageBracketResponseModel {
    symbol: string
    brackets: {
        bracket: number // Notional bracket
        initialLeverage: number // Max initial leverage for this bracket
        notionalCap: number // Cap notional of this bracket
        notionalFloor: number // Notional threshold of this bracket
        maintMarginRatio: number // Maintenance ratio for this bracket
        cum: number // Auxiliary number for quick calculation
    }[]
}

export interface BinanceLeverageResponseModel {
    leverage: number
    maxNotionalValue: string
    symbol: string
}

export interface BinanceSetOrderResponseModel {
    clientOrderId: string
    cumQty: string
    cumQuote: string
    executedQty: string
    orderId: number
    avgPrice: string
    origQty: string
    price: string
    reduceOnly: boolean
    side: BinanceOrderSideTypes
    positionSide: BinancePositionSideTypes
    status: BinanceOrderStatusTypes
    stopPrice: string // please ignore when order type is TRAILING_STOP_MARKET
    closePosition: boolean // if Close-All
    symbol: string
    timeInForce: BinanceTimeInForceTypes
    type: BinanceOrderTypes
    origType: BinanceOrderTypes
    activatePrice: string // activation price, only return with TRAILING_STOP_MARKET order
    priceRate: string // callback rate, only return with TRAILING_STOP_MARKET order
    updateTime: number
    workingType: BinanceWorkingTypes
    priceProtect: boolean // if conditional order trigger is protected
}

export interface BinanceSetOrderRequestModel {
    symbol: string
    side: BinanceOrderSideTypes
    orderType: BinanceOrderTypes
    market_id: number
    positionSide?: BinancePositionSideTypes
    timeInForce?: BinanceTimeInForceTypes
    quantity?: number
    reduceOnly?: boolean
    price?: number
    stopPrice?: number
    closePosition?: boolean
}

export interface BinanceCancelOrderRequestModel {
    symbol: string
    order_id: number
    market_id: number
}

export interface BinanceOrdersResponseModel {
    avgPrice: string
    clientOrderId: string
    closePosition: boolean // if Close-All
    cumQuote: string
    executedQty: string
    orderId: number
    origQty: string
    origType: BinanceOrderTypes
    positionSide: BinancePositionSideTypes
    price: string
    priceProtect: boolean
    reduceOnly: boolean
    side: BinanceOrderSideTypes
    status: BinanceOrderStatusTypes
    stopPrice: string // please ignore when order type is TRAILING_STOP_MARKET
    symbol: string
    time: number // order time
    timeInForce: BinanceTimeInForceTypes
    type: string
    updateTime: number // update time
    workingType: BinanceWorkingTypes
    activatePrice: number // activation price, only return with TRAILING_STOP_MARKET order
    priceRate: number // callback rate, only return with TRAILING_STOP_MARKET order
}

export interface BinanceCancelOrderResponseModel {
    clientOrderId: string
    cumQty: string
    cumQuote: string
    executedQty: string
    orderId: number
    origQty: string
    origType: BinanceOrderTypes
    price: string
    reduceOnly: false
    side: BinanceOrderSideTypes
    positionSide: BinancePositionSideTypes
    status: BinanceOrderStatusTypes
    stopPrice: string // please ignore when order type is TRAILING_STOP_MARKET
    closePosition: false // if Close-All
    symbol: string
    timeInForce: BinanceTimeInForceTypes
    type: BinanceOrderTypes
    activatePrice: string // activation price, only return with TRAILING_STOP_MARKET order
    priceRate: string // callback rate, only return with TRAILING_STOP_MARKET order
    updateTime: number
    workingType: BinanceWorkingTypes
    priceProtect: false // if conditional order trigger is protected
}

export interface BinanceGetSymbolsForSelectResponseModel {
    symbol: string
    price_precision: number
    quantity_precision: number
}

export interface BinanceSymbolsCreateRequestModel {
    name: string
    symbol: string
    step_size: number
    default_position_size: number
    default_max_lots: number
    min_balance: number
    take_profit_ratio: number
    price_digits: number
    quantity_digits: number
    only_admin: boolean
}

export interface BinanceSymbolsUpdateRequestModel
    extends BinanceSymbolsCreateRequestModel {
    id: number
}

export interface BinanceSymbolsResponseModel
    extends BinanceSymbolsUpdateRequestModel {
    create_date: number
}

export interface BinanceSymbolsForSelectResponseModel {
    id: number
    name: string
    symbol: string
    min_balance: number
    price_digits: number
    only_admin: boolean
}

export interface BinanceSymbolsUpdateResponseModel {
    user_id: number
    error: string
}

export interface BinanceSymbolsDeleteRequestModel {
    id: number
    new_symbol_id: number
}

export enum BinanceOrderSideTypes {
    BUY = 'BUY',
    SELL = 'SELL',
}

export enum BinanceOrderStatusTypes {
    NEW = 'NEW',
    PARTIALLY_FILLED = 'PARTIALLY_FILLED',
    FILLED = 'FILLED',
    CANCELED = 'CANCELED',
    EXPIRED = 'EXPIRED',
    NEW_INSURANCE = 'NEW_INSURANCE ',
    NEW_ADL = 'NEW_ADL ',
}

export enum BinanceWorkingTypes {
    CONTRACT_PRICE = 'CONTRACT_PRICE',
    MARK_PRICE = 'MARK_PRICE',
}

export enum BinanceOrderTypes {
    LIMIT = 'LIMIT',
    MARKET = 'MARKET',
    STOP = 'STOP',
    STOP_MARKET = 'STOP_MARKET',
    TAKE_PROFIT = 'TAKE_PROFIT',
    TAKE_PROFIT_MARKET = 'TAKE_PROFIT_MARKET',
    TRAILING_STOP_MARKET = 'TRAILING_STOP_MARKET',
}

export enum BinancePositionSideTypes {
    BOTH = 'BOTH',
    LONG = 'LONG',
    SHORT = 'SHORT',
}

export enum BinanceTimeInForceTypes {
    GTC = 'GTC',
    IOC = 'IOC',
    FOK = 'FOK',
    GTX = 'GTX',
}

export enum BinanceCandlesIntervalTypes {
    min = '1m',
    min3 = '3m',
    min5 = '5m',
    min15 = '15m',
    min30 = '30m',
    hour1 = '1h',
    hour2 = '2h',
    hour4 = '4h',
    hour6 = '6h',
    hour8 = '8h',
    hour12 = '12h',
    day1 = '1d',
    day3 = '3d',
    week1 = '1w',
    mon1 = '1M',
}
