import s from './Keys.module.scss'

import React from 'react'
import { getKeysActions } from './Keys.utils'
import { BlockWrapper } from 'shared/components'
import { KeysProps } from './Keys.types'
import cn from 'classnames'

/** Блок с ключами */
export const Keys: React.FC<KeysProps> = React.memo(
    ({ className, marketData, onMarketFetch }) => {
        return (
            <BlockWrapper className={className}>
                <div className={s.title}>
                    <h2>Keys</h2>

                    {getKeysActions(onMarketFetch, marketData)}
                </div>

                <a
                    href="https://www.bybit.com/invite?ref=LB825P"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    bybit.com
                </a>

                <div className={cn(s.text, 'mt-15')}>
                    <span className={s.label}>Login:</span>

                    <span className={s.key}>
                        {marketData?.accessKey || '-'}
                    </span>
                </div>

                <div className={s.text}>
                    <span className={s.label}>Password:</span>

                    <span className={s.key}>
                        {marketData?.secretKey || '-'}
                    </span>
                </div>
            </BlockWrapper>
        )
    }
)
