import { useCallback, useContext, useState } from 'react'
import { AxiosError } from 'axios'
import { HTTPValidationError } from 'api/http/models'
import { LoaderContext } from 'core/context'

/** тип результата работы хука useHttp, где Request - тип параметров, Response - тип ответа */
export type UseHttpResultType<Response, Request extends any[]> = [
    (...values: Request) => Promise<void>,
    Response | undefined,
    AxiosError<HTTPValidationError> | undefined
]

/**
 * Хук useFetch используется для запроса метода api, возвращает результат или ошибки
 * @param httpMethod - метод запроса данных, который предоставляет api
 */
export const useFetch = <Response, Request extends any[]>(
    httpMethod: (...args: Request) => Promise<Response>
): UseHttpResultType<Response, Request> => {
    const { setLoader } = useContext(LoaderContext)
    const [data, setData] = useState<Response>()
    const [error, setError] = useState<AxiosError<HTTPValidationError>>()

    /** Получение данных */
    const getData = useCallback(
        async (...params: Request) => {
            try {
                setLoader(true)
                setData(await httpMethod(...params))
            } catch (error) {
                setError(error as AxiosError<HTTPValidationError>)
            } finally {
                setLoader(false)
            }
        },
        [httpMethod, setLoader]
    )

    return [getData, data, error]
}
