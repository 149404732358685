import { FnActionProps, FnActionRequiredProps } from 'App.types'
import { useCallback, useState } from 'react'

export interface UseElementVisibleResultProps {
    /** Флаг отображения элемента */
    elementVisible: boolean
    /** Обработчик переключения отображения элемента */
    toggleElementVisible: FnActionProps
    /** Обработчик скрытия элемента */
    handleHideElement: FnActionProps
    /** Обработчик показа элемента */
    handleShowElement: FnActionProps
    updateElementVisible: FnActionRequiredProps<boolean>
}

/**
 * Хук для переключения видимости элемента, с учетом клика за пределами
 * переключателя, а также самого элемента
 * @initialValue - начальное положение элемента
 */
export function useElementVisible(
    initialValue = false
): UseElementVisibleResultProps {
    const [elementVisible, setElementVisible] = useState(initialValue)

    const toggleElementVisible = useCallback(() => {
        setElementVisible(elementVisible => !elementVisible)
    }, [])

    const handleHideElement = useCallback(() => {
        setElementVisible(false)
    }, [])

    const handleShowElement = useCallback(() => {
        setElementVisible(true)
    }, [])

    const updateElementVisible = useCallback((visible: boolean) => {
        setElementVisible(visible)
    }, [])

    return {
        elementVisible,
        toggleElementVisible,
        handleHideElement,
        handleShowElement,
        updateElementVisible,
    }
}
