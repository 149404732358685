import s from './WhatIs.module.scss';
import React from 'react';
import { Button, Col, Row } from 'antd';
import { useLanguage } from 'context/LanguageContext'; 
import { translations } from './translations'; 

const WhatIs: React.FC = React.memo(() => {
    const { language } = useLanguage();
    const { title, description, button, info } = translations[language];

    return (
        <div className={s.wrapper}>
            <h1 className={s.title}>{title}</h1>
            <div className={s.description}>{description}</div>
            <Row gutter={20}>
                {info.map(({ text, icon: Icon }, i) => (
                    <Col key={i} xs={24} sm={12} md={6} className={s.col}>
                        <Icon className={s.icon} />
                        <div className={s.text}>{text}</div>
                    </Col>
                ))}
            </Row>
            <Button type="primary" className={s.button} href="#about">
                {button}
            </Button>
        </div>
    );
});

export default WhatIs;

