import React from 'react'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import { TooltipPropsWithTitle } from 'antd/lib/tooltip'

/** Иконка вопроса с подсказкой */
export const QuestionIconTip: React.FC<Omit<TooltipPropsWithTitle, 'trigger'>> =
    React.memo(props => {
        return (
            <Tooltip trigger="click" {...props}>
                <QuestionCircleOutlined />
            </Tooltip>
        )
    })
