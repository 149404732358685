import s from './Guides.module.scss';
import img0 from 'shared/img/guides/0.png';
import img1 from 'shared/img/guides/1.png';
import img2 from 'shared/img/guides/2.png';
import img3 from 'shared/img/guides/3.png';
import img4 from 'shared/img/guides/4.png';
import img5 from 'shared/img/guides/5.png';
import img6 from 'shared/img/guides/6.png';
import img7 from 'shared/img/guides/7.png';
import img8 from 'shared/img/guides/8.png';
import img9 from 'shared/img/guides/9.png';
import img10 from 'shared/img/guides/10.png';
import img11 from 'shared/img/guides/11.png';
import img12 from 'shared/img/guides/12.png';
import img13 from 'shared/img/guides/13.png';
import img14 from 'shared/img/guides/14.png';
import img15 from 'shared/img/guides/15.png';
import img16 from 'shared/img/guides/16.png';
import img17 from 'shared/img/guides/17.png';
import img18 from 'shared/img/guides/18.png';
import img19 from 'shared/img/guides/19.png';
import img20 from 'shared/img/guides/20.png';
import img21 from 'shared/img/guides/21.png';
import img22 from 'shared/img/guides/22.png';
import img23 from 'shared/img/guides/23.png';
import img24 from 'shared/img/guides/24.png';
import img25 from 'shared/img/guides/25.png';
import img26 from 'shared/img/guides/26.png';
import img27 from 'shared/img/guides/27.png';
import img28 from 'shared/img/guides/28.png';
import img29 from 'shared/img/guides/29.png';
import img30 from 'shared/img/guides/30.png';
import img31 from 'shared/img/guides/31.png';
import img32 from 'shared/img/guides/32.png';
import img33 from 'shared/img/guides/33.png';
import img34 from 'shared/img/guides/34.png';
import img35 from 'shared/img/guides/35.jpeg';
import img36 from 'shared/img/guides/36.png';
import img37 from 'shared/img/guides/37.png';
import img38 from 'shared/img/guides/38.png';
import img39 from 'shared/img/guides/39.png';
import img40 from 'shared/img/guides/40.png';
import img41 from 'shared/img/guides/41.png';
import img42 from 'shared/img/guides/42.png';
import img43 from 'shared/img/guides/43.png';
import img44 from 'shared/img/guides/44.png';
import img45 from 'shared/img/guides/45.png';
import React from 'react';
import { VideoIFrame } from 'shared/components';
import { translations } from './translations';
import { GuideContent } from './types';

export const CONTENT: { [key: string]: GuideContent[] } = {
    en: [
        {
            title: translations.en.guides.howToCreateTronLink.title,
            key: 1,
            video: 'https://www.youtube.com/embed/E6RXBr__GZ0',
            text: (
                <ol>
                    {translations.en.guides.howToCreateTronLink.steps.map((step, index) => (
                        <li key={index}>
                            {step}
                            {index === 1 && <img src={img0} alt="img0" />}
                            {index === 2 && <img src={img1} alt="img1" />}
                            {index === 3 && (
                                <>
                                    <img src={img2} alt="img2" />
                                    <img src={img3} alt="img3" />
                                </>
                            )}
                            {index === 4 && <img src={img4} alt="img4" />}
                            {index === 5 && <img src={img5} alt="img5" />}
                            {index === 6 && <img src={img6} alt="img6" />}
                            {index === 7 && (
                                <>
                                    <img src={img7} alt="img7" />
                                    <img src={img8} alt="img8" />
                                </>
                            )}
                            {index === 8 && <img src={img9} alt="img9" />}
                            {index === 9 && <img src={img10} alt="img10" />}
                            {index === 10 && <img src={img11} alt="img11" />}
                        </li>
                    ))}
                    <div className={s.note}>
                        {translations.en.guides.howToCreateTronLink.note}
                    </div>
                </ol>
            ),
        },
        {
            title: translations.en.guides.howToConnectTronLink.title,
            key: 2,
            text: (
                <ol>
                    {translations.en.guides.howToConnectTronLink.steps.map((step, index) => (
                        <li key={index}>
                            {step}
                            {index === 0 && <img src={img12} alt="img12" />}
                            {index === 1 && (
                                <>
                                    <img src={img13} alt="img13" />
                                    <img src={img14} alt="img14" />
                                </>
                            )}
                        </li>
                    ))}
                    <div className={s.note}>
                        {translations.en.guides.howToConnectTronLink.note}
                    </div>
                </ol>
            ),
        },
        {
            title: translations.en.guides.howToAddRCBC.title,
            key: 3,
            text: (
                <ol>
                    {translations.en.guides.howToAddRCBC.steps.map((step, index) => (
                        <li key={index}>
                            {step}
                            {index === 0 && <img src={img40} alt="img40" />}
                            {index === 1 && <img src={img41} alt="img41" />}
                            {index === 2 && <img src={img42} alt="img42" />}
                            {index === 3 && <img src={img43} alt="img43" />}
                            {index === 4 && <img src={img44} alt="img44" />}
                            {index === 5 && <img src={img45} alt="img45" />}
                        </li>
                    ))}
                </ol>
            ),
        },
        {
            title: translations.en.guides.howToGetRCBC.title,
            key: 4,
            subContent: translations.en.guides.howToGetRCBC.subContent.map((sub, subIndex) => ({
                key: sub.key,
                steps: sub.steps,
            })),
        },
        {
            title: translations.en.guides.howToCreateAndConnectBinanceAPI.title,
            key: 10,
            video: 'https://www.youtube.com/embed/8_lNxcCm6mw',
            text: (
                <ol>
                    {translations.en.guides.howToCreateAndConnectBinanceAPI.steps.map((step, index) => (
                        <li key={index}>
                            {step}
                            {index === 2 && <img src={img23} alt="img23" />}
                            {index === 3 && <img src={img24} alt="img24" />}
                            {index === 4 && <img src={img25} alt="img25" />}
                            {index === 5 && <img src={img26} alt="img26" />}
                            {index === 6 && <img src={img27} alt="img27" />}
                            {index === 9 && <img src={img28} alt="img28" />}
                            {index === 10 && <img src={img29} alt="img29" />}
                            {index === 13 && <img src={img30} alt="img30" />}
                            {index === 14 && <img src={img31} alt="img31" />}
                            {index === 15 && <img src={img32} alt="img32" />}
                            {index === 16 && <img src={img33} alt="img33" />}
                        </li>
                    ))}
                    <VideoIFrame
                        src="https://www.youtube.com/embed/-6StLtFLkgE"
                        className={s.video}
                    />
                    <VideoIFrame
                        src="https://www.youtube.com/embed/KXlcgetI15E"
                        className={s.video}
                    />
                </ol>
            ),
        },
        {
            title: translations.en.guides.howToTopUpCommissionBalance.title,
            key: 11,
            text: (
                <ol>
                    {translations.en.guides.howToTopUpCommissionBalance.steps.map((step, index) => (
                        <li key={index}>
                            {step}
                            {index === 1 && <img src={img34} alt="img34" />}
                        </li>
                    ))}
                </ol>
            ),
        },
        {
            title: translations.en.guides.howToInviteFriend.title,
            key: 12,
            text: (
                <ol>
                    {translations.en.guides.howToInviteFriend.steps.map((step, index) => (
                        <li key={index}>
                            {step}
                            {index === 2 && <img src={img35} alt="img35" />}
                        </li>
                    ))}
                </ol>
            ),
        },
        {
            title: translations.en.guides.howToLockRCBC.title,
            key: 13,
            text: (
                <ol>
                    {translations.en.guides.howToLockRCBC.steps.map((step, index) => (
                        <li key={index}>
                            {step}
                            {index === 1 && <img src={img36} alt="img36" />}
                            {index === 2 && <img src={img37} alt="img37" />}
                            {index === 3 && <img src={img38} alt="img38" />}
                            {index === 4 && <img src={img39} alt="img39" />}
                        </li>
                    ))}
                </ol>
            ),
        },
        {
            title: translations.en.guides.calculationsSection.title,
            key: 14,
            text: (
                <ol>
                    {translations.en.guides.calculationsSection.steps.map((step, index) => (
                        <li key={index}>{step}</li>
                    ))}
                </ol>
            ),
        },
    ],
    es: [
        {
            title: translations.es.guides.howToCreateTronLink.title,
            key: 1,
            video: 'https://www.youtube.com/embed/E6RXBr__GZ0',
            text: (
                <ol>
                    {translations.es.guides.howToCreateTronLink.steps.map((step, index) => (
                        <li key={index}>
                            {step}
                            {index === 1 && <img src={img0} alt="img0" />}
                            {index === 2 && <img src={img1} alt="img1" />}
                            {index === 3 && (
                                <>
                                    <img src={img2} alt="img2" />
                                    <img src={img3} alt="img3" />
                                </>
                            )}
                            {index === 4 && <img src={img4} alt="img4" />}
                            {index === 5 && <img src={img5} alt="img5" />}
                            {index === 6 && <img src={img6} alt="img6" />}
                            {index === 7 && (
                                <>
                                    <img src={img7} alt="img7" />
                                    <img src={img8} alt="img8" />
                                </>
                            )}
                            {index === 8 && <img src={img9} alt="img9" />}
                            {index === 9 && <img src={img10} alt="img10" />}
                            {index === 10 && <img src={img11} alt="img11" />}
                        </li>
                    ))}
                    <div className={s.note}>
                        {translations.es.guides.howToCreateTronLink.note}
                    </div>
                </ol>
            ),
        },
        {
            title: translations.es.guides.howToConnectTronLink.title,
            key: 2,
            text: (
                <ol>
                    {translations.es.guides.howToConnectTronLink.steps.map((step, index) => (
                        <li key={index}>
                            {step}
                            {index === 0 && <img src={img12} alt="img12" />}
                            {index === 1 && (
                                <>
                                    <img src={img13} alt="img13" />
                                    <img src={img14} alt="img14" />
                                </>
                            )}
                        </li>
                    ))}
                    <div className={s.note}>
                        {translations.es.guides.howToConnectTronLink.note}
                    </div>
                </ol>
            ),
        },
        {
            title: translations.es.guides.howToAddRCBC.title,
            key: 3,
            text: (
                <ol>
                    {translations.es.guides.howToAddRCBC.steps.map((step, index) => (
                        <li key={index}>
                            {step}
                            {index === 0 && <img src={img40} alt="img40" />}
                            {index === 1 && <img src={img41} alt="img41" />}
                            {index === 2 && <img src={img42} alt="img42" />}
                            {index === 3 && <img src={img43} alt="img43" />}
                            {index === 4 && <img src={img44} alt="img44" />}
                            {index === 5 && <img src={img45} alt="img45" />}
                        </li>
                    ))}
                </ol>
            ),
        },
        {
            title: translations.es.guides.howToGetRCBC.title,
            key: 4,
            subContent: translations.es.guides.howToGetRCBC.subContent.map((sub, subIndex) => ({
                key: sub.key,
                steps: sub.steps,
            })),
        },
        {
            title: translations.es.guides.howToCreateAndConnectBinanceAPI.title,
            key: 10,
            video: 'https://www.youtube.com/embed/8_lNxcCm6mw',
            text: (
                <ol>
                    {translations.es.guides.howToCreateAndConnectBinanceAPI.steps.map((step, index) => (
                        <li key={index}>
                            {step}
                            {index === 2 && <img src={img23} alt="img23" />}
                            {index === 3 && <img src={img24} alt="img24" />}
                            {index === 4 && <img src={img25} alt="img25" />}
                            {index === 5 && <img src={img26} alt="img26" />}
                            {index === 6 && <img src={img27} alt="img27" />}
                            {index === 9 && <img src={img28} alt="img28" />}
                            {index === 10 && <img src={img29} alt="img29" />}
                            {index === 13 && <img src={img30} alt="img30" />}
                            {index === 14 && <img src={img31} alt="img31" />}
                            {index === 15 && <img src={img32} alt="img32" />}
                            {index === 16 && <img src={img33} alt="img33" />}
                        </li>
                    ))}
                    <VideoIFrame
                        src="https://www.youtube.com/embed/-6StLtFLkgE"
                        className={s.video}
                    />
                    <VideoIFrame
                        src="https://www.youtube.com/embed/KXlcgetI15E"
                        className={s.video}
                    />
                </ol>
            ),
        },
        {
            title: translations.es.guides.howToTopUpCommissionBalance.title,
            key: 11,
            text: (
                <ol>
                    {translations.es.guides.howToTopUpCommissionBalance.steps.map((step, index) => (
                        <li key={index}>
                            {step}
                            {index === 1 && <img src={img34} alt="img34" />}
                        </li>
                    ))}
                </ol>
            ),
        },
        {
            title: translations.es.guides.howToInviteFriend.title,
            key: 12,
            text: (
                <ol>
                    {translations.es.guides.howToInviteFriend.steps.map((step, index) => (
                        <li key={index}>
                            {step}
                            {index === 2 && <img src={img35} alt="img35" />}
                        </li>
                    ))}
                </ol>
            ),
        },
        {
            title: translations.es.guides.howToLockRCBC.title,
            key: 13,
            text: (
                <ol>
                    {translations.es.guides.howToLockRCBC.steps.map((step, index) => (
                        <li key={index}>
                            {step}
                            {index === 1 && <img src={img36} alt="img36" />}
                            {index === 2 && <img src={img37} alt="img37" />}
                            {index === 3 && <img src={img38} alt="img38" />}
                            {index === 4 && <img src={img39} alt="img39" />}
                        </li>
                    ))}
                </ol>
            ),
        },
        {
            title: translations.es.guides.calculationsSection.title,
            key: 14,
            text: (
                <ol>
                    {translations.es.guides.calculationsSection.steps.map((step, index) => (
                        <li key={index}>{step}</li>
                    ))}
                </ol>
            ),
        },
    ],
    ru: [
        {
            title: translations.ru.guides.howToCreateTronLink.title,
            key: 1,
            video: 'https://www.youtube.com/embed/E6RXBr__GZ0',
            text: (
                <ol>
                    {translations.ru.guides.howToCreateTronLink.steps.map((step, index) => (
                        <li key={index}>
                            {step}
                            {index === 1 && <img src={img0} alt="img0" />}
                            {index === 2 && <img src={img1} alt="img1" />}
                            {index === 3 && (
                                <>
                                    <img src={img2} alt="img2" />
                                    <img src={img3} alt="img3" />
                                </>
                            )}
                            {index === 4 && <img src={img4} alt="img4" />}
                            {index === 5 && <img src={img5} alt="img5" />}
                            {index === 6 && <img src={img6} alt="img6" />}
                            {index === 7 && (
                                <>
                                    <img src={img7} alt="img7" />
                                    <img src={img8} alt="img8" />
                                </>
                            )}
                            {index === 8 && <img src={img9} alt="img9" />}
                            {index === 9 && <img src={img10} alt="img10" />}
                            {index === 10 && <img src={img11} alt="img11" />}
                        </li>
                    ))}
                    <div className={s.note}>
                        {translations.ru.guides.howToCreateTronLink.note}
                    </div>
                </ol>
            ),
        },
        {
            title: translations.ru.guides.howToConnectTronLink.title,
            key: 2,
            text: (
                <ol>
                    {translations.ru.guides.howToConnectTronLink.steps.map((step, index) => (
                        <li key={index}>
                            {step}
                            {index === 0 && <img src={img12} alt="img12" />}
                            {index === 1 && (
                                <>
                                    <img src={img13} alt="img13" />
                                    <img src={img14} alt="img14" />
                                </>
                            )}
                        </li>
                    ))}
                    <div className={s.note}>
                        {translations.ru.guides.howToConnectTronLink.note}
                    </div>
                </ol>
            ),
        },
        {
            title: translations.ru.guides.howToAddRCBC.title,
            key: 3,
            text: (
                <ol>
                    {translations.ru.guides.howToAddRCBC.steps.map((step, index) => (
                        <li key={index}>
                            {step}
                            {index === 0 && <img src={img40} alt="img40" />}
                            {index === 1 && <img src={img41} alt="img41" />}
                            {index === 2 && <img src={img42} alt="img42" />}
                            {index === 3 && <img src={img43} alt="img43" />}
                            {index === 4 && <img src={img44} alt="img44" />}
                            {index === 5 && <img src={img45} alt="img45" />}
                        </li>
                    ))}
                </ol>
            ),
        },
        {
            title: translations.ru.guides.howToGetRCBC.title,
            key: 4,
            subContent: translations.ru.guides.howToGetRCBC.subContent.map((sub, subIndex) => ({
                key: sub.key,
                steps: sub.steps,
            })),
        },
        {
            title: translations.ru.guides.howToCreateAndConnectBinanceAPI.title,
            key: 10,
            video: 'https://www.youtube.com/embed/8_lNxcCm6mw',
            text: (
                <ol>
                    {translations.ru.guides.howToCreateAndConnectBinanceAPI.steps.map((step, index) => (
                        <li key={index}>
                            {step}
                            {index === 2 && <img src={img23} alt="img23" />}
                            {index === 3 && <img src={img24} alt="img24" />}
                            {index === 4 && <img src={img25} alt="img25" />}
                            {index === 5 && <img src={img26} alt="img26" />}
                            {index === 6 && <img src={img27} alt="img27" />}
                            {index === 9 && <img src={img28} alt="img28" />}
                            {index === 10 && <img src={img29} alt="img29" />}
                            {index === 13 && <img src={img30} alt="img30" />}
                            {index === 14 && <img src={img31} alt="img31" />}
                            {index === 15 && <img src={img32} alt="img32" />}
                            {index === 16 && <img src={img33} alt="img33" />}
                        </li>
                    ))}
                    <VideoIFrame
                        src="https://www.youtube.com/embed/-6StLtFLkgE"
                        className={s.video}
                    />
                    <VideoIFrame
                        src="https://www.youtube.com/embed/KXlcgetI15E"
                        className={s.video}
                    />
                </ol>
            ),
        },
        {
            title: translations.ru.guides.howToTopUpCommissionBalance.title,
            key: 11,
            text: (
                <ol>
                    {translations.ru.guides.howToTopUpCommissionBalance.steps.map((step, index) => (
                        <li key={index}>
                            {step}
                            {index === 1 && <img src={img34} alt="img34" />}
                        </li>
                    ))}
                </ol>
            ),
        },
        {
            title: translations.ru.guides.howToInviteFriend.title,
            key: 12,
            text: (
                <ol>
                    {translations.ru.guides.howToInviteFriend.steps.map((step, index) => (
                        <li key={index}>
                            {step}
                            {index === 2 && <img src={img35} alt="img35" />}
                        </li>
                    ))}
                </ol>
            ),
        },
        {
            title: translations.ru.guides.howToLockRCBC.title,
            key: 13,
            text: (
                <ol>
                    {translations.ru.guides.howToLockRCBC.steps.map((step, index) => (
                        <li key={index}>
                            {step}
                            {index === 1 && <img src={img36} alt="img36" />}
                            {index === 2 && <img src={img37} alt="img37" />}
                            {index === 3 && <img src={img38} alt="img38" />}
                            {index === 4 && <img src={img39} alt="img39" />}
                        </li>
                    ))}
                </ol>
            ),
        },
        {
            title: translations.ru.guides.calculationsSection.title,
            key: 14,
            text: (
                <ol>
                    {translations.ru.guides.calculationsSection.steps.map((step, index) => (
                        <li key={index}>{step}</li>
                    ))}
                </ol>
            ),
        },
    ],
};
