export interface HTTPValidationError {
    detail: ValidationError[] | string
}

export interface ValidationError {
    loc: string[]
    msg: string
    type: string
}

export interface TronNonceBodyModel {
    address: string
}

export interface TronNonceModel {
    nonce: string
    isNew: boolean
}

export interface TronVerifyModel {
    nonce: string
    sign: string
    address: string
    referrerAddress?: string | null
}

export interface AccountBaseModel {
    id: number
    createDate: number
    active: boolean
    isAdmin: boolean
    deposit: number
    locked: number
    tronAddress?: string | null
    username?: string | null
    email?: string | null
    phone?: number | null
    firstName?: string | null
    lastName?: string | null
}

export interface AccountModel extends AccountBaseModel {
    referrerId?: number | null
}

export interface AccountWithMarketsModel extends AccountBaseModel {
    binance?: BinanceDataModel
    rithmic?: RithmicDataModel
    bybit?: BybitDataModel
}

export interface AccountCreateModel {
    username: string
    password: string
    token: string
}

export interface AccountUpdateModel {
    tronAddress?: string
    active?: boolean
    isAdmin?: boolean
}

export interface AccountSearchModel {
    isRun?: boolean
}

export interface AccountDepositModel {
    deposit: number
}

export interface LoginModel {
    username: string
    password: string
}

export interface BinanceCreateModel {
    secretKey: string
    accessKey: string
}

export interface BinanceUpdateModel {
    secretKey: string
    accessKey: string
}

export interface RithmicCreateModel {
    secretKey: string
    accessKey: string
    system: RithmicSystemsTypes
}

export interface RithmicUpdateDto {
    secretKey?: string
    accessKey?: string
    system?: RithmicSystemsTypes
    commissionRatio?: number
}

export interface BybitCreateDto {
    secretKey: string
    accessKey: string
}

export interface BybitUpdateDto {
    secretKey?: string
    accessKey?: string
    isWithdraw?: boolean
}

export interface BinanceDataModel {
    id: number
    createDate: number
    secretKey: string
    accessKey: string
    isWithdraw: boolean
    robotId?: string | null
    symbolId?: number | null
}

export interface RithmicDataModel {
    id: number
    createDate: number
    secretKey: string
    accessKey: string
    commissionRatio: number
    system: RithmicSystemsTypes
    robotId?: string | null
    symbol?: string | null
}

export interface BybitDataModel {
    id: number
    account: AccountBaseModel
    createDate: number
    isWithdraw: boolean
    secretKey?: string
    accessKey?: string
    robotId?: string
    symbolId?: number
}

export interface BybitDataSearchDto extends BybitDataModel {
    account: AccountModel
}

export interface RithmicDataSearchDto extends RithmicDataModel {
    account: Pick<
        AccountModel,
        | 'id'
        | 'createDate'
        | 'active'
        | 'deposit'
        | 'locked'
        | 'isAdmin'
        | 'username'
    >
}

export interface BaseSearchResponseModel<T extends object> {
    items: T[] | []
    total: number
}

export enum MarketType {
    BINANCE = 'BINANCE',
    HUOBI = 'HUOBI',
    RITHMIC = 'RITHMIC',
    BYBIT = 'BYBIT',
    ADVANTAGE_FUTURES = 'ADVANTAGE_FUTURES',
}

export interface DealsRequestModel {
    start_date: number
    end_date: number
    user_id?: number
}

export interface DealsResponseModel {
    id: number
    create_date: number
    symbol: string
    size: number
    entry_price: number
    exit_price: number
    market_commission: number
    commission: number
    profit: number
    status: CommissionStatusTypes
    withdraw_date: number
}

export interface LiveTradingResponseModel {
    create_date: number
    symbol: string
    size: number
    entry_price: number
    exit_price: number
    market_commission: number
    profit: number
}

export enum CommissionStatusTypes {
    TAKEN = 'TAKEN',
    TRANSFER = 'TRANSFER',
    NOT_PROCESSED = 'NOT_PROCESSED',
}

export enum RithmicSystemsTypes {
    RITHMIC_01 = 'Rithmic 01',
    RITHMIC_PAPER_TRADING = 'Rithmic Paper Trading',
}

export interface StatisticRequestModel {
    user_id?: number
}

export interface StatisticResponseModel {
    today: number
    month: number
    year: number
}

export interface PeriodStatisticResponseModel {
    amount: number
}

export enum FeeStatusTypes {
    SUCCESS = 'SUCCESS',
    FAIL = 'FAIL',
}

export interface FeeResponseModel {
    id: number
    create_date: number
    fee: number
    status: FeeStatusTypes
    user: AccountModel
    binance_deals: DealsResponseModel[]
    comment?: string
}

export interface StakingResponseModel {
    id: number
    create_date: number
    amount: number
    transaction: string
}

export interface ReferrerProfitResponseModel {
    id: number
    create_date: number
    amount: number
    transaction: string
}

export interface FeeUpdateRequestModel {
    id: number
    status: FeeStatusTypes
    dealStatus: CommissionStatusTypes
    comment?: string
}

export interface MarketDataStatusResponseModel {
    isRun: boolean
    symbolId?: number
    symbol?: string
}

export interface MarketDataStartRequestModel {
    symbolId: number
}

export interface RithmicStartDto {
    symbol: string
}

export interface BybitStartDto {
    symbolId: number
}

export interface WithdrawStatusRequestModel {
    isWithdraw: boolean
}

export interface ChangeRobotParamsModel {
    candleCount?: number
}

export interface BinanceSymbolsGetMinTokenBalanceRequestModel {
    symbol_id: number
}

export interface BinanceSymbolsGetMinTokenBalanceResponseModel {
    min_balance: number
}

export interface AccountReferrerDto {
    referrerAddress?: string
}

export interface BybitSearchDto {
    isRun: boolean
}

export interface BybitSymbolBaseDto {
    name: string
    symbol: string
    stepSize: number
    defaultPositionSize: number
    defaultMaxLots: number
    minBalance: number
    takeProfitRatio: number
    feedCount: number
    onlyAdmin: boolean
    priceStep: number
    quantityStep: number
}

export interface BybitSymbolDto extends BybitSymbolBaseDto {
    id: number
}

export interface BybitSymbolForSelectDto {
    id: number
    name: string
    symbol: string
    onlyAdmin: boolean
    minBalance: number
}

export interface BybitDealsSearchDto {
    accountId: number
    startDate: number
    endDate: number
}

export interface BybitDealDto {
    id: number
    createDate: number
    symbol: string
    size: number
    entryPrice: number
    exitPrice: number
    marketCommission: number
    commission: number
    profit: number
}

export interface BybitDealsStatisticSummaryDto {
    today: number
    month: number
    year: number
}

export interface BybitDealsPeriodStatisticDto {
    startDate: number
    endDate: number
}

export interface MinTokenBalanceDto {
    marketId: number
    symbolId: number
}
