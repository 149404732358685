import { Form } from 'antd'
import React from 'react'
import { BinanceFeeCellProps } from './BinanceFeeCell.types'

/** Ячейка для таблицы комиссии бинанс */
export const BinanceFeeCell: React.FC<BinanceFeeCellProps> = React.memo(
    ({ isEditing, dataIndex, title, children, inputNode, ...restProps }) => {
        return (
            <td {...restProps}>
                {isEditing ? (
                    <Form.Item name={dataIndex} noStyle initialValue={title}>
                        {inputNode}
                    </Form.Item>
                ) : (
                    children
                )}
            </td>
        )
    }
)
