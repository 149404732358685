import s from './RithmicKeys.module.scss'

import React from 'react'
import { getKeysActions } from './RithmicKeys.utils'
import { BlockWrapper } from 'shared/components'
import { RithmicKeysProps } from './RithmicKeys.types'
import cn from 'classnames'
import { RITHMIC_SYSTEM } from 'shared/consts'

/** Блок с ключами */
export const RithmicKeys: React.FC<RithmicKeysProps> = React.memo(
    ({ className, marketData, onMarketFetch }) => {
        return (
            <BlockWrapper className={className}>
                <div className={s.title}>
                    <h2>Keys</h2>

                    {getKeysActions(onMarketFetch, marketData)}
                </div>

                <a
                    href="https://yyy3.rithmic.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    rithmic.com
                </a>

                <div className={cn(s.text, 'mt-15')}>
                    <span className={s.label}>Login:</span>

                    <span className={s.key}>
                        {marketData?.accessKey || '-'}
                    </span>
                </div>

                <div className={s.text}>
                    <span className={s.label}>Password:</span>

                    <span className={s.key}>
                        {marketData?.secretKey || '-'}
                    </span>
                </div>

                <div className={s.text}>
                    <span className={s.label}>Mode:</span>

                    <span className={s.key}>
                        {
                            RITHMIC_SYSTEM[
                                marketData?.system as keyof typeof RITHMIC_SYSTEM
                            ]
                        }
                    </span>
                </div>
            </BlockWrapper>
        )
    }
)
