import s from './Orders.module.scss'

import React from 'react'
import { ColumnsType } from 'antd/lib/table'
import { defaultRuleSortingTableNumberColumn, formatDate } from 'shared/utils'
import {
    RithmicOrdersResponseModel,
    RithmicTransactionTypes,
} from 'api/http/models'
import { ColorWrapper } from 'shared/components'
import { Button, Tag } from 'antd'
import {
    RITHMIC_ORDER_TYPES,
    RITHMIC_TRANSACTION_TYPES,
} from '../../RithmicTrade.consts'
import { FnActionProps, FnActionRequiredProps } from 'App.types'

export const ordersTableColumns = (
    onCancelOrder: FnActionRequiredProps<string>,
    onCancelAllOrders: FnActionProps,
    dataLength?: number
): ColumnsType<RithmicOrdersResponseModel> => [
    {
        title: 'Time',
        dataIndex: 'time',
        key: 'time',
        render: formatDate,
        align: 'center',
        sorter: defaultRuleSortingTableNumberColumn('time'),
        sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
        title: 'Symbol',
        dataIndex: 'symbol',
        key: 'symbol',
        align: 'center',
    },
    {
        title: 'Type',
        dataIndex: 'price_type',
        key: 'price_type',
        align: 'center',
        render: (_, { price_type }) => RITHMIC_ORDER_TYPES[price_type],
    },
    {
        title: 'Side',
        dataIndex: 'side',
        key: 'side',
        align: 'center',
        render: (_, { transaction_type }) => (
            <ColorWrapper
                text={RITHMIC_TRANSACTION_TYPES[transaction_type]}
                condition={transaction_type === RithmicTransactionTypes.BUY}
            />
        ),
    },
    {
        title: 'Price',
        dataIndex: 'price',
        key: 'price',
        align: 'center',
    },
    {
        title: 'Amount',
        dataIndex: 'quantity',
        key: 'quantity',
        align: 'center',
        sorter: defaultRuleSortingTableNumberColumn('quantity'),
        sortDirections: ['descend', 'ascend'],
    },
    {
        title: 'Filled',
        dataIndex: 'total_fill_size',
        key: 'total_fill_size',
        align: 'center',
    },
    {
        title: () => (
            <Button
                onClick={onCancelAllOrders}
                disabled={!dataLength}
                className={s.button}
            >
                <Tag>cancel all</Tag>
            </Button>
        ),
        render: ({ basket_id }) => (
            <Button
                onClick={() => {
                    onCancelOrder(basket_id)
                }}
                className={s.button}
            >
                <Tag>cancel</Tag>
            </Button>
        ),
        width: 101,
        align: 'center',
    },
]
