import { FilterTypes } from './Filters.types'

export const FILTER_TAGS = [
    {
        id: FilterTypes.Day,
        tagName: 'Day',
    },
    {
        id: FilterTypes.Week,
        tagName: 'Last 1000 deals',
    },
]

export const MAX_LIMIT_DEAL = 1000
