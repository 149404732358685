import s from './TradeActions.module.scss'

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Col, Form, message, Row, Tag } from 'antd'
import {
    CheckboxControl,
    InputControl,
    InputNumberControl,
    PopupAdapter,
    SelectControl,
} from 'shared/components'
import { INITIAL_VALUES, TAGS } from './TradeActions.consts'
import { isTagTypeLimitBinance } from './TradeActions.utils'
import {
    BinanceLeverageResponseModel,
    BinanceOrderSideTypes,
    BinanceOrderTypes,
    BinanceSetOrderRequestModel,
    BinanceTimeInForceTypes,
} from 'api/http/models'
import { LeverageModal } from './LeverageModal'
import { BinanceNativeService } from 'api/http'
import { normalizeDataForSelectAndRadio } from 'shared/utils'
import { TradeActionsProps } from './TradeActions.types'

/** Кнопки управления заявками для покупки и продажи */
export const TradeActions: React.FC<TradeActionsProps> = React.memo(
    ({ tradeSettings: { symbolData }, marketId, positionsData }) => {
        const [orderType, setOrderType] = useState<BinanceOrderTypes>(
            BinanceOrderTypes.MARKET
        )
        const [side, setSide] = useState<BinanceOrderSideTypes>()
        const [form] = Form.useForm()

        /** Отправка формы */
        const handleFinish = useCallback(
            async (body: BinanceSetOrderRequestModel) => {
                try {
                    if (!symbolData || !side) return

                    const { orderId } = await BinanceNativeService.setOrder({
                        ...body,
                        symbol: symbolData.symbol,
                        orderType,
                        side,
                    })

                    message.success(`Ордер id${orderId} успешно размещен`)
                } catch (e) {
                    console.log(e)
                }
            },
            [side, symbolData, orderType]
        )

        /** Калькулятор значения кредитного плеча */
        const leverageInfo = useMemo(
            () => positionsData?.find(el => el.symbol === symbolData?.symbol),
            [positionsData, symbolData]
        )

        /** Обработчик закрытия модального окна изменения кредитного плеча */
        const handleRequestFinish = useCallback(
            (data?: BinanceLeverageResponseModel) => {
                if (data) {
                    message.success(
                        `Кредитное плечо ${data?.symbol} изменено на ${data?.leverage}х`
                    )
                }
            },
            []
        )

        /**
         * Выбор типа заявки
         * @param tagName тип заявки
         */
        const handleChangeTag = useCallback(
            (tagName: BinanceOrderTypes) => {
                setOrderType(tagName)
                form.setFieldsValue({
                    price: undefined,
                    timeInForce: isTagTypeLimitBinance(tagName)
                        ? BinanceTimeInForceTypes.GTC
                        : undefined,
                })
            },
            [form]
        )

        /** Обработчик покупки */
        const handleBuy = useCallback(() => {
            setSide(BinanceOrderSideTypes.BUY)
        }, [])

        /** Обработчик продажи */
        const handleSell = useCallback(() => {
            setSide(BinanceOrderSideTypes.SELL)
        }, [])

        const initialValue = {
            ...INITIAL_VALUES,
            timeInForce: isTagTypeLimitBinance(orderType)
                ? BinanceTimeInForceTypes.GTC
                : undefined,
            market_id: marketId,
        }

        useEffect(() => {
            if (symbolData) form.resetFields()
        }, [symbolData, form])

        return (
            <Form
                layout="vertical"
                className={s.wrapper}
                onFinish={handleFinish}
                form={form}
                initialValues={initialValue}
            >
                <Form.Item name="market_id" hidden>
                    <InputControl />
                </Form.Item>

                <Row justify="space-between" align="middle">
                    <Col>
                        <PopupAdapter
                            component={LeverageModal}
                            formId="leverageModalId"
                            buttonText={`${leverageInfo?.leverage}x`}
                            modalOptions={{
                                title: 'Change leverage',
                            }}
                            buttonOption={{
                                title: 'Change leverage',
                                disabled: !!leverageInfo?.leverage,
                            }}
                            formOptions={{
                                initialValues: {
                                    ...leverageInfo,
                                    leverage: Number(leverageInfo?.leverage),
                                    market_id: marketId,
                                },
                            }}
                            onRequestFinish={handleRequestFinish}
                        />
                    </Col>

                    <Col>
                        <div className={s.tags}>
                            {TAGS.map(tag => (
                                <Tag.CheckableTag
                                    key={tag.id}
                                    checked={orderType === tag.id}
                                    onChange={() => {
                                        handleChangeTag(tag.id)
                                    }}
                                >
                                    {tag.tagName}
                                </Tag.CheckableTag>
                            ))}
                        </div>
                    </Col>
                </Row>

                <Row gutter={10} align="middle">
                    <Col xs={12}>
                        <Form.Item
                            name="quantity"
                            label={`Size (${symbolData?.symbol || ''})`}
                        >
                            <InputNumberControl min={0} placeholder="0.0" />
                        </Form.Item>
                    </Col>

                    <Col xs={12}>
                        <Form.Item name="reduceOnly" valuePropName="checked">
                            <CheckboxControl>Reduce-only</CheckboxControl>
                        </Form.Item>
                    </Col>
                </Row>

                {isTagTypeLimitBinance(orderType) && (
                    <Row gutter={10} align="middle">
                        <Col xs={12}>
                            <Form.Item name="price" label="Price (USDT)">
                                <InputNumberControl min={0} placeholder="0.0" />
                            </Form.Item>
                        </Col>

                        <Col xs={12}>
                            <Form.Item name="timeInForce" label="TIF">
                                <SelectControl
                                    options={Object.keys(
                                        BinanceTimeInForceTypes
                                    ).map(normalizeDataForSelectAndRadio)}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                )}

                <div className={s.buttons}>
                    <Button
                        htmlType="submit"
                        type="primary"
                        onClick={handleBuy}
                        disabled={!marketId}
                    >
                        Buy/Long
                    </Button>

                    <Button
                        htmlType="submit"
                        type="primary"
                        onClick={handleSell}
                        danger
                        disabled={!marketId}
                    >
                        Sell/Short
                    </Button>
                </div>
            </Form>
        )
    }
)
