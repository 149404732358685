import React, { useMemo } from 'react'
import { Col, Row, Tabs } from 'antd'
import { Positions } from './Positions'
import { Orders } from './Orders'
import { PositionsAndOrdersTabsProps } from './Tables.types'

/** Таблицы торговли */
export const Tables: React.FC<PositionsAndOrdersTabsProps> = React.memo(
    ({ positionsData, ordersData, onSendMessage }) => {
        const tabItems = useMemo(
            () => [
                {
                    label: `Positions (${positionsData?.length || 0})`,
                    key: 'positions',
                    children: (
                        <Row gutter={40}>
                            <Col xs={24} xxl={12}>
                                <Positions
                                    positionsData={positionsData}
                                    onSendMessage={onSendMessage}
                                />
                            </Col>

                            <Col xs={24} xxl={12}>
                                <Orders
                                    ordersData={ordersData}
                                    onSendMessage={onSendMessage}
                                />
                            </Col>
                        </Row>
                    ),
                },
                {
                    label: 'Trade history',
                    key: 'dealsHistory',
                    disable: true,
                },
                {
                    label: 'Robot deals',
                    key: 'robotDeals',
                    disable: true,
                },
            ],
            [ordersData, positionsData, onSendMessage]
        )

        return <Tabs defaultActiveKey="positions" items={tabItems} />
    }
)
