import s from './Partners.module.scss'
import React from 'react'
import { PARTNERS } from './Partners.consts'
import { Button, Col, Row } from 'antd'
import { BlockWrapper } from 'shared/components'
import { NAV_LINK_NAMES } from 'shared/consts'
import { Link } from 'react-router-dom'

/** Блок партнеров для главной страницы */
export const Partners: React.FC = React.memo(() => {
    return (
        <div className={s.wrapper}>
            <Row gutter={30}>
                {PARTNERS.map(el => (
                    <Col xs={24} sm={12} key={el.key}>
                        <BlockWrapper className={s.bwrapper}>
                            <h3 className={s.subtitle}>
                                SUPPORTED {el.key} EXCHANGES
                            </h3>

                            <div className={s.partners}>
                                {el.partners.map((partner, i) => (
                                    <a
                                        key={i}
                                        href={partner.href}
                                        className={s.link}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <img
                                            src={partner.icon}
                                            alt={partner.href}
                                        />
                                    </a>
                                ))}
                            </div>

                            <Link
                                to={
                                    el.key === 'GLOBAL'
                                        ? NAV_LINK_NAMES.GLOBAL_MARKETS
                                        : NAV_LINK_NAMES.CRYPTO_MARKETS
                                }
                            >
                                <Button type="primary" className={s.button}>
                                    START WITH {el.key}
                                </Button>
                            </Link>
                        </BlockWrapper>
                    </Col>
                ))}
            </Row>
            <div className={s.call}>
                <b>Join RealColibri today and discover a new <br/> level of financial independence and success!</b>
            </div>
        </div>
    )
})
