import {
    BybitCandlesIntervalTypes,
    BybitOrderSideTypes,
    BybitOrderTypes,
} from 'api/http/models'

export const INITIAL_TRADE_SETTINGS = {
    period: BybitCandlesIntervalTypes.min,
}

export const BYBIT_ORDER_TYPES = {
    [BybitOrderTypes.Market]: 'Market',
    [BybitOrderTypes.Limit]: 'Limit',
}

export const BYBIT_ORDER_SIDE_TYPES = {
    [BybitOrderSideTypes.Buy]: 'Buy',
    [BybitOrderSideTypes.Sell]: 'Sell',
}
