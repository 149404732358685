export const ROUTE_NAMES = {
    MAIN: '/',
    GUIDES: 'guides',
    PREMIUM: 'premium',
    GLOBAL_MARKETS: 'global',
    CRYPTO_MARKETS: 'crypto',
    ACCOUNT: 'account',
    TERMS: 'terms',
    TRADING: 'trading',
    BINANCE: 'binance',
    RITHMIC: 'rithmic',
    BYBIT: 'bybit',
    TRADE: 'trade',
    ACCOUNTS: 'accounts',
    FEE: 'fee',
    SYMBOLS: 'symbols',
    STAKING: 'staking',
    REFERRERS_PROFIT: 'referrers-profit',
}

export const NAV_LINK_NAMES = {
    MAIN: ROUTE_NAMES.MAIN,
    GLOBAL_MARKETS: `/${ROUTE_NAMES.GLOBAL_MARKETS}`,
    CRYPTO_MARKETS: `/${ROUTE_NAMES.CRYPTO_MARKETS}`,
    PREMIUM: `/${ROUTE_NAMES.PREMIUM}`,
    GUIDES: `/${ROUTE_NAMES.GUIDES}`,
    TERMS: `/${ROUTE_NAMES.TERMS}`,
    ACCOUNT: `/${ROUTE_NAMES.ACCOUNT}`,
    TRADING_BINANCE: `/${ROUTE_NAMES.TRADING}/${ROUTE_NAMES.BINANCE}`,
    TRADING_RITHMIC: `/${ROUTE_NAMES.TRADING}/${ROUTE_NAMES.RITHMIC}`,
    TRADING_BYBIT: `/${ROUTE_NAMES.TRADING}/${ROUTE_NAMES.BYBIT}`,
    BINANCE_TRADE: `/${ROUTE_NAMES.BINANCE}/${ROUTE_NAMES.TRADE}`,
    BINANCE_ACCOUNTS: `/${ROUTE_NAMES.BINANCE}/${ROUTE_NAMES.ACCOUNTS}`,
    BINANCE_FEE: `/${ROUTE_NAMES.BINANCE}/${ROUTE_NAMES.FEE}`,
    BINANCE_SYMBOLS: `/${ROUTE_NAMES.BINANCE}/${ROUTE_NAMES.SYMBOLS}`,
    BINANCE_STAKING: `/${ROUTE_NAMES.BINANCE}/${ROUTE_NAMES.STAKING}`,
    BINANCE_REFERRERS_PROFIT: `/${ROUTE_NAMES.BINANCE}/${ROUTE_NAMES.REFERRERS_PROFIT}`,
    RITHMIC_TRADE: `/${ROUTE_NAMES.RITHMIC}/${ROUTE_NAMES.TRADE}`,
    RITHMIC_ACCOUNTS: `/${ROUTE_NAMES.RITHMIC}/${ROUTE_NAMES.ACCOUNTS}`,
    BYBIT_TRADE: `/${ROUTE_NAMES.BYBIT}/${ROUTE_NAMES.TRADE}`,
    BYBIT_ACCOUNTS: `/${ROUTE_NAMES.BYBIT}/${ROUTE_NAMES.ACCOUNTS}`,
    BYBIT_SYMBOLS: `/${ROUTE_NAMES.BYBIT}/${ROUTE_NAMES.SYMBOLS}`,
}

export const ROUTES_WITHOUT_PERMISSIONS = [
    NAV_LINK_NAMES.MAIN,
    NAV_LINK_NAMES.TERMS,
    NAV_LINK_NAMES.ACCOUNT,
    NAV_LINK_NAMES.GUIDES,
    NAV_LINK_NAMES.GLOBAL_MARKETS,
    NAV_LINK_NAMES.CRYPTO_MARKETS,
    NAV_LINK_NAMES.PREMIUM,
]
