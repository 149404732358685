import s from './SliderSingleControl.module.scss'

import React, { useMemo } from 'react'
import { Slider } from 'antd'
import { InputNumberControl } from '../InputNumberControl'
import { SliderSingleControlProps } from './SliderSingleControl.types'
import cn from 'classnames'

/** Декоратор для `Slider` от `antd` */
export const SliderSingleControl: React.FC<SliderSingleControlProps> =
    React.memo(
        ({
            value,
            onChange,
            min,
            max,
            addonNumberAfter,
            addonNumberOptions,
            defaultValue,
            initialValue,
            className,
            ...restProps
        }) => {
            /**
             * Обработчик внешней формы
             * @param value выбранное значение
             */
            const triggerChange = (value?: number | string | null) => {
                if (value === null) {
                    onChange?.('')

                    return
                }
                onChange?.(value)
            }

            /**
             * Определяем текущее значение для компонента
             */
            const currentValue = useMemo(
                () => (typeof value === 'number' ? value : initialValue),
                [value, initialValue]
            )

            return (
                <div className={cn(s.wrapper, className)}>
                    <Slider
                        onChange={triggerChange}
                        value={currentValue}
                        min={min}
                        max={max}
                        defaultValue={defaultValue}
                        {...restProps}
                    />

                    {addonNumberAfter && (
                        <InputNumberControl
                            value={currentValue}
                            onChange={triggerChange}
                            min={min}
                            max={max}
                            defaultValue={defaultValue}
                            {...addonNumberOptions}
                            className={cn(
                                'ml-15',
                                addonNumberOptions?.className
                            )}
                        />
                    )}
                </div>
            )
        }
    )
