import React, { useContext } from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import {
    Account,
    Binance,
    BinanceAccounts,
    BinanceFee,
    BinanceReferrersProfit,
    BinanceStaking,
    BinanceSymbols,
    BinanceTrade,
    Bybit,
    BybitAccounts,
    BybitSymbols,
    BybitTrade,
    CryptoMarkets,
    ErrorPage,
    GlobalMarkets,
    Guides,
    Layout,
    Main,
    Rithmic,
    RithmicAccounts,
    RithmicTrade,
    TermsAndConditions,
    Premium,
} from './pages';
import { ROUTE_NAMES } from 'shared/consts';
import { AccountContext } from 'core/context';
import { LanguageProvider } from 'context/LanguageContext';

export const App: React.FC = React.memo(() => {
    const { accountData, tronAddresses } = useContext(AccountContext);

    return (
        <LanguageProvider>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index path={ROUTE_NAMES.MAIN} element={<Main />} />
                    <Route path={ROUTE_NAMES.TERMS} element={<TermsAndConditions />} />
                    <Route path={ROUTE_NAMES.GUIDES} element={<Guides />} />
                    <Route path={ROUTE_NAMES.GLOBAL_MARKETS} element={<GlobalMarkets />} />
                    <Route path={ROUTE_NAMES.CRYPTO_MARKETS} element={<CryptoMarkets />} />
                    <Route path={ROUTE_NAMES.PREMIUM} element={<Premium />} />
                    {accountData && (
                        <Route path={ROUTE_NAMES.TRADING} element={<Outlet />}>
                            <Route path={ROUTE_NAMES.BINANCE} element={<Binance />} />
                            <Route path={ROUTE_NAMES.RITHMIC} element={<Rithmic />} />
                            <Route path={ROUTE_NAMES.BYBIT} element={<Bybit />} />
                        </Route>
                    )}
                    {(tronAddresses.service || accountData) && (
                        <Route path={ROUTE_NAMES.ACCOUNT} element={<Account />} />
                    )}
                    {accountData?.isAdmin && (
                        <>
                            <Route path={ROUTE_NAMES.BINANCE} element={<Outlet />}>
                                <Route path={`${ROUTE_NAMES.TRADE}/:id`} element={<BinanceTrade />} />
                                <Route path={ROUTE_NAMES.ACCOUNTS} element={<BinanceAccounts />} />
                                <Route path={ROUTE_NAMES.FEE} element={<BinanceFee />} />
                                <Route path={ROUTE_NAMES.SYMBOLS} element={<BinanceSymbols />} />
                                <Route path={ROUTE_NAMES.STAKING} element={<BinanceStaking />} />
                                <Route path={ROUTE_NAMES.REFERRERS_PROFIT} element={<BinanceReferrersProfit />} />
                            </Route>
                            <Route path={ROUTE_NAMES.RITHMIC} element={<Outlet />}>
                                <Route path={ROUTE_NAMES.ACCOUNTS} element={<RithmicAccounts />} />
                                <Route path={`${ROUTE_NAMES.TRADE}/:id`} element={<RithmicTrade />} />
                            </Route>
                            <Route path={ROUTE_NAMES.BYBIT} element={<Outlet />}>
                                <Route path={`${ROUTE_NAMES.TRADE}/:id`} element={<BybitTrade />} />
                                <Route path={ROUTE_NAMES.ACCOUNTS} element={<BybitAccounts />} />
                                <Route path={ROUTE_NAMES.SYMBOLS} element={<BybitSymbols />} />
                            </Route>
                        </>
                    )}
                    <Route path="/*" element={<ErrorPage errorCode="404" />} />
                </Route>
            </Routes>
        </LanguageProvider>
    );
});
