export enum RithmicCandlesIntervalTypes {
    min = '1m',
    min5 = '5m',
    min15 = '15m',
    hour1 = '1h',
    hour4 = '4h',
}

export interface RithmicTickersModel {
    symbol: string
    last_price: number
}

export interface RithmicAccountModel {
    balance: string
}

export interface RithmicPositionsModel {
    symbol: string
    price: number
    pnl: string
    quantity: number
}

export enum RithmicTransactionTypes {
    BUY = 1,
    SELL,
}

export enum RithmicPriceTypes {
    LIMIT = 1,
    MARKET,
}

export interface RithmicOrdersResponseModel {
    exchange_order_id: string
    basket_id: string
    symbol: string
    price: number
    total_fill_size: number
    price_type: RithmicPriceTypes
    transaction_type: RithmicTransactionTypes
    notify_type: number
    quantity: number
    time: number
    text: string
}
