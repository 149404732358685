import React from 'react'
import { ColumnsType } from 'antd/lib/table'
import {
    RithmicDataSearchDto,
    RithmicSystemsTypes,
    RithmicUpdateDto,
} from 'api/http/models'
import { fixedNumber, formatDate } from 'shared/utils'
import { Button } from 'antd'
import { EyeOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { NAV_LINK_NAMES, RITHMIC_SYSTEM } from 'shared/consts'
import Paragraph from 'antd/lib/typography/Paragraph'
import s from './RithmicAccounts.module.scss'

/** Настройки колонок таблицы аккаунтов */
export const getAccountTableColumns = (
    onMarketUpdate: (id: number, body: RithmicUpdateDto) => void
): ColumnsType<RithmicDataSearchDto> => [
    {
        title: 'AID',
        dataIndex: ['account', 'id'],
        key: 'id',
        align: 'center',
    },
    {
        title: 'Create date',
        dataIndex: 'createDate',
        key: 'createDate',
        render: formatDate,
    },
    {
        title: 'Username',
        dataIndex: ['account', 'username'],
        key: 'username',
    },
    {
        title: 'System',
        dataIndex: 'system',
        key: 'system',
        render: (system: RithmicSystemsTypes) => RITHMIC_SYSTEM[system],
    },
    {
        title: 'Deposit',
        dataIndex: ['account', 'deposit'],
        key: 'deposit',
        render: deposit => fixedNumber(deposit),
    },
    {
        title: 'Locked',
        dataIndex: ['account', 'locked'],
        key: 'locked',
        render: locked => fixedNumber(locked),
    },
    {
        title: 'Access key',
        key: 'accessKey',
        dataIndex: 'accessKey',
    },
    {
        title: 'Secret key',
        key: 'secretKey',
        dataIndex: 'secretKey',
    },
    {
        title: 'Commission',
        key: 'commissionRatio',
        dataIndex: 'commissionRatio',
        render: (commissionRatio, { id }) => {
            const commissionPercent = commissionRatio * 100
            return (
                <Paragraph
                    className={s.commission}
                    editable={{
                        onChange: value => {
                            const count = Number(value)
                            if (
                                Number.isInteger(count) &&
                                count !== commissionPercent
                            ) {
                                onMarketUpdate(id, {
                                    commissionRatio: count / 100,
                                })
                            }
                        },
                        maxLength: 3,
                        text: `${commissionPercent}`,
                    }}
                >
                    {`${commissionPercent}%`}
                </Paragraph>
            )
        },
        align: 'center',
    },
    {
        key: 'view',
        dataIndex: ['account', 'id'],
        render: id => (
            <Link to={`${NAV_LINK_NAMES.RITHMIC_TRADE}/${id}`}>
                <Button type="link" icon={<EyeOutlined />} />
            </Link>
        ),
        align: 'center',
    },
    {
        title: 'Торгуемый символ',
        key: 'symbol',
        width: 120,
        align: 'center',
    },
]
