import React, { useCallback } from 'react'
import { OrdersProps } from './Orders.types'
import { TableAdapter } from 'shared/components'
import { getOrdersTableColumns } from './Orders.utils'
import { BybitNativeService } from 'api/http'
import { message } from 'antd'
import { BybitCancelOrderDto } from 'api/http/models'

/** Таблица открытых ордеров */
export const Orders: React.FC<OrdersProps> = React.memo(
    ({ marketId, orders }) => {
        /** Обработчик отмены ордера */
        const handleCancelOrder = useCallback(
            async (value: BybitCancelOrderDto) => {
                try {
                    if (marketId) {
                        await BybitNativeService.cancelOrder(marketId, value)
                        message.success('Order cancelled')
                    }
                } catch (e) {
                    console.log(e)
                }
            },
            [marketId]
        )

        /** Обработчик отмены всех ордеров */
        const handleCancelAllOrders = useCallback(async () => {
            try {
                if (marketId) {
                    await BybitNativeService.cancelAllOrders(marketId)
                }
            } catch (e) {
                console.log(e)
            }
        }, [marketId])

        return (
            <TableAdapter
                title={() => <h2>Orders</h2>}
                columns={getOrdersTableColumns(
                    handleCancelOrder,
                    handleCancelAllOrders
                )}
                dataSource={orders}
                pagination={false}
                rowKey="orderId"
            />
        )
    }
)
