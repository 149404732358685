const translations = {
    en: {
        ACCOUNT_LOGIN: "ACCOUNT LOGIN",
        USERNAME_PLACEHOLDER: "Username",
        PASSWORD_PLACEHOLDER: "Password",
        LOGIN: "Login",
        REQUIRED_FIELD: "required field",
        OR: "OR",
        LOGIN_WITH_TRONLINK: "Login with TronLink",
        TRONLINK_PROMPT: "Requires an account with complete access (not autotrading only).",
        TRONLINK_REFRESH_PROMPT: "Refresh the page to log in via TronLink here. Make sure that TronLink is installed and logged in to the wallet. Requires an account with complete access (not autotrading only)."
    },
    es: {
        ACCOUNT_LOGIN: "INICIAR SESIÓN",
        USERNAME_PLACEHOLDER: "Nombre de usuario",
        PASSWORD_PLACEHOLDER: "Contraseña",
        LOGIN: "Iniciar sesión",
        REQUIRED_FIELD: "campo requerido",
        OR: "O",
        LOGIN_WITH_TRONLINK: "Iniciar sesión con TronLink",
        TRONLINK_PROMPT: "Requiere una cuenta con acceso completo (no solo autotrading).",
        TRONLINK_REFRESH_PROMPT: "Actualice la página para iniciar sesión a través de TronLink aquí. Asegúrese de que TronLink esté instalado e iniciado sesión en la billetera. Requiere una cuenta con acceso completo (no solo autotrading)."
    },
    ru: {
        ACCOUNT_LOGIN: "ВОЙТИ В АККАУНТ",
        USERNAME_PLACEHOLDER: "Имя пользователя",
        PASSWORD_PLACEHOLDER: "Пароль",
        LOGIN: "Войти",
        REQUIRED_FIELD: "обязательное поле",
        OR: "ИЛИ",
        LOGIN_WITH_TRONLINK: "Войти с TronLink",
        TRONLINK_PROMPT: "Требуется учетная запись с полным доступом (не только автотрейдинг).",
        TRONLINK_REFRESH_PROMPT: "Обновите страницу, чтобы войти через TronLink здесь. Убедитесь, что TronLink установлен и выполнен вход в кошелек. Требуется учетная запись с полным доступом (не только автотрейдинг)."
    },
    hy: {
        ACCOUNT_LOGIN: "ՄՈՒՏՔ ԳՈՐԾԵԼ ՀԱՇԻՎ",
        USERNAME_PLACEHOLDER: "Օգտանուն",
        PASSWORD_PLACEHOLDER: "Գաղտնաբառ",
        LOGIN: "Մուտք գործել",
        REQUIRED_FIELD: "պարտադիր դաշտ",
        OR: "ԿԱՄ",
        LOGIN_WITH_TRONLINK: "Մուտք գործել TronLink-ով",
        TRONLINK_PROMPT: "Պահանջվում է հաշիվ ամբողջական հասանելիությամբ (ոչ միայն ավտոտրեյդինգի համար):",
        TRONLINK_REFRESH_PROMPT: "Թարմացրեք էջը՝ TronLink-ով այստեղ մուտք գործելու համար։ Համոզվեք, որ TronLink-ը տեղադրված է և մուտք գործած է դրամապանակ։ Պահանջվում է հաշիվ ամբողջական հասանելիությամբ (ոչ միայն ավտոտրեյդինգի համար):"
    },
    fr: {
        ACCOUNT_LOGIN: "CONNEXION AU COMPTE",
        USERNAME_PLACEHOLDER: "Nom d'utilisateur",
        PASSWORD_PLACEHOLDER: "Mot de passe",
        LOGIN: "Connexion",
        REQUIRED_FIELD: "champ requis",
        OR: "OU",
        LOGIN_WITH_TRONLINK: "Connexion avec TronLink",
        TRONLINK_PROMPT: "Nécessite un compte avec un accès complet (pas seulement autotrading).",
        TRONLINK_REFRESH_PROMPT: "Rafraîchissez la page pour vous connecter via TronLink ici. Assurez-vous que TronLink est installé et connecté au portefeuille. Nécessite un compte avec un accès complet (pas seulement autotrading)."
    },
    de: {
        ACCOUNT_LOGIN: "KONTO ANMELDUNG",
        USERNAME_PLACEHOLDER: "Benutzername",
        PASSWORD_PLACEHOLDER: "Passwort",
        LOGIN: "Anmelden",
        REQUIRED_FIELD: "Pflichtfeld",
        OR: "ODER",
        LOGIN_WITH_TRONLINK: "Mit TronLink anmelden",
        TRONLINK_PROMPT: "Erfordert ein Konto mit vollständigem Zugriff (nicht nur Autotrading).",
        TRONLINK_REFRESH_PROMPT: "Aktualisieren Sie die Seite, um sich hier über TronLink anzumelden. Stellen Sie sicher, dass TronLink installiert ist und im Wallet eingeloggt ist. Erfordert ein Konto mit vollständigem Zugriff (nicht nur Autotrading)."
    }
};

export default translations;
