import { ColumnsType } from 'antd/lib/table'
import { BybitDealDto } from 'api/http/models'
import {
    defaultRuleSortingTableNumberColumn,
    fixedNumber,
    formatDate,
} from 'shared/utils'
import dayjs from 'dayjs'

/** Настройки отображения столбцов для таблицы сделок робота */
export const DEAL_TABLE_COLUMNS: ColumnsType<BybitDealDto> = [
    {
        title: 'Time',
        dataIndex: 'createDate',
        key: 'createDate',
        render: formatDate,
        align: 'center',
        showSorterTooltip: false,
        sorter: defaultRuleSortingTableNumberColumn('createDate'),
        sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
        title: 'Size',
        dataIndex: 'size',
        key: 'size',
        align: 'center',
        showSorterTooltip: false,
        sorter: defaultRuleSortingTableNumberColumn('size'),
        sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
        title: 'Entry price',
        dataIndex: 'entryPrice',
        key: 'entryPrice',
        align: 'center',
    },
    {
        title: 'Exit price',
        dataIndex: 'exitPrice',
        key: 'exitPrice',
        align: 'center',
    },
    {
        title: 'Market commission',
        dataIndex: 'marketCommission',
        key: 'marketCommission',
        align: 'center',
        showSorterTooltip: false,
        sorter: defaultRuleSortingTableNumberColumn('marketCommission'),
        sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
        title: 'RealColibri commission (RCBC)',
        dataIndex: 'commission',
        key: 'commission',
        render: commission => fixedNumber(commission),
        align: 'center',
        showSorterTooltip: false,
        sorter: defaultRuleSortingTableNumberColumn('commission'),
        sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
        title: 'Profit',
        dataIndex: 'profit',
        key: 'profit',
        align: 'center',
        showSorterTooltip: false,
        sorter: defaultRuleSortingTableNumberColumn('profit'),
        sortDirections: ['ascend', 'descend', 'ascend'],
    },
]

export const QUERY_PARAMS_INITIAL_VALUES = {
    startDate: 0,
    endDate: dayjs().endOf('day').valueOf(),
}
