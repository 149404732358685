import React, { useCallback, useEffect, useState } from 'react'
import {
    CheckboxControl,
    HiddenFormItem,
    InputControl,
    InputNumberControl,
    SelectControl,
} from 'shared/components'
import { BybitNativeService, BybitSymbolsService } from 'api/http'
import { Form, Spin } from 'antd'
import { PopupAdapterFormProps } from 'shared/components/popups/PopupAdapter.types'
import { Store } from 'antd/lib/form/interface'
import { BybitSymbolDto } from 'api/http/models'
import { normalizeDataForSelectAndRadio } from 'shared/utils'
import { useFetch } from 'shared/hooks'

/** Форма для символов Bybit */
export const BybitSymbol: React.FC<PopupAdapterFormProps> = React.memo(
    ({ initialValues, onRequestFinish, ...props }) => {
        const [form] = Form.useForm()
        const [fetchSymbolsInfo, symbolsData] = useFetch(
            BybitNativeService.getInstrumentsInfo
        )
        const [localLoader, setLocalLoader] = useState(false)

        const handleChangeForm = useCallback(
            (symbol?: string) => {
                const symbolData = symbolsData?.find(el => el.symbol === symbol)

                form.setFieldsValue({
                    priceStep: symbolData?.priceStep,
                    quantityStep: symbolData?.quantityStep,
                })
            },
            [symbolsData]
        )

        const handleFinish = useCallback(
            async ({ id, ...values }: BybitSymbolDto) => {
                try {
                    setLocalLoader(true)

                    if (id) {
                        await BybitSymbolsService.update(id, values)
                    } else {
                        await BybitSymbolsService.create(values)
                    }
                    onRequestFinish?.()
                } catch (e) {
                    console.log(e)
                } finally {
                    setLocalLoader(false)
                }
            },
            [onRequestFinish, setLocalLoader]
        )

        const handleValuesChange = useCallback(
            (changeValue: Store) => {
                const [key, value] = Object.entries(changeValue)[0]

                if (key === 'symbol') {
                    handleChangeForm(value)
                }
            },
            [handleChangeForm]
        )

        useEffect(() => {
            fetchSymbolsInfo()
        }, [fetchSymbolsInfo])

        useEffect(() => {
            if (initialValues?.symbol) {
                handleChangeForm(initialValues?.symbol)
            }
        }, [handleChangeForm, initialValues?.symbol])

        return (
            <Spin spinning={localLoader}>
                <Form
                    {...props}
                    initialValues={initialValues || { onlyAdmin: true }}
                    form={form}
                    layout="vertical"
                    onFinish={handleFinish}
                    onValuesChange={handleValuesChange}
                >
                    <HiddenFormItem name="id" />

                    <HiddenFormItem name="priceStep" />

                    <HiddenFormItem name="quantityStep" />

                    <Form.Item name="name" label="Название">
                        <InputControl maxLength={20} />
                    </Form.Item>

                    <Form.Item name="symbol" label="Биржевой символ">
                        <SelectControl
                            options={symbolsData?.map(el =>
                                normalizeDataForSelectAndRadio(el.symbol)
                            )}
                            showSearch
                        />
                    </Form.Item>

                    <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, nextValues) =>
                            prevValues.priceStep !== nextValues.priceStep ||
                            prevValues.quantityStep !== nextValues.quantityStep
                        }
                    >
                        {({ getFieldsValue }) => {
                            const { priceStep, quantityStep } = getFieldsValue()

                            return (
                                <>
                                    <Form.Item
                                        name="stepSize"
                                        label="Размер шага"
                                    >
                                        <InputNumberControl
                                            min={priceStep}
                                            step={priceStep}
                                            placeholder={priceStep || '1'}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        name="defaultPositionSize"
                                        label="Размер позиции при открытии"
                                    >
                                        <InputNumberControl
                                            min={quantityStep}
                                            step={quantityStep}
                                            placeholder={quantityStep || '1'}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        name="defaultMaxLots"
                                        label="Максимум лотов"
                                    >
                                        <InputNumberControl
                                            min={quantityStep}
                                            step={quantityStep}
                                            placeholder={quantityStep || '100'}
                                        />
                                    </Form.Item>
                                </>
                            )
                        }}
                    </Form.Item>

                    <Form.Item name="minBalance" label="Минимальный баланс">
                        <InputNumberControl placeholder="5000" min={1} />
                    </Form.Item>

                    <Form.Item
                        name="takeProfitRatio"
                        label="Шагов до тейк-профита"
                    >
                        <InputNumberControl min={1} placeholder="1" />
                    </Form.Item>

                    <Form.Item
                        name="feedCount"
                        label="Кол-во свечей для динамического тейк-профита"
                    >
                        <InputNumberControl min={1} placeholder="1" />
                    </Form.Item>

                    <Form.Item name="onlyAdmin" valuePropName="checked">
                        <CheckboxControl>
                            Только для администратора
                        </CheckboxControl>
                    </Form.Item>
                </Form>
            </Spin>
        )
    }
)
