import React from 'react'
import { ColumnsType } from 'antd/lib/table'
import { ReferrerProfitResponseModel } from 'api/http/models'
import { fixedNumber, formatDate } from 'shared/utils'

export const BINANCE_REFERRERS_PROFIT_TABLE_COLUMNS: ColumnsType<ReferrerProfitResponseModel> =
    [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: 100,
        },
        {
            title: 'Create date',
            dataIndex: 'create_date',
            key: 'create_date',
            render: formatDate,
            align: 'center',
        },
        {
            title: 'Amount (RCBC)',
            dataIndex: 'amount',
            key: 'amount',
            render: commission => fixedNumber(commission),
            align: 'center',
        },
        {
            title: 'Transaction ID',
            dataIndex: 'transaction',
            key: 'transaction',
            render: value => (
                <a
                    href={`https://${
                        process.env.NODE_ENV === 'production' ? '' : 'nile.'
                    }tronscan.org/#/transaction/${value}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {value}
                </a>
            ),
            align: 'center',
        },
    ]
