import s from './TradeTickers.module.scss'

import React, { useCallback } from 'react'
import { TRADE_TICKERS_TABLE_COLUMNS } from './TradeTickers.consts'
import { TableAdapter } from 'shared/components'
import { TradeTickersProps } from './TradeTickers.types'
import { BybitTickersModel } from 'api/http/models'

/** Таблица тикеров для страницы торговли */
export const TradeTickers: React.FC<TradeTickersProps> = React.memo(
    ({ onSettings, tickersData }) => {
        /**
         * Обработчик одиночного нажатия на строку таблицы
         * @param record - запись в таблице на которой произошел клик
         */
        const handleRowClick = useCallback(
            ({ symbol, lastPrice }: BybitTickersModel) =>
                () => {
                    if (lastPrice) {
                        onSettings({ symbol: symbol })
                    }
                },
            [onSettings]
        )

        return (
            <TableAdapter
                columns={TRADE_TICKERS_TABLE_COLUMNS}
                rowClassName={s.row}
                dataSource={tickersData}
                loading={!tickersData}
                rowKey="symbol"
                onRow={record => ({
                    onClick: handleRowClick(record),
                })}
                pagination={{
                    pageSize: 4,
                }}
            />
        )
    }
)
