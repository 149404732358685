import s from './Calculated.module.scss'

import React, { useCallback, useContext } from 'react'
import { Button, Col, Row, Space } from 'antd'
import { LoaderContext } from 'core/context'
import { TronLink } from 'core/tron'
import { CalculatedProps } from './Calculated.types'
import {
    BlockWrapper,
    QuestionIconTip,
    TextWithRefresh,
} from 'shared/components'
import cn from 'classnames'
import { fixedNumber } from 'shared/utils'
import { MAX_FEE_LIMIT } from 'core/tron/tron.consts'

/** Обсчет */
export const Calculated: React.FC<CalculatedProps> = React.memo(
    ({ serviceData, onServiceCalculatedAmount, onServiceBalance }) => {
        const { setLoader } = useContext(LoaderContext)

        const handleRefresh = useCallback(async () => {
            await onServiceCalculatedAmount()
            await onServiceBalance()
        }, [onServiceCalculatedAmount, onServiceBalance])

        /** Пересчитать доход */
        const handleCalculated = useCallback(async () => {
            try {
                setLoader(true)

                await TronLink.instanceService
                    .calc()
                    .send({ shouldPollResponse: true, feeLimit: MAX_FEE_LIMIT })
                await handleRefresh()
            } catch (e) {
                console.log(e)
            } finally {
                setLoader(false)
            }
        }, [setLoader, handleRefresh])

        /** Собрать профит с рефералов */
        const handleCalcProfit = useCallback(async () => {
            try {
                setLoader(true)

                await TronLink.instanceService
                    .calcProfit()
                    .send({ shouldPollResponse: true, feeLimit: MAX_FEE_LIMIT })
                await handleRefresh()
            } catch (e) {
                console.log(e)
            } finally {
                setLoader(false)
            }
        }, [setLoader, handleRefresh])

        /** Забрать обсчитанное */
        const handleClaimEarnings = useCallback(async () => {
            try {
                setLoader(true)

                await TronLink.instanceService
                    .claimEarnings()
                    .send({ shouldPollResponse: true, feeLimit: MAX_FEE_LIMIT })
                await handleRefresh()
            } catch (e) {
                console.log(e)
            } finally {
                setLoader(false)
            }
        }, [setLoader, handleRefresh])

        /** Обсчитать и забрать */
        const handleClaim = useCallback(async () => {
            try {
                setLoader(true)

                await TronLink.instanceService
                    .claim()
                    .send({ shouldPollResponse: true, feeLimit: MAX_FEE_LIMIT })
                await handleRefresh()
            } catch (e) {
                console.log(e)
            } finally {
                setLoader(false)
            }
        }, [setLoader, handleRefresh])

        return (
            <BlockWrapper className={s.wrapper}>
                <Space>
                    <h2>Calculations</h2>

                    <QuestionIconTip
                        title={
                            <div>
                                <div>
                                    First of all you need to calculate your
                                    profit share in ecosystem using blockchain
                                    smart contracts by clicking:
                                </div>

                                <div>
                                    1 Calculate stake button - for stake
                                    calculations, if you want to calculate it.
                                    You can skip this step if you&apos;re not
                                    about to claim your staking rewards this
                                    time
                                </div>

                                <div>
                                    2 Calculate profit button - for referral and
                                    stake calculations(if you have not skipped
                                    step 1)
                                </div>

                                <div>
                                    3 Claim - claim all the RCBC rewards from
                                    step 2
                                </div>
                            </div>
                        }
                    />
                </Space>

                <Row gutter={20} align="middle" className="mb-0">
                    <Col xs={24} sm={12}>
                        <TextWithRefresh
                            text={`Calculated earnings: ${serviceData.calculatedAmount} RCBC`}
                            onCallback={handleCalculated}
                        />

                        <div className={s.uncalculated}>
                            {`Uncalculated earnings: ${fixedNumber(
                                serviceData.balance -
                                    serviceData.calculatedAmount -
                                    serviceData.stake
                            )} RCBC`}
                        </div>
                    </Col>

                    <Col xs={24} sm={12}>
                        <Space>
                            <Button
                                onClick={handleCalcProfit}
                                className={s.button}
                            >
                                Calculate profit
                            </Button>

                            <QuestionIconTip
                                title="Calculate your profit"
                                className={s.tip}
                            />
                        </Space>

                        <Button
                            onClick={handleClaimEarnings}
                            className={cn(s.button, s.claim)}
                            disabled={!serviceData.calculatedAmount}
                        >
                            Claim earning
                        </Button>

                        <Space>
                            <Button onClick={handleClaim} className={s.button}>
                                Calculate and claim
                            </Button>

                            <QuestionIconTip
                                title="Calculate and claim your earnings in one step"
                                className={s.tip}
                            />
                        </Space>
                    </Col>
                </Row>
            </BlockWrapper>
        )
    }
)
