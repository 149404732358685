// src/pages/Main/Steps/Steps.tsx
import s from './Steps.module.scss';
import React from 'react';
import { useLanguage } from 'context/LanguageContext'; // Assuming you have a language context for managing translations
import { translations } from './translations'; // Import translations

const Steps: React.FC = React.memo(() => {
    const { language } = useLanguage();
    const { title, steps } = translations[language];

    return (
        <div className={s.wrapper}>
            <h2 className={s.title}>{title}</h2>

            <div className={s.steps}>
                {steps.map((step, i) => (
                    <div className={s.step} key={i}>
                        <div className={s.number}>{i + 1}</div>

                        <div className={s.text}>{step}</div>
                    </div>
                ))}
            </div>
        </div>
    );
});

export default Steps;
