import s from './BybitAccounts.module.scss'

import React, { useCallback, useContext, useEffect, useState } from 'react'
import { LoaderContext } from 'core/context'
import { BybitService, BybitSymbolsService } from 'api/http'
import { CheckboxControl, PageContent, TableAdapter } from 'shared/components'
import { getAccountsTableColumns } from './BybitAccounts.utils'
import { BybitDataModel, BybitUpdateDto } from 'api/http/models'
import { useFetch, useQueryParams } from 'shared/hooks'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'

/** Таблица пользователей */
export const BybitAccounts: React.FC = React.memo(() => {
    const { setLoader } = useContext(LoaderContext)
    const [fetchSymbols, symbols] = useFetch(BybitSymbolsService.getForSelect)
    const [accountsData, setAccountsData] = useState<BybitDataModel[]>()
    const { paginationOptions, queryParams, setQueryParams } = useQueryParams({
        isRun: false,
    })
    const [itemsCount, setItemsCount] = useState(0)

    /** Запрос за данными аккаунтов */
    const fetchAccounts = useCallback(async () => {
        try {
            setLoader(true)

            const {
                body: { isRun },
                limit,
                skip,
            } = queryParams

            if (isRun !== undefined) {
                const dataSource = await BybitService.search(skip, limit, {
                    isRun,
                })
                setAccountsData(dataSource.items)
                setItemsCount(dataSource.total)
            }
        } catch (e) {
            console.log(e)
        } finally {
            setLoader(false)
        }
    }, [queryParams, setLoader])

    const handleIsRun = useCallback(
        (value: CheckboxChangeEvent) => {
            setQueryParams(prevState => ({
                ...prevState,
                body: {
                    isRun: value.target.checked,
                },
            }))
        },
        [setQueryParams]
    )

    const handleMarketUpdate = useCallback(
        async (marketId: number, body: BybitUpdateDto) => {
            try {
                setLoader(true)

                await BybitService.update(marketId, body)
                await fetchAccounts()
            } catch (e) {
                console.log(e)
            } finally {
                setLoader(false)
            }
        },
        [fetchAccounts, setLoader]
    )

    useEffect(() => {
        fetchAccounts()
        fetchSymbols()
    }, [fetchAccounts, fetchSymbols])

    return (
        <PageContent>
            <div className={s.header}>
                <h2>Bybit accounts</h2>

                <CheckboxControl onChange={handleIsRun}>
                    Показать только включенные
                </CheckboxControl>
            </div>

            <TableAdapter
                columns={getAccountsTableColumns(
                    fetchAccounts,
                    handleMarketUpdate,
                    symbols
                )}
                dataSource={accountsData}
                pagination={{
                    ...paginationOptions,
                    total: itemsCount,
                }}
            />
        </PageContent>
    )
})
