import React, { useCallback, useContext } from 'react'
import { Form } from 'antd'
import { InputControl } from 'shared/components/InputControl'
import { RithmicService } from 'api/http'
import { PopupAdapterFormProps } from '../PopupAdapter.types'
import { RithmicCreateModel } from 'api/http/models'
import { SelectControl } from 'shared/components/SelectControl'
import { LoaderContext } from 'core/context'
import { RITHMIC_SYSTEM } from 'shared/consts'

/** Модалка с формой ввода ключей биржи Rithmic */
export const RithmicConnectFormModal: React.FC<PopupAdapterFormProps> =
    React.memo(({ onRequestFinish, initialValues, ...props }) => {
        const { setLoader } = useContext(LoaderContext)

        /**
         * Обработчик отправки данных на сервер
         * @param value данные формы
         */
        const handleFinish = useCallback(
            async (values: RithmicCreateModel) => {
                try {
                    setLoader(true)

                    if (!initialValues?.marketId) {
                        await RithmicService.create(values)
                    } else if (values.accessKey || values.secretKey) {
                        await RithmicService.update(
                            initialValues.marketId,
                            values
                        )
                    } else {
                        await RithmicService.delete(initialValues.marketId)
                    }

                    onRequestFinish?.()
                } catch (e) {
                    console.log(e)
                } finally {
                    setLoader(false)
                }
            },
            [onRequestFinish, setLoader, initialValues]
        )

        return (
            <Form<RithmicCreateModel>
                layout="vertical"
                onFinish={handleFinish}
                {...props}
            >
                {initialValues?.type !== 'unplug' ? (
                    <>
                        <Form.Item name="accessKey" label="Access key (Login)">
                            <InputControl placeholder="Access key (Login)" />
                        </Form.Item>

                        <Form.Item
                            name="secretKey"
                            label="Secret key (Password)"
                        >
                            <InputControl placeholder="Secret key (Password)" />
                        </Form.Item>

                        <Form.Item
                            name="system"
                            label="System"
                            initialValue="Rithmic Paper Trading"
                        >
                            <SelectControl
                                options={Object.entries(RITHMIC_SYSTEM).map(
                                    ([key, value]) => ({
                                        label: value,
                                        key: key,
                                        value: key,
                                    })
                                )}
                            />
                        </Form.Item>
                    </>
                ) : (
                    <div>Are you sure you want to disable the exchange?</div>
                )}
            </Form>
        )
    })
