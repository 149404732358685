import React from 'react'
import { Form } from 'antd'
import { InputControl } from 'shared/components/InputControl'
import { HiddenFormItemProps } from './HiddenFormItem.types'

/** Скрытое поле формы */
export const HiddenFormItem: React.FC<HiddenFormItemProps> = React.memo(
    ({ name }) => {
        return (
            <Form.Item hidden name={name}>
                <InputControl />
            </Form.Item>
        )
    }
)
