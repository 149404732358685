import dayjs from 'dayjs'
import binanceIcon from 'shared/img/binance.svg'
// import bybitIcon from 'shared/img/bybit.svg'
// import okxIcon from 'shared/img/okx.svg'
import cmeIcon from 'shared/img/CME.svg'
import eurexIcon from 'shared/img/eurex.svg'
import sgxIcon from 'shared/img/SGX.svg'

export const TIME_ZONE_OFFSET = dayjs().utcOffset() * 60

export const EXCHANGES = {
    global: [
        {
            icon: cmeIcon,
            href: 'https://www.cmegroup.com/',
        },
        {
            icon: eurexIcon,
            href: 'https://www.eurex.com/',
        },
        {
            icon: sgxIcon,
            href: 'https://www.sgx.com/',
        },
    ],
    crypto: [
        {
            icon: binanceIcon,
            href: 'https://www.binance.com/en/register?ref=39094470',
        },
        // {
        //     icon: okxIcon,
        //     href: 'https://www.okx.com/join/9154993',
        // },
        // {
        //     icon: bybitIcon,
        //     href: 'https://www.bybit.com/invite?ref=LB825P',
        // },
    ],
}
