import s from './Info.module.scss'

import React, { useCallback, useContext, useEffect, useState } from 'react'
import {
    BlockWrapper,
    QuestionIconTip,
    TextWithRefresh,
} from 'shared/components'
import { Button, Col, Row, Space } from 'antd'
import Paragraph from 'antd/lib/typography/Paragraph'
import { InfoProps } from './Info.types'
import { TronLink } from 'core/tron'
import { LoaderContext } from 'core/context'
import { fixedNumber, toRCBC } from 'shared/utils'
import { BinanceDealsService } from 'api/http'
import { MAX_FEE_LIMIT } from 'core/tron/tron.consts'

/** Общая информация */
export const Info: React.FC<InfoProps> = React.memo(
    ({ tronAddresses, serviceData, onServiceBalance, accountData }) => {
        const { setLoader } = useContext(LoaderContext)
        const [costPing, setCostPing] = useState<number>()
        const [profit, setProfit] = useState<number>()

        const getAvailableProfit = useCallback(async () => {
            try {
                if (!accountData?.id) return

                setLoader(true)

                setProfit(await BinanceDealsService.getReferrerProfit())
            } catch (e) {
                console.log(e)
            } finally {
                setLoader(false)
            }
        }, [setLoader, accountData?.id])

        const transferProfit = useCallback(async () => {
            try {
                setLoader(true)

                await BinanceDealsService.profitToReferrer()
                await getAvailableProfit()
                await onServiceBalance()
            } catch (e) {
                console.log(e)
            } finally {
                setLoader(false)
            }
        }, [setLoader, getAvailableProfit, onServiceBalance])

        const fetchCostPing = useCallback(async () => {
            setCostPing(await TronLink.instanceService?.lastFib(1).call())
        }, [])

        const handlePing = useCallback(async () => {
            try {
                setLoader(true)
                if (!costPing) return

                const dataSource = await TronLink.instanceRcbc
                    .approve(tronAddresses.service, toRCBC(costPing))
                    .send({ shouldPollResponse: true, feeLimit: MAX_FEE_LIMIT })

                if (dataSource) {
                    await TronLink.instanceService.ping().send({
                        shouldPollResponse: true,
                        feeLimit: MAX_FEE_LIMIT,
                    })
                    await fetchCostPing()
                }
            } catch (e) {
                console.log(e)
            } finally {
                setLoader(false)
            }
        }, [setLoader, tronAddresses.service, costPing, fetchCostPing])

        useEffect(() => {
            if (costPing || !tronAddresses.service) return

            fetchCostPing()
        }, [fetchCostPing, costPing, tronAddresses.service])

        useEffect(() => {
            if (!accountData?.id) return

            getAvailableProfit()
        }, [getAvailableProfit, accountData?.id])

        return (
            <BlockWrapper className={s.wrapper}>
                <Space className="mb-10">
                    <h2>Info</h2>

                    <QuestionIconTip title="After the release of the auction smart contract, you will be able to sell your account along with all referrals as NFT RCID token, and also any other NFT" />
                </Space>

                <Row gutter={20} className="mb-0">
                    <Col xs={24} md={12} className={s.general}>
                        <div>
                            <div
                                className={s.nft}
                            >{`Your NFT contract: ${tronAddresses.service}`}</div>

                            <TextWithRefresh
                                text={`Balance: ${serviceData.balance} RCBC`}
                                onCallback={onServiceBalance}
                            />
                        </div>

                        <Space className={s.ping}>
                            <Button onClick={handlePing} className={s.btn}>
                                {`Ping (${
                                    costPing ? costPing + ' RCBC' : '-'
                                })`}
                            </Button>

                            <QuestionIconTip title="Pressing the Ping button will tell the system that the account is active. Do it only if there are no active referrals or active trading on account" />
                        </Space>
                    </Col>

                    <Col xs={24} md={12}>
                        <Space>
                            <h3>Referral link</h3>

                            <QuestionIconTip title="Share your link with your friends. Users who register through your link will bring you 50% of their market commission in the RealColibri ecosystem" />
                        </Space>

                        <Paragraph copyable className={s.link}>
                            {`https://realcolibri.com?ref=${tronAddresses.tron}`}
                        </Paragraph>

                        {accountData?.id && (
                            <Row gutter={20}>
                                <Col xs={24} sm={6} xxl={4}>
                                    <Button
                                        onClick={transferProfit}
                                        type="primary"
                                        className={s.transferBtn}
                                        disabled={(profit || 0) < 10}
                                    >
                                        Transfer
                                    </Button>
                                </Col>

                                <Col xs={24} sm={18} xxl={20}>
                                    <TextWithRefresh
                                        text={`Profit for transfer: ${fixedNumber(
                                            profit || 0
                                        )} RCBC`}
                                        onCallback={getAvailableProfit}
                                    />
                                </Col>
                            </Row>
                        )}
                    </Col>
                </Row>
            </BlockWrapper>
        )
    }
)
