import { BinanceLeverageBracketResponseModel } from 'api/http/models'

/**
 * Список маркеров для тикера
 * @param brackets список плечей с параметрами
 */
export const getMarks = (
    brackets?: BinanceLeverageBracketResponseModel['brackets']
) => {
    if (!brackets) return

    const step = brackets[0].initialLeverage / 5

    const marks: { [key: number]: string } = { 1: '1x' }

    for (let i = 1; i <= 5; i++) {
        marks[step * i] = `${step * i}x`
    }

    return marks
}
