import s from './Positions.module.scss'

import React from 'react'
import { ColumnsType } from 'antd/lib/table'
import { RithmicPositionsModel } from 'api/http/models'
import { ColorWrapper } from 'shared/components'
import { Button, Tag } from 'antd'
import { FnActionRequiredProps } from 'App.types'
import { HandleCancelOrderProps } from './Positions.types'

export const positionsTableColumns = (
    onCancelOrder: FnActionRequiredProps<HandleCancelOrderProps>
): ColumnsType<RithmicPositionsModel> => [
    {
        title: 'Symbol',
        dataIndex: 'symbol',
        key: 'symbol',
    },
    {
        title: 'Size',
        dataIndex: 'quantity',
        key: 'quantity',
        render: quantity => (
            <ColorWrapper text={quantity} condition={quantity >= 0} />
        ),
        align: 'center',
    },
    {
        title: 'Entry price',
        dataIndex: 'price',
        key: 'price',
        align: 'center',
    },
    {
        title: 'PNL',
        dataIndex: 'pnl',
        key: 'pnl',
        align: 'center',
        render: pnl => <ColorWrapper text={pnl} condition={Number(pnl) >= 0} />,
    },
    {
        render: ({ symbol, quantity }) => (
            <Button
                onClick={() =>
                    onCancelOrder({ symbol, quantity: Math.abs(quantity) })
                }
                className={s.button}
            >
                <Tag>market</Tag>
            </Button>
        ),
        align: 'center',
    },
]
