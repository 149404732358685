import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import {
    createChart,
    IChartApi,
    ISeriesApi,
    LineStyle,
} from 'lightweight-charts'
import { DIAGRAM_OPTIONS, TIME_TAGS } from './TradeDiagram.consts'
import {
    ChartLinesOrdersProps,
    ChartLinesProps,
    TradeDiagramProps,
} from './TradeDiagram.types'
import s from './TradeDiagram.module.scss'
import { Spin, Tag } from 'antd'
import { RithmicTransactionTypes } from 'api/http/models'

/** Диаграмма Rithmic */
export const TradeDiagram: React.FC<TradeDiagramProps> = React.memo(
    ({
        settings,
        onSettings,
        candlestickData,
        tickerLastPrice,
        position,
        orders,
    }) => {
        const chartContainerRef = useRef<HTMLDivElement>(null)
        const [candlestickSeries, setCandlestickSeries] =
            useState<ISeriesApi<'Candlestick'>>()
        const [chartLib, setChartLib] = useState<IChartApi>()
        const chartLines = useRef<ChartLinesProps>({})
        const lastTickTimeRef = useRef(0)

        useEffect(() => {
            if (!chartContainerRef?.current) return

            const chartLib = createChart(chartContainerRef.current, {
                width: chartContainerRef.current.clientWidth,
                height: chartContainerRef.current.clientHeight,
                ...DIAGRAM_OPTIONS,
            })

            setChartLib(chartLib)
            setCandlestickSeries(chartLib.addCandlestickSeries())
        }, [])

        useEffect(() => {
            if (candlestickData?.length && candlestickSeries) {
                if (candlestickData.length === 1) {
                    if (candlestickData[0].time >= lastTickTimeRef.current) {
                        lastTickTimeRef.current = Number(
                            candlestickData[0].time
                        )
                        candlestickSeries.update(candlestickData[0])
                    }
                } else {
                    lastTickTimeRef.current = Number(
                        candlestickData[candlestickData.length - 1].time
                    )
                    candlestickSeries.setData(candlestickData)
                }
            }
        }, [candlestickData, candlestickSeries])

        useEffect(() => {
            if (!candlestickSeries) return

            const prevChartLines = chartLines.current

            if (prevChartLines?.position?.line) {
                candlestickSeries.removePriceLine(prevChartLines.position.line)
            }

            if (position?.quantity) {
                chartLines.current = {
                    ...prevChartLines,
                    position: {
                        line: candlestickSeries.createPriceLine({
                            price: position.price,
                            color: 'blue',
                            lineWidth: 1,
                            title: '',
                            axisLabelVisible: true,
                            lineStyle: LineStyle.LargeDashed,
                            lineVisible: true,
                        }),
                        ticker: position.symbol,
                    },
                }
            } else {
                if (!prevChartLines) return
                chartLines.current = {
                    ...prevChartLines,
                    position: undefined,
                }
            }
        }, [candlestickSeries, position])

        useEffect(() => {
            if (!candlestickSeries) return
            const prevChartLines = chartLines.current

            if (orders?.length) {
                const currentOrdersIds = prevChartLines?.orders?.map(
                    el => el.id
                )
                const newOpenOrdersTicker = orders.filter(
                    el => !currentOrdersIds?.includes(el.basket_id)
                )
                const currentOrders =
                    prevChartLines.orders?.reduce<ChartLinesOrdersProps[]>(
                        (acc, el) => {
                            const order = orders?.find(
                                order => order.basket_id === el.id
                            )
                            if (!order || !order.quantity) {
                                candlestickSeries.removePriceLine(el.line)
                                return acc
                            }
                            return [...acc, el]
                        },
                        []
                    ) || []
                if (newOpenOrdersTicker.length) {
                    const addLines = newOpenOrdersTicker.map(el => ({
                        line: candlestickSeries.createPriceLine({
                            price: el.price,
                            color:
                                el.transaction_type ===
                                RithmicTransactionTypes.SELL
                                    ? 'red'
                                    : 'green',
                            lineWidth: 1,
                            title: '',
                            axisLabelVisible: true,
                            lineStyle: LineStyle.LargeDashed,
                            lineVisible: true,
                        }),
                        id: el.basket_id,
                    }))
                    chartLines.current = {
                        ...prevChartLines,
                        orders: [...currentOrders, ...addLines],
                    }
                    return
                }
                chartLines.current = {
                    ...prevChartLines,
                    orders: currentOrders,
                }
            } else {
                if (!prevChartLines) return
                prevChartLines.orders?.forEach(el => {
                    candlestickSeries.removePriceLine(el.line)
                })
                chartLines.current = {
                    ...prevChartLines,
                    orders: undefined,
                }
            }
        }, [candlestickSeries, orders])

        useLayoutEffect(() => {
            const chartLibWidth = () => {
                if (!chartContainerRef.current) return
                chartLib?.resize(
                    chartContainerRef.current.clientWidth,
                    chartContainerRef.current.clientHeight
                )
            }

            window.addEventListener('resize', chartLibWidth)

            return () => {
                window.removeEventListener('resize', chartLibWidth)
            }
        }, [chartLib])

        return (
            <Spin spinning={tickerLastPrice === undefined}>
                <div className={s.header}>
                    <h1>
                        {settings.symbol?.toUpperCase()}&nbsp;
                        {tickerLastPrice || ''}
                    </h1>

                    <div className={s.tags}>
                        {TIME_TAGS.map(tag => (
                            <Tag.CheckableTag
                                key={tag.id}
                                checked={settings.period === tag.id}
                                onChange={() => {
                                    onSettings({ period: tag.id })
                                }}
                            >
                                {tag.tagName}
                            </Tag.CheckableTag>
                        ))}
                    </div>
                </div>

                <div ref={chartContainerRef} className={s.container} />
            </Spin>
        )
    }
)
