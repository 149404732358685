import React from 'react'
import { ColorWrapperProps } from './ColorWrapper.types'

/** Обертка для цветового оформления текса по условию */
export const ColorWrapper: React.FC<ColorWrapperProps> = React.memo(
    ({ text, condition, colors = ['green', 'red'] }) => {
        return (
            <span style={{ color: condition ? colors[0] : colors[1] }}>
                {text}
            </span>
        )
    }
)
