import { FnActionAsyncProps, FnActionRequiredProps } from 'App.types'
import { SettingProps } from '../BybitTrade.types'
import { BybitDataModel, BybitSymbolForSelectDto } from 'api/http/models'

export interface TradeTabsProps {
    settings: SettingProps
    onSettings: FnActionRequiredProps<Partial<SettingProps>>
    onFetchMarket: FnActionAsyncProps
    marketData?: BybitDataModel
    symbols?: BybitSymbolForSelectDto[]
}

export enum TABS_KEYS {
    COLIBRI = 'COLIBRI',
    MANUAL = 'MANUAL',
}
