import s from './LeverageModal.module.scss'

import React, { useCallback, useContext, useEffect } from 'react'
import { HiddenFormItem, SliderSingleControl } from 'shared/components'
import { PopupAdapterFormProps } from 'shared/components/popups/PopupAdapter.types'
import { Form, message, Spin } from 'antd'
import { BybitNativeService } from 'api/http'
import { getMarks, getMaximumPosition } from './LeverageModal.utils'
import { useFetch } from 'shared/hooks'
import { useForm, useWatch } from 'antd/es/form/Form'
import { LoaderContext } from 'core/context'
import { BybitChangeLeverageDto } from 'api/http/models'

/** Модалка для выбора кредитного плеча */
export const LeverageModal: React.FC<PopupAdapterFormProps> = React.memo(
    ({ initialValues, onRequestFinish, id }) => {
        const { setLoader } = useContext(LoaderContext)
        const [form] = useForm()
        const leverageField = useWatch('leverage', form)
        const [riskLimitFetch, riskLimits] = useFetch(
            BybitNativeService.riskLimit
        )

        /** Обработчик отправки данных формы */
        const handleFinish = useCallback(
            async ({
                marketId,
                ...body
            }: BybitChangeLeverageDto & { marketId: number }) => {
                try {
                    setLoader(true)
                    await BybitNativeService.changeLeverage(marketId, body)
                    message.success(
                        `Кредитное плечо ${body.symbol} изменено на ${body.leverage}х`
                    )
                    onRequestFinish?.()
                } catch (e) {
                    console.log(e)
                } finally {
                    setLoader(false)
                }
            },
            [onRequestFinish]
        )

        useEffect(() => {
            if (initialValues?.symbol) {
                riskLimitFetch(initialValues.symbol)
            }
        }, [initialValues?.symbol])

        return (
            <Spin spinning={!riskLimits}>
                <Form
                    form={form}
                    layout="vertical"
                    initialValues={initialValues}
                    onFinish={handleFinish}
                    id={id}
                >
                    <HiddenFormItem name="symbol" />

                    <HiddenFormItem name="marketId" />

                    {riskLimits && (
                        <Form.Item name="leverage" label="Leverage">
                            <SliderSingleControl
                                className={s.leverage}
                                min={1}
                                max={riskLimits.limits[0].maxLeverage}
                                step={0.01}
                                addonNumberAfter
                                marks={getMarks(riskLimits.limits)}
                            />
                        </Form.Item>
                    )}

                    {!!leverageField && riskLimits?.limits && (
                        <div>
                            Maximum position at current leverage:{' '}
                            {getMaximumPosition(
                                riskLimits.limits,
                                leverageField
                            )}{' '}
                            USDT
                        </div>
                    )}
                </Form>
            </Spin>
        )
    }
)
